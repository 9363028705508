import ProjectsInfo from "../../projectsDesktop/projectsInfo/projectsInfo";
const { MobContainer } = require("components/components");

const AddProject = () => {
  return (
    <MobContainer heading={"New Project"} addCompany>
      <div className="px-3">
        <ProjectsInfo />
      </div>
    </MobContainer>
  );
};

export default AddProject;
