import { Container } from "components/components";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FaqsData } from "constant/constant";
import { ThemeProvider, createTheme } from "@mui/material";
import Inter from "assets/font/Inter.ttf";

const theme = createTheme({
  typography: {
    fontFamily: Inter,
  },
});

const Faqs = () => {
  return (
    <Container>
      <div className="flex justify-center overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
        <div className="py-8 w-3/4">
          <p className="text-[28px] pb-8 font-extrabold">
            Frequently Asked Questions
          </p>
          {FaqsData.map((e, i) => {
            return (
              <ThemeProvider theme={theme}>
                <div key={i} className="pb-12">
                  <p className="text-lg font-semibold mt-2 pb-5">{e.title}</p>
                  {e.data.map((val, ind) => {
                    return (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{ fontSize: 14 }}>
                            {val.question}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography style={{ fontSize: 14 }}>
                            {val.answer}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </div>
              </ThemeProvider>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default Faqs;
