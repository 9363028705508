import { faEarthAmericas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import { profile_pic } from "assets/images/images";
import { useNavigate } from "react-router-dom";

const SuperAdminHeader = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        backgroundColor: "#fff",
        boxShadow: "5px -2px 4px 0px rgba(0,0,0,.25)",
      }}
      className={`z-10 flex items-center border-b-2 border-[rgba(0,0,0,.2)] px-7 justify-end sticky top-0 h-[9vh] desktop:h-[7vh] bigDesktop:h-[5vh]`}
    >
      <div className="h-10 w-10 cursor-pointer rounded-full bg-gray-100 flex items-center justify-center">
        <FontAwesomeIcon icon={faEarthAmericas} />
      </div>
      <Button className="bg-red-100 ml-6 text-red-600 !border-none hover:!text-red-600">
        Clear Cache
      </Button>
      <img
        onClick={() => navigate("/backend/account")}
        src={profile_pic}
        alt=""
        className="ml-6 cursor-pointer"
        width={37}
      />
      <div className="h-10 w-10 cursor-pointer rounded-xl border-2 border-[rgba(0,0,0,.2)] ml-6 flex items-center justify-center">
        <div className="relative">
          <div className="absolute left-3 bottom-1 h-6 w-6 rounded-full text-xs text-white flex items-center justify-center bg-orange-600">
            <p>1</p>
          </div>
        </div>
        <svg
          width="15"
          height="22"
          viewBox="0 0 17 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5 8C14.5 6.4087 13.8679 4.88258 12.7426 3.75736C11.6174 2.63214 10.0913 2 8.5 2C6.9087 2 5.38258 2.63214 4.25736 3.75736C3.13214 4.88258 2.5 6.4087 2.5 8V16H14.5V8ZM16.5 16.667L16.9 17.2C16.9557 17.2743 16.9896 17.3626 16.998 17.4551C17.0063 17.5476 16.9887 17.6406 16.9472 17.7236C16.9057 17.8067 16.8419 17.8765 16.7629 17.9253C16.6839 17.9741 16.5929 18 16.5 18H0.5C0.407144 18 0.316123 17.9741 0.237135 17.9253C0.158147 17.8765 0.094313 17.8067 0.0527866 17.7236C0.0112601 17.6406 -0.0063184 17.5476 0.00202058 17.4551C0.0103596 17.3626 0.0442865 17.2743 0.1 17.2L0.5 16.667V8C0.5 5.87827 1.34286 3.84344 2.84315 2.34315C4.34344 0.842855 6.37827 0 8.5 0C10.6217 0 12.6566 0.842855 14.1569 2.34315C15.6571 3.84344 16.5 5.87827 16.5 8V16.667ZM6 19H11C11 19.663 10.7366 20.2989 10.2678 20.7678C9.79893 21.2366 9.16304 21.5 8.5 21.5C7.83696 21.5 7.20107 21.2366 6.73223 20.7678C6.26339 20.2989 6 19.663 6 19Z"
            fill={"#000"}
          />
        </svg>
      </div>
    </div>
  );
};

export default SuperAdminHeader;
