import {
  faFilePdf,
  faShield,
  faSignature,
  faSquareCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch } from "@mui/material";

const Authorization = () => {
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        <div className="flex items-center justify-between mt-3">
          <div className="flex items-center">
            <FontAwesomeIcon
              icon={faShield}
              className="opacity-50 mr-5 text-xl"
            />
            <div>
              <p>Password Protect Invoices</p>
              <p className="text-themeGray-dark text-xs">
                If enabled the contact will be able to set a password when
                viewing their invoice
              </p>
            </div>
          </div>
          <Switch />
        </div>
        <div className="flex items-center justify-between mt-5">
          <div className="flex items-center">
            <FontAwesomeIcon
              icon={faSquareCheck}
              className="opacity-50 mr-5 text-xl"
            />
            <div>
              <p>Invoice Terms Checkbox</p>
              <p className="text-themeGray-dark text-xs">
                Reqiure clients to confirm that they accept the invoice terms
              </p>
            </div>
          </div>
          <Switch />
        </div>
        <div className="flex items-center justify-between mt-5">
          <div className="flex items-center">
            <FontAwesomeIcon
              icon={faSquareCheck}
              className="opacity-50 mr-5 text-xl"
            />
            <div>
              <p>Quote Terms Checkbox</p>
              <p className="text-themeGray-dark text-xs">
                Reqiure clients to confirm that they accept the quote terms
              </p>
            </div>
          </div>
          <Switch />
        </div>
        <div className="flex items-center justify-between mt-5">
          <div className="flex items-center">
            <FontAwesomeIcon
              icon={faSignature}
              className="opacity-50 mr-5 text-xl"
            />
            <div>
              <p>Invoice Signature</p>
              <p className="text-themeGray-dark text-xs">
                Reqiure clients to provide their signature
              </p>
            </div>
          </div>
          <Switch />
        </div>
        <div className="flex items-center justify-between mt-5">
          <div className="flex items-center">
            <FontAwesomeIcon
              icon={faSignature}
              className="opacity-50 mr-5 text-xl"
            />
            <div>
              <p>Quote Signature</p>
              <p className="text-themeGray-dark text-xs">
                Reqiure clients to provide their signature
              </p>
            </div>
          </div>
          <Switch />
        </div>
        <div className="flex items-center justify-between mt-5">
          <div className="flex items-center">
            <FontAwesomeIcon
              icon={faSignature}
              className="opacity-50 mr-5 text-xl"
            />
            <div>
              <p>Purhcase Order Signature</p>
              <p className="text-themeGray-dark text-xs">
                Reqiure vendor to provide their signature
              </p>
            </div>
          </div>
          <Switch />
        </div>
        <div className="flex items-center justify-between mt-5">
          <div className="flex items-center">
            <FontAwesomeIcon
              icon={faFilePdf}
              className="opacity-50 mr-5 text-xl"
            />
            <div>
              <p>Show on PDF</p>
              <p className="text-themeGray-dark text-xs">
                Show the client signature on the invoice/quote PDF
              </p>
            </div>
          </div>
          <Switch />
        </div>
      </div>
    </div>
  );
};

export default Authorization;
