import { useState } from "react";
import { ThemeInput, ThemeSelect } from "components/components";
import { QuestionOutlined } from "@ant-design/icons";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Col, Row, Tabs, Checkbox, Dropdown, Tooltip } from "antd";
import { arrow_icon, submenu_icon } from "assets/icons/icons";
import Switch from "@mui/material/Switch";

const items = [
  {
    key: "1",
    label: `Terms`,
    children: ``,
  },
  {
    key: "2",
    label: `Footer`,
    children: ``,
  },
  {
    key: "3",
    label: `Public Notes`,
    children: ``,
  },
  {
    key: "4",
    label: `Private Notes`,
    children: ``,
  },
  {
    key: "5",
    label: `Settings`,
    children: (
      <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }} className="mb-3">
        <Col span={12}>
          <ThemeSelect
            label={"Design"}
            data={["Design 1", "Design 2", "Design 3"]}
            className={"!mb-2"}
          />
        </Col>
        <Col span={12}>
          <ThemeSelect
            className={"!mb-2"}
            label={"User"}
            data={["User 1", "User 2", "User 3"]}
          />
        </Col>
        <Col span={12}>
          <ThemeInput placeholder={"Project"} />
        </Col>
        <Col span={12}>
          <ThemeInput placeholder={"Vendor"} />
        </Col>
        <Col span={12}>
          <ThemeInput placeholder={"Exchange Rate"} />
        </Col>
        <Col span={12}>
          <div className="flex items-center justify-between mt-3">
            <p>Auto Bill Enabled</p>
            <Switch />
          </div>
        </Col>
      </Row>
    ),
  },
  {
    key: "6",
    label: `Documents`,
    children: (
      <div className="flex items-center justify-center h-32">
        <p className="text-xl text-themeGray-dark font-semibold mb-4">
          Save the record to upload documents
        </p>
      </div>
    ),
  },
];

const CreateInvoice = () => {
  const [tab, setTab] = useState("1");
  const [recurringInvoice, setRecurringInvoice] = useState("");
  const [isCustomRecurring, setIsCustomRecurring] = useState(false);
  const onChange = (key) => {
    setTab(key);
  };
  const [itemsData, setItemsData] = useState([1]);
  const addRow = (e, i) => {
    if (e.target.value.length === 1 && i === itemsData.length - 1) {
      let update = [...itemsData];
      update.push(Math.random());
      setItemsData(update);
    }
  };
  // const removeRow = (i) => {
  //   let update = [...itemsData];
  //   update.splice(i, 1);
  //   setItemsData(update);
  //   console.log(update);
  //   console.log(i);
  // };
  const recurringOptions = [
    "Every 1 month",
    "Every 2 months",
    "Every 3 months",
    "Every 4 months",
    "Every 5 months",
    "Every 6 months",
    "Every 7 months",
    "Every 8 months",
    "Every 9 months",
    "Every 10 months",
    "Every 11 months",
    "Every 12 months",
    "Custom",
  ];
  const handleBillingType = (e) => {
    setRecurringInvoice(e);
    e === "Custom" ? setIsCustomRecurring(true) : setIsCustomRecurring(false);
  };
  return (
    <>
      <div className="grid grid-cols-2 gap-5">
        <div className="shadow-md col-span-1 flex items-end justify-between p-3">
          <ThemeInput placeholder={"Client"} />
        </div>
        <div className="shadow-md col-span-1 flex items-end justify-between p-3">
          <ThemeInput placeholder={"Invoice Number"} />
        </div>
      </div>
      <div className="shadow-md col-span-1 flex items-end justify-between p-3">
        <div className="w-full grid grid-cols-2 gap-11">
          <div className="col-span-1">
            <div className="dateDiv">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD-MM-YYYY"
                  label="Invoice Date*"
                  className="!w-full"
                  slotProps={{ textField: { variant: "filled" } }}
                />
              </LocalizationProvider>
            </div>
            {isCustomRecurring && (
              <>
                <div className="relative">
                  <Tooltip
                    className="absolute right-0 top-4 z-10"
                    title="please write a number!"
                  >
                    <QuestionOutlined style={{ fontSize: 20 }} />
                  </Tooltip>
                </div>
                <ThemeInput noPadding={"!mt-1"} placeholder={"Number"} />
              </>
            )}
            <div className="dateDiv">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD-MM-YYYY"
                  label="Due Date*"
                  className="!w-full"
                  slotProps={{ textField: { variant: "filled" } }}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="col-span-1">
            <ThemeSelect
              value={recurringInvoice}
              setValue={(e) => handleBillingType(e)}
              className={"!mt-[10px]"}
              label={"Recurring Invoice?"}
              data={recurringOptions}
            />
            {isCustomRecurring && (
              <>
                <div className="relative">
                  <Tooltip
                    className="absolute right-8 top-4 z-10"
                    title="please select duration!"
                  >
                    <QuestionOutlined style={{ fontSize: 20 }} />
                  </Tooltip>
                </div>
                <ThemeSelect
                  label={"Duration"}
                  className={"!mt-1"}
                  data={["Day(s)", "Week(s)", "Month(s)", "Years(s)"]}
                />
              </>
            )}
            <div className="flex mt-1">
              <ThemeInput placeholder={"Discount"} />
              <div className="w-36 mt-1">
                <ThemeSelect label={"Type"} data={["Percent", "Amount"]} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shadow-md mt-5">
        <Row className="bg-themeGray-medium300 py-3 pl-7 text-white">
          <Col span={3}>
            <p>Item</p>
          </Col>
          <Col span={7}>
            <p>Description</p>
          </Col>
          <Col span={3}>
            <p>Tax</p>
          </Col>
          <Col span={3}>
            <p>Unit Cost</p>
          </Col>
          <Col span={3}>
            <p>Quantity</p>
          </Col>
          <Col span={3}>
            <p>Line Total</p>
          </Col>
          <Col span={2} className="flex justify-center">
            <img src={arrow_icon} alt="" className="cursor-pointer" />
          </Col>
        </Row>
        {itemsData.map((e, i) => {
          return (
            <Row
              key={i}
              className="py-3 pl-7"
              gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}
            >
              <Col span={3}>
                <ThemeInput
                  simple
                  noPadding
                  onChange={(val) => addRow(val, i)}
                />
              </Col>
              <Col span={7}>
                <ThemeInput
                  simple
                  noPadding
                  onChange={(val) => addRow(val, i)}
                />
              </Col>
              <Col span={3}>
                <ThemeInput
                  simple
                  noPadding
                  onChange={(val) => addRow(val, i)}
                />
              </Col>
              <Col span={3}>
                <ThemeInput
                  textAlign={"end"}
                  simple
                  noPadding
                  onChange={(val) => addRow(val, i)}
                />
              </Col>
              <Col span={3}>
                <ThemeInput textAlign={"end"} value={1} simple noPadding />
              </Col>
              <Col span={3}>
                <ThemeInput
                  textAlign={"end"}
                  value={"$0.00"}
                  simple
                  noPadding
                />
              </Col>
              <Col span={2} className="flex justify-center">
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: (
                          <p className="w-32 text-base">Remove</p>
                          // <p
                          //   onClick={() => removeRow(i)}
                          //   className="w-32 text-base"
                          // >
                          //   Remove
                          // </p>
                        ),
                      },
                    ],
                  }}
                  placement="bottomLeft"
                  trigger={["click"]}
                >
                  <img
                    src={submenu_icon}
                    width={5}
                    alt=""
                    className="cursor-pointer"
                  />
                </Dropdown>
              </Col>
            </Row>
          );
        })}
        <div className="py-5 flex flex-col items-end w-[92%]">
          <div className="flex items-center pb-1 justify-between w-80 border-b-2 border-[rgba(0,0,0,.2)]">
            <p className="text-sm">Subtotal</p>
            <p className="text-sm">$0.00</p>
          </div>
          <div className="flex items-center mt-3 pb-1 justify-between w-80 border-b-2 border-[rgba(0,0,0,.2)]">
            <p className="text-sm">Balance due</p>
            <p className="text-sm">$0.00</p>
          </div>
        </div>
      </div>
      <div className="shadow-md pt-5 px-3 mt-5">
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        {(tab === "1" || tab === "2" || tab === "3" || tab === "4") && (
          <>
            <ThemeInput simple placeholder={"Note"} multiline rows={6} />
            {tab < "3" && (
              <Checkbox className="mt-2 pb-5">
                Save as default {tab === "1" ? "terms" : "footer"}
              </Checkbox>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CreateInvoice;
