import { Tabs } from "antd";
import { ThemeInput } from "components/components";
import Command from "./command/command";
import Invoice from "./invoice/invoice";

const Schedules = () => {
  const items = [
    {
      key: "1",
      label: `Command`,
      children: <Command />,
    },
    {
      key: "2",
      label: `Invoice`,
      children: <Invoice />,
    },
    {
      key: "3",
      label: `Deals`,
      children: (
        <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
          <div className="bg-white p-3 shadow-md">
            <ThemeInput
              placeholder={"Hour of day to perform automatic operations"}
            />
            <ThemeInput
              placeholder={"Send expiration reminder before (DAYS)"}
            />
          </div>
        </div>
      ),
    },
    {
      key: "4",
      label: `Payments`,
      children: (
        <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
          <div className="bg-white p-3 shadow-md">
            <ThemeInput
              placeholder={"Hour of day to perform automatic operations"}
            />
            <ThemeInput
              placeholder={"Send expiration reminder before (DAYS)"}
            />
          </div>
        </div>
      ),
    },
    {
      key: "5",
      label: `Expenses`,
      children: (
        <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
          <div className="bg-white p-3 shadow-md">
            <ThemeInput
              placeholder={"Hour of day to perform automatic operations"}
            />
          </div>
        </div>
      ),
    },
  ];
  return (
    <div className="ml-2">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default Schedules;
