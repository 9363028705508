import { Col, Row } from "antd";
import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

const Cards = () => {
  return (
    <Row gutter={[16, 16]} className="mt-3">
      <Col span={24} md={8} lg={6} xl={4}>
        <a
          href="#"
          class="block max-w-sm h-[110px] p-4 pt-2  bg-white rounded-md shadow "
        >
          <span class="mb-2 text-[12px] text-[#000] font-medium">
            Everyone's Tasks
          </span>
          <p class="text-[12px] text-[#494F59] text-center mt-3">Total</p>
          <p className="text-[#1F2226] text-[24px] text-center hover:text-[#E43958]">
            24
          </p>
        </a>
      </Col>
      <Col span={24} md={8} lg={6} xl={4}>
        <a
          href="#"
          class="block max-w-sm h-[110px] p-4 pt-2  bg-white rounded-md shadow "
        >
          <span class="mb-2 text-[12px] text-[#000] font-medium">
            Sales conversion rate
          </span>
          <div className="flex items-center justify-between mt-5 mb-3">
            <p class="text-[12px] text-[#494F59] text-center">655,3 K</p>
            <p className="text-[#1F2226] text-[24px] text-center hover:text-[#E43958] bg-[#F0F3FA] px-4 rounded-md leading-[33px]">
              54%
            </p>
            <p class="text-[12px] text-[#494F59] text-center">655,3 K</p>
          </div>
        </a>
      </Col>
      <Col span={24} md={8} lg={6} xl={4}>
        <a
          href="#"
          class="block max-w-sm h-[110px] p-4 pt-2  bg-white rounded-md shadow "
        >
          <span class="mb-2 text-[12px] text-[#000] font-medium">
            Sales conversion rate
          </span>
          <div className="flex items-center justify-center mt-3 mb-1">
            <div style={{ width: 50, height: 50, position: "relative" }}>
              <CircularProgressbar
                value={101}
                strokeWidth={11}
                // text={`${101}%`}
                styles={buildStyles({
                  rotation: 0.25,

                  strokeLinecap: "butt",

                  textSize: "20px",

                  pathTransitionDuration: 0.5,

                  pathColor: `#50b83c`,
                  textColor: "#717A89",
                  trailColor: "#bbe5b3",
                  backgroundColor: "#3e98c7",
                  trail: {
                    strokeLinecap: "butt",
                    transform: "translate(0%, 50%)",
                  },
                  text: {
                    fill: "#717A89",
                    fontSize: "20px",
                    dominantBaseline: "central",
                    textAnchor: "middle",
                  },
                })}
              />
              <div
                style={{
                  fontSize: "10px",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                }}
              >
                {`${101}%`}
              </div>
            </div>
            <div className="ml-3">
              <p class="text-[12px] text-[#494F59] font-medium">
                Sum (Excl tax)
              </p>
              <p className="text-[#1F2226] text-[24px] hover:text-[#E43958] leading-[25px]">
                72,33
                <sub class="text-[12px] text-[#494F59] font-medium">k EUR</sub>
              </p>
            </div>
          </div>
        </a>
      </Col>
      <Col span={24} md={8} lg={6} xl={4}>
        <a
          href="#"
          class="block max-w-sm h-[110px] p-4 pt-2  bg-white rounded-md shadow "
        >
          <span class="mb-2 text-[12px] text-[#000] font-medium">
            Deals closing this month
          </span>
          <div className="flex items-center justify-center mt-3 mb-1">
            <div style={{ width: 50, height: 50, position: "relative" }}>
              <CircularProgressbar
                value={63}
                strokeWidth={11}
                // text={`${63}%`}
                styles={buildStyles({
                  rotation: 0.25,

                  strokeLinecap: "butt",

                  textSize: "20px",

                  pathTransitionDuration: 0.5,

                  pathColor: `#50b83c`,
                  textColor: "#717A89",
                  trailColor: "#bbe5b3",
                  backgroundColor: "#3e98c7",
                })}
              />
              <div
                style={{
                  fontSize: "10px",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                }}
              >
                {`${63}%`}
              </div>
            </div>
            <div className="ml-3">
              <p class="text-[12px] text-[#494F59] font-medium">Sum</p>
              <p className="text-[#1F2226] text-[24px] hover:text-[#E43958] leading-[25px]">
                72,33
                <sub class="text-[12px] text-[#494F59] font-medium">k EUR</sub>
              </p>
            </div>
          </div>
        </a>
      </Col>
      <Col span={24} md={8} lg={6} xl={4}>
        <a
          href="#"
          class="block max-w-sm h-[110px] p-4 pt-2  bg-white rounded-md shadow "
        >
          <span class="mb-2 text-[12px] text-[#000] font-medium">
            Sales conversion rate
          </span>
          <div className="flex items-center justify-between mt-5 mb-3">
            <p class="text-[12px] text-[#494F59] text-center">0,00</p>
            <p className="text-[#1F2226] text-[24px] text-center hover:text-[#E43958] bg-[#F0F3FA] px-4 rounded-md leading-[33px]">
              0%
            </p>
            <p class="text-[12px] text-[#494F59] text-center">0,00</p>
          </div>
        </a>
      </Col>
      <Col span={24} md={8} lg={6} xl={4}>
        <a
          href="#"
          class="block max-w-sm h-[110px] p-4 pt-2  bg-white rounded-md shadow "
        >
          <span class="mb-2 text-[12px] text-[#000] font-medium">
            My hours this week
          </span>
          <div className="flex items-center justify-center mt-3 mb-1">
            <div style={{ width: 50, height: 50, position: "relative" }}>
              <CircularProgressbar
                value={8}
                strokeWidth={11}
                // text={`${8}%`}
                styles={buildStyles({
                  rotation: 0.25,

                  strokeLinecap: "butt",

                  textSize: "20px",

                  pathTransitionDuration: 0.5,

                  pathColor: `#50b83c`,
                  textColor: "#717A89",
                  trailColor: "#bbe5b3",
                  backgroundColor: "#3e98c7",
                })}
              />
              <div
                style={{
                  fontSize: "10px",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                }}
              >
                {`${8}%`}
              </div>
            </div>
            <div className="ml-3">
              <p class="text-[12px] text-[#494F59] font-medium">Duration</p>
              <p className="text-[#1F2226] text-[24px] hover:text-[#E43958] leading-[25px]">
                3:00
                <sub class="text-[12px] text-[#494F59] font-medium">h</sub>
              </p>
            </div>
          </div>
        </a>
      </Col>
      <Col span={24} md={8} lg={6} xl={4}>
        <a
          href="#"
          class="block max-w-sm h-[110px] p-4 pt-2  bg-white rounded-md shadow "
        >
          <span class="mb-2 text-[12px] text-[#000] font-medium">
            New leads this month
          </span>
          <div className="flex items-center justify-center mt-3 mb-1">
            <div style={{ width: 50, height: 50, position: "relative" }}>
              <CircularProgressbar
                value={0}
                strokeWidth={11}
                // text={`${0}%`}
                styles={buildStyles({
                  rotation: 0.25,

                  strokeLinecap: "butt",

                  textSize: "20px",

                  pathTransitionDuration: 0.5,

                  pathColor: `#50b83c`,
                  textColor: "#717A89",
                  trailColor: "#bbe5b3",
                  backgroundColor: "#3e98c7",
                })}
              />
              <div
                style={{
                  fontSize: "10px",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                }}
              >
                {`${0}%`}
              </div>
            </div>
            <div className="ml-3">
              <p class="text-[12px] text-[#494F59] font-medium">Total</p>
              <p className="text-[#1F2226] text-[24px] hover:text-[#E43958] leading-[25px]">
                0
                <sub class="text-[12px] text-[#494F59] font-medium">quotes</sub>
              </p>
            </div>
          </div>
        </a>
      </Col>
      <Col span={24} md={8} lg={6} xl={4}>
        <a
          href="#"
          class="block max-w-sm h-[110px] p-4 pt-2  bg-white rounded-md shadow "
        >
          <span class="mb-2 text-[12px] text-[#000] font-medium">
            Sales conversion rate
          </span>
          <div className="flex items-center mt-3 mb-1 justify-center">
            <div className="text-center">
              {/* <BiSolidDownArrow fill="#DE1818" size={30} /> */}
              <FontAwesomeIcon
                icon={faPlay}
                rotation={90}
                color="#DE1818"
                fontSize={25}
              />
              <p className="text-[#1F2226] text-[14px] hover:text-[#E43958] leading-[25px]">
                -100%
              </p>
            </div>
            <div className="ml-3">
              <p class="text-[12px] text-[#494F59] font-medium">
                Sum (Excl tax)
              </p>
              <p className="text-[#1F2226] text-[24px] hover:text-[#E43958] leading-[25px]">
                72,33
                <sub class="text-[12px] text-[#494F59] font-medium">k EUR</sub>
              </p>
            </div>
          </div>
        </a>
      </Col>
      <Col span={24} md={8} lg={6} xl={4}>
        <a
          href="#"
          class="block max-w-sm h-[110px] p-4 pt-2  bg-white rounded-md shadow "
        >
          <span class="mb-2 text-[12px] text-[#000] font-medium">
            Sales conversion rate
          </span>
          <div className="flex items-center mt-4 mb-2 justify-center">
            <div className="ml-3">
              <p class="text-[12px] text-[#494F59] font-medium">
                Sum (Excl tax)
              </p>
              <p className="text-[#1F2226] text-[24px] hover:text-[#E43958] leading-[25px]">
                72,33
                <sub class="text-[12px] text-[#494F59] font-medium">k EUR</sub>
              </p>
            </div>
          </div>
        </a>
      </Col>
      <Col span={24} md={8} lg={6} xl={4}>
        <a
          href="#"
          class="block max-w-sm h-[110px] p-4 pt-2  bg-white rounded-md shadow "
        >
          <span class="mb-2 text-[12px] text-[#000] font-medium">
            Sales conversion rate
          </span>
          <div className="flex items-center mt-4 mb-2 justify-center">
            <div className="ml-3">
              <p class="text-[12px] text-[#494F59] font-medium">
                Sum (Excl tax)
              </p>
              <p className="text-[#1F2226] text-[24px] hover:text-[#E43958] leading-[25px]">
                72,33
                <sub class="text-[12px] text-[#494F59] font-medium">k EUR</sub>
              </p>
            </div>
          </div>
        </a>
      </Col>
      <Col span={24} md={8} lg={6} xl={4}>
        <a
          href="#"
          class="block max-w-sm h-[110px] p-4 pt-2  bg-white rounded-md shadow "
        >
          <span class="mb-2 text-[12px] text-[#000] font-medium">
            Sales conversion rate
          </span>
          <div className="flex items-center mt-4 mb-2 justify-center">
            <div className="ml-3">
              <p class="text-[12px] text-[#494F59] font-medium">
                Sum (Excl tax)
              </p>
              <p className="text-[#1F2226] text-[24px] hover:text-[#E43958] leading-[25px]">
                72,33
                <sub class="text-[12px] text-[#494F59] font-medium">k EUR</sub>
              </p>
            </div>
          </div>
        </a>
      </Col>
      <Col span={24} md={8} lg={6} xl={4}>
        <a
          href="#"
          class="block max-w-sm h-[110px] p-4 pt-2  bg-white rounded-md shadow "
        >
          <span class="mb-2 text-[12px] text-[#000] font-medium">
            Sales conversion rate
          </span>
          <div className="flex items-center mt-3 mb-1 justify-center">
            <div className="text-center">
              {/* <BiSolidDownArrow fill="#DE1818" size={30} /> */}
              <FontAwesomeIcon
                icon={faPlay}
                rotation={90}
                color="#DE1818"
                fontSize={25}
              />
              <p className="text-[#1F2226] text-[14px] hover:text-[#E43958] leading-[25px]">
                -100%
              </p>
            </div>
            <div className="ml-3">
              <p class="text-[12px] text-[#494F59] font-medium">
                Sum (Excl tax)
              </p>
              <p className="text-[#1F2226] text-[24px] hover:text-[#E43958] leading-[25px]">
                72,33
                <sub class="text-[12px] text-[#494F59] font-medium">k EUR</sub>
              </p>
            </div>
          </div>
        </a>
      </Col>
    </Row>
  );
};

export default Cards;
