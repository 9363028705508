import { home_icon } from "assets/icons/icons";

const {
  faArrowsRotate,
  faEnvelope,
  faDownload,
  faPrint,
  faEllipsisVertical,
  faXmark,
} = require("@fortawesome/free-solid-svg-icons");
const { FontAwesomeIcon } = require("@fortawesome/react-fontawesome");
const {
  Container,
  FilterOptions,
  ThemeTable,
} = require("components/components");

const CustomerSales = () => {
  // Generate an array of complete month names
  const monthNames = [
    "Jan",
    "Feb",
    "March",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Updated columns with new keys for complete month names
  const columns = [
    {
      title: "Customers",
      dataIndex: "staffMember",
      key: "staffMember",
    },
    {
      title: monthNames[0], // January
      dataIndex: "month1",
      key: "month1",
    },
    {
      title: monthNames[1], // February
      dataIndex: "month2",
      key: "month2",
    },
    {
      title: monthNames[2], // March
      dataIndex: "month3",
      key: "month3",
    },
    {
      title: monthNames[3], // March
      dataIndex: "month4",
      key: "month4",
    },
    {
      title: monthNames[4], // March
      dataIndex: "month5",
      key: "month5",
    },
    {
      title: monthNames[5], // March
      dataIndex: "month6",
      key: "month6",
    },
    {
      title: monthNames[6], // March
      dataIndex: "month7",
      key: "month7",
    },
    {
      title: monthNames[7], // March
      dataIndex: "month8",
      key: "month8",
    },
    {
      title: monthNames[8], // March
      dataIndex: "month9",
      key: "month3",
    },
    {
      title: monthNames[9], // March
      dataIndex: "month10",
      key: "month10",
    },
    {
      title: monthNames[10], // March
      dataIndex: "month11",
      key: "month11",
    },
    {
      title: monthNames[11], // March
      dataIndex: "month12",
      key: "month12",
    },
    // ... Repeat for the rest of the months ...
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
  ];

  // Updated data with new data indices for complete month names
  const data = [
    {
      key: "1",
      staffMember: "Lewis inc",
      month1: "",
      month2: "",
      month3: "",
      month4: "",
      month5: "",
      month6: "",
      month7: "$584",
      month8: "",
      month9: "",
      month10: "",
      month11: "",
      month12: "",
      total: "$584",
    },
    {
      key: "2",
      staffMember: "Lets do business",
      month1: "$5,400,00",
      month2: "",
      month3: "",
      month4: "",
      month5: "",
      month6: "",
      month7: "$584",
      month8: "",
      month9: "",
      month10: "",
      month11: "",
      month12: "",
      total: "$584",
    },
    {
      key: "3",
      staffMember: "Lewis inc",
      month1: "",
      month2: "$5,400,00",
      month3: "",
      month4: "",
      month5: "",
      month6: "",
      month7: "$584",
      month8: "",
      month9: "",
      month10: "",
      month11: "",
      month12: "",
      total: "$5,400,00",
    },
    {
      key: "4",
      staffMember: "Lewis inc",
      month1: "",
      month2: "",
      month3: "",
      month4: "",
      month5: "",
      month6: "",
      month7: "$584",
      month8: "",
      month9: "",
      month10: "",
      month11: "",
      month12: "",
      total: "$584",
    },
    {
      key: "5",
      staffMember: "Lets do business",
      month1: "",
      month2: "",
      month3: "",
      month4: "",
      month5: "",
      month6: "",
      month7: "$584",
      month8: "",
      month9: "",
      month10: "",
      month11: "",
      month12: "",
      total: "$584",
    },
  ];

  const handleRowClick = (record) => {
    console.log("Clicked row:", record);
  };

  const handleDataChange = (updatedData) => {
    console.log("Updated data:", updatedData);
  };
  return (
    <Container>
      <div className="flex">
        <div className="w-full overflow-y-scroll h-[83vh] desktop:h-[87vh] bigDesktop:h-[91vh] scrol">
          <div className="bg-white" style={{ top: 54 }}>
            <div className="flex items-center justify-between px-5 py-3">
              <div className="flex items-center">
                <p className="font-medium text-sm">Company</p>
                <img src={home_icon} alt="" className="mr-3 ml-8" />
                <p className="font-medium text-sm">
                  Home
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    Report
                  </a>
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    Default
                  </a>
                </p>
              </div>
              <div className="text-end pe-5">
                <div className="flex items-center">
                  <div className="me-4">
                    <FontAwesomeIcon icon={faArrowsRotate} />
                  </div>
                  <p className="p-0 m-0 text-gray-700">
                    Data refreshed at jul 18, 2023 04:06 PM
                  </p>
                  <div className="flex ">
                    <div className="ms-4">
                      <FontAwesomeIcon icon={faEnvelope} className="fs-1" />
                    </div>
                    <div className="ms-4">
                      <FontAwesomeIcon icon={faDownload} />
                    </div>
                    <div className="ms-4">
                      <FontAwesomeIcon icon={faPrint} />
                    </div>
                  </div>
                  <FontAwesomeIcon
                    icon={faEllipsisVertical}
                    className="fs-1 mx-4"
                  />
                  <FontAwesomeIcon icon={faXmark} className="fs-1" />
                </div>
              </div>
            </div>
            <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
          </div>
          <FilterOptions onTop/>
          <div className="mt-7">
            <div className="px-4">
              <div className="font-bold text-xl mb-2">Customers Sales</div>
              <div className="rounded border shadow-lg w-full mb-5">
                <ThemeTable
                  columns={columns}
                  data={data}
                  onClick={handleRowClick}
                  setData={handleDataChange}
                  density="default"
                  noSelectRows={true}
                  noPagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CustomerSales;
