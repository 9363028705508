import ProductsDesktop from "./productsDesktop/productsDesktop";
import ProductsMobile from "./productsMobile/productsMobile";

const Products = () => {
  return (
    <>
      <div className="hidden xl:block">
        <ProductsDesktop />
      </div>
      <div className="block xl:hidden">
        <ProductsMobile />
      </div>
    </>
  );
};

export default Products;
