const LeadsData = [
  {
    key: 1,
    id: `01`,
    name: "ABC name",
    company: "ABC Company",
    email: `example@gmail.com`,
    phone: `02345678`,
    stage: `ABC Stage`,
    source: `ABC Source`,
    last_contact: `15-05-2023`,
  },
  {
    key: 2,
    id: `01`,
    name: "ABC name",
    company: "ABC Company",
    email: `example@gmail.com`,
    phone: `02345678`,
    stage: `ABC Stage`,
    source: `ABC Source`,
    last_contact: `15-05-2023`,
  },
  {
    key: 3,
    id: `01`,
    name: "ABC name",
    company: "ABC Company",
    email: `example@gmail.com`,
    phone: `02345678`,
    stage: `ABC Stage`,
    source: `ABC Source`,
    last_contact: `15-05-2023`,
  },
  {
    key: 4,
    id: `01`,
    name: "ABC name",
    company: "ABC Company",
    email: `example@gmail.com`,
    phone: `02345678`,
    stage: `ABC Stage`,
    source: `ABC Source`,
    last_contact: `15-05-2023`,
  },
  {
    key: 5,
    id: `01`,
    name: "ABC name",
    company: "ABC Company",
    email: `example@gmail.com`,
    phone: `02345678`,
    stage: `ABC Stage`,
    source: `ABC Source`,
    last_contact: `15-05-2023`,
  },
  {
    key: 6,
    id: `01`,
    name: "ABC name",
    company: "ABC Company",
    email: `example@gmail.com`,
    phone: `02345678`,
    stage: `ABC Stage`,
    source: `ABC Source`,
    last_contact: `15-05-2023`,
  },
  {
    key: 7,
    id: `01`,
    name: "ABC name",
    company: "ABC Company",
    email: `example@gmail.com`,
    phone: `02345678`,
    stage: `ABC Stage`,
    source: `ABC Source`,
    last_contact: `15-05-2023`,
  },
  {
    key: 8,
    id: `01`,
    name: "ABC name",
    company: "ABC Company",
    email: `example@gmail.com`,
    phone: `02345678`,
    stage: `ABC Stage`,
    source: `ABC Source`,
    last_contact: `15-05-2023`,
  },
  {
    key: 9,
    id: `01`,
    name: "ABC name",
    company: "ABC Company",
    email: `example@gmail.com`,
    phone: `02345678`,
    stage: `ABC Stage`,
    source: `ABC Source`,
    last_contact: `15-05-2023`,
  },
  {
    key: 10,
    id: `01`,
    name: "ABC name",
    company: "ABC Company",
    email: `example@gmail.com`,
    phone: `02345678`,
    stage: `ABC Stage`,
    source: `ABC Source`,
    last_contact: `15-05-2023`,
  },
  {
    key: 11,
    id: `01`,
    name: "ABC name",
    company: "ABC Company",
    email: `example@gmail.com`,
    phone: `02345678`,
    stage: `ABC Stage`,
    source: `ABC Source`,
    last_contact: `15-05-2023`,
  },
  {
    key: 12,
    id: `01`,
    name: "ABC name",
    company: "ABC Company",
    email: `example@gmail.com`,
    phone: `02345678`,
    stage: `ABC Stage`,
    source: `ABC Source`,
    last_contact: `15-05-2023`,
  },
  {
    key: 13,
    id: `01`,
    name: "ABC name",
    company: "ABC Company",
    email: `example@gmail.com`,
    phone: `02345678`,
    stage: `ABC Stage`,
    source: `ABC Source`,
    last_contact: `15-05-2023`,
  },
  {
    key: 14,
    id: `01`,
    name: "ABC name",
    company: "ABC Company",
    email: `example@gmail.com`,
    phone: `02345678`,
    stage: `ABC Stage`,
    source: `ABC Source`,
    last_contact: `15-05-2023`,
  },
  {
    key: 15,
    id: `01`,
    name: "ABC name",
    company: "ABC Company",
    email: `example@gmail.com`,
    phone: `02345678`,
    stage: `ABC Stage`,
    source: `ABC Source`,
    last_contact: `15-05-2023`,
  },
  {
    key: 16,
    id: `01`,
    name: "ABC name",
    company: "ABC Company",
    email: `example@gmail.com`,
    phone: `02345678`,
    stage: `ABC Stage`,
    source: `ABC Source`,
    last_contact: `15-05-2023`,
  },
  {
    key: 17,
    id: `01`,
    name: "ABC name",
    company: "ABC Company",
    email: `example@gmail.com`,
    phone: `02345678`,
    stage: `ABC Stage`,
    source: `ABC Source`,
    last_contact: `15-05-2023`,
  },
  {
    key: 18,
    id: `01`,
    name: "ABC name",
    company: "ABC Company",
    email: `example@gmail.com`,
    phone: `02345678`,
    stage: `ABC Stage`,
    source: `ABC Source`,
    last_contact: `15-05-2023`,
  },
  {
    key: 19,
    id: `01`,
    name: "ABC name",
    company: "ABC Company",
    email: `example@gmail.com`,
    phone: `02345678`,
    stage: `ABC Stage`,
    source: `ABC Source`,
    last_contact: `15-05-2023`,
  },
  {
    key: 20,
    id: `01`,
    name: "ABC name",
    company: "ABC Company",
    email: `example@gmail.com`,
    phone: `02345678`,
    stage: `ABC Stage`,
    source: `ABC Source`,
    last_contact: `15-05-2023`,
  },
];

const LeadsMobData = [];

export { LeadsData, LeadsMobData };
