import React, { useState } from "react";
import { Modal } from "antd";
import { ThemeInput, ThemeSelect } from "components/components";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const ThemeFlagSelect = () => {
  const { value, setValue } = useState("");
  const object = [
    {
      title: "Saudi Arabia",
      flag: "https://cdn.britannica.com/79/5779-050-46C999AF/Flag-Saudi-Arabia.jpg",
    },
    {
      title: "English",
      flag: "https://cdn.britannica.com/29/22529-004-ED1907BE/Union-Flag-Cross-St-Andrew-of-George.jpg",
    },
  ];
  const handleChange = (event) => {
    // setValue(event.target.value);
  };
  return (
    <FormControl variant="standard" sx={{ minWidth: "100%" }}>
      <InputLabel id="demo-simple-select-standard-label">Flag</InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={value}
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {object.map((e, i) => {
          return (
            <MenuItem key={i} value={e.flag}>
              <img width={20} src={e.flag} className="mr-2" /> {e.title}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const AddLangModal = ({ isModalOpen, setIsModalOpen, title }) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      title={title}
      okText={"Save"}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <ThemeInput placeholder={"Name"} />
      <ThemeInput placeholder={"Language Code"} />
      <ThemeFlagSelect />
      <ThemeSelect
        className={"!mt-3"}
        label={"Status"}
        data={["Disabled", "Enabled"]}
      />
    </Modal>
  );
};

export default AddLangModal;
