import { home_icon } from "assets/icons/icons";

const Summary = () => {
  return (
    <div>
      <div className="shadow-md mt-2 mx-2 flex items-center flex-col justify-center h-[90px]">
        <p className="text-2xl font-bold text-[rgba(0,0,0,.8)]">
          Lets do international Business BV
        </p>
        <p className="text-lg font-semibold text-[rgba(0,0,0,.8)]">
          Bert van Duivendijk
        </p>
      </div>
      <div className="grid grid-cols-3 gap-4 shadow-md mt-2 mx-2 py-4 px-3">
        <div className="col-span-1 border-[1px] border-[rgba(0,0,0,.1)] rounded-lg h-[110px] flex flex-col justify-center items-center">
          <p className="text-2xl font-bold text-[rgba(0,0,0,.8)]">10</p>
          <p className="text-sm font-medium mt-1 text-[rgba(0,0,0,.8)]">
            Deals
          </p>
        </div>
        <div className="col-span-1 border-[1px] border-[rgba(0,0,0,.1)] rounded-lg h-[110px] flex flex-col justify-center items-center">
          <p className="text-2xl font-bold text-[rgba(0,0,0,.8)]">$25k</p>
          <p className="text-sm font-medium mt-1 text-[rgba(0,0,0,.8)]">
            Purchases
          </p>
        </div>
        <div className="col-span-1 border-[1px] border-[rgba(0,0,0,.1)] rounded-lg h-[110px] flex flex-col justify-center items-center">
          <p className="text-2xl font-bold text-[rgba(0,0,0,.8)]">$85k</p>
          <p className="text-sm font-medium mt-1 text-[rgba(0,0,0,.8)]">
            Life Time Value (LTV)
          </p>
        </div>
        <div className="col-span-1 border-[1px] border-[rgba(0,0,0,.1)] rounded-lg h-[110px] flex flex-col justify-center items-center">
          <p className="text-2xl font-bold text-[rgba(0,0,0,.8)]">25 Days</p>
          <p className="text-sm font-medium mt-1 text-[rgba(0,0,0,.8)]">
            Last Contact
          </p>
        </div>
        <div className="col-span-1 border-[1px] border-[rgba(0,0,0,.1)] rounded-lg h-[110px] flex flex-col justify-center items-center">
          <p className="text-2xl font-bold text-[rgba(0,0,0,.8)]">90 Days</p>
          <p className="text-sm font-medium mt-1 text-[rgba(0,0,0,.8)]">
            Last Order
          </p>
        </div>
        <div className="col-span-1 border-[1px] border-[rgba(0,0,0,.1)] rounded-lg h-[110px] flex flex-col justify-center items-center">
          <p className="text-2xl font-bold text-[rgba(0,0,0,.8)]">111</p>
          <p className="text-sm font-medium mt-1 text-[rgba(0,0,0,.8)]">
            RFM Score
          </p>
        </div>
      </div>
      <div className="shadow-md mt-2 px-3 mx-2">
        <div className="flex items-center h-14 border-b-[1px] border-[rgba(0,0,0,.1)]">
          <img src={home_icon} alt="" />
          <p className="font-bold text-sm ml-1">Details</p>
        </div>
        <div className="flex pb-4">
          <div className="pt-3 ml-6 w-44">
            <p className="text-lightBlack text-sm">Company name</p>
            <p className="text-lightBlack text-sm mt-3">Address</p>
            <p className="text-lightBlack text-sm mt-3">Postal Code</p>
            <p className="text-lightBlack text-sm mt-3">City</p>
            <p className="text-lightBlack text-sm mt-3">Province / State</p>
            <p className="text-lightBlack text-sm mt-3">Country</p>
          </div>
          <div className="pt-3">
            <p className="text-lightBlack text-sm">Let dop business</p>
            <p className="text-lightBlack text-sm mt-3">Myhsteret 16</p>
            <p className="text-lightBlack text-sm mt-3">8535 OK</p>
            <p className="text-lightBlack text-sm mt-3">Amsterdam</p>
            <p className="text-lightBlack text-sm mt-3">Zuid holland</p>
            <p className="text-lightBlack text-sm mt-3">Netherlands</p>
          </div>
        </div>
      </div>
      {/* <div className="shadow-md mt-2 mb-5 px-3 mx-2">
        <div className="flex items-center h-14 border-b-[1px] border-[rgba(0,0,0,.1)]">
          <img src={home_icon} alt="" />
          <p className="font-bold text-sm ml-1">Contact Info</p>
        </div>
        <div className="flex pb-4">
          <div className="pt-3 ml-6 w-44">
            <p className="text-lightBlack text-sm">Email</p>
            <p className="text-lightBlack text-sm mt-3">Website</p>
            <p className="text-lightBlack text-sm mt-3">Phone</p>
            <p className="text-lightBlack text-sm mt-3">Fax machine</p>
            <p className="text-lightBlack text-sm mt-3">Coc</p>
            <p className="text-lightBlack text-sm mt-3">Vat number</p>
            <p className="text-lightBlack text-sm mt-3">Industry</p>
            <p className="text-lightBlack text-sm mt-3">Sector</p>
            <p className="text-lightBlack text-sm mt-3">Size</p>
          </div>
          <div className="pt-3">
            <p className="text-lightBlack text-sm">test@google.nl</p>
            <p className="text-lightBlack text-sm mt-3">www.google.nl</p>
            <p className="text-lightBlack text-sm mt-3">+31 020-32647874</p>
            <p className="text-lightBlack text-sm mt-3">+31 020-5741126</p>
            <p className="text-lightBlack text-sm mt-3">123345356</p>
            <p className="text-lightBlack text-sm mt-3">142342435356</p>
            <p className="text-lightBlack text-sm mt-3">Media</p>
            <p className="text-lightBlack text-sm mt-3">Marketing</p>
            <p className="text-lightBlack text-sm mt-3">25</p>
          </div>
        </div>
      </div> */}
      <div className="shadow-md mt-2 mb-5 px-3 mx-2">
        <div className="flex items-center h-14 border-b-[1px] border-[rgba(0,0,0,.1)]">
          <img src={home_icon} alt="" />
          <p className="font-bold text-sm ml-1">Quick create</p>
        </div>
        <div className="flex pb-4 items-center justify-between">
          <div className="pt-3 ml-6">
            <p className="text-lightBlack text-sm">Create task</p>
            <p className="text-lightBlack text-sm mt-3">Create project</p>
            <p className="text-lightBlack text-sm mt-3">Edit task list</p>
            <p className="text-lightBlack text-sm mt-3">Assign users</p>
            <p className="text-lightBlack text-sm mt-3">Create team</p>
          </div>
          <div className="pt-3">
            <p className="text-gray-400 text-sm">2 Active</p>
            <p className="text-gray-400 text-sm mt-3">2 Active</p>
            <p className="text-gray-400 text-sm mt-3">2 Active</p>
            <p className="text-gray-400 text-sm mt-3">2 Active</p>
            <p className="text-gray-400 text-sm mt-3">2 Active</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
