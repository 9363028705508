import { Button, Input } from "antd";
import { Editor } from "react-draft-wysiwyg";

const ESign = () => {
  return (
    <div className="bg-themeGray-light200 p-3 mt-4 min-h-[73.8vh] desktop:min-h-[79vh] bigDesktop:min-h-[87vh]">
      <div className="bg-white p-3 shadow-md">
        <p className="text-bgBlue">Signature</p>
        <p className="text-sm mt-1">
          #name# #proposal# #company# #phone# #mobile# #fax# #website# #address#
          #street# #zip# #city# #country# #email# #description#
        </p>
        <div
          style={{ minHeight: 250 }}
          className="border-[1px] mb-5 mt-7 border-[rgba(0,0,0,.1)]"
        >
          <Editor
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName px-3"
          />
        </div>
        <Button>Preview {`>>`}</Button>
        <p className="text-sm mt-1 opacity-50">
          Click on the dynamic fields (e.g. #name#) on the left to add them to
          your signature, then click on the{" "}
          <span className="font-bold">Preview</span> button to see what they
          will look like in your e-mail.
        </p>
        <Input.TextArea rows={8} className="mt-4" />
      </div>
    </div>
  );
};

export default ESign;
