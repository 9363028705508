import { useState } from "react";
import { Switch } from "@mui/material";
import { Button } from "antd";
import { settings_white_icon } from "assets/icons/icons";
import CreateExpense from "./createExpense/createExpense";

const Expense = () => {
  const [configure, setConfigure] = useState(false);
  return (
    <div className="mt-3">
      {configure && <CreateExpense />}
      {!configure && (
        <div className="bg-themeGray-light200 p-3 mt-4 min-h-[73.8vh] desktop:h-[79vh] bigDesktop:h-[87vh]">
          <div className="bg-white p-3 shadow-md">
            <div className="flex items-center justify-between my-5">
              <div>
                <p className="text-sm">Should be Invoinced</p>
                <p className="text-themeGray-dark text-xs">
                  Enable the expense to be invoinced to your client
                </p>
              </div>
              <Switch />
            </div>
            <div className="flex items-center justify-between my-5">
              <div>
                <p className="text-sm">Mark Paid</p>
                <p className="text-themeGray-dark text-xs">
                  Track the expense has been paid to the vendor
                </p>
              </div>
              <Switch />
            </div>
            <div className="flex items-center justify-between my-5">
              <div>
                <p className="text-sm">Convert Currency</p>
                <p className="text-themeGray-dark text-xs">
                  Set an exchange rate when creating an expense
                </p>
              </div>
              <Switch />
            </div>
            <div className="flex items-center justify-between my-5">
              <div>
                <p className="text-sm">Add Documents to Invoice</p>
                <p className="text-themeGray-dark text-xs">
                  Make the documents visible to clients
                </p>
              </div>
              <Switch />
            </div>
            <div className="flex items-center justify-between my-5">
              <div>
                <p className="text-sm">Notify Vendor When Paid</p>
                <p className="text-themeGray-dark text-xs">
                  Send an email to the vendor when the expense is marked as paid
                </p>
              </div>
              <Switch />
            </div>
          </div>
          <Button
            onClick={() => setConfigure(true)}
            type="primary"
            className="w-full mt-4 h-10 flex items-center justify-center"
          >
            <img src={settings_white_icon} className="mr-3" />
            CONFIGURE CATEGORIES
          </Button>
        </div>
      )}
    </div>
  );
};

export default Expense;
