import { Button, Col, Row } from "antd";
import { delete_icon } from "assets/icons/icons";
import { receipt } from "assets/images/images";
import { NavLink } from "react-router-dom";

const OverView = () => {
  return (
    <div className="z-0">
      <Row>
        <Col span={14} className="">
          <div className="shadow-md mt-2 mx-2 flex flex-col justify-center px-3 h-[120px]">
            <div className="flex items-end justify-between">
              <div>
                <p className="text-base text-themeGray-dark mb-1">Amount</p>
                <p className="text-3xl font-medium text-[rgba(0,0,0,.8)]">
                  $66.00
                </p>
              </div>
              <div
                className={`bg-themeGreen mb-1 text-white h-7 w-24 text-sm flex justify-center items-center rounded-[5px]`}
              >
                <p>Paid</p>
              </div>
            </div>
          </div>
          <div className="shadow-md mt-2 mx-2 flex flex-col justify-center p-3">
            <p className="mb-4 font-medium text-base">Description</p>
            <div>
              <p className="text-base font-medium">
                Amount: <span className="text-red-500">$418.00</span>
              </p>
              <p className="text-sm font-medium text-themeGray-dark mb-1">
                Paid via bank
              </p>
            </div>
            <div className="mt-1">
              <p className="text-base font-medium">
                Tax 1: <span className="text-themeGray-dark">5.00% (TAX3)</span>
              </p>
              <p className="text-base font-medium text-red-500 mb-1">
                Total with tax: $438.90
              </p>
            </div>
            <div className="mt-1">
              <p className="text-base font-medium">
                Date: <span className="text-themeGray-dark">05-08-2023</span>
              </p>
            </div>
            <div className="mt-2">
              <p className="text-base font-medium">
                Ref #: <span className="text-themeGray-dark">1044</span>
              </p>
            </div>
          </div>
          <div className="shadow-md mt-2 mx-2 flex items-end justify-between p-3">
            <div>
              <p className="text-base font-medium">History</p>
              <p className="text-xs text-themeGray-dark mb-1">
                03.26.2015 11:22pm
              </p>
            </div>
            <div className="mb-1">
              <p className="text-xs font-medium italic">
                Expense Created for $11.75 -{" "}
                <span className="font-normal text-themeGray-dark mb-1">
                  Tricia Boyle
                </span>
              </p>
            </div>
          </div>
        </Col>
        <Col span={10}>
          <div className="p-3 mx-2 mt-2 shadow-lg">
            <img src={receipt} alt="" width={"100%"} height={"auto"} />
            <div className="flex items-center justify-between mt-2">
              <NavLink className="text-bgBlue">Change Receipt</NavLink>
              <img
                src={delete_icon}
                alt=""
                width={15}
                className="cursor-pointer"
              />
            </div>
          </div>
        </Col>
      </Row>

      <div className="shadow-md mt-2 p-3 mx-2">
        <p className="text-base font-medium">Note:</p>
        <p className="text-sm mt-1 font-normal mb-1">
          Two friends reunited at a café, sharing laughter and heartfelt
          conversations that deepened their bond. Time seemed to freeze as they
          exchanged stories, support, and advice.
        </p>
      </div>
      <div className="my-5 flex justify-around mx-2">
        <Button className="h-[90px] w-2/4 shadow-md !border-[1px] !border-[rgba(0,0,0,.05)] text-lg font-medium">
          Invoice expense
        </Button>
        <Button className="h-[90px] w-2/4 shadow-md !border-[1px] !border-[rgba(0,0,0,.05)] text-primary text-lg font-medium">
          Clone to expense
        </Button>
      </div>
    </div>
  );
};

export default OverView;
