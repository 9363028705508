import CreditsDesktop from "./creditsDesktop/creditsDesktop";
import CreditsMobile from "./creditsMobile/creditsMobile";

const Credits = () => {
  return (
    <>
      <div className="hidden xl:block">
        <CreditsDesktop />
      </div>
      <div className="block xl:hidden">
        <CreditsMobile />
      </div>
    </>
  );
};

export default Credits;
