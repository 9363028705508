import { ThemeInput } from "components/components";

const Customize = () => {
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        <ThemeInput multiline rows={4} placeholder={"Header"} />
        <ThemeInput multiline rows={4} placeholder={"Footer"} />
        <ThemeInput multiline rows={4} placeholder={"Custom CSS"} />
        <ThemeInput multiline rows={4} placeholder={"Custom JavaScript"} />
      </div>
    </div>
  );
};

export default Customize;
