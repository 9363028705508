import { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const ThemeSelect = ({
  label,
  data,
  className,
  width,
  setValue,
  value,
  object,
  disabled,
}) => {
  const [age, setAge] = useState("");
  const handleChange = (event) => {
    setValue ? setValue(event.target.value) : setAge(event.target.value);
  };
  return (
    <FormControl
      className={className}
      variant="standard"
      sx={{ minWidth: width ? width : "100%" }}
    >
      <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
      <Select
        disabled={disabled}
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={value ? value : age}
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {!object &&
          data.map((e, i) => {
            return (
              <MenuItem key={i} value={e}>
                {e}
              </MenuItem>
            );
          })}
        {object &&
          object.map((e, i) => {
            return (
              <MenuItem key={i} value={e.color}>
                <div
                  style={{ backgroundColor: e.color }}
                  className={`h-3 w-3 mr-2 rounded-full`}
                ></div>{" "}
                {e.title}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

export default ThemeSelect;
