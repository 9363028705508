import { useState } from "react";
import { Form, Button, message } from "antd";
import { myscrm } from "assets/images/images";
import { google_icon } from "assets/icons/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { ThemeInput } from "components/components";

const LoginMob = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleLogin = () => {
    if (email && password) {
      if (
        email === "backend@gmail.com" ||
        email === "app@gmail.com" ||
        email === "client@gmail.com"
      ) {
        localStorage.setItem("User", email);
        email === "backend@gmail.com" && navigate("/backend/dashboard");
        email === "app@gmail.com" && navigate("/app/dashboard");
        email === "client@gmail.com" && navigate("/client/dashboard");
      } else {
        message.error("User not found!");
      }
    }
  };
  return (
    <div className="flex flex-col justify-center min-h-screen">
      <img src={myscrm} alt="" className="absolute top-6 right-2" />
      <div className="w-full px-4 py-3">
        <p className="text-2xl font-bold mt-2 pb-3">Login</p>
        <Button className="flex items-center h-12 bg-gray-100 hover:!text-black w-full justify-center">
          <img src={google_icon} alt="" width={22} />
          <p className="ml-1 font-semibold text-lg">Google</p>
        </Button>
        <div className="flex items-center justify-between mt-5">
          <div className="border-[1px] w-full border-gray-300"></div>
          <div className="w-full text-center">
            <p className="text-gray-500 text-sm">Or log in with email</p>
          </div>
          <div className="border-[1px] w-full border-gray-300"></div>
        </div>
        <Form name="normal_login" className="login-form w-full mt-2">
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: `Please input your Email!`,
              },
            ]}
          >
            <ThemeInput
              noPadding
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={"Email"}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <ThemeInput
              type={"password"}
              noPadding
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={"Password"}
            />
          </Form.Item>
          <Form.Item>
            <Button
              onClick={handleLogin}
              type="primary"
              htmlType="submit"
              className="h-12 bg-primary !w-full font-semibold text-lg"
            >
              Log in
            </Button>
          </Form.Item>
          <Form.Item className="-mt-3">
            <NavLink className="text-primary text-xs">Forget Password?</NavLink>
          </Form.Item>
          <Form.Item className="-mt-8">
            <NavLink className="text-black text-xs">No account yet?</NavLink>
            <NavLink className="text-primary text-xs ml-1">
              Get a free trial
            </NavLink>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LoginMob;
