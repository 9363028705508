import React from "react";
import ReactApexChart from "react-apexcharts";

class ApexChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Series-1",
          data: [44, 55, 57, 56, 61, 58, 63],
          color: "#8FC0EE",
        },
        {
          name: "Series-2",
          data: [76, 85, 101, 98, 87, 105, 91],
          color: "#5F5F63   ",
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "90%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ["2001", "2002", "2003", "2004", "2005", "2006", "2007"],
        },
        fill: {
          opacity: 1,
          colors: ["#8FC0EE", "#5F5F63"],
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val + " thousands";
            },
          },
        },
      },
    };
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={350}
        />
      </div>
    );
  }
}

export default ApexChart;
