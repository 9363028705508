const InvoiceSuperData = [
  {
    key: 1,
    id: `01`,
    company: "ABC Company",
    package: `Basic`,
    payment_date: `15-05-2023`,
    next_payment_date: `20-05-2023`,
    amount: `$200`,
    payment_gateway: `Paypal`,
    status: `Paid`,
  },
  {
    key: 2,
    id: `01`,
    company: "ABC Company",
    package: `Basic`,
    payment_date: `15-05-2023`,
    next_payment_date: `20-05-2023`,
    amount: `$200`,
    payment_gateway: `Paypal`,
    status: `Sent`,
  },
  {
    key: 3,
    id: `01`,
    company: "ABC Company",
    package: `Basic`,
    payment_date: `15-05-2023`,
    next_payment_date: `20-05-2023`,
    amount: `$200`,
    payment_gateway: `Paypal`,
    status: `Draft`,
  },
  {
    key: 4,
    id: `01`,
    company: "ABC Company",
    package: `Basic`,
    payment_date: `15-05-2023`,
    next_payment_date: `20-05-2023`,
    amount: `$200`,
    payment_gateway: `Paypal`,
    status: `Paid`,
  },
  {
    key: 5,
    id: `01`,
    company: "ABC Company",
    package: `Basic`,
    payment_date: `15-05-2023`,
    next_payment_date: `20-05-2023`,
    amount: `$200`,
    payment_gateway: `Paypal`,
    status: `Sent`,
  },
  {
    key: 6,
    id: `01`,
    company: "ABC Company",
    package: `Basic`,
    payment_date: `15-05-2023`,
    next_payment_date: `20-05-2023`,
    amount: `$200`,
    payment_gateway: `Paypal`,
    status: `Draft`,
  },
  {
    key: 7,
    id: `01`,
    company: "ABC Company",
    package: `Basic`,
    payment_date: `15-05-2023`,
    next_payment_date: `20-05-2023`,
    amount: `$200`,
    payment_gateway: `Paypal`,
    status: `Paid`,
  },
  {
    key: 8,
    id: `01`,
    company: "ABC Company",
    package: `Basic`,
    payment_date: `15-05-2023`,
    next_payment_date: `20-05-2023`,
    amount: `$200`,
    payment_gateway: `Paypal`,
    status: `Sent`,
  },
  {
    key: 9,
    id: `01`,
    company: "ABC Company",
    package: `Basic`,
    payment_date: `15-05-2023`,
    next_payment_date: `20-05-2023`,
    amount: `$200`,
    payment_gateway: `Paypal`,
    status: `Draft`,
  },
  {
    key: 10,
    id: `01`,
    company: "ABC Company",
    package: `Basic`,
    payment_date: `15-05-2023`,
    next_payment_date: `20-05-2023`,
    amount: `$200`,
    payment_gateway: `Paypal`,
    status: `Paid`,
  },
  {
    key: 11,
    id: `01`,
    company: "ABC Company",
    package: `Basic`,
    payment_date: `15-05-2023`,
    next_payment_date: `20-05-2023`,
    amount: `$200`,
    payment_gateway: `Paypal`,
    status: `Sent`,
  },
  {
    key: 12,
    id: `01`,
    company: "ABC Company",
    package: `Basic`,
    payment_date: `15-05-2023`,
    next_payment_date: `20-05-2023`,
    amount: `$200`,
    payment_gateway: `Paypal`,
    status: `Draft`,
  },
  {
    key: 13,
    id: `01`,
    company: "ABC Company",
    package: `Basic`,
    payment_date: `15-05-2023`,
    next_payment_date: `20-05-2023`,
    amount: `$200`,
    payment_gateway: `Paypal`,
    status: `Paid`,
  },
  {
    key: 14,
    id: `01`,
    company: "ABC Company",
    package: `Basic`,
    payment_date: `15-05-2023`,
    next_payment_date: `20-05-2023`,
    amount: `$200`,
    payment_gateway: `Paypal`,
    status: `Sent`,
  },
  {
    key: 15,
    id: `01`,
    company: "ABC Company",
    package: `Basic`,
    payment_date: `15-05-2023`,
    next_payment_date: `20-05-2023`,
    amount: `$200`,
    payment_gateway: `Paypal`,
    status: `Draft`,
  },
  {
    key: 16,
    id: `01`,
    company: "ABC Company",
    package: `Basic`,
    payment_date: `15-05-2023`,
    next_payment_date: `20-05-2023`,
    amount: `$200`,
    payment_gateway: `Paypal`,
    status: `Paid`,
  },
  {
    key: 17,
    id: `01`,
    company: "ABC Company",
    package: `Basic`,
    payment_date: `15-05-2023`,
    next_payment_date: `20-05-2023`,
    amount: `$200`,
    payment_gateway: `Paypal`,
    status: `Sent`,
  },
  {
    key: 18,
    id: `01`,
    company: "ABC Company",
    package: `Basic`,
    payment_date: `15-05-2023`,
    next_payment_date: `20-05-2023`,
    amount: `$200`,
    payment_gateway: `Paypal`,
    status: `Draft`,
  },
  {
    key: 19,
    id: `01`,
    company: "ABC Company",
    package: `Basic`,
    payment_date: `15-05-2023`,
    next_payment_date: `20-05-2023`,
    amount: `$200`,
    payment_gateway: `Paypal`,
    status: `Paid`,
  },
  {
    key: 20,
    id: `01`,
    company: "ABC Company",
    package: `Basic`,
    payment_date: `15-05-2023`,
    next_payment_date: `20-05-2023`,
    amount: `$200`,
    payment_gateway: `Paypal`,
    status: `Draft`,
  },
];

const InvoicesData = [
  {
    key: 1,
    client: `Jacobi-Windler`,
    number: `0127`,
    amount: "$2000",
    balance: "$2000",
    date: "15-05-2023",
    status: "Paid",
  },
  {
    key: 2,
    client: `Jacobi-Windler`,
    number: `0127`,
    amount: "$2000",
    balance: "$2000",
    date: "15-05-2023",
    status: "Sent",
  },
  {
    key: 3,
    client: `Jacobi-Windler`,
    number: `0127`,
    amount: "$2000",
    balance: "$2000",
    date: "15-05-2023",
    status: "Draft",
  },
  {
    key: 4,
    client: `Jacobi-Windler`,
    number: `0127`,
    amount: "$2000",
    balance: "$2000",
    date: "15-05-2023",
    status: "Paid",
  },
  {
    key: 5,
    client: `Jacobi-Windler`,
    number: `0127`,
    amount: "$2000",
    balance: "$2000",
    date: "15-05-2023",
    status: "Sent",
  },
  {
    key: 6,
    client: `Jacobi-Windler`,
    number: `0127`,
    amount: "$2000",
    balance: "$2000",
    date: "15-05-2023",
    status: "Draft",
  },
  {
    key: 7,
    client: `Jacobi-Windler`,
    number: `0127`,
    amount: "$2000",
    balance: "$2000",
    date: "15-05-2023",
    status: "Paid",
  },
  {
    key: 8,
    client: `Jacobi-Windler`,
    number: `0127`,
    amount: "$2000",
    balance: "$2000",
    date: "15-05-2023",
    status: "Sent",
  },
  {
    key: 9,
    client: `Jacobi-Windler`,
    number: `0127`,
    amount: "$2000",
    balance: "$2000",
    date: "15-05-2023",
    status: "Draft",
  },
  {
    key: 10,
    client: `Jacobi-Windler`,
    number: `0127`,
    amount: "$2000",
    balance: "$2000",
    date: "15-05-2023",
    status: "Paid",
  },
  {
    key: 11,
    client: `Jacobi-Windler`,
    number: `0127`,
    amount: "$2000",
    balance: "$2000",
    date: "15-05-2023",
    status: "Sent",
  },
  {
    key: 12,
    client: `Jacobi-Windler`,
    number: `0127`,
    amount: "$2000",
    balance: "$2000",
    date: "15-05-2023",
    status: "Draft",
  },
  {
    key: 13,
    client: `Jacobi-Windler`,
    number: `0127`,
    amount: "$2000",
    balance: "$2000",
    date: "15-05-2023",
    status: "Paid",
  },
  {
    key: 14,
    client: `Jacobi-Windler`,
    number: `0127`,
    amount: "$2000",
    balance: "$2000",
    date: "15-05-2023",
    status: "Sent",
  },
  {
    key: 15,
    client: `Jacobi-Windler`,
    number: `0127`,
    amount: "$2000",
    balance: "$2000",
    date: "15-05-2023",
    status: "Draft",
  },
  {
    key: 16,
    client: `Jacobi-Windler`,
    number: `0127`,
    amount: "$2000",
    balance: "$2000",
    date: "15-05-2023",
    status: "Paid",
  },
  {
    key: 17,
    client: `Jacobi-Windler`,
    number: `0127`,
    amount: "$2000",
    balance: "$2000",
    date: "15-05-2023",
    status: "Sent",
  },
  {
    key: 18,
    client: `Jacobi-Windler`,
    number: `0127`,
    amount: "$2000",
    balance: "$2000",
    date: "15-05-2023",
    status: "Draft",
  },
  {
    key: 19,
    client: `Jacobi-Windler`,
    number: `0127`,
    amount: "$2000",
    balance: "$2000",
    date: "15-05-2023",
    status: "Paid",
  },
  {
    key: 20,
    client: `Jacobi-Windler`,
    number: `0127`,
    amount: "$2000",
    balance: "$2000",
    date: "15-05-2023",
    status: "Sent",
  },
];

const InvoicesMobData = [
  {
    key: 1,
    title: `Support lead`,
    id: "Quinn Flynn",
    status: `Complete`,
  },
  {
    key: 2,
    title: `Integration Specialist`,
    id: "Rhona Davidson",
    status: `Working on`,
  },
  {
    key: 3,
    title: `Support Engineer`,
    id: "Sakuura Yamamto",
    status: `Backlog`,
  },
  {
    key: 11,
    title: `Data Coordinator`,
    id: "Serge Baldwin",
    status: `Open`,
  },
  {
    key: 4,
    title: `Regional Director`,
    id: "Shad Decker",
    status: `Complete`,
  },
  {
    key: 5,
    title: `Regional Marketing`,
    id: "Shou ltous",
    status: `Working on`,
  },
  {
    key: 6,
    title: `Developer`,
    id: "Thor walton",
    status: `Backlog`,
  },
  {
    key: 7,
    title: `Regional Director`,
    id: "Shad Decker",
    status: `Complete`,
  },
  {
    key: 8,
    title: `Regional Marketing`,
    id: "Shou ltous",
    status: `Working on`,
  },
  {
    key: 9,
    title: `Developer`,
    id: "Thor walton",
    status: `Backlog`,
  },
];

export { InvoicesData, InvoicesMobData, InvoiceSuperData };
