import { ThemeInput, ThemeSelect } from "components/components";
import { Editor } from "react-draft-wysiwyg";
import { Button } from "antd";

const Details = ({
  categories,
  data,
  setData,
  title,
  setTitle,
  category,
  setCategory,
  description,
  setDescription,
  setAddModal,
  id,
  type,
}) => {
  const handleData = () => {
    if (title && category && description.blocks[0].text) {
      let update = [...data];
      update.push({
        id: data.length,
        title: category,
        heading: title,
        // category: description.blocks[0].text,
        category: description,
      });
      setData(update);
      setTitle("");
      setCategory("");
      setDescription("");
      setAddModal("detailsModalClose");
    }
  };
  const handleEditData = () => {
    let update = [...data];
    update.splice(id, 1, {
      id: data.length,
      title: category,
      heading: title,
      category: description,
    });
    setData(update);
    setAddModal("detailsModalClose");
  };
  return (
    <div className="p-5">
      <ThemeInput
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder={"Title"}
      />
      <ThemeSelect
        value={category}
        setValue={setCategory}
        label={"Category*"}
        data={categories}
      />
      <p className="text-themeGray-medium300 mt-5 mb-3">Description</p>
      <div
        style={{ minHeight: 250 }}
        className="border-[1px] mb-4 border-[rgba(0,0,0,.1)]"
      >
        <Editor
          contentState={description}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName px-3"
          onChange={(editorState) => setDescription(editorState)}
        />
      </div>
      {type === "add" && (
        <Button onClick={handleData} type="primary">
          Add
        </Button>
      )}
      {type === "edit" && (
        <Button onClick={handleEditData} type="primary">
          Edit
        </Button>
      )}
    </div>
  );
};

export default Details;
