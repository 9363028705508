import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, Radio } from "antd";
import { Checkbox, Switch } from "@mui/material";

const Budget = ({ setSteps }) => {
  const [dollar, setDollar] = useState(50);
  const [value, setValue] = useState(1);
  return (
    <>
      <p className="font-semibold">Budget</p>
      <p className="text-themeGray-medium300 text-sm">
        If you need more info, please check{" "}
        <span className="text-primary cursor-pointer">Project Guidelines</span>
      </p>
      <div className="my-5">
        <p className="text-themeGray-medium300 text-[15px]">Setup Budget*</p>
        <div className="bg-gray-100 mt-1 h-10 justify-between flex items-center px-3 rounded-md w-2/4">
          <div className="flex items-center">
            <div
              onClick={() => setDollar(dollar - 5)}
              className="h-6 w-6 cursor-pointer bg-gray-300 flex justify-center items-center rounded-full"
            >
              <FontAwesomeIcon icon={faMinus} className="text-sm" />
            </div>
            <p className="ml-2">${dollar}.00</p>
          </div>
          <div
            onClick={() => setDollar(dollar + 5)}
            className="h-6 w-6 cursor-pointer bg-gray-300 flex justify-center items-center rounded-full"
          >
            <FontAwesomeIcon icon={faPlus} className="text-sm" />
          </div>
        </div>
        <div className="mt-5">
          <p className="text-themeGray-medium300 text-[15px]">Budget Usage</p>
          <div className="grid mt-2 grid-cols-2 gap-5">
            <div
              className={`cursor-pointer flex items-start border-dashed ${
                value === 1 ? "border-primary bg-blue-50" : "border-gray-300"
              }  border-2 rounded-md p-4`}
              onClick={() => setValue(1)}
            >
              <Radio checked={value === 1} />
              <div>
                <p className="font-semibold">Precise Usage</p>
                <p className="text-sm text-themeGray-medium300 mt-1">
                  Withdraw money to your bank account per transaction under
                  $50,000 budget
                </p>
              </div>
            </div>
            <div
              className={`cursor-pointer flex items-start border-dashed ${
                value === 2 ? "border-primary bg-blue-50" : "border-gray-300"
              }  border-2 rounded-md p-4`}
              onClick={() => setValue(2)}
            >
              <Radio checked={value === 2} />
              <div>
                <p className="font-semibold">Extreme Usage</p>
                <p className="text-sm text-themeGray-medium300 mt-1">
                  Withdraw money to your bank account per transaction under
                  $50,000 budget
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex mt-5 items-center justify-between">
          <div>
            <p>Allow Changes in Budget</p>
            <p className="text-themeGray-medium300 text-sm">
              If you need more info, please check budget plannning
            </p>
          </div>
          <div className="flex items-center">
            <Switch />
            <p>Allowed</p>
          </div>
        </div>
        <div className="flex items-center justify-between mt-5">
          <Button onClick={() => setSteps(1)}>Back</Button>
          <Button onClick={() => setSteps(3)} type="primary">
            Continue
          </Button>
        </div>
      </div>
    </>
  );
};

export default Budget;
