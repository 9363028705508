import { ClientProjectCard } from "components/components";

const BoardView = ({ onClick, data, onMob }) => {
  return (
    <div
      className={`${
        onMob ? "flex-col items-center w-full" : "w-fit"
      } flex pt-3`}
    >
      {data.map((e, i) => {
        return (
          <div key={i} className="pl-3 w-80">
            <div className="bg-themeGray-light200 mb-2 rounded-md h-[88.5vh] desktop:h-[88.5vh] bigDesktop:h-[86.5vh] scrol">
              <div
                className={`flex items-center justify-between pr-2 mb-1 ${e.bgColor} ${e.txtColor} p-2 rounded-tr-md rounded-tl-md`}
              >
                <p className="p-2 text-sm">{e.title}</p>
              </div>
              <div className="p-2 overflow-y-scroll">
                {e.data.map((item, index) => {
                  return (
                    <div key={index} className="mb-2">
                      <ClientProjectCard
                        title={item.title}
                        statusColor={item.status}
                        onClick={onClick}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BoardView;
