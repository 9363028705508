import { Tabs } from "antd";
import Google from "./google/google";
import Facebook from "./facebook/facebook";
import Linkedin from "./linkedin/linkedin";
import Twitter from "./twitter/twitter";

const Social = () => {
  const items = [
    {
      key: "1",
      label: (
        <div className="flex items-center">
          <img
            height={15}
            width={13}
            src="https://static-00.iconduck.com/assets.00/google-icon-2048x2048-czn3g8x8.png"
            alt=""
          />
          <p className="mx-2">Google</p>
          {/* <div className="h-3 w-3 bg-[#d30000] rounded-full"></div> */}
        </div>
      ),
      children: <Google />,
    },
    {
      key: "2",
      label: (
        <div className="flex items-center">
          <img
            height={15}
            width={15}
            src="https://cdn-icons-png.flaticon.com/512/124/124010.png"
            alt=""
          />
          <p className="mx-2">Facebook</p>
          {/* <div className="h-3 w-3 bg-[#d30000] rounded-full"></div> */}
        </div>
      ),
      children: <Facebook />,
    },
    {
      key: "3",
      label: (
        <div className="flex items-center">
          <img
            height={15}
            width={15}
            src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
            alt=""
          />
          <p className="mx-2">LinkedIn</p>
          {/* <div className="h-3 w-3 bg-[#d30000] rounded-full"></div> */}
        </div>
      ),
      children: <Linkedin />,
    },
    {
      key: "4",
      label: (
        <div className="flex items-center">
          <img
            height={15}
            width={15}
            src="https://cdn-icons-png.flaticon.com/512/3670/3670151.png"
            alt=""
          />
          <p className="mx-2">Twitter</p>
          {/* <div className="h-3 w-3 bg-[#d30000] rounded-full"></div> */}
        </div>
      ),
      children: <Twitter />,
    },
  ];
  return (
    <div className="ml-2">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default Social;
