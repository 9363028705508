import { Tabs } from "antd";
import Details from "./details/details";
import Localization from "./localization/localization";
import Logo from "./logo/logo";
import Contact from "./contact/contact";

const General = () => {
  const items = [
    {
      key: "1",
      label: `Details`,
      children: <Details />,
    },
    {
      key: "2",
      label: `Localization`,
      children: <Localization />,
    },
    {
      key: "3",
      label: `Logo`,
      children: <Logo />,
    },
    {
      key: "4",
      label: `Contact Settings`,
      children: <Contact />,
    },
  ];
  return (
    <div className="ml-2">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default General;
