import { Button, Modal } from "antd";
import { check_icon } from "assets/icons/icons";

const ColorPaletteModal = ({
  isModalOpen,
  setIsModalOpen,
  setColor,
  color,
}) => {
  const colors = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#40E0D0",
    "#FA8072",
    "#000080",
    "#FFD700",
    "#FF7F50",
    "#808000",
    "#4B0082",
    "#E6E6FA",
    "#008080",
    "#00FF00",
    "#800080",
    "#FFC0CB",
    "#FFA500",
    "#A52A2A",
    "#808080",
    "#FF00FF",
    "#00FFFF",
    "#FFFF00",
    "#00A1E4",
    "#b93700",
  ];
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      closeIcon={<div></div>}
      open={isModalOpen}
      footer={false}
      onCancel={handleCancel}
      width={360}
    >
      <div className="grid grid-cols-6 gap-3 w-80">
        {colors.map((e, i) => {
          return (
            <div
              onClick={() => {
                handleCancel();
                setColor(e);
              }}
              key={i}
              style={{ backgroundColor: e }}
              className="h-8 w-8 flex justify-center items-center col-span-1 rounded-full cursor-pointer"
            >
              {e === color && <img src={check_icon} alt="" />}
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default ColorPaletteModal;
