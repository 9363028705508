import { ThemeInput } from "components/components";

const Integrations = () => {
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        <div className="flex items-center">
          <ThemeInput placeholder={"Google Analytics Tracking ID"} />
          <a
            className="text-bgBlue ml-6 w-28"
            href="https://support.google.com/analytics/answer/1037249?hl=en"
            target="_blank"
          >
            Learn More
          </a>
        </div>
        <ThemeInput placeholder={"Matomo Id"} />
        <ThemeInput placeholder={"Matomo URL"} />
      </div>
    </div>
  );
};

export default Integrations;
