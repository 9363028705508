import { ThemeInput } from "components/components";
import React from "react";

const RestApi = () => {
  return (
    <div className="bg-themeGray-light200 p-3 mt-4 min-h-[73.8vh] desktop:h-[79vh] bigDesktop:h-[87vh]">
      <div className="bg-white p-3 shadow-md">
        <p>Rest API Settings</p>
        <ThemeInput password placeholder={"FCM Key"} />
      </div>
    </div>
  );
};

export default RestApi;
