import { useState } from "react";
import {
  cross_icon,
  download_icon,
  filter_icon,
  home_icon,
  print_icon,
} from "assets/icons/icons";
import { Container, ThemeTable } from "components/components";
import { InvoiceSuperData } from "constant/constant";
import Details from "./details/details";

const Invoices = () => {
  const [data, setData] = useState(InvoiceSuperData);
  const [densityOpt, setDensityOpt] = useState(false);
  const [density, setDensity] = useState("middle");
  const [status, setStatus] = useState("");
  const [detailsModal, setDetailsModal] = useState("detailsModalClose");
  const handleCompanyDetails = (event, record) => {
    setStatus(record.status);
    setDetailsModal("detailsModalOpen");
  };
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      width: "6%",
      sorter: (a, b) => a.id - b.id,
      filters: [
        {
          text: "01",
          value: "01",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.id.includes(value),
    },
    {
      title: "Company",
      dataIndex: "company",
      sorter: (a, b) => a.company - b.company,
      width: "11%",
      filters: [
        {
          text: "ABC Company",
          value: "ABC Company",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.company.includes(value),
    },
    {
      title: "Package",
      dataIndex: "package",
      width: "14%",
      sorter: (a, b) => a.package - b.package,
      filters: [
        {
          text: "Basic",
          value: "Basic",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.package.includes(value),
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      sorter: (a, b) => a.payment_date - b.payment_date,
      width: "11%",
      filters: [
        {
          text: "15-05-2023",
          value: "15-05-2023",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.payment_date.includes(value),
    },
    {
      title: "Next Payment Date",
      dataIndex: "next_payment_date",
      width: "11%",
      sorter: (a, b) => a.next_payment_date - b.next_payment_date,
      filters: [
        {
          text: "20-05-2023",
          value: "20-05-2023",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.next_payment_date.includes(value),
    },
    {
      title: "Transaction Id",
      dataIndex: "id",
      width: "6%",
      sorter: (a, b) => a.id - b.id,
      filters: [
        {
          text: "01",
          value: "01",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.id.includes(value),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: "11%",
      sorter: (a, b) => a.amount - b.amount,
      filters: [
        {
          text: "$200",
          value: "$200",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.amount.includes(value),
    },
    {
      title: "Payment Gateway",
      dataIndex: "payment_gateway",
      width: "11%",
      sorter: (a, b) => a.payment_gateway - b.payment_gateway,
      filters: [
        {
          text: "Paypal",
          value: "Paypal",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.payment_gateway.includes(value),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "11%",
      filters: [
        {
          text: "Paid",
          value: "Paid",
        },
        {
          text: "Sent",
          value: "Sent",
        },
        {
          text: "Draft",
          value: "Draft",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.status.includes(value),
      render: (text) => (
        <div
          className={`${
            text === "Paid"
              ? "bg-themeGreen"
              : text === "Sent"
              ? "bg-blue-600"
              : text === "Draft" && "bg-themeGray-dark"
          } text-white w-[80px] h-7 text-sm flex justify-center items-center rounded-[5px]`}
        >
          <p>{text}</p>
        </div>
      ),
    },
  ];
  const handleDensity = () => {
    setDensityOpt(!densityOpt);
  };
  return (
    <Container
      superAdmin
      active={"Invoices"}
      onClick={() => densityOpt && setDensityOpt(false)}
    >
      <div className="flex">
        <div className="w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
          <div className="bg-white" style={{ top: 54 }}>
            <div className="flex items-center justify-between px-5 h-[52px]">
              <div className="flex items-center">
                <p className="font-medium text-sm">Invoice</p>
                <img src={home_icon} alt="" className="mr-3 ml-8" />
                <p className="font-medium text-sm">
                  Home
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    Invoice
                  </a>
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    All invoices
                  </a>
                </p>
              </div>
              <div className="flex items-center">
                <div>
                  <img
                    src={filter_icon}
                    onClick={handleDensity}
                    alt=""
                    className="cursor-pointer"
                  />
                  {densityOpt && (
                    <div className="relative">
                      <div className="text-sm right-0 absolute my-2 bg-white px-3 py-1 z-20 top-0 leading-7 rounded-md shadow-lg">
                        <p
                          className="cursor-pointer"
                          onClick={() => {
                            setDensityOpt(false);
                            setDensity("small");
                          }}
                        >
                          Compact
                        </p>
                        <p
                          className="cursor-pointer"
                          onClick={() => {
                            setDensityOpt(false);
                            setDensity("middle");
                          }}
                        >
                          Standard
                        </p>
                        <p
                          className="cursor-pointer"
                          onClick={() => {
                            setDensityOpt(false);
                            setDensity("large");
                          }}
                        >
                          Comfortable
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
          </div>
          <div className="-mt-3">
            <ThemeTable
              onClick={handleCompanyDetails}
              columns={columns}
              data={data}
              setData={(e) => setData(e)}
              density={density}
            />
          </div>
        </div>

        <div
          className={`bg-white ${detailsModal} overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
        >
          {detailsModal === "detailsModalOpen" && (
            <>
              <div className="flex z-10 items-center justify-between w-full pr-5 shadow-md border-b-2 border-[rgba(0,0,0,.2)] bg-white sticky top-0">
                <div className="h-11 items-center w-[432px] flex"></div>
                <div className="flex items-center">
                  <img src={download_icon} />
                  <img src={print_icon} className="ml-4" />
                  <img
                    src={cross_icon}
                    alt=""
                    className="ml-4 cursor-pointer opacity-70"
                    width={16}
                    onClick={() => setDetailsModal("detailsModalClose")}
                  />
                </div>
              </div>
              <Details status={status} />
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Invoices;
