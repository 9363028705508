import { submenu_icon } from "assets/icons/icons";
import MobContainer from "components/mobContainer/mobContainer";
import { CompanyMobData } from "constant/constant";
import { Dropdown } from "antd";
import items from "../invoicesDesktop/optionItems/optionItems";
import { useNavigate } from "react-router-dom";

const InvoicesMobile = () => {
  const navigate = useNavigate();
  return (
    <MobContainer onAdd={() => navigate("/company/add")}>
      {CompanyMobData.map((e, i) => {
        return (
          <div
            key={i}
            className="flex items-center justify-between hover:bg-gray-100 border-b-2 border-[0,0,0,.1] px-3"
          >
            <Dropdown
              menu={{
                items,
              }}
              placement="bottomLeft"
              trigger={["click"]}
              className="px-2"
            >
              <img src={submenu_icon} alt="" className="mr-4 cursor-pointer" />
            </Dropdown>
            <div
              onClick={() => navigate("/company/details")}
              className="h-[60px] w-full flex flex-col justify-center cursor-pointer"
            >
              <p className="text-[15px]">{e.title}</p>
              <p className="text-[12px] text-[rgba(0,0,0,.67)]">{e.client}</p>
            </div>
            <p
              onClick={() => navigate("/company/details")}
              className={`w-[40%] text-xs flex items-center justify-end h-[60px] cursor-pointer ${
                e.status === "Complete"
                  ? "text-themeLightGreen"
                  : e.status === "Working on"
                  ? "text-themePink"
                  : e.status === "Backlog"
                  ? "text-themeBlue"
                  : e.status === "Open" && "text-bgBlue"
              }`}
            >
              {e.status}
            </p>
          </div>
        );
      })}
    </MobContainer>
  );
};

export default InvoicesMobile;
