const ContactPersonData = [
  {
    key: 1,
    first_name: `Antonia`,
    last_name: "Emmerich",
    company: `ABC Company`,
    email: `example@gmail.com`,
    phone: `02345678`,
    date: `15-05-2023`,
  },
  {
    key: 2,
    first_name: `Antonia`,
    last_name: "Emmerich",
    company: `ABC Company`,
    email: `example@gmail.com`,
    phone: `02345678`,
    date: `15-05-2023`,
  },
  {
    key: 3,
    first_name: `Antonia`,
    last_name: "Emmerich",
    company: `ABC Company`,
    email: `example@gmail.com`,
    phone: `02345678`,
    date: `15-05-2023`,
  },
  {
    key: 4,
    first_name: `Antonia`,
    last_name: "Emmerich",
    company: `ABC Company`,
    email: `example@gmail.com`,
    phone: `02345678`,
    date: `15-05-2023`,
  },
  {
    key: 5,
    first_name: `Antonia`,
    last_name: "Emmerich",
    company: `ABC Company`,
    email: `example@gmail.com`,
    phone: `02345678`,
    date: `15-05-2023`,
  },
  {
    key: 6,
    first_name: `Antonia`,
    last_name: "Emmerich",
    company: `ABC Company`,
    email: `example@gmail.com`,
    phone: `02345678`,
    date: `15-05-2023`,
  },
  {
    key: 7,
    first_name: `Antonia`,
    last_name: "Emmerich",
    company: `ABC Company`,
    email: `example@gmail.com`,
    phone: `02345678`,
    date: `15-05-2023`,
  },
  {
    key: 8,
    first_name: `Antonia`,
    last_name: "Emmerich",
    company: `ABC Company`,
    email: `example@gmail.com`,
    phone: `02345678`,
    date: `15-05-2023`,
  },
  {
    key: 9,
    first_name: `Antonia`,
    last_name: "Emmerich",
    company: `ABC Company`,
    email: `example@gmail.com`,
    phone: `02345678`,
    date: `15-05-2023`,
  },
  {
    key: 10,
    first_name: `Antonia`,
    last_name: "Emmerich",
    company: `ABC Company`,
    email: `example@gmail.com`,
    phone: `02345678`,
    date: `15-05-2023`,
  },
  {
    key: 11,
    first_name: `Antonia`,
    last_name: "Emmerich",
    company: `ABC Company`,
    email: `example@gmail.com`,
    phone: `02345678`,
    date: `15-05-2023`,
  },
  {
    key: 12,
    first_name: `Antonia`,
    last_name: "Emmerich",
    company: `ABC Company`,
    email: `example@gmail.com`,
    phone: `02345678`,
    date: `15-05-2023`,
  },
  {
    key: 13,
    first_name: `Antonia`,
    last_name: "Emmerich",
    company: `ABC Company`,
    email: `example@gmail.com`,
    phone: `02345678`,
    date: `15-05-2023`,
  },
  {
    key: 14,
    first_name: `Antonia`,
    last_name: "Emmerich",
    company: `ABC Company`,
    email: `example@gmail.com`,
    phone: `02345678`,
    date: `15-05-2023`,
  },
  {
    key: 15,
    first_name: `Antonia`,
    last_name: "Emmerich",
    company: `ABC Company`,
    email: `example@gmail.com`,
    phone: `02345678`,
    date: `15-05-2023`,
  },
  {
    key: 16,
    first_name: `Antonia`,
    last_name: "Emmerich",
    company: `ABC Company`,
    email: `example@gmail.com`,
    phone: `02345678`,
    date: `15-05-2023`,
  },
  {
    key: 17,
    first_name: `Antonia`,
    last_name: "Emmerich",
    company: `ABC Company`,
    email: `example@gmail.com`,
    phone: `02345678`,
    date: `15-05-2023`,
  },
  {
    key: 18,
    first_name: `Antonia`,
    last_name: "Emmerich",
    company: `ABC Company`,
    email: `example@gmail.com`,
    phone: `02345678`,
    date: `15-05-2023`,
  },
  {
    key: 19,
    first_name: `Antonia`,
    last_name: "Emmerich",
    company: `ABC Company`,
    email: `example@gmail.com`,
    phone: `02345678`,
    date: `15-05-2023`,
  },
  {
    key: 20,
    first_name: `Antonia`,
    last_name: "Emmerich",
    company: `ABC Company`,
    email: `example@gmail.com`,
    phone: `02345678`,
    date: `15-05-2023`,
  },
];

const ContactPersonMobData = [
  {
    key: 1,
    title: `Support lead`,
    client: "Quinn Flynn",
    status: `Complete`,
  },
  {
    key: 2,
    title: `Integration Specialist`,
    client: "Rhona Davidson",
    status: `Working on`,
  },
  {
    key: 3,
    title: `Support Engineer`,
    client: "Sakuura Yamamto",
    status: `Backlog`,
  },
  {
    key: 11,
    title: `Data Coordinator`,
    client: "Serge Baldwin",
    status: `Open`,
  },
  {
    key: 4,
    title: `Regional Director`,
    client: "Shad Decker",
    status: `Complete`,
  },
  {
    key: 5,
    title: `Regional Marketing`,
    client: "Shou ltous",
    status: `Working on`,
  },
  {
    key: 6,
    title: `Developer`,
    client: "Thor walton",
    status: `Backlog`,
  },
  {
    key: 7,
    title: `Regional Director`,
    client: "Shad Decker",
    status: `Complete`,
  },
  {
    key: 8,
    title: `Regional Marketing`,
    client: "Shou ltous",
    status: `Working on`,
  },
  {
    key: 9,
    title: `Developer`,
    client: "Thor walton",
    status: `Backlog`,
  },
];

export { ContactPersonData, ContactPersonMobData };
