import { PaymentInfo } from "../../paymentsDesktop/paymentInfo/paymentInfo";
const { MobContainer } = require("components/components");

const AddPayment = () => {
  return (
    <MobContainer onCancel={"/products"} addCompany heading={"New Product"}>
      <div className="px-3">
        <PaymentInfo />
      </div>
    </MobContainer>
  );
};

export default AddPayment;
