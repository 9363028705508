import QuotesMobile from "./quotesMobile/quotesMobile";
import QuotesDesktop from "./quotesDesktop/quotesDesktop";

const Quotes = () => {
  return (
    <>
      <div className="hidden xl:block">
        <QuotesDesktop />
      </div>
      <div className="block xl:hidden">
        <QuotesMobile />
      </div>
    </>
  );
};

export default Quotes;
