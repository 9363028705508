const ProjectDetails = [
  {
    title: "To Do",
    data: [
      {
        status: "bg-blue-400",
        title: "Learning",
      },
    ],
    selected: true,
    bgColor: "bg-red-200",
    txtColor: "text-red-600",
  },
  {
    title: "Doing",
    data: [
      {
        status: "bg-red-400",
        title: "Design",
      },
      {
        status: "bg-orange-400",
        title: "Development",
      },
    ],
    selected: false,
    bgColor: "bg-green-200",
    txtColor: "text-green-600",
  },
];

export default ProjectDetails;
