import React from "react";
import { Select, Table } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faAnglesRight,
  faBarsStaggered,
} from "@fortawesome/free-solid-svg-icons";
import { Container, FilterOptions } from "components/components";
import {
  faArrowsRotate,
  faDownload,
  faEllipsisVertical,
  faEnvelope,
  faPrint,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { home_icon } from "assets/icons/icons";

const Pipeline = () => {
  const columns = [
    {
      title: "Stage",
      dataIndex: "Stage",
      key: "Stage",
      render: (text) => (
        <div
          className={` p-5 ${
            text === "total"
              ? ""
              : text === "Opportunity"
              ? "bg-[#F7F9FD] border-r-8 border-[#e3d0ff]"
              : text === "Scoping"
              ? "bg-[#F7F9FD] border-r-8 border-[#b7ecec]"
              : text === "Proposal"
              ? "bg-[#F7F9FD] border-r-8 border-[#ffc58b]"
              : text === "Confirmed"
              ? "bg-[#F7F9FD] border-r-8 border-[#50b83c]"
              : ""
          }`}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Earlier",
      dataIndex: "Earlier",
      key: "Earlier",
      render: (text) => <div className="p-5">{text}</div>,
    },

    {
      title: "August",
      dataIndex: "August",
      key: "August",
      render: (text) => <div className="p-5">{text}</div>,
    },
    {
      title: "September",
      dataIndex: "September",
      key: "September",
      render: (text) => <div className="p-5">{text}</div>,
    },
    {
      title: "October",
      dataIndex: "October",
      key: "October",
      render: (text) => <div className="p-5">{text}</div>,
    },
    {
      title: "Later",
      dataIndex: "Later",
      key: "Later",
      render: (text) => <div className="p-5">{text}</div>,
    },
    {
      title: "Total",
      dataIndex: "Total",
      key: "Total",
      render: (text) => <div className="p-5">{text}</div>,
    },
  ];
  const data = [
    {
      key: "1",
      Stage: "Total",
      Earlier: "11.400,00",
      August: "5.400,00",
      September: "6.954,00",
      Later: "8.600,00",
      October: "8.600,00",
      Total: "4.575,00",
    },
    {
      key: "2",
      Stage: "Opportunity",
      Earlier: "11.400,00",
      August: "5.400,00",
      September: "6.954,00",
      Later: "8.600,00",
      October: "8.600,00",
      Total: "4.575,00",
    },
    {
      key: "3",
      Stage: "Scoping",
      Earlier: "11.400,00",
      August: "5.400,00",
      September: "6.954,00",
      Later: "8.600,00",
      October: "8.600,00",
      Total: "4.575,00",
    },
    {
      key: "1",
      Stage: "Proposal",
      Earlier: "11.400,00",
      August: "5.400,00",
      September: "6.954,00",
      Later: "8.600,00",
      October: "8.600,00",
      Total: "4.575,00",
    },
    {
      key: "1",
      Stage: "Confirmed",
      Earlier: "11.400,00",
      August: "5.400,00",
      September: "6.954,00",
      Later: "8.600,00",
      October: "8.600,00",
      Total: "4.575,00",
    },
  ];

  const itemsData2 = [
    {
      label: "Spent time",
      key: "1",
      icon: (
        <FontAwesomeIcon
          icon={faBarsStaggered}
          fill="#494F59"
          size={15}
          className="mr-2"
        />
      ),

      className: "border-b rounded-none ",
    },

    {
      label: (
        <Select
          showSearch
          placeholder="Select a person"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={[
            {
              value: "jack",
              label: "Jack",
            },
            {
              value: "lucy",
              label: "Lucy",
            },
            {
              value: "tom",
              label: "Tom",
            },
          ]}
        />
      ),
      key: "2",
    },
  ];

  return (
    <Container>
      <div className="w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
        <div className="bg-white" style={{ top: 54 }}>
          <div className="flex items-center justify-between px-5 py-3">
            <div className="flex items-center">
              <p className="font-medium text-sm">Company</p>
              <img src={home_icon} alt="" className="mr-3 ml-8" />
              <p className="font-medium text-sm">
                Home
                <span className="text-bgBlue"> / </span>
                <a href="#" className="text-bgBlue">
                  Report
                </a>
                <span className="text-bgBlue"> / </span>
                <a href="#" className="text-bgBlue">
                  Default
                </a>
              </p>
            </div>
            <div className="text-end pe-5">
              <div className="flex items-center">
                <div className="me-4">
                  <FontAwesomeIcon icon={faArrowsRotate} />
                </div>
                <p className="p-0 m-0 text-gray-700">
                  Data refreshed at jul 18, 2023 04:06 PM
                </p>
                <div className="flex ">
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faEnvelope} className="fs-1" />
                  </div>
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faDownload} />
                  </div>
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faPrint} />
                  </div>
                </div>
                <FontAwesomeIcon
                  icon={faEllipsisVertical}
                  className="fs-1 mx-4"
                />
                <FontAwesomeIcon icon={faXmark} className="fs-1" />
              </div>
            </div>
          </div>
          <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
        </div>
        <FilterOptions onTop />
        <div className="mt-7 mb-5">
          <div className="px-4">
            <div className="font-bold text-xl mb-2">Pipeline report</div>
            <section className="summery-sec border shadow-lg rounded-md pb-5">
              <div className="flex justify-center my-5">
                <span className="text-[#969FAE] text-[13px] mx-2">
                  <FontAwesomeIcon className="mx-1" icon={faAnglesLeft} />
                  Previous
                </span>
                <span className="text-[#969FAE] text-[13px] mx-2">Current</span>
                <span className="text-[#969FAE] text-[13px] mx-2">
                  next
                  <FontAwesomeIcon className="mx-1" icon={faAnglesRight} />
                </span>
              </div>
              <div className="px-3 pt-3 pb-[100px] pipeline">
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  bordered
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Pipeline;
