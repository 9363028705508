const Command = () => {
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        <div className="bg-blue-50 rounded-lg border-2 border-blue-100 text-primary p-4">
          <p>CRON COMMAND: wget -q -O- https://perfexcrm.com/demo/cron/index</p>
          <a className="hover:text-blue-800">Run Cron Manually</a>
        </div>
      </div>
    </div>
  );
};

export default Command;
