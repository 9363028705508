import {
  archive_icon,
  delete_icon,
  plus_blue_icon,
} from "assets/icons/icons";

const items = [
  {
    key: "1",
    label: (
      <div className="flex items-center pt-2 pr-5">
        <img src={plus_blue_icon} alt="" className="mr-2" width={20} />
        <p>Clone to Expense</p>
      </div>
    ),
  },
  {
    key: "3",
    label: (
      <div className="flex items-center pt-2 pr-5 pb-1">
        <img src={plus_blue_icon} alt="" className="mr-2" width={20} />
        <p>Clone to Recurring</p>
      </div>
    ),
  },
  {
    key: "4",
    label: (
      <div className="flex items-center pt-3 pr-5 border-t-[1px] border-[rgba(0,0,0,.1)]">
        <img src={archive_icon} alt="" className="mr-2" width={20} />
        <p>Archive</p>
      </div>
    ),
  },
  {
    key: "5",
    label: (
      <div className="flex items-center py-2 pr-5">
        <img src={delete_icon} alt="" className="mr-2" width={15} />
        <p>Delete</p>
      </div>
    ),
  },
];

export default items;
