import { Tabs } from "antd";
import Design from "./design/design";
import Preview from "./preview/preview";
import Settings from "./settings/settings";

const InvoiceDesign = () => {
  const items = [
    {
      key: "1",
      label: `Design`,
      children: <Design />,
    },
    {
      key: "2",
      label: `Settings`,
      children: <Settings />,
    },
    {
      key: "3",
      label: `Preview`,
      children: <Preview />,
    },
  ];
  return (
    <div className="ml-2">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default InvoiceDesign;
