import { profile_pic } from "assets/images/images";

const ContactView = ({ isChat, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="flex justify-between cursor-pointer hover:bg-gray-50 p-4 border-t-[1px] border-[rgba(0,0,0,.1)]"
    >
      <div className="flex">
        <div className="relative">
          <img src={profile_pic} alt="" width={45} />
          <div className="h-3 w-3 absolute right-0 bottom-0 bg-green-500 border-[1px] border-white rounded-full"></div>
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium">Brian Hughes</p>
          <p className="text-sm font-medium opacity-70">See you tomorrow</p>
        </div>
      </div>
      {isChat && (
        <div className="flex flex-col justify-between items-end">
          <p className="text-sm font-medium opacity-70">Jan 5, 2022</p>
          <div className="w-5 h-5 rounded-full bg-primary flex justify-center items-center">
            <p className="text-[10px] text-white">2</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactView;
