import { ThemeSelect } from "components/components";

const Notifications = () => {
  const data = [
    "Invoice Created",
    "Invoice Sent",
    "Invoice Viewed",
    "Invoice Late",
    "Payment Success",
    "Payment Failure",
    "Payment Manual",
    "Quote Created",
    "Qoute Sent",
    "Quote Viewed",
    "Quote Approved",
    "Qoute Expired",
    "Quote Created",
    "Qoute Sent",
    "Quote Viewed",
    "Quote Approved",
    "Qoute Expired",
  ];
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2">
      <div className="bg-white p-3 shadow-md">
        <p className="border-b-2 border-[rgba(0,0,0,.2)] p-3">All Events</p>
        {data.map((e, i) => {
          return (
            <div
              key={i}
              className="border-b-2 border-[rgba(0,0,0,.2)] px-3 py-2 text-sm flex items-center justify-between"
            >
              <p>{e}</p>
              <ThemeSelect
                width={"50%"}
                label={"Select"}
                data={["All records", "Owned by user", "None"]}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Notifications;
