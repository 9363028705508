import {
  faCreditCard,
  faDiagramProject,
  faFileInvoice,
  faListCheck,
  faQuoteLeft,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { myscrm } from "assets/images/images";
import { useNavigate } from "react-router-dom";

const ClientSidebar = ({ active }) => {
  const navigate = useNavigate();
  const data = [
    {
      icon: (
        <svg
          className="w-[17px]"
          viewBox="0 0 20 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.06601 1.55923V10.6351H11.3855C11.2425 10.1297 11.214 9.6073 11.2914 9.10115H3.54335C3.40038 9.10115 3.28475 8.98935 3.28475 8.8511C3.28475 8.71286 3.40038 8.60067 3.54335 8.60067H5.17967V4.85407C5.17967 4.79591 5.2285 4.74869 5.28865 4.74869H6.11482C6.17498 4.74869 6.22381 4.79591 6.22381 4.85407V8.60067H7.3906V5.97137C7.3906 5.9132 7.43943 5.86599 7.49958 5.86599H8.32575C8.38552 5.86599 8.43474 5.91358 8.43474 5.97137V8.60067H9.60153V3.7364C9.60153 3.67823 9.65036 3.63102 9.71051 3.63102H10.5367C10.5961 3.63102 10.6457 3.67899 10.6457 3.7364V8.60067H11.4043C11.4992 8.28498 11.6365 7.98263 11.8125 7.70132V5.17325C11.8125 5.11508 11.8609 5.06786 11.9211 5.06786H12.7476C12.8074 5.06786 12.8562 5.11546 12.8562 5.17325V6.60594C13.1007 6.4353 13.3648 6.2927 13.6433 6.181C14.1007 5.99743 14.6019 5.8962 15.1265 5.8962C15.2519 5.8962 15.3765 5.90225 15.5003 5.91358V1.55923H2.06601ZM11.6976 11.3984H9.16794V12.7171L11.0511 14.4485L10.5082 15L9.16794 13.7675V14.7571H8.37849V13.7679L7.03787 15L6.49529 14.4485L8.37849 12.7171V11.3984H0V10.6351H1.27656V1.55923H0.0171874V0.795855H8.37849V0H9.16794V0.795855H17.5288V1.55923H16.2898V6.06882C16.8854 6.24986 17.427 6.56748 17.8683 6.99461C18.8742 7.96724 19.248 9.40106 18.8433 10.7178C18.7501 11.0201 18.6177 11.3097 18.4492 11.5798L19.9511 13.1624C19.9844 13.1975 20.0019 13.2439 19.9998 13.2915C19.9978 13.3391 19.9763 13.3839 19.9402 13.4162L18.8374 14.39C18.762 14.4565 18.6449 14.4516 18.5761 14.3787L17.1394 12.8508C16.5308 13.2092 15.839 13.3958 15.1265 13.3958C14.0964 13.3958 13.1125 13.0019 12.3843 12.2974C12.1104 12.0325 11.8789 11.7295 11.6976 11.3984ZM17.3144 7.53022C16.4312 6.67657 15.0925 6.41972 13.9425 6.88016C11.8789 7.70661 11.4046 10.28 12.9378 11.7614C13.8226 12.6151 15.1582 12.8727 16.3097 12.4119C17.4675 11.9469 18.2203 10.8579 18.2203 9.64583C18.2203 9.23978 18.137 8.85299 17.9867 8.50171C17.8304 8.13849 17.602 7.80843 17.3144 7.53022Z"
            fill={"#fff"}
            fill-opacity="1"
          />
        </svg>
      ),
      title: "Dashboard",
      navigate: "/client/dashboard",
    },
    {
      icon: <FontAwesomeIcon icon={faFileInvoice} style={{ color: "#fff" }} />,
      title: "Invoices",
      navigate: "/client/invoices",
    },
    {
      icon: <FontAwesomeIcon icon={faQuoteLeft} style={{ color: "#fff" }} />,
      title: "Quotes",
      navigate: "/client/quotes",
    },
    {
      icon: <FontAwesomeIcon icon={faCreditCard} style={{ color: "#fff" }} />,
      title: "Credit",
      navigate: "/client/credit",
    },
    {
      icon: (
        <FontAwesomeIcon icon={faDiagramProject} style={{ color: "#fff" }} />
      ),
      title: "Projects",
      navigate: "/client/projects",
    },
    {
      icon: <FontAwesomeIcon icon={faListCheck} style={{ color: "#fff" }} />,
      title: "Tasks",
      navigate: "/client/tasks",
    },
    {
      icon: <FontAwesomeIcon icon={faUser} style={{ color: "#fff" }} />,
      title: "Account",
      navigate: "/client/account",
    },
  ];
  return (
    <div className="h-screen bg-[#3576AF] py-6 w-72 border-r-[1px] border-[(rgba(0,0,0,.2))] flex flex-col justify-between">
      <div>
        <img src={myscrm} alt="" className="pl-7 mb-5" />
        {data.map((e, i) => {
          return (
            <div
              key={i}
              className={`py-4 ${
                active === e.title && "bg-bgBlue"
              } flex items-center pl-7 hover:bg-bgBlue cursor-pointer`}
              onClick={() => e.navigate && navigate(e.navigate)}
            >
              <div className="w-10">{e.icon}</div>
              <p className="text-sm text-white ml-1">{e.title}</p>
            </div>
          );
        })}
        <div
          onClick={() => {
            localStorage.removeItem("User");
            navigate("/");
          }}
          style={{
            borderRadius: 5,
          }}
          className="bg-bgBlue cursor-pointer text-white flex items-center justify-center !text-sm p-0 font-medium h-10 mx-5 mt-3"
        >
          Logout
        </div>
      </div>
      <div className="flex justify-center">
        <svg
          onClick={() => navigate("/client/chat")}
          className="w-[20px] cursor-pointer mr-10"
          viewBox="0 0 29 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.45975 22.9744L0 28V1.4359C0 1.05507 0.152767 0.689848 0.424695 0.420565C0.696623 0.151282 1.06544 0 1.45 0H27.55C27.9346 0 28.3034 0.151282 28.5753 0.420565C28.8472 0.689848 29 1.05507 29 1.4359V21.5385C29 21.9193 28.8472 22.2845 28.5753 22.5538C28.3034 22.8231 27.9346 22.9744 27.55 22.9744H6.45975ZM5.45635 20.1026H26.1V2.87179H2.9V22.0913L5.45635 20.1026ZM13.05 10.0513H15.95V12.9231H13.05V10.0513ZM7.25 10.0513H10.15V12.9231H7.25V10.0513ZM18.85 10.0513H21.75V12.9231H18.85V10.0513Z"
            fill={"#fff"}
          />
        </svg>
        <svg
          onClick={() => navigate("/client/support")}
          className="w-[20px] cursor-pointer"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.75 30C1.25 30 0.833333 29.8333 0.5 29.5C0.166667 29.1667 0 28.75 0 28.25V21.5C0 21.1111 0.125 20.7706 0.375 20.4783C0.625 20.1872 0.944444 20 1.33333 19.9167L7.08333 18.75C7.47222 18.6944 7.86833 18.7289 8.27167 18.8533C8.67389 18.9789 9 19.1667 9.25 19.4167L13.1667 23.3333C15.2778 22.0556 17.2083 20.5417 18.9583 18.7917C20.7083 17.0417 22.1667 15.1667 23.3333 13.1667L19.3333 9.08333C19.0833 8.83333 18.9233 8.54833 18.8533 8.22833C18.7844 7.90944 18.7778 7.55556 18.8333 7.16667L19.9167 1.33333C19.9722 0.944444 20.1528 0.625 20.4583 0.375C20.7639 0.125 21.1111 0 21.5 0H28.25C28.75 0 29.1667 0.166667 29.5 0.5C29.8333 0.833333 30 1.25 30 1.75C30 5.33333 29.2011 8.82611 27.6033 12.2283C26.0067 15.6317 23.8956 18.6456 21.27 21.27C18.6456 23.8956 15.6322 26.0067 12.23 27.6033C8.82667 29.2011 5.33333 30 1.75 30Z"
            fill={"#fff"}
          />
        </svg>
      </div>
    </div>
  );
};

export default ClientSidebar;
