import React from "react";
import { domy_profile } from "assets/images/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";

const OverView = () => {
  return (
    <div className="p-4 rounded shadow-lg mb-4 border">
      <div className="flex justify-between">
        <div
          className="schedule-card border-dashed flex justify-center p-3 rounded"
          style={{ width: "200px" }}
        >
          <div className="">
            <h1 className="text-green-500" style={{ fontSize: "12px" }}>
              Total Lagged Time
            </h1>
            <h1 className="text-blue-600 font-bold">00:59</h1>
          </div>
        </div>
        <div
          className="schedule-card border-dashed flex justify-center p-3 rounded"
          style={{ width: "200px" }}
        >
          <div className="">
            <h1 className="text-blue-500" style={{ fontSize: "12px" }}>
              Total Month Lagged Time
            </h1>
            <h1 className="text-blue-600 font-bold">00:59</h1>
          </div>
        </div>
        <div className="schedule-card border-dashed flex justify-center p-3 rounded">
          <div className="">
            <h1 className="text-green-500" style={{ fontSize: "12px" }}>
              This Month Lagged Time
            </h1>
            <h1 className="text-blue-600 font-bold">00:59</h1>
          </div>
        </div>
        <div className="schedule-card border-dashed flex justify-center p-3 rounded">
          <div className="">
            <h1 className="text-blue-500" style={{ fontSize: "12px" }}>
              Last Week Lagged Time
            </h1>
            <h1 className="text-blue-600 font-bold">00:59</h1>
          </div>
        </div>
        <div className="schedule-card border-dashed flex justify-center p-3 rounded">
          <div className="">
            <h1 className="text-green-500" style={{ fontSize: "12px" }}>
              This Week Lagged Time
            </h1>
            <h1 className="text-blue-600 font-bold">00:59</h1>
          </div>
        </div>
      </div>

      <div>
        <div className="my-12">
          <div className="flex items-center text-lg">
            <FontAwesomeIcon className="opacity-70" icon={faClockRotateLeft} />
            <p className="ml-2">Activities</p>
          </div>
          <p className="text-sm text-gray-500">
            By default all activities of customers are logged for investigating.
            Visit{" "}
            <a className="text-bgBlue cursor-pointer underline">
              Customers Dashboard
            </a>{" "}
            to see the full list
          </p>
        </div>

        {[1, 2, 3, 4].map((e, i) => {
          return (
            <div key={i} className="my-3 flex items-center">
              <img
                src={domy_profile}
                width={60}
                className="rounded-full overflow-hidden"
              />
              <div className="flex justify-between w-full border-2 border-[rgba(0,0,0,.2)] p-3 rounded-lg ml-3">
                <div>
                  <p className="font-semibold">Yui Moore</p>
                  <p className="text-sm">
                    The list "
                    <span className="text-bgBlue">
                      Potential Healthcare Clients
                    </span>
                    " was created!
                  </p>
                </div>
                <div className="flex items-center text-themeGray-medium300 text-sm">
                  <FontAwesomeIcon icon={faClockRotateLeft} />
                  <p className="ml-2">6 months ago</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OverView;
