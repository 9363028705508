import {
  faLanguage,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch } from "@mui/material";
import { Button } from "antd";
import { ThemeTable } from "components/components";
import { useState } from "react";
import AddLangModal from "./addLangModal/addLangModal";
import Translate from "./translate/translate";

const Language = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [translate, setTranslate] = useState(false);
  const [title, setTitle] = useState("");
  const [data, setData] = useState([
    {
      code: "en",
    },
    {
      code: "en",
    },
    {
      code: "en",
    },
    {
      code: "en",
    },
    {
      code: "en",
    },
  ]);
  const columns = [
    {
      title: "Language Name",
      dataIndex: "language",
      render: () => (
        <div className="flex items-center">
          <img
            width={35}
            src="https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/800px-Flag_of_the_United_Kingdom.svg.png"
          />
          <p className="ml-2">English</p>
        </div>
      ),
    },
    {
      title: "Language Code",
      dataIndex: "code",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: () => <Switch />,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record, index) => (
        <div className="text-lg opacity-70">
          <FontAwesomeIcon
            onClick={() => {
              setTitle("Edit Language");
              setIsModalOpen(true);
            }}
            icon={faPenToSquare}
            className="cursor-pointer"
          />
          <FontAwesomeIcon
            onClick={() => setTranslate(true)}
            icon={faLanguage}
            className="mx-3 cursor-pointer"
          />
          <FontAwesomeIcon
            onClick={() => {
              let update = [...data];
              update.splice(index, 1);
              setData(update);
            }}
            icon={faTrashCan}
            className="cursor-pointer"
          />
        </div>
      ),
    },
  ];
  return (
    <div className="bg-themeGray-light200 p-3 mt-4 min-h-[73.8vh] desktop:min-h-[79vh] bigDesktop:min-h-[87vh]">
      <AddLangModal
        title={title}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      {translate ? (
        <>
          <Translate />
          <Button
            className="w-full h-10 mt-4"
            type="primary"
            onClick={() => setTranslate(false)}
          >
            Go Back
          </Button>
        </>
      ) : (
        <div className="bg-white shadow-md">
          <div className="p-3">
            <Button
              onClick={() => {
                setTitle("Add new Language");
                setIsModalOpen(true);
              }}
              type="primary"
              className="h-9"
            >
              Add New Language
            </Button>
          </div>
          <ThemeTable columns={columns} data={data} noPagination noSelectRows />
        </div>
      )}
    </div>
  );
};

export default Language;
