import { useState } from "react";
import { Button } from "antd";
import { ThemeInput } from "components/components";

const Details = () => {
  const [data, setData] = useState({});
  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        <ThemeInput
          noRequired
          placeholder={"First Name"}
          onChange={onChange}
          value={data?.first_name}
          name={"first_name"}
          clearBtn={data.first_name}
          onClear={() => setData({ ...data, first_name: "" })}
        />
        <ThemeInput
          noRequired
          placeholder={"Last Name"}
          onChange={onChange}
          value={data?.last_name}
          name={"last_name"}
          clearBtn={data.last_name}
          onClear={() => setData({ ...data, last_name: "" })}
        />
        <ThemeInput
          noRequired
          placeholder={"Email"}
          onChange={onChange}
          value={data?.email}
          name={"email"}
          clearBtn={data.email}
          onClear={() => setData({ ...data, email: "" })}
        />
        <ThemeInput
          noRequired
          placeholder={"Phone"}
          onChange={onChange}
          value={data?.phone}
          name={"phone"}
          clearBtn={data.phone}
          onClear={() => setData({ ...data, phone: "" })}
        />
        <ThemeInput noRequired placeholder={"Password"} password />
      </div>
      <Button type="primary" className="w-full mt-4 h-10">
        ENABLE TWO FACTOR
      </Button>
    </div>
  );
};

export default Details;
