import { Progress } from "antd";

const Summary = () => {
  return (
    <div className="m-3">
      <div className="flex items-center pb-3">
        <div className="bg-primary h-6 w-6 text-white text-sm flex items-center justify-center rounded-md">
          <p>1</p>
        </div>
        <p className="text-xl ml-2 font-bold editAble" contentEditable>
          Demoproject (testgegevens)
        </p>
      </div>
      <p className="mt-1 mb-3 font-semibold">Project info</p>
      <div className="grid grid-cols-3">
        <div className="col-span-1 flex items-center justify-between pr-2 mb-2">
          <div>
            <p className="font-semibold text-sm">Owners</p>
            <p className="font-semibold my-3 text-sm">Assignees</p>
            <p className="font-semibold text-sm">Customers</p>
          </div>
          <div>
            <p contentEditable className="editAble text-sm">
              marcel asscdscds
            </p>
            <p className="my-3 editAble text-sm" contentEditable>
              Unassigned
            </p>
            <p contentEditable className="editAble text-sm">
              + Customers
            </p>
          </div>
        </div>
        <div className="col-span-1 flex items-center justify-between border-r-2 border-l-2 px-2 border-[rgba(0,0,0,.2)]">
          <div>
            <p className="font-semibold text-sm">Start Date</p>
            <p className="font-semibold my-3 text-sm">End Date</p>
            <p className="font-semibold text-sm">Billing method</p>
          </div>
          <div>
            <p className="text-sm editAble">08-14-2021</p>
            <p className="my-3 text-sm editAble">08-31-2022</p>
            <p className="text-sm editAble">Time</p>
          </div>
        </div>
        <div className="col-span-1 flex items-center justify-between pl-2">
          <div>
            <p className="font-semibold text-sm">Price</p>
            <p className="font-semibold my-3 text-sm">Cost</p>
            <p className="font-semibold text-sm">Price</p>
          </div>
          <div>
            <p className="text-sm editAble">$200</p>
            <p className="my-3 text-sm editAble">$200</p>
            <p className="text-sm editAble">$200</p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 pt-4 mb-4">
        <div className="col-span-1 border-[1px] border-[rgba(0,0,0,.2)] p-3">
          <p className="font-medium">Budget spent (10.4%)</p>
          <Progress />
          <div>
            <div className="flex items-center justify-between mb-[18px]">
              <p className="text-2xl font-bold">$200</p>
              <p className="text-themeGray-dark text-sm font-semibold">
                / $300
              </p>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-sm">Tasks</p>
              <p className="text-sm">$200</p>
            </div>
            <div className="flex mt-1 items-center justify-between">
              <p className="text-sm">Materials</p>
              <p className="text-sm">$250</p>
            </div>
          </div>
        </div>
        <div className="col-span-1 border-[1px] border-[rgba(0,0,0,.2)] p-3">
          <p className="font-medium border-b-2 border-[rgba(0,0,0,.2)] pb-3">
            Margin
          </p>
          <div className="pt-3">
            <div className="flex items-center my-2 justify-between">
              <p className="text-2xl font-bold text-bgBlue mb-2">+ $200</p>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-sm">Price</p>
              <p className="text-sm">$200</p>
            </div>
            <div className="flex mt-1 items-center justify-between">
              <p className="text-sm">Cost</p>
              <p className="text-sm">$250</p>
            </div>
          </div>
        </div>
        <div className="col-span-1 border-[1px] border-[rgba(0,0,0,.2)] p-3">
          <p className="font-medium border-b-2 border-[rgba(0,0,0,.2)] pb-3">
            Time Tracked
          </p>
          <div className="pt-3">
            <div className="flex items-center my-2 justify-between">
              <p className="text-2xl font-bold mb-2">08h00</p>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-sm">Billable</p>
              <p className="text-sm">08h00</p>
            </div>
            <div className="flex mt-1 items-center justify-between">
              <p className="text-sm">Non-billable</p>
              <p className="text-sm">08h00</p>
            </div>
          </div>
        </div>
      </div>

      <p className="mt-1 mb-3 font-semibold">Description</p>
      <p contentEditable className="text-sm editAble">
        After moving a tesk, the resulting change set is animated into place so
        users can track how one change affects other more easily.
      </p>
    </div>
  );
};

export default Summary;
