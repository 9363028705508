import { useRef, useState } from "react";
import { Button, Input } from "antd";
import { LeadsCard } from "components/components";
import { cross_icon } from "assets/icons/icons";

const BoardView = ({ onClick, data, setData, onMob }) => {
  const [text, setText] = useState("");
  const [list, setList] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [showListDesc, setShowListDesc] = useState(false);
  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragAnotherColItem = useRef();
  const dragAnotherColOverItem = useRef();
  const dragStart = (e, position, i) => {
    dragItem.current = position;
    dragAnotherColItem.current = i;
  };
  const dragEnter = (e, position, i) => {
    dragOverItem.current = position;
    dragAnotherColOverItem.current = i;
  };
  const drop = (e, i) => {
    let update = [...data];
    const dragItemContent = update[i].data[dragItem.current];
    update[dragAnotherColItem.current].data.splice(dragItem.current, 1);
    update[dragAnotherColOverItem.current].data.splice(
      dragOverItem.current,
      0,
      dragItemContent
    );
    dragItem.current = null;
    dragOverItem.current = null;
    setData(update);
  };
  const addList = () => {
    setList("");
    if (list) {
      let update = [...data];
      console.log(update[update.length - 1].bgColor);
      update.push({
        title: list,
        data: [],
        selected: false,
        bgColor:
          update[update.length - 1].bgColor === "bg-red-200"
            ? "bg-green-200"
            : update[update.length - 1].bgColor === "bg-green-200"
            ? "bg-blue-200"
            : update[update.length - 1].bgColor === "bg-blue-200"
            ? "bg-orange-200"
            : "bg-red-200",
        txtColor:
          update[update.length - 1].txtColor === "text-red-600"
            ? "text-green-600"
            : update[update.length - 1].txtColor === "text-green-600"
            ? "text-blue-600"
            : update[update.length - 1].txtColor === "text-blue-600"
            ? "text-orange-600"
            : "text-red-600",
      });
      setData(update);
    }
  };
  const addCard = (i) => {
    setText("");
    if (text) {
      let update = [...data];
      update[i].data.push({
        status: "bg-green-400",
        title: text,
      });
      setData(update);
    }
  };
  const handleTitle = (val, i) => {
    let update = [...data];
    update[i].title = val.target.value;
    setData(update);
  };
  return (
    <div
      className={`${
        onMob ? "flex-col items-center w-full" : "w-fit"
      } flex pt-3`}
    >
      {data.map((e, i) => {
        return (
          <div key={i} className="pl-3 w-80">
            <div
              className={`flex items-center justify-between pr-2 ${e.bgColor} ${e.txtColor} p-2 rounded-tr-md rounded-tl-md`}
            >
              <Input
                className={`!bg-transparent
                   !border-none font-medium ${e.txtColor}`}
                value={e.title}
                onChange={(val) => handleTitle(val, i)}
              />
              <p className="text-sm">$1,000</p>
            </div>
            <div className="p-2 bg-themeGray-light200 overflow-y-scroll scrol mb-2 rounded-md h-[67.5vh] desktop:h-[71.5vh] bigDesktop:h-[75.5vh]">
              {e.data.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="mb-2"
                    draggable
                    onDragStart={(e) => dragStart(e, index, i)}
                    onDragEnter={(e) => dragEnter(e, index, i)}
                    onDragEnd={() => drop(e, i)}
                  >
                    <LeadsCard
                      title={item.title}
                      statusColor={item.status}
                      onClick={onClick}
                    />
                  </div>
                );
              })}
              {selectedId === i ? (
                <div className="my-3">
                  <Input.TextArea
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Enter Title for card"
                  />
                  <div className="flex items-center mt-2">
                    <Button type="primary" onClick={() => addCard(i)}>
                      Add Lead
                    </Button>
                    <img
                      src={cross_icon}
                      onClick={() => setSelectedId("")}
                      alt=""
                      className="ml-3 cursor-pointer opacity-50"
                      width={16}
                    />
                  </div>
                </div>
              ) : (
                <p
                  onClick={() => setSelectedId(i)}
                  className="text-themeGray-dark font-medium text-center text-sm py-2 cursor-pointer"
                >
                  + New Lead
                </p>
              )}
            </div>
          </div>
        );
      })}
      {showListDesc ? (
        <div className={`px-3 ${onMob && "pb-3"}`}>
          <Input.TextArea
            value={list}
            onChange={(e) => setList(e.target.value)}
            rows={1}
            placeholder="Enter list title"
            className={onMob ? "w-72" : "w-48"}
          />
          <div className="flex items-center mt-2">
            <Button type="primary" onClick={addList}>
              Add List
            </Button>
            <img
              src={cross_icon}
              onClick={() => setShowListDesc(false)}
              alt=""
              className="ml-3 cursor-pointer opacity-50"
              width={16}
            />
          </div>
        </div>
      ) : (
        <Button
          onClick={() => setShowListDesc(true)}
          className={`h-10 !bg-themeGray-light200 hover:!text-black !border-none mx-3 ${
            onMob ? "w-72 mb-3" : "w-48"
          } text-sm font-medium`}
        >
          Add another list
        </Button>
      )}
    </div>
  );
};

export default BoardView;
