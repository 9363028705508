import { home_icon } from "assets/icons/icons";
import { DatePicker } from "antd";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useParams } from "react-router-dom";
const {
  faArrowsRotate,
  faEnvelope,
  faDownload,
  faPrint,
  faEllipsisVertical,
  faXmark,
} = require("@fortawesome/free-solid-svg-icons");
const { FontAwesomeIcon } = require("@fortawesome/react-fontawesome");
const {
  Container,
  FilterOptions,
  ThemeTable,
} = require("components/components");

const ProfitLoss = () => {
  const { type } = useParams();
  //Table
  const columns = [
    {
      title: "",
      dataIndex: "staffMember",
      key: "staffMember",
    },
    {
      title: "",
      dataIndex: "task",
      key: "task",
    },
    {
      title: "",
      dataIndex: "timesheetTags",
      key: "timesheetTags",
    },
    {
      title: "",
      dataIndex: "timeStart",
      key: "timeStart",
    },
    {
      title: "",
      dataIndex: "endTime",
      key: "endTime",
    },
    {
      title: "Debit",
      dataIndex: "note",
      key: "note",
    },
    {
      title: "",
      dataIndex: "related",
      key: "related",
    },
    {
      title: " ",
      dataIndex: "timeN",
      key: "timeN",
    },
    {
      title: "Credit",
      dataIndex: "timeDecimal",
      key: "timeDecimal",
    },
  ];

  // Define table data with row data
  const data = [
    {
      key: "1",
      staffMember: "Turnover",
      task: "",
      timesheetTags: "",
      timeStart: "",
      endTime: "",
      note: "",
      related: "",
      timeN: "",
      timeDecimal: "54,072",
    },
    {
      key: "1",
      staffMember: "Sales",
      task: "",
      timesheetTags: "",
      timeStart: "",
      endTime: "",
      note: "",
      related: "",
      timeN: "",
      timeDecimal: "54,072",
    },
    {
      key: "2",
      staffMember: "Apples",
      task: "",
      timesheetTags: "",
      timeStart: "",
      endTime: "",
      note: "",
      related: "",
      timeN: "",
      timeDecimal: "50,922",
    },
    {
      key: "3",
      staffMember: "Loss Administration Expenses",
      task: "",
      timesheetTags: "",
      timeStart: "",
      endTime: "",
      note: "25,385",
      related: "",
      timeN: "",
      timeDecimal: "",
    },
    {
      key: "4",
      staffMember: "Milaege",
      task: "",
      timesheetTags: "",
      timeStart: "",
      endTime: "",
      note: "0",
      related: "",
      timeN: "",
      timeDecimal: "",
    },
    {
      key: "5",
      staffMember: "Office Costs",
      task: "",
      timesheetTags: "",
      timeStart: "",
      endTime: "",
      note: "393",
      related: "",
      timeN: "",
      timeDecimal: "",
    },
    {
      key: "6",
      staffMember: "Computer Hardware",
      task: "",
      timesheetTags: "",
      timeStart: "",
      endTime: "",
      note: "240",
      related: "",
      timeN: "",
      timeDecimal: "",
    },
    {
      key: "7",
      staffMember: "Printing",
      task: "",
      timesheetTags: "",
      timeStart: "",
      endTime: "",
      note: "16",
      related: "",
      timeN: "",
      timeDecimal: "",
    },
    {
      key: "8",
      staffMember: "Accomendations and Meals",
      task: "",
      timesheetTags: "",
      timeStart: "",
      endTime: "",
      note: "36",
      related: "",
      timeN: "",
      timeDecimal: "",
    },
    {
      key: "9",
      staffMember: "Stationary",
      task: "",
      timesheetTags: "",
      timeStart: "",
      endTime: "",
      note: "36",
      related: "",
      timeN: "",
      timeDecimal: "",
    },
    {
      key: "10",
      staffMember: "Consultncy Fees",
      task: "",
      timesheetTags: "",
      timeStart: "",
      endTime: "",
      note: "47",
      related: "",
      timeN: "",
      timeDecimal: "",
    },
    {
      key: "11",
      staffMember: "Insurance",
      task: "",
      timesheetTags: "",
      timeStart: "",
      endTime: "",
      note: "0",
      related: "",
      timeN: "",
      timeDecimal: "",
    },
    {
      key: "12",
      staffMember: "Realized Currency Exchange Gain/Loss",
      task: "",
      timesheetTags: "",
      timeStart: "",
      endTime: "",
      note: "-33",
      related: "",
      timeN: "",
      timeDecimal: "",
    },
    {
      key: "13",
      staffMember: "UnRealized Currency Exchange Gain/Loss",
      task: "",
      timesheetTags: "",
      timeStart: "",
      endTime: "",
      note: "-3",
      related: "",
      timeN: "",
      timeDecimal: "",
    },
    {
      key: "14",
      staffMember: "Less Staff Cost",
      task: "",
      timesheetTags: "",
      timeStart: "",
      endTime: "",
      note: "14,675",
      related: "",
      timeN: "",
      timeDecimal: "",
    },
    {
      key: "15",
      staffMember: "Directors Salaries",
      task: "",
      timesheetTags: "",
      timeStart: "",
      endTime: "",
      note: "14,500",
      related: "",
      timeN: "",
      timeDecimal: "",
    },
    {
      key: "16",
      staffMember: "Directors Employer Salarie NIC's",
      task: "",
      timesheetTags: "",
      timeStart: "",
      endTime: "",
      note: "0",
      related: "",
      timeN: "",
      timeDecimal: "",
    },
    {
      key: "17",
      staffMember: "Director's Staff Pension",
      task: "",
      timesheetTags: "",
      timeStart: "",
      endTime: "",
      note: "175",
      related: "",
      timeN: "",
      timeDecimal: "",
    },
    {
      key: "18",
      staffMember: "Less  Depreciation charge",
      task: "",
      timesheetTags: "",
      timeStart: "",
      endTime: "",
      note: "20,044",
      related: "",
      timeN: "",
      timeDecimal: "",
    },
    {
      key: "19",
      staffMember: "Less  Depreciation charge",
      task: "",
      timesheetTags: "",
      timeStart: "",
      endTime: "",
      note: "20,044",
      related: "",
      timeN: "",
      timeDecimal: "",
    },
  ];

  const handleRowClick = (record) => {
    console.log("Clicked row:", record);
  };

  const handleDataChange = (updatedData) => {
    console.log("Updated data:", updatedData);
  };
  return (
    <Container superAdmin={type === "backend" && true} active={"Reports"}>
      <div className="flex">
        <div className="w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
          <div className="bg-white" style={{ top: 54 }}>
            <div className="flex items-center justify-between px-5 py-3">
              <div className="flex items-center">
                <p className="font-medium text-sm">Company</p>
                <img src={home_icon} alt="" className="mr-3 ml-8" />
                <p className="font-medium text-sm">
                  Home
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    Report
                  </a>
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    Default
                  </a>
                </p>
              </div>
              <div className="text-end pe-5">
                <div className="flex items-center">
                  <div className="me-4">
                    <FontAwesomeIcon icon={faArrowsRotate} />
                  </div>
                  <p className="p-0 m-0 text-gray-700">
                    Data refreshed at jul 18, 2023 04:06 PM
                  </p>
                  <div className="flex ">
                    <div className="ms-4">
                      <FontAwesomeIcon icon={faEnvelope} className="fs-1" />
                    </div>
                    <div className="ms-4">
                      <FontAwesomeIcon icon={faDownload} />
                    </div>
                    <div className="ms-4">
                      <FontAwesomeIcon icon={faPrint} />
                    </div>
                  </div>
                  <FontAwesomeIcon
                    icon={faEllipsisVertical}
                    className="fs-1 mx-4"
                  />
                  <FontAwesomeIcon icon={faXmark} className="fs-1" />
                </div>
              </div>
            </div>
            <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
          </div>
          <FilterOptions onTop />
          <div className="mt-7">
            <div className="px-4">
              <div className="font-bold text-xl mb-2">Profit & Loss</div>
              <div className="rounded border shadow-lg w-full mb-5">
                <div className="bg-gray-100 p-4 flex justify-between">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="DD-MM-YYYY"
                      label="Current year to date"
                      className=""
                      slotProps={{ textField: { variant: "filled" } }}
                    />
                  </LocalizationProvider>
                  <div>
                    <p className="text-gray-700 font-bold">
                      Current Accounting Year to 25 May 22
                    </p>
                  </div>
                </div>
                <ThemeTable
                  columns={columns}
                  data={data}
                  onClick={handleRowClick}
                  setData={handleDataChange}
                  density="default"
                  noSelectRows={true}
                  noPagination={true}
                />
                <div className="bg-gray-100">
                  <div className="flex justify-between p-3">
                    <p className="text-gray-700 font-bold">Operating Profit</p>
                    <p className="text-gray-700 font-bold">$11,967</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ProfitLoss;
