import { useEffect, useRef, useState } from "react";
import { faSort, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input } from "antd";
import { SketchPicker } from "react-color";

const Status = ({ name }) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selected, setSelected] = useState("");
  const colorRef = useRef();
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowColorPicker(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(colorRef);
  const [data, setData] = useState([
    {
      name: "New",
      color: "blue",
    },
    {
      name: "Contact",
      color: "green",
    },
    {
      name: "Loss",
      color: "red",
    },
  ]);
  const onChange = (val, i) => {
    let update = [...data];
    update[i].name = val.target.value;
    setData(update);
  };
  const updateColor = (val, i) => {
    let update = [...data];
    update[i].color = val;
    setData(update);
  };
  const handleAddStatus = () => {
    let update = [...data];
    update.push({
      name: "Write text",
      color: "green",
    });
    setData(update);
  };
  const handleDeleteStatus = (i) => {
    let update = [...data];
    update.splice(i, 1);
    setData(update);
  };
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white shadow-md">
        <div className="grid grid-cols-10 border-y-2 border-[rgba(0,0,0,.2)] py-3 px-2">
          <div className="col-span-8">
            <p className="font-medium">{name ? name : "Status Name"}</p>
          </div>
          <div className="col-span-1">
            <p>Color</p>
          </div>
          <div className="col-span-1"></div>
        </div>
        {data.map((e, i) => {
          return (
            <div
              key={i}
              className="grid grid-cols-10 py-3 px-2 border-b-[1px] border-[rgba(0,0,0,.1)]"
            >
              <div className="col-span-1 flex justify-center items-center opacity-40">
                <FontAwesomeIcon icon={faSort} className="cursor-pointer" />
              </div>
              <div className="col-span-7 mr-4">
                <Input value={e.name} onChange={(val) => onChange(val, i)} />
              </div>
              <div className="col-span-1 flex items-center">
                <div
                  onClick={() => {
                    setSelected(i);
                    setShowColorPicker(true);
                  }}
                  style={{ backgroundColor: e.color }}
                  className="h-6 w-6 rounded-md ml-2 cursor-pointer"
                ></div>
                {showColorPicker && selected === i && (
                  <div className="relative z-10" ref={colorRef}>
                    <SketchPicker
                      color={e.color}
                      className="absolute right-20"
                      onChangeComplete={(val) => updateColor(val.hex, i)}
                    />
                  </div>
                )}
              </div>
              <div className="col-span-1 flex items-center justify-end">
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className="opacity-50 cursor-pointer mr-3 text-lg"
                  onClick={() => handleDeleteStatus(i)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <Button type="primary w-full mt-4 h-9" onClick={handleAddStatus}>
        New {name ? name : "Status"}
      </Button>
    </div>
  );
};

export default Status;
