import { useState } from "react";
import { submenu_icon } from "assets/icons/icons";
import { profile_pic } from "assets/images/images";
import { Dropdown, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { faFaceSmile } from "@fortawesome/free-regular-svg-icons";
import { SendOutlined } from "@ant-design/icons";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

const ChatPanel = ({ onInfo }) => {
  const items = [
    {
      key: "1",
      label: <p onClick={onInfo}>Contact info</p>,
    },
  ];
  const [emojis, setEmojis] = useState(false);
  const [msg, setMsg] = useState("");
  return (
    <div>
      <div>
        <div className="border-b-2 border-[rgba(0,0,0,.2)] sticky top-0 z-10 bg-white">
          <div className="flex items-center justify-between px-4 py-3">
            <div className="flex items-center">
              <div className="relative">
                <img src={profile_pic} alt="" width={45} />
                <div className="h-3 w-3 absolute right-0 bottom-0 bg-green-500 border-[1px] border-white rounded-full"></div>
              </div>
              <p className="ml-3 text-sm font-medium">Brian Hughes</p>
            </div>
            <Dropdown
              menu={{
                items,
              }}
              placement="bottomLeft"
              trigger={["click"]}
            >
              <img
                src={submenu_icon}
                width={4}
                alt=""
                className="cursor-pointer"
              />
            </Dropdown>
          </div>
        </div>
        <div className="flex flex-col justify-end z-0">
          <div className="flex justify-end mt-3">
            <p className="bg-gray-200 text-sm rounded-[5px] py-2 px-3 mx-3">
              How are you?
            </p>
          </div>
          <p className="text-xs opacity-90 text-end mb-4 mx-5 mt-1">
            over 1 year ago
          </p>
          <div className="flex">
            <p className="bg-blue-200 text-sm rounded-[5px] py-2 px-3 mx-3">
              I am fine!
            </p>
          </div>
          <p className="text-xs opacity-90 mb-4 mx-5 mt-1">over 1 year ago</p>
          <div className="flex justify-end">
            <p className="bg-gray-200 text-sm rounded-[5px] py-2 px-3 mx-3">
              What are you doing?
            </p>
          </div>
          <p className="text-xs opacity-90 text-end mb-4 mx-5 mt-1">
            over 1 year ago
          </p>
          <div className="flex">
            <p className="bg-blue-200 text-sm rounded-[5px] py-2 px-3 mx-3">
              I am developing an awesome project!
            </p>
          </div>
          <p className="text-xs opacity-90 mb-4 mx-5 mt-1">over 1 year ago</p>
          <div className="flex justify-end">
            <p className="bg-gray-200 text-sm rounded-[5px] py-2 px-3 mx-3">
              How are you?
            </p>
          </div>
          <p className="text-xs opacity-90 text-end mb-4 mx-5 mt-1">
            over 1 year ago
          </p>
          <div className="flex">
            <p className="bg-blue-200 text-sm rounded-[5px] py-2 px-3 mx-3">
              I am fine!
            </p>
          </div>
          <p className="text-xs opacity-90 mb-4 mx-5 mt-1">over 1 year ago</p>
          <div className="flex justify-end">
            <p className="bg-gray-200 text-sm rounded-[5px] py-2 px-3 mx-3">
              What are you doing?
            </p>
          </div>
          <p className="text-xs opacity-90 text-end mb-4 mx-5 mt-1">
            over 1 year ago
          </p>
          <div className="flex">
            <p className="bg-blue-200 text-sm rounded-[5px] py-2 px-3 mx-3">
              I am developing an awesome project!
            </p>
          </div>
          <p className="text-xs opacity-90 mb-4 mx-5 mt-1">over 1 year ago</p>
          <div className="flex justify-end">
            <p className="bg-gray-200 text-sm rounded-[5px] py-2 px-3 mx-3">
              How are you?
            </p>
          </div>
          <p className="text-xs opacity-90 text-end mb-4 mx-5 mt-1">
            over 1 year ago
          </p>
          <div className="flex">
            <p className="bg-blue-200 text-sm rounded-[5px] py-2 px-3 mx-3">
              I am fine!
            </p>
          </div>
          <p className="text-xs opacity-90 mb-4 mx-5 mt-1">over 1 year ago</p>
          <div className="flex justify-end">
            <p className="bg-gray-200 text-sm rounded-[5px] py-2 px-3 mx-3">
              What are you doing?
            </p>
          </div>
          <p className="text-xs opacity-90 text-end mb-4 mx-5 mt-1">
            over 1 year ago
          </p>
          <div className="flex">
            <p className="bg-blue-200 text-sm rounded-[5px] py-2 px-3 mx-3">
              I am developing an awesome project!
            </p>
          </div>
          <p className="text-xs opacity-90 mb-4 mx-5 mt-1">over 1 year ago</p>
        </div>
      </div>
      {emojis && (
        <div className="relative">
          <div className="fixed bottom-32">
            <Picker
              data={data}
              onEmojiSelect={(e) => {
                setEmojis(false);
                setMsg(msg + e.native);
              }}
            />
          </div>
        </div>
      )}
      <div className="border-t-2 border-[rgba(0,0,0,.2)] sticky bottom-0 bg-white z-10">
        <div className="px-4 py-3 flex justify-between items-center">
          <FontAwesomeIcon
            onClick={() => {
              emojis && setEmojis(false);
              !emojis && setEmojis(true);
            }}
            icon={faFaceSmile}
            className="cursor-pointer"
            style={{ fontSize: 20 }}
          />
          <FontAwesomeIcon
            icon={faPaperclip}
            className="mx-6 cursor-pointer"
            style={{ fontSize: 20 }}
          />
          <Input
            value={msg}
            onChange={(e) => setMsg(e.target.value)}
            className="h-10 !rounded-[5px] px-4"
            placeholder="Type your message"
          />
          <SendOutlined
            className="ml-6 cursor-pointer"
            style={{ fontSize: 20 }}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatPanel;
