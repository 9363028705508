import { useState } from "react";
import { Table } from "antd";
import { delete_icon } from "assets/icons/icons";

const ThemeTable = ({
  columns,
  data,
  onClick,
  density,
  noSelectRows,
  noPagination,
  rowClassName,
  ...props
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <>
      {selectedRowKeys.length > 0 && (
        <div className="py-3 text-sm px-5 flex items-center justify-between">
          <p>Selected {selectedRowKeys.length} items</p>
          <img src={delete_icon} alt="" width={14} className="cursor-pointer" />
        </div>
      )}
      <Table
        pagination={
          !noPagination && {
            // pageSize: pageSize,
            total: 120,
          }
        }
        columns={columns}
        dataSource={data}
        rowClassName={onClick ? "cursor-pointer" : rowClassName}
        size={density}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => onClick && onClick(event, record, rowIndex),
          };
        }}
        rowSelection={!noSelectRows && rowSelection}
        {...props}
      />
    </>
  );
};

export default ThemeTable;
