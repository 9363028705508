import { useState } from "react";
import { Container, ThemeTable } from "components/components";
import { cross_icon, download_icon, print_icon } from "assets/icons/icons";
import OverView from "./overView/overView";
import { ClientQuotes } from "constant/constant";

const Quotes = () => {
  const [detailsModal, setDetailsModal] = useState("detailsModalClose");
  const handleCompanyDetails = () => {
    setDetailsModal("detailsModalOpen");
  };
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      width: "10%",
    },
    {
      title: "Invoice",
      dataIndex: "invoice",
      width: "60%",
      render: (text) => (
        <div>
          <p>{text}</p>
          <p className="text-xs text-themeGray-medium300">
            09/May/2023 * 26/May/2023
          </p>
        </div>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: "15%",
      render: (text) => (
        <div className="flex justify-end">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "15%",
      render: (text) => (
        <div className="flex justify-end">
          <div
            className={`${
              text === "Accept"
                ? "bg-green-500"
                : text === "Decline"
                ? "bg-red-500"
                : text === "Waiting"
                ? "bg-orange-500"
                : text === "New" && "bg-blue-500"
            } text-white w-3/4 h-7 text-sm flex justify-center items-center rounded-[5px]`}
          >
            <p>{text}</p>
          </div>
        </div>
      ),
    },
  ];

  return (
    <Container client active={"Quotes"}>
      <div className="flex">
        <div className="w-full overflow-y-scroll h-screen scrol">
          <div className="bg-white" style={{ top: 54 }}>
            <div className="flex items-center px-5 py-3">
              <p className="font-semibold">Quotes</p>
            </div>
            <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
          </div>
          <div className="-mt-3">
            <ThemeTable
              noSelectRows
              noPagination
              showHeader={false}
              onClick={handleCompanyDetails}
              columns={columns}
              data={ClientQuotes}
            />
          </div>
        </div>

        <div
          className={`bg-white ${detailsModal} overflow-y-scroll h-screen scrol`}
        >
          {detailsModal === "detailsModalOpen" && (
            <>
              <div className="flex items-center justify-between w-full pr-5 shadow-md border-b-2 border-[rgba(0,0,0,.2)] bg-white sticky top-0">
                <div className="h-11 items-center w-[432px] flex">
                  <div className="pl-3">
                    <p className="font-semibold">
                      Quote ID: QUNNQ7PU7TLZAITMD5WFCZB66TF4
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <img src={download_icon} alt="" className="mr-4" />
                  <img src={print_icon} alt="" className="mr-4" />
                  <img
                    src={cross_icon}
                    alt=""
                    className="cursor-pointer opacity-70"
                    width={16}
                    onClick={() => setDetailsModal("detailsModalClose")}
                  />
                </div>
              </div>
              <OverView />
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Quotes;
