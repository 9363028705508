import React, { useState } from "react";
import { Radio, Space } from "antd";
import { ThemeInput } from "components/components";

const Invoice = () => {
  const [value, setValue] = useState(1);
  const [value2, setValue2] = useState(1);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        <ThemeInput
          placeholder={"Hour of day to perform automatic operations"}
        />
        <p className="mt-3">Overdue Notices</p>
        <p className="text-sm">
          Overdue notices are sent when the invoice becomes overdue.
        </p>
        <div className="grid grid-cols-2 gap-5">
          <ThemeInput placeholder={"Auto send reminder after (days)"} />
          <ThemeInput placeholder={"Auto re-send reminder after (days)"} />
        </div>
        <p className="mt-3">Due Reminders</p>
        <p className="text-sm">
          Due reminders are sent to unpaid and partially paid invoices as
          reminder to the customer to pay the invoice before is due.
        </p>
        <div className="grid grid-cols-2 gap-5">
          <ThemeInput
            placeholder={"Send due reminder X days before due date"}
          />
          <ThemeInput placeholder={"Auto re-send reminder after (days)"} />
        </div>
        <p className="mt-3 pb-2">Recurring Invoices</p>
        <Radio.Group onChange={onChange} value={value}>
          <Space direction="vertical">
            <Radio value={1}>
              Generate and autosend the renewed invoice to the customer
            </Radio>
            <Radio value={2}>Generate a Unpaid Invoice</Radio>
            <Radio value={3}>Generate a Draft Invoice</Radio>
          </Space>
        </Radio.Group>
        <p className="mt-5 pb-2">Recurring Invoices</p>
        <Radio.Group onChange={(e) => setValue2(e.target.value)} value={value2}>
          <Radio value={1}>Yes</Radio>
          <Radio value={2}>No</Radio>
        </Radio.Group>
      </div>
    </div>
  );
};

export default Invoice;
