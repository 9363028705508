import { faShareFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch } from "@mui/material";
import { Tabs } from "antd";
import Authorization from "./authorization/authorization";
import Customize from "./customize/customize";
import Message from "./message/message";
import Settings from "./settings/settings";

const ClientPortal = () => {
  const items = [
    {
      key: "1",
      label: `Settings`,
      children: <Settings />,
    },
    {
      key: "2",
      label: `Registration`,
      children: (
        <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
          <div className="bg-white p-3 shadow-md">
            <div className="flex items-center justify-between mt-3">
              <div className="flex items-center">
                <FontAwesomeIcon
                  icon={faShareFromSquare}
                  className="opacity-50 mr-5 text-xl"
                />
                <div>
                  <p>Client Registration</p>
                  <p className="text-themeGray-dark text-xs">
                    Enable clients to self register in the portal
                  </p>
                </div>
              </div>
              <Switch defaultChecked />
            </div>
          </div>
        </div>
      ),
    },
    {
      key: "3",
      label: `Authorization`,
      children: <Authorization />,
    },
    {
      key: "4",
      label: `Messages`,
      children: <Message />,
    },
    {
      key: "5",
      label: `Customize`,
      children: <Customize />,
    },
  ];
  return (
    <div className="ml-2">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default ClientPortal;
