import { useState } from "react";
import { Modal } from "antd";
import { ThemeInput } from "components/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const CategoryModal = ({
  isModalOpen,
  setIsModalOpen,
  categories,
  setCategories,
}) => {
  const [value, setValue] = useState("");
  const handleOk = () => {
    if (value) {
      let update = [...categories];
      update.push(value);
      setCategories(update);
      setValue("");
      setIsModalOpen(false);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleDelete = (i) => {
    let update = [...categories];
    update.splice(i, 1);
    setCategories(update);
  };
  return (
    <Modal
      title="Admin FAQ Category"
      open={isModalOpen}
      onOk={handleOk}
      okText={"Save"}
      onCancel={handleCancel}
    >
      {categories.map((e, i) => {
        return (
          <div key={i} className="py-2 flex items-center">
            <p className="w-36">{e}</p>
            <FontAwesomeIcon
              icon={faTrash}
              className="opacity-70 cursor-pointer"
              onClick={() => handleDelete(i)}
            />
          </div>
        );
      })}
      <ThemeInput
        placeholder={"Category Name"}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </Modal>
  );
};

export default CategoryModal;
