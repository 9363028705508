import { useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { ThemeInput, ThemeSelect } from "components/components";
import Inter from "assets/font/Inter.ttf";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";

const theme = createTheme({
  typography: {
    fontFamily: Inter,
  },
  palette: {
    primary: {
      main: "#00A1E4",
    },
  },
});

const ProductInfo = ({ className }) => {
  const [categories, setCategories] = useState([1]);
  const [taxes, setTaxes] = useState([1]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalTax, setIsModalTax] = useState(false);
  const handleCategories = () => {
    let update = [...categories];
    update.push(Math.random);
    setCategories(update);
    setIsModalVisible(false);
  };
  const handleTaxes = () => {
    let update = [...taxes];
    update.push(Math.random);
    setTaxes(update);
    setIsModalTax(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleCancelTax = () => {
    setIsModalTax(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <Modal
        title="Add Category"
        footer={false}
        open={isModalVisible}
        onCancel={handleCancel}
      >
        <div className="flex justify-center">
          <Button
            onClick={handleCategories}
            type="primary"
            className="bg-primary h-10 w-52"
          >
            Add New
          </Button>
        </div>
      </Modal>
      <Modal
        title="Add Tax"
        footer={false}
        open={isModalTax}
        onCancel={handleCancelTax}
      >
        <div className="flex justify-center">
          <Button
            onClick={handleTaxes}
            type="primary"
            className="bg-primary h-10 w-52"
          >
            Add New
          </Button>
        </div>
      </Modal>
      <div className="bg-themeGray-light200 p-3 mt-2">
        <div className="bg-white px-3 pb-3 pt-2 my-2  shadow-md">
          <ThemeInput placeholder={"Product Name"} />
          <ThemeInput placeholder={"Product code"} />
          <ThemeInput placeholder={"SKU code"} />
        </div>
        <div className="bg-white px-3 pb-3 pt-2 my-2  shadow-md">
          {categories.map((e, i) => {
            return (
              <div key={i} className="flex items-center justify-between">
                <ThemeSelect
                  width={"92%"}
                  className={"!mt-3"}
                  label={"Category*"}
                  data={["General", "Normal"]}
                />
                <div
                  onClick={() => setIsModalVisible(true)}
                  className="w-12 h-12 border-b-[1px] mt-[10px] items-center border-[rgba(0,0,0,.4)] flex justify-center"
                >
                  <PlusOutlined
                    style={{ color: "rgba(0,0,0,.6)" }}
                    className="cursor-pointer"
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="bg-white px-3 pb-3 pt-2 my-2  shadow-md">
          {taxes.map((e, i) => {
            return (
              <div key={i} className="flex items-center justify-between">
                <ThemeSelect
                  width={"92%"}
                  className={"!mt-3"}
                  label={"Tax*"}
                  data={["10%", "20%", "30%"]}
                />
                <div
                  onClick={() => setIsModalTax(true)}
                  className="w-12 h-12 border-b-[1px] mt-[10px] items-center border-[rgba(0,0,0,.4)] flex justify-center"
                >
                  <PlusOutlined
                    style={{ color: "rgba(0,0,0,.6)" }}
                    className="cursor-pointer"
                  />
                </div>
              </div>
            );
          })}
          <ThemeInput placeholder={"Description"} multiline rows={4} />
          <ThemeInput placeholder={"Price"} />
          <ThemeInput placeholder={"Default Quantity"} />
          <ThemeInput placeholder={"Max Quantity"} />
          <ThemeInput placeholder={"Image URL"} />
        </div>
      </div>
    </ThemeProvider>
  );
};

export { ProductInfo };
