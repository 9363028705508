import React from "react";
import ReactApexChart from "react-apexcharts";

const chartData = {
  series: [
    {
      name: "2021 Invoices",
      data: [10, 20, 30, 40, 50, 60, 30, 40, 60],
    },
    {
      name: "2022 Invoices",
      data: [0, 0, 0, 0, 20, 45, 67, 23, 27],
    },
  ],
  options: {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    colors: ["#50b83c", "#dee5f1"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    legend: {
      position: "right",
      offsetX: -30,
      offsetY: 0,
      markers: {
        radius: 0,
      },
    },
    toolbar: {
      show: true, // Set this to false to remove the toolbar
    },
    xaxis: {
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " thousands";
        },
      },
    },
  },
};

const RevenueChart = ({ height }) => {
  return (
    <div id="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={height}
      />
    </div>
  );
};

export default RevenueChart;
