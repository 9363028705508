import React from "react";
import { Col, Row } from "antd";
import ReactApexChart from "react-apexcharts";
import { Container, FilterOptions } from "components/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faDownload,
  faEllipsisVertical,
  faEnvelope,
  faPrint,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { home_icon } from "assets/icons/icons";
import { useParams } from "react-router-dom";

const Summary = () => {
  const { type } = useParams();
  const chartData = {
    series: [44, 55],
    options: {
      chart: {
        type: "donut",
      },
      legend: {
        show: false,
      },
      colors: ["#5ab2ed", "#85ce77"],
      plotOptions: {
        pie: {
          donut: {
            size: "30%",
          },
        },
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: false,
            },
          },
        },
      ],
    },
  };
  const chartData2 = {
    series: [44, 55, 20, 40, 30],
    options: {
      chart: {
        type: "donut",
      },
      legend: {
        show: false,
      },
      colors: ["#5ab2ed", "#85ce77", "#ffea8a", "#c9d2e3", "#b3bcf5"],
      plotOptions: {
        pie: {
          donut: {
            size: "30%",
          },
        },
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: false,
            },
          },
        },
      ],
    },
  };

  return (
    <Container superAdmin={type === "backend" && true} active={"Reports"}>
      <div className="w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
        <div className="bg-white" style={{ top: 54 }}>
          <div className="flex items-center justify-between px-5 py-3">
            <div className="flex items-center">
              <p className="font-medium text-sm">Company</p>
              <img src={home_icon} alt="" className="mr-3 ml-8" />
              <p className="font-medium text-sm">
                Home
                <span className="text-bgBlue"> / </span>
                <a href="#" className="text-bgBlue">
                  Report
                </a>
                <span className="text-bgBlue"> / </span>
                <a href="#" className="text-bgBlue">
                  Default
                </a>
              </p>
            </div>
            <div className="text-end pe-5">
              <div className="flex items-center">
                <div className="me-4">
                  <FontAwesomeIcon icon={faArrowsRotate} />
                </div>
                <p className="p-0 m-0 text-gray-700">
                  Data refreshed at jul 18, 2023 04:06 PM
                </p>
                <div className="flex ">
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faEnvelope} className="fs-1" />
                  </div>
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faDownload} />
                  </div>
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faPrint} />
                  </div>
                </div>
                <FontAwesomeIcon
                  icon={faEllipsisVertical}
                  className="fs-1 mx-4"
                />
                <FontAwesomeIcon icon={faXmark} className="fs-1" />
              </div>
            </div>
          </div>
          <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
        </div>
        <FilterOptions onTop />
        <div className="mt-7 mb-5">
          <div className="px-4">
            <div className="font-bold text-xl mb-2">Summary </div>
            <section className="summery-sec rounded-md border shadow-lg">
              <div>
                <Row className="border-b py-4">
                  <Col
                    span={12}
                    sm={12}
                    md={8}
                    lg={6}
                    className="text-center my-2"
                  >
                    <p>Activity types</p>
                    <strong className="text-lg">5</strong>
                  </Col>
                  <Col
                    span={12}
                    sm={12}
                    md={8}
                    lg={6}
                    className="text-center my-2"
                  >
                    <p>Activity types</p>
                    <strong className="text-lg">
                      42:30 <sub> h</sub>
                    </strong>
                  </Col>
                  <Col
                    span={12}
                    sm={12}
                    md={8}
                    lg={6}
                    className="text-center my-2"
                  >
                    <p>Activity types</p>
                    <strong className="text-lg">5</strong>
                  </Col>
                  <Col
                    span={12}
                    sm={12}
                    md={8}
                    lg={6}
                    className="text-center my-2"
                  >
                    <p>Activity types</p>
                    <strong className="text-lg">5</strong>
                  </Col>
                </Row>
              </div>
              <div className="px-5">
                <h3 className="text-[#0085E0] text-[16px] border-b-2 pt-6 pb-3">
                  People
                </h3>
                <Row className="items-center py-3 border-b">
                  <Col span={24} md={12}>
                    <span className="font-semibold">sandjai pr</span>
                  </Col>
                  <Col span={24} md={12}>
                    <div class="relative pt-1">
                      <div class="overflow-hidden h-[1.125rem] text-xs flex rounded bg-white">
                        <span className="mr-2"> 42:30 h</span>
                        <div
                          style={{ width: "200px" }}
                          class="shadow-none rounded-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-[#50B83C]"
                        ></div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <h3 className="text-[#0085E0] text-[16px] border-b-2 pt-6 pb-3">
                  Activity Group
                </h3>
                <Row>
                  <Col md={12}>
                    <div className="flex items-center justify-between border-b py-3">
                      <div className="flex items-center">
                        <span className="bg-[#5ab2ed] p-2 mr-2"></span>
                        <p className="font-semibold m-0 text-[#1F2226] text-[14px]">
                          2. Non-billable / admin
                        </p>
                      </div>
                      <span className="block">21:30 h</span>
                    </div>
                    <div className="flex items-center justify-between border-b py-3">
                      <div className="flex items-center">
                        <span className="bg-[#85ce77] p-2 mr-2"></span>
                        <p className="font-semibold m-0 text-[#1F2226] text-[14px]">
                          1. Billable / client work
                        </p>
                      </div>
                      <span className="block">21:30 h</span>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div id="chart">
                      <ReactApexChart
                        options={chartData.options}
                        series={chartData.series}
                        type="donut"
                        height={300}
                      />
                    </div>
                  </Col>
                </Row>

                <h3 className="text-[#0085E0] text-[16px] border-b-2 pt-6 pb-3">
                  Activity types
                </h3>
                <Row>
                  <Col md={12}>
                    <div className="flex items-center justify-between border-b py-3">
                      <div className="flex items-center">
                        <span className="bg-[#5ab2ed] p-2 mr-2"></span>
                        <p className="font-semibold m-0 text-[#1F2226] text-[14px]">
                          Internal meeting
                        </p>
                      </div>
                      <span className="block">21:30 h</span>
                    </div>
                    <div className="flex items-center justify-between border-b py-3">
                      <div className="flex items-center">
                        <span className="bg-[#85ce77] p-2 mr-2"></span>
                        <p className="font-semibold m-0 text-[#1F2226] text-[14px]">
                          Strategy
                        </p>
                      </div>
                      <span className="block">21:30 h</span>
                    </div>
                    <div className="flex items-center justify-between border-b py-3">
                      <div className="flex items-center">
                        <span className="bg-[#ffea8a] p-2 mr-2"></span>
                        <p className="font-semibold m-0 text-[#1F2226] text-[14px]">
                          Consultation
                        </p>
                      </div>
                      <span className="block">21:30 h</span>
                    </div>
                    <div className="flex items-center justify-between border-b py-3">
                      <div className="flex items-center">
                        <span className="bg-[#c9d2e3] p-2 mr-2"></span>
                        <p className="font-semibold m-0 text-[#1F2226] text-[14px]">
                          Non-billable work
                        </p>
                      </div>
                      <span className="block">21:30 h</span>
                    </div>
                    <div className="flex items-center justify-between border-b py-3">
                      <div className="flex items-center">
                        <span className="bg-[#b3bcf5] p-2 mr-2"></span>
                        <p className="font-semibold m-0 text-[#1F2226] text-[14px]">
                          Sales meeting
                        </p>
                      </div>
                      <span className="block">21:30 h</span>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div id="chart">
                      <ReactApexChart
                        options={chartData2.options}
                        series={chartData2.series}
                        type="donut"
                        height={300}
                      />
                    </div>
                  </Col>
                </Row>
                <h3 className="text-[#0085E0] text-[16px] border-b-2 pt-6 pb-3">
                  Projects
                </h3>
                <Row className="items-center py-3 border-b">
                  <Col md={12}>
                    <span className="font-semibold">
                      Time and Material Project (example)
                    </span>
                  </Col>
                  <Col md={12}>
                    <div class="relative pt-1">
                      <div class="overflow-hidden h-[1.125rem] text-xs flex rounded bg-white">
                        <span className="mr-2"> 42:30 h</span>
                        <div
                          style={{ width: "200px" }}
                          class="shadow-none rounded-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-[#50B83C]"
                        ></div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <h3 className="text-[#0085E0] text-[16px] border-b-2 pt-6 pb-3">
                  Contacts
                </h3>
                <Row className="items-center py-3 border-b">
                  <Col md={12}>
                    <span className="font-semibold">
                      Time and Material Project (example)
                    </span>
                  </Col>
                  <Col md={12}>
                    <div class="relative pt-1">
                      <div class="overflow-hidden h-[1.125rem] text-xs flex rounded bg-white">
                        <span className="mr-2"> 42:30 h</span>
                        <div
                          style={{ width: "200px" }}
                          class="shadow-none rounded-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-[#50B83C]"
                        ></div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <h3 className="text-[#0085E0] text-[16px] border-b-2 pt-6 pb-3">
                  Shared resources
                </h3>
                <Row className="items-center py-3 border-b">
                  <Col md={12}>
                    <span className="font-semibold">Conference call room</span>
                  </Col>
                  <Col md={12}>
                    <div class="relative pt-1">
                      <div class="overflow-hidden h-[1.125rem] text-xs flex rounded bg-white">
                        <span className="mr-2"> 42:30 h</span>
                        <div
                          style={{ width: "200px" }}
                          class="shadow-none rounded-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-[#50B83C]"
                        ></div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Summary;
