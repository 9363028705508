import React from "react";
import { Button, Table } from "antd";
import {
  Container,
  InvoicesExpenses,
  FilterOptions,
} from "components/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faDownload,
  faEllipsisVertical,
  faEnvelope,
  faPrint,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { home_icon } from "assets/icons/icons";
import { useParams } from "react-router-dom";

const FinanceSummery = () => {
  const columns = [
    {
      title: "Month",
      dataIndex: "Month",
      key: "Month",

      render: (text) => <div className="font-bold">{text}</div>,
    },

    {
      title: "Invoices",
      dataIndex: "Invoices2021",
      key: "Invoices2021",
    },
    {
      title: "",
      dataIndex: "Invoices2022",
      key: "Invoices2022",
    },
    {
      title: "Bills/Expenses",
      dataIndex: "Bills2021",
      key: "Bills2021",
    },
    {
      title: "",
      dataIndex: "Bills2022",
      key: "Bills2022",
    },
    {
      title: "Gross income",
      dataIndex: "grossIncome2021",
      key: "grossIncome2021",
    },
    {
      title: "",
      dataIndex: "grossIncome2022",
      key: "grossIncome2022",
    },
  ];
  const data = [
    {
      Invoices2021: 2021,
      Invoices2022: 2022,
      Bills2021: "2021",
      Bills2022: "2022",
      grossIncome2021: "2021",
      grossIncome2022: "2022",
    },
    {
      Month: "January",
      Invoices2021: "7.800,00",
      Invoices2022: "7.800,00",
      Bills2021: "7.800,00",
      Bills2022: "7.800,00",
      grossIncome2021: "7.800,00",
      grossIncome2022: "7.800,00",
    },
    {
      Month: "February",
      Invoices2021: "7.800,00",
      Invoices2022: "7.800,00",
      Bills2021: "7.800,00",
      Bills2022: "7.800,00",
      grossIncome2021: "7.800,00",
      grossIncome2022: "7.800,00",
    },
    {
      Month: "March",
      Invoices2021: "7.800,00",
      Invoices2022: "7.800,00",
      Bills2021: "7.800,00",
      Bills2022: "7.800,00",
      grossIncome2021: "7.800,00",
      grossIncome2022: "7.800,00",
    },
  ];

  const getTotal = (data, dataIndex) => {
    return data.reduce((total, item) => {
      const value = item[dataIndex];
      if (typeof value === "string") {
        const parsedValue = parseFloat(
          value.replace(",", "").replace(".", "").replace("€", "")
        );
        return total + parsedValue;
      } else if (typeof value === "number") {
        return total + value;
      } else {
        return total;
      }
    }, 0);
  };

  const totalRow = {
    Month: "Total",
    Invoices2021: getTotal(data, "Invoices2021").toLocaleString(),
    Invoices2022: getTotal(data, "Invoices2022").toLocaleString(),
    Bills2021: getTotal(data, "Bills2021").toLocaleString(),
    Bills2022: getTotal(data, "Bills2022").toLocaleString(),
    grossIncome2021: getTotal(data, "grossIncome2021").toLocaleString(),
    grossIncome2022: getTotal(data, "grossIncome2022").toLocaleString(),
  };
  const { type } = useParams();

  return (
    <Container superAdmin={type === "backend" && true} active={"Reports"}>
      <div className="w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
        <div className="bg-white" style={{ top: 54 }}>
          <div className="flex items-center justify-between px-5 py-3">
            <div className="flex items-center">
              <p className="font-medium text-sm">Company</p>
              <img src={home_icon} alt="" className="mr-3 ml-8" />
              <p className="font-medium text-sm">
                Home
                <span className="text-bgBlue"> / </span>
                <a href="#" className="text-bgBlue">
                  Report
                </a>
                <span className="text-bgBlue"> / </span>
                <a href="#" className="text-bgBlue">
                  Default
                </a>
              </p>
            </div>
            <div className="text-end pe-5">
              <div className="flex items-center">
                <div className="me-4">
                  <FontAwesomeIcon icon={faArrowsRotate} />
                </div>
                <p className="p-0 m-0 text-gray-700">
                  Data refreshed at jul 18, 2023 04:06 PM
                </p>
                <div className="flex ">
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faEnvelope} className="fs-1" />
                  </div>
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faDownload} />
                  </div>
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faPrint} />
                  </div>
                </div>
                <FontAwesomeIcon
                  icon={faEllipsisVertical}
                  className="fs-1 mx-4"
                />
                <FontAwesomeIcon icon={faXmark} className="fs-1" />
              </div>
            </div>
          </div>
          <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
        </div>
        <FilterOptions onTop />
        <div className="mt-7 mb-5">
          <div className="px-4">
            <div className="font-bold text-xl mb-2">Summary</div>
            <section className="summery-sec border shadow-lg rounded-md">
              <div>
                <div className="flex justify-end pt-4 pr-4 mb-6">
                  <Button className="border-0 mx-2">Invoices</Button>
                  <Button className="border-0 mx-2">Summary</Button>
                </div>
                <InvoicesExpenses legendTitle={"show"} />
              </div>
              <div className="mt-6">
                <Table
                  columns={columns}
                  dataSource={[...data, totalRow]}
                  pagination={false}
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default FinanceSummery;
