import { faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { menu_icon, right_arrow } from "assets/icons/icons";

const OverView = () => {
  return (
    <div>
      <div className="flex items-center my-3">
        <div className="w-2/4">
          <p className="text-sm">Credit Amount</p>
          <p className="text-2xl py-1">$4,995.00</p>
        </div>
        <div>
          <p className="text-sm">Balance Due</p>
          <p className="text-2xl">$4,995.00</p>
        </div>
      </div>
      <div className="flex items-center justify-between border-y-2 mt-5 border-[rgba(0,0,0,.2)] py-3">
        <div className="flex items-center">
          <FontAwesomeIcon className="opacity-50" icon={faUserGroup} />
          <p className="ml-4">Golden Infotech</p>
        </div>
        <img src={menu_icon} alt="" />
      </div>
      <div className="flex items-center flex-wrap my-3">
        <div className="w-2/4">
          <p className="text-sm">Date</p>
          <p>09/May/2023</p>
        </div>
        <div className="w-2/4">
          <p className="text-sm">Due Date</p>
          <p>26/May/2023</p>
        </div>
        <div className="w-2/4 mt-4">
          <p className="text-sm">Partial Due</p>
          <p>$500.00</p>
        </div>
        <div className="w-2/4 mt-4">
          <p className="text-sm">Partial Due Date</p>
          <p>31/May/2023</p>
        </div>
        <div className="w-2/4 mt-4">
          <p className="text-sm">PO Number</p>
          <p>3333</p>
        </div>
        <div className="w-2/4 mt-4">
          <p className="text-sm">Discount</p>
          <p>$5.00</p>
        </div>
      </div>
      <div className="flex items-center justify-between border-y-2 mt-5 border-[rgba(0,0,0,.2)] py-3">
        <div>
          <p>Test product</p>
          <p className="text-xs text-themeGray-medium300">100 x $50.00</p>
          <p className="text-xs text-themeGray-medium300">loream ispum</p>
        </div>
        <div className="flex items-center">
          <p>$5,000.00</p>
          <img src={right_arrow} alt="" className="ml-4" />
        </div>
      </div>
      <div className="flex flex-col items-end mt-4 mr-5">
        <div className="flex items-center mb-2">
          <p>Subtotal</p>
          <p className="ml-4">$5,000.00</p>
        </div>
        <div className="flex items-center mb-2">
          <p>Paid to Date</p>
          <p className="ml-4">$0.00</p>
        </div>
        <div className="flex items-center mb-2">
          <p>Balance Due</p>
          <p className="ml-4">$4,995.00</p>
        </div>
        <div className="flex items-center">
          <p>Partial Due</p>
          <p className="ml-4">$500.00</p>
        </div>
      </div>
    </div>
  );
};

export default OverView;
