import { useState, useRef, useEffect } from "react";
import { Container } from "components/components";
import ContactPanel from "./contactPanel/contactPanel";
import ChatPanel from "./chatPanel/chatPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import Profile from "./profile/profile";
import ContactInfo from "./contactInfo/contactInfo";
import { useParams } from "react-router-dom";

const Chat = () => {
  const { type } = useParams();
  const [showChatPanel, setShowChatPanel] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(false);
  const toBottom = useRef(null);
  useEffect(() => {
    toBottom?.current?.scrollTo(430, 430);
  });
  return (
    <Container
      client={type === "client" ? true : false}
      superAdmin={type === "backend" ? true : false}
      active={type === "client" ? "Chat" : type === "backend" && "Message"}
    >
      <div className="flex">
        <div
          className={`${
            showProfile ? "contactsOpen" : "contactsClose"
          } overflow-y-scroll ${
            type === "client"
              ? "h-screen"
              : "h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh]"
          } scrol`}
        >
          {showProfile && <Profile onContacts={() => setShowProfile(false)} />}
        </div>
        <div
          className={`${
            !showProfile ? "contactsOpen" : "contactsClose"
          } overflow-y-scroll ${
            type === "client"
              ? "h-screen"
              : "h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh]"
          } scrol`}
        >
          {!showProfile && (
            <ContactPanel
              onProfile={() => setShowProfile(true)}
              onClick={() => setShowChatPanel(true)}
            />
          )}
        </div>
        <div
          className={`w-full flex ${
            type === "client"
              ? "h-screen"
              : "h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh]"
          } scrol`}
        >
          <div
            ref={toBottom}
            className={`${
              showContactInfo ? "chatsHalf" : "chatsFull"
            } overflow-y-scroll scrol`}
          >
            {showChatPanel ? (
              <ChatPanel onInfo={() => setShowContactInfo(true)} />
            ) : (
              <div
                className={`flex flex-col justify-center items-center ${
                  type === "client"
                    ? "h-screen"
                    : "h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh]"
                }`}
              >
                <FontAwesomeIcon
                  style={{ fontSize: 40, opacity: 0.5 }}
                  icon={faCommentDots}
                />
                <p className="text-xl font-extrabold opacity-50 mt-3">
                  Select a conversation or start a new chat
                </p>
              </div>
            )}
          </div>
          <div
            className={`${
              showContactInfo ? "contactsOpen" : "contactsClose"
            } overflow-y-scroll ${
              type === "client"
                ? "h-screen"
                : "h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh]"
            } scrol`}
          >
            {showContactInfo && (
              <ContactInfo onContactsInfo={() => setShowContactInfo(false)} />
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Chat;
