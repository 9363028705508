import { MobContainer } from "components/components";
import MobOverView from "./overView/mobOverView";

const ProductDetails = () => {
  return (
    <MobContainer className={"bg-[#F5F5F5]"}>
      <MobOverView />
    </MobContainer>
  );
};

export default ProductDetails;
