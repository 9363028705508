import React from "react";
import { google_icon } from "assets/icons/icons";
import { Button } from "antd";
import { Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { domy_profile } from "assets/images/images";
import { ThemeInput } from "components/components";

const InviteFriends = () => {
  const menu = (
    <Menu>
      <Menu.Item key="1">Owner</Menu.Item>
      <Menu.Item key="2">Guest</Menu.Item>
    </Menu>
  );
  return (
    <div className="p-4 rounded shadow-lg mb-4 border">
      <h3 className="fw-500 text-xl mb-2">Timesheets</h3>
      <hr />
      <div className="mt-10">
        <div className="grid md:grid-cols-2 md:gap-6">
          <div className="relative z-0 w-full mb-6 group">
            <div className="text-center">
              <h3 className="fw-500 text-xl">Invite a Friend</h3>
              <span className="text-gray-500 text-sm mb-2">
                if you need more info. Please check Our{" "}
                <a href="#" className="text-blue-500">
                  FAQ Page
                </a>
              </span>
              <Button className="flex mt-7 items-center h-12 bg-green-100 my-4 hover:!text-black w-full justify-center">
                <img src={google_icon} alt="" width={22} />
                <p className="ml-1 font-semibold ms-4 text-sm">
                  Invite Gmail Contacts
                </p>
              </Button>
              <p className="ml-1 text-gray-300 ms-4 text-lg">OR</p>
              <textarea
                name="floating_address"
                id="floating_address"
                className="block mt-3 py-2.5 px-3 w-full text-sm text-gray-900 bg-gray-100 border rounded border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                rows="4" // Adjust the number of rows as needed
              ></textarea>
            </div>
          </div>
          <div className="relative z-0 w-full mb-6 group">
            <div>
              <h3 className="fw-500 text-xl">Your Referral Link</h3>
              <span className="text-gray-500 text-sm mb-2">
                Plan your blog post choosing a topic, creating an conduct
                research, and checking facts
              </span>
              <div className="flex items-center mt-2 ">
                <ThemeInput
                  value={"www.google.com"}
                  placeholder="Invite Link"
                  disabled
                />
                <Button className="flex items-center bg-gray-100 hover:!text-black ms-2 p-5">
                  Copy
                </Button>
              </div>
            </div>
          </div>

          <div className="">
            <h3 className="fw-500 text-xl mb-2">Your Invitations</h3>
            <div className="invitations-section mt-4">
              <div className="flex items-center justify-between invitations-card p-3">
                <div className="flex items-center">
                  <img
                    src={domy_profile}
                    width={55}
                    style={{ borderRadius: "50%" }}
                  />
                  <div className="ms-2">
                    <h3 className="font-bold text-sm">Emma Smith</h3>
                    <p className="text-gray-500 text-sm">emmasmith@gmail.com</p>
                  </div>
                </div>
                <Dropdown overlay={menu} placement="bottomLeft">
                  <Button>
                    Owner <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
              <div className="flex items-center justify-between invitations-card p-3">
                <div className="flex items-center">
                  <img
                    src={domy_profile}
                    width={55}
                    style={{ borderRadius: "50%" }}
                  />
                  <div className="ms-2">
                    <h3 className="font-bold text-sm">Emma Smith</h3>
                    <p className="text-gray-500 text-sm">emmasmith@gmail.com</p>
                  </div>
                </div>
                <Dropdown overlay={menu} placement="bottomLeft">
                  <Button>
                    Owner <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
              <div className="flex items-center justify-between invitations-card p-3">
                <div className="flex items-center">
                  <img
                    src={domy_profile}
                    width={55}
                    style={{ borderRadius: "50%" }}
                  />
                  <div className="ms-2">
                    <h3 className="font-bold text-sm">Emma Smith</h3>
                    <p className="text-gray-500 text-sm">emmasmith@gmail.com</p>
                  </div>
                </div>
                <Dropdown overlay={menu} placement="bottomLeft">
                  <Button>
                    Owner <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
              <div className="flex items-center justify-between invitations-card p-3">
                <div className="flex items-center">
                  <img
                    src={domy_profile}
                    width={55}
                    style={{ borderRadius: "50%" }}
                  />
                  <div className="ms-2">
                    <h3 className="font-bold text-sm">Emma Smith</h3>
                    <p className="text-gray-500 text-sm">emmasmith@gmail.com</p>
                  </div>
                </div>
                <Dropdown overlay={menu} placement="bottomLeft">
                  <Button>
                    Owner <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
              <div className="flex items-center justify-between invitations-card p-3">
                <div className="flex items-center">
                  <img
                    src={domy_profile}
                    width={55}
                    style={{ borderRadius: "50%" }}
                  />
                  <div className="ms-2">
                    <h3 className="font-bold text-sm">Emma Smith</h3>
                    <p className="text-gray-500 text-sm">emmasmith@gmail.com</p>
                  </div>
                </div>
                <Dropdown overlay={menu} placement="bottomLeft">
                  <Button>
                    Owner <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
              <div className="flex items-center justify-between invitations-card p-3">
                <div className="flex items-center">
                  <img
                    src={domy_profile}
                    width={55}
                    style={{ borderRadius: "50%" }}
                  />
                  <div className="ms-2">
                    <h3 className="font-bold text-sm">Emma Smith</h3>
                    <p className="text-gray-500 text-sm">emmasmith@gmail.com</p>
                  </div>
                </div>
                <Dropdown overlay={menu} placement="bottomLeft">
                  <Button>
                    Owner <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
              <div className="flex items-center justify-between invitations-card p-3">
                <div className="flex items-center">
                  <img
                    src={domy_profile}
                    width={55}
                    style={{ borderRadius: "50%" }}
                  />
                  <div className="ms-2">
                    <h3 className="font-bold text-sm">Emma Smith</h3>
                    <p className="text-gray-500 text-sm">emmasmith@gmail.com</p>
                  </div>
                </div>
                <Dropdown overlay={menu} placement="bottomLeft">
                  <Button>
                    Owner <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
              <div className="flex items-center justify-between invitations-card p-3">
                <div className="flex items-center">
                  <img
                    src={domy_profile}
                    width={55}
                    style={{ borderRadius: "50%" }}
                  />
                  <div className="ms-2">
                    <h3 className="font-bold text-sm">Emma Smith</h3>
                    <p className="text-gray-500 text-sm">emmasmith@gmail.com</p>
                  </div>
                </div>
                <Dropdown overlay={menu} placement="bottomLeft">
                  <Button>
                    Owner <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
              <div className="flex items-center justify-between invitations-card p-3">
                <div className="flex items-center">
                  <img
                    src={domy_profile}
                    width={55}
                    style={{ borderRadius: "50%" }}
                  />
                  <div className="ms-2">
                    <h3 className="font-bold text-sm">Emma Smith</h3>
                    <p className="text-gray-500 text-sm">emmasmith@gmail.com</p>
                  </div>
                </div>
                <Dropdown overlay={menu} placement="bottomLeft">
                  <Button>
                    Owner <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteFriends;
