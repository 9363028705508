import { useState } from "react";
import { Progress } from "antd";
import { Checkbox } from "@mui/material";
import { menu_icon } from "assets/icons/icons";

const OverView = () => {
  const [checklist, setChecklist] = useState([
    {
      title: "Breakdown",
      isChoosen: true,
    },
    {
      title: "Element Crosscheck",
      isChoosen: false,
    },
    {
      title: "Fixing Hosting",
      isChoosen: false,
    },
  ]);
  const checked = checklist.filter((e) => {
    if (e.isChoosen) return e;
  });
  const handleChecklist = (e, i) => {
    let update = [...checklist];
    update[i].isChoosen = !e.isChoosen;
    setChecklist(update);
  };
  const checklistProgress = (checked.length / checklist.length) * 100;
  const removeDecimal = checklistProgress.toFixed(1);
  return (
    <div className="z-0">
      <div className="shadow-md mt-2 px-3 pb-5 mx-2 flex flex-col justify-center">
        <div className="flex items-center justify-between h-16 mb-4 border-b-[1px] border-[rgba(0,0,0,.1)]">
          <p className="font-medium mt-2">Overview</p>
          <div className="flex items-center">
            <div>
              <p className="text-sm pl-2">Status</p>
              <div className="bg-blue-50 h-7 items-center border-[1px] border-primary rounded-md flex justify-center text-primary w-28">
                <p className="text-sm">In Progress</p>
              </div>
            </div>
            <div className="ml-3">
              <p className="text-sm pl-2">Priority</p>
              <div className="bg-red-50 h-7 items-center border-[1px] border-red-500 rounded-md flex justify-center text-red-500 w-28">
                <p className="text-sm">High</p>
              </div>
            </div>
          </div>
        </div>
        <p className="text-themeGray-dark text-sm font-medium">
          An overview provides a concise summary of a subject, capturing its
          essential features and key points in a condensed format. It serves as
          a bird's-eye view, allowing readers to quickly grasp the main aspects
          of a topic without diving into the details.
        </p>
        <div className="border-b-[1px] border-[rgba(0,0,0,.2)]  pt-2 pb-4">
          <div className="flex items-center justify-between my-1">
            <p className="font-medium text-sm">Assignees</p>
            <p className="font-medium text-sm">Alice Harding</p>
          </div>
          <div className="flex items-center justify-between mt-3">
            <p className="font-medium text-sm">Work type</p>
            <p className="font-medium text-sm">Track Service Worker</p>
          </div>
        </div>
        <div className="border-b-[1px] border-[rgba(0,0,0,.2)] pt-2 pb-4">
          <p className="font-medium my-2">Planning</p>
          <div className="flex items-center justify-between my-1">
            <p className="font-medium text-sm">Start Date</p>
            <p className="font-medium text-sm">03-09-2023</p>
          </div>
          <div className="flex items-center justify-between mt-3">
            <p className="font-medium text-sm">End Date</p>
            <p className="font-medium text-sm">06-09-2023</p>
          </div>
        </div>
        <div className="border-b-[1px] border-[rgba(0,0,0,.2)] pt-2 pb-4">
          <p className="font-medium my-2">Budget</p>
          <div className="flex items-center justify-between my-1">
            <p className="font-medium text-sm">Billing Method</p>
            <p className="font-medium text-sm">Project Hours</p>
          </div>
          <div className="flex items-center justify-between mt-3">
            <p className="font-medium text-sm">Price</p>
            <p className="font-medium text-sm">$40.00</p>
          </div>
        </div>

        <div className="flex justify-between items-center mt-4">
          <p className="font-semibold">What we need</p>
          <p className="opacity-50">
            {checked.length}/{checklist.length} Completed
          </p>
        </div>
        <Progress percent={removeDecimal} className="mt-2" />
        {checklist.map((e, i) => {
          return (
            <div
              key={i}
              className="bg-themeGray-light200 text-sm pr-4 pl-1 justify-between flex items-center rounded-xl my-3"
            >
              <div className="flex items-center">
                <Checkbox
                  onChange={() => handleChecklist(e, i)}
                  checked={e.isChoosen}
                />
                <p className={`${e.isChoosen && "line-through"}`}>{e.title}</p>
              </div>
              <img
                width={18}
                className="cursor-pointer"
                src={menu_icon}
                alt=""
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OverView;
