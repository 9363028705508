import { useState } from "react";
import {
  cross_icon,
  download_icon,
  filter_icon,
  home_icon,
  print_icon,
  submenu_icon,
} from "assets/icons/icons";
import { Container, ThemeTable } from "components/components";
import { TicketsData } from "constant/constant";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Details from "./details/details";

const Tickets = () => {
  const [data, setData] = useState(TicketsData);
  const [densityOpt, setDensityOpt] = useState(false);
  const [density, setDensity] = useState("middle");
  const [detailsModal, setDetailsModal] = useState("detailsModalClose");
  const handleCompanyDetails = (event, record) => {
    setDetailsModal("detailsModalOpen");
  };
  const columns = [
    {
      title: "Ticket ID",
      dataIndex: "id",
      width: "14%",
      sorter: (a, b) => a.id - b.id,
      filters: [
        {
          text: "20230918-084927",
          value: "20230918-084927",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.id.includes(value),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      sorter: (a, b) => a.subject - b.subject,
      width: "11%",
      filters: [
        {
          text: "Test ticket",
          value: "Test ticket",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.subject.includes(value),
    },
    {
      title: "Customer",
      dataIndex: "customer",
      width: "11%",
      sorter: (a, b) => a.customer - b.customer,
      filters: [
        {
          text: "Lisa Elizabeth",
          value: "Lisa Elizabeth",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.customer.includes(value),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email - b.email,
      width: "14%",
      filters: [
        {
          text: "example@gmail.com",
          value: "example@gmail.com",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.email.includes(value),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      width: "12%",
      sorter: (a, b) => a.phone - b.phone,
      filters: [
        {
          text: "02345678",
          value: "02345678",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.phone.includes(value),
    },

    {
      title: "Status",
      dataIndex: "status",
      width: 130,
      filters: [
        {
          text: "Paid",
          value: "Paid",
        },
        {
          text: "Sent",
          value: "Sent",
        },
        {
          text: "Draft",
          value: "Draft",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.status.includes(value),
      render: (text) => (
        <div
          className={`${
            text === "Paid" || text === "New"
              ? "bg-themeGreen"
              : text === "Sent" || text === "Waiting"
              ? "bg-blue-600"
              : text === "Draft" && "bg-themeGray-dark"
          } text-white w-[130px] h-7 text-sm flex justify-center items-center rounded-[5px]`}
        >
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Created",
      dataIndex: "next_payment_date",
      width: "11%",
      sorter: (a, b) => a.next_payment_date - b.next_payment_date,
      filters: [
        {
          text: "20-05-2023",
          value: "20-05-2023",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.next_payment_date.includes(value),
    },
    {
      title: "Category",
      dataIndex: "category",
      width: "11%",
      sorter: (a, b) => a.category - b.category,
      filters: [
        {
          text: "Administration",
          value: "Administration",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.category.includes(value),
    },
  ];
  const handleDensity = () => {
    setDensityOpt(!densityOpt);
  };
  const tabs = ["Summary"];
  return (
    <Container
      superAdmin
      active={"Tickets"}
      onClick={() => densityOpt && setDensityOpt(false)}
    >
      <div className="flex">
        <div className="w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
          <div className="bg-white" style={{ top: 54 }}>
            <div className="flex items-center justify-between px-5 h-[52px]">
              <div className="flex items-center">
                <p className="font-medium text-sm">Invoice</p>
                <img src={home_icon} alt="" className="mr-3 ml-8" />
                <p className="font-medium text-sm">
                  Home
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    Invoice
                  </a>
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    All invoices
                  </a>
                </p>
              </div>
              <div className="flex items-center">
                <div>
                  <img
                    src={filter_icon}
                    onClick={handleDensity}
                    alt=""
                    className="cursor-pointer"
                  />
                  {densityOpt && (
                    <div className="relative">
                      <div className="text-sm right-0 absolute my-2 bg-white px-3 py-1 z-20 top-0 leading-7 rounded-md shadow-lg">
                        <p
                          className="cursor-pointer"
                          onClick={() => {
                            setDensityOpt(false);
                            setDensity("small");
                          }}
                        >
                          Compact
                        </p>
                        <p
                          className="cursor-pointer"
                          onClick={() => {
                            setDensityOpt(false);
                            setDensity("middle");
                          }}
                        >
                          Standard
                        </p>
                        <p
                          className="cursor-pointer"
                          onClick={() => {
                            setDensityOpt(false);
                            setDensity("large");
                          }}
                        >
                          Comfortable
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
          </div>
          <div className="-mt-3">
            <ThemeTable
              onClick={handleCompanyDetails}
              columns={columns}
              data={data}
              setData={(e) => setData(e)}
              density={density}
            />
          </div>
        </div>

        <div
          className={`bg-white ${detailsModal} overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
        >
          {detailsModal === "detailsModalOpen" && (
            <>
              <div className="flex z-10 items-center justify-between w-full pr-5 shadow-md border-b-2 border-[rgba(0,0,0,.2)] bg-white sticky top-0">
                <div className="h-11 items-center flex">
                  {tabs.map((e, i) => {
                    return (
                      <Button
                        key={i}
                        className={`bg-bgBlue !border-none text-sm text-white font-medium !rounded-none h-11 w-[108px]`}
                      >
                        {e}
                      </Button>
                    );
                  })}
                </div>
                <div className="flex items-center">
                  <p className="font-semibold">Ticket 358</p>
                  <FontAwesomeIcon icon={faPen} className="ml-4 opacity-50" />
                  <img src={submenu_icon} className="ml-4" />
                  <img
                    src={cross_icon}
                    alt=""
                    className="ml-4 cursor-pointer opacity-70"
                    width={16}
                    onClick={() => setDetailsModal("detailsModalClose")}
                  />
                </div>
              </div>
              <Details />
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Tickets;
