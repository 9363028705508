import { Button } from "antd";
import {
  application_icon,
  helpcenter_icon,
  home_icon,
  menu_icon,
  reports_icon,
  settings_icon,
  cross_icon,
} from "assets/icons/icons";

const MobSidebar = ({ open, onClose }) => {
  const tabs = [
    {
      icon: home_icon,
      title: "Home Page",
    },
    {
      icon: application_icon,
      title: "Application",
    },
    {
      icon: reports_icon,
      title: "Reports",
    },
    {
      icon: settings_icon,
      title: "Settings",
    },
    {
      icon: helpcenter_icon,
      title: "Help Center",
    },
  ];
  return (
    <div
      className={`bg-white fixed h-screen top-0 z-20 ${
        open === "mobSidebarOpen" && "p-3"
      }  ${open}`}
    >
      {open === "mobSidebarOpen" && (
        <div>
          <div className="relative">
            <img
              src={cross_icon}
              className="absolute top-0 right-0"
              width={18}
              onClick={onClose}
              alt=""
            />
          </div>
          <div className="pt-8">
            {tabs.map((e, i) => {
              return (
                <>
                  {i === 4 && (
                    <div className="border-b-2 mb-1 border-themeGray-medium"></div>
                  )}
                  <Button
                    key={i}
                    className={`mb-3 hover:!text-black w-full hover:bg-themeGray-medium h-12 flex items-center !border-none rounded-xl`}
                    onClick={onClose}
                  >
                    <img src={e.icon} alt="" width={20} />
                    <p className="ml-5">{e.title}</p>
                  </Button>
                  {i === 4 && (
                    <div className="border-b-2 -mt-2 mb-3 border-themeGray-medium"></div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default MobSidebar;
