import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Space } from "antd";
import React from "react";
import { useState } from "react";
import { RiArrowDownSFill } from "react-icons/ri";

const FilterDropdown = ({ items, icon, name, trigger }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleDropdownVisibleChange = (visible) => {
    setDropdownVisible(visible);
  };
  return (
    <Dropdown
      visible={dropdownVisible}
      onVisibleChange={handleDropdownVisibleChange}
      menu={{
        items,
      }}
      trigger={trigger}
      className="mx-2 my-1"
    >
      <a
        onClick={(e) => e.preventDefault()}
        className="font-medium text-[#494F59]"
      >
        <Space className="text-[#717A89] hover:text-red-500">
          <FontAwesomeIcon color="#717A89" fontSize={14} icon={icon} />
          {name && <span className="text-[13px]"> {name}</span>}
          <RiArrowDownSFill />
        </Space>
      </a>
    </Dropdown>
  );
};

export default FilterDropdown;
