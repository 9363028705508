import { ThemeProvider, createTheme } from "@mui/material";
import { ThemeInput, ThemeSelect } from "components/components";
import Inter from "assets/font/Inter.ttf";

const theme = createTheme({
  typography: {
    fontFamily: Inter,
  },
  palette: {
    primary: {
      main: "#00A1E4",
    },
  },
});

const BasicInfo = ({ className }) => {
  return (
    <ThemeProvider theme={theme}>
      <div className={`${className} bg-themeGray-light200`}>
        <div className="bg-white px-3 pb-3 pt-2 my-2 shadow-md">
          <ThemeInput placeholder={"Stage"} />
          <ThemeSelect
            label={"Source*"}
            data={["Source A", "Source B", "Source C"]}
          />
        </div>
        <div className="bg-white px-3 pb-3 pt-2 my-2 shadow-md">
          <ThemeInput placeholder={"Name"} />
          <ThemeInput placeholder={"Position"} />
        </div>
        <div className="bg-white px-3 pb-3 pt-2 my-2 shadow-md">
          <ThemeInput placeholder={"Phone"} />
          <ThemeInput placeholder={"Email"} />
          <ThemeInput placeholder={"Address"} />
          <ThemeInput placeholder={"Zip"} />
          <ThemeInput placeholder={"City"} />
          <ThemeInput placeholder={"Country"} />
        </div>
        <div className="bg-white px-3 pb-3 pt-2 my-2 shadow-md">
          <ThemeInput placeholder={"Lead value"} />
          <ThemeInput placeholder={"Probaility"} />
        </div>
        <div className="bg-white px-3 pb-3 pt-2 my-2 shadow-md">
          <ThemeInput placeholder={"Company Name"} />
          <ThemeInput placeholder={"Address"} />
          <ThemeInput placeholder={"Zip"} />
          <ThemeInput placeholder={"City"} />
          <ThemeInput placeholder={"Country"} />
          <ThemeInput placeholder={"Phone"} />
          <ThemeInput placeholder={"Email"} />
          <ThemeInput placeholder={"Website"} />
          <ThemeInput placeholder={"Description"} multiline rows={4} />
        </div>
      </div>
    </ThemeProvider>
  );
};

export { BasicInfo };
