import { useState, useEffect, useRef } from "react";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { profile_pic } from "assets/images/images";
import { Dropdown } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStopwatch } from "@fortawesome/free-solid-svg-icons";
import {
  headerColor,
  headerIcons,
  logoutColor,
  searchColor,
  timerColor,
} from "constant/themeColors";
import Timer from "./timer/timer";

const items = [
  {
    key: "1",
    label: <p className="font-bold w-48">Sabtain</p>,
  },
  {
    key: "2",
    label: <p>My profile</p>,
  },
  {
    type: "divider",
  },
  {
    key: "3",
    label: <p>Upgrade demo</p>,
  },
  {
    key: "4",
    label: <p>Integrations / Marketplace</p>,
  },
  {
    key: "5",
    label: <p>Email & export logs</p>,
  },
  {
    key: "6",
    label: <p>Legal information</p>,
  },
  {
    key: "7",
    label: <p>Reset data</p>,
  },
  {
    type: "divider",
  },
  {
    key: "8",
    label: <p className="text-[#e84b17]">Logout</p>,
  },
];

const Header = ({ onClose, onOpen, open, client }) => {
  const [index, setIndex] = useState(2);
  const [timeData, setTimeData] = useState([
    {
      date: "August 21",
      data: [],
    },
    {
      date: "August 22",
      data: [],
    },
    {
      date: "Today",
      data: [],
    },
  ]);
  const [description, setDescription] = useState("");
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowOption(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const navigate = useNavigate();
  const timerRef = useRef();
  useOutsideAlerter(timerRef);
  const getSec = localStorage.getItem("Seconds");
  const getMin = localStorage.getItem("Minutes");
  const [sec, setSec] = useState(+getSec);
  const [min, setMin] = useState(+getMin);
  const [hour, setHour] = useState("");
  const [showOption, setShowOption] = useState(false);
  const [startTime, setStartTime] = useState(false);
  useEffect(() => {
    if (startTime || localStorage.getItem("timer") === "true") {
      if (sec > 59) {
        if (min > 58) {
          setHour(hour + 1);
          localStorage.setItem("Minutes", 0);
          setMin(0);
        } else {
          setMin(min + 1);
          localStorage.setItem("Minutes", min + 1);
        }
        setSec(0);
        localStorage.setItem("Seconds", 0);
      } else {
        setTimeout(() => {
          localStorage.setItem("Seconds", sec + 1);
          setSec(sec + 1);
        }, 1000);
      }
    } else {
      localStorage.setItem("Minutes", 0);
      localStorage.setItem("Seconds", 0);
      setSec(0);
      setMin(0);
    }
  });
  const handlePause = () => {
    let update = [...timeData];
    update[index].data.push({
      title: description,
      hour: hour,
      min: min,
      sec: sec,
    });
    setTimeData(update);
    setDescription("");
    setHour("");
    setMin("");
    setSec("");
    setStartTime(false);
    localStorage.removeItem("timer");
  };
  const handleStartTimer = () => {
    localStorage.setItem("timer", true);
    setStartTime(true);
  };
  return (
    <div
      style={{ backgroundColor: client ? "#fff" : headerColor }}
      className={`z-10 flex items-center pr-3 justify-between sticky top-0 h-[9vh] desktop:h-[7vh] bigDesktop:h-[5vh]`}
    >
      <div className="flex items-center">
        {!client && (
          <div
            className="mr-1 h-[9vh] desktop:h-[7vh] bigDesktop:h-[5vh]"
            style={{ width: 7, backgroundColor: logoutColor }}
          ></div>
        )}
        {!client && (
          <>
            {open ? (
              <ArrowLeftOutlined
                onClick={onClose}
                style={{ color: headerIcons, fontSize: 24 }}
                className="cursor-pointer"
              />
            ) : (
              <ArrowRightOutlined
                onClick={onOpen}
                style={{ color: headerIcons, fontSize: 24 }}
                className="cursor-pointer"
              />
            )}
          </>
        )}
        <div
          style={{ backgroundColor: client ? "#fff" : searchColor }}
          className="ml-6 flex justify-center items-center px-3 h-[9vh] desktop:h-[7vh] bigDesktop:h-[5vh]"
        >
          <SearchOutlined
            style={{ color: client ? "#000" : headerIcons, fontSize: 24 }}
            className="pr-2"
          />
          <input
            type="text"
            style={{ backgroundColor: client ? "#fff" : searchColor }}
            name=""
            id=""
            placeholder="Search"
            className={`${
              client
                ? "placeholder:text-black text-black"
                : "placeholder:text-white text-white"
            } px-2 focus:!outline-none h-[9vh] desktop:h-[7vh] bigDesktop:h-[5vh]`}
          />
        </div>
      </div>
      <div className="flex items-center">
        {!client && (
          <svg
            className="mr-6 cursor-pointer"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => navigate("/app/calendar")}
          >
            <path
              d="M16 13C16.2652 13 16.5196 12.8946 16.7071 12.7071C16.8946 12.5196 17 12.2652 17 12C17 11.7348 16.8946 11.4804 16.7071 11.2929C16.5196 11.1054 16.2652 11 16 11C15.7348 11 15.4804 11.1054 15.2929 11.2929C15.1054 11.4804 15 11.7348 15 12C15 12.2652 15.1054 12.5196 15.2929 12.7071C15.4804 12.8946 15.7348 13 16 13ZM16 17C16.2652 17 16.5196 16.8946 16.7071 16.7071C16.8946 16.5196 17 16.2652 17 16C17 15.7348 16.8946 15.4804 16.7071 15.2929C16.5196 15.1054 16.2652 15 16 15C15.7348 15 15.4804 15.1054 15.2929 15.2929C15.1054 15.4804 15 15.7348 15 16C15 16.2652 15.1054 16.5196 15.2929 16.7071C15.4804 16.8946 15.7348 17 16 17ZM12 12C12 12.2652 11.8946 12.5196 11.7071 12.7071C11.5196 12.8946 11.2652 13 11 13C10.7348 13 10.4804 12.8946 10.2929 12.7071C10.1054 12.5196 10 12.2652 10 12C10 11.7348 10.1054 11.4804 10.2929 11.2929C10.4804 11.1054 10.7348 11 11 11C11.2652 11 11.5196 11.1054 11.7071 11.2929C11.8946 11.4804 12 11.7348 12 12ZM12 16C12 16.2652 11.8946 16.5196 11.7071 16.7071C11.5196 16.8946 11.2652 17 11 17C10.7348 17 10.4804 16.8946 10.2929 16.7071C10.1054 16.5196 10 16.2652 10 16C10 15.7348 10.1054 15.4804 10.2929 15.2929C10.4804 15.1054 10.7348 15 11 15C11.2652 15 11.5196 15.1054 11.7071 15.2929C11.8946 15.4804 12 15.7348 12 16ZM6 13C6.26522 13 6.51957 12.8946 6.70711 12.7071C6.89464 12.5196 7 12.2652 7 12C7 11.7348 6.89464 11.4804 6.70711 11.2929C6.51957 11.1054 6.26522 11 6 11C5.73478 11 5.48043 11.1054 5.29289 11.2929C5.10536 11.4804 5 11.7348 5 12C5 12.2652 5.10536 12.5196 5.29289 12.7071C5.48043 12.8946 5.73478 13 6 13ZM6 17C6.26522 17 6.51957 16.8946 6.70711 16.7071C6.89464 16.5196 7 16.2652 7 16C7 15.7348 6.89464 15.4804 6.70711 15.2929C6.51957 15.1054 6.26522 15 6 15C5.73478 15 5.48043 15.1054 5.29289 15.2929C5.10536 15.4804 5 15.7348 5 16C5 16.2652 5.10536 16.5196 5.29289 16.7071C5.48043 16.8946 5.73478 17 6 17Z"
              fill={headerIcons}
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6 0.75C6.19891 0.75 6.38968 0.829018 6.53033 0.96967C6.67098 1.11032 6.75 1.30109 6.75 1.5V2.263C7.412 2.25 8.141 2.25 8.943 2.25H13.056C13.859 2.25 14.588 2.25 15.25 2.263V1.5C15.25 1.30109 15.329 1.11032 15.4697 0.96967C15.6103 0.829018 15.8011 0.75 16 0.75C16.1989 0.75 16.3897 0.829018 16.5303 0.96967C16.671 1.11032 16.75 1.30109 16.75 1.5V2.327C17.01 2.347 17.256 2.372 17.489 2.403C18.661 2.561 19.61 2.893 20.359 3.641C21.107 4.39 21.439 5.339 21.597 6.511C21.75 7.651 21.75 9.106 21.75 10.944V13.056C21.75 14.894 21.75 16.35 21.597 17.489C21.439 18.661 21.107 19.61 20.359 20.359C19.61 21.107 18.661 21.439 17.489 21.597C16.349 21.75 14.894 21.75 13.056 21.75H8.944C7.106 21.75 5.65 21.75 4.511 21.597C3.339 21.439 2.39 21.107 1.641 20.359C0.893 19.61 0.561 18.661 0.403 17.489C0.25 16.349 0.25 14.894 0.25 13.056V10.944C0.25 9.106 0.25 7.65 0.403 6.511C0.561 5.339 0.893 4.39 1.641 3.641C2.39 2.893 3.339 2.561 4.511 2.403C4.744 2.372 4.991 2.347 5.25 2.327V1.5C5.25 1.30109 5.32902 1.11032 5.46967 0.96967C5.61032 0.829018 5.80109 0.75 6 0.75ZM4.71 3.89C3.705 4.025 3.125 4.279 2.702 4.702C2.279 5.125 2.025 5.705 1.89 6.711C1.867 6.881 1.848 7.061 1.832 7.25H20.168C20.152 7.06 20.133 6.881 20.11 6.71C19.975 5.705 19.721 5.125 19.298 4.702C18.875 4.279 18.295 4.025 17.289 3.89C16.262 3.752 14.907 3.75 13 3.75H9C7.093 3.75 5.739 3.752 4.71 3.89ZM1.75 11C1.75 10.146 1.75 9.403 1.763 8.75H20.237C20.25 9.403 20.25 10.146 20.25 11V13C20.25 14.907 20.248 16.262 20.11 17.29C19.975 18.295 19.721 18.875 19.298 19.298C18.875 19.721 18.295 19.975 17.289 20.11C16.262 20.248 14.907 20.25 13 20.25H9C7.093 20.25 5.739 20.248 4.71 20.11C3.705 19.975 3.125 19.721 2.702 19.298C2.279 18.875 2.025 18.295 1.89 17.289C1.752 16.262 1.75 14.907 1.75 13V11Z"
              fill={headerIcons}
            />
          </svg>
        )}
        <svg
          className="mr-6"
          width="17"
          height="22"
          viewBox="0 0 17 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5 8C14.5 6.4087 13.8679 4.88258 12.7426 3.75736C11.6174 2.63214 10.0913 2 8.5 2C6.9087 2 5.38258 2.63214 4.25736 3.75736C3.13214 4.88258 2.5 6.4087 2.5 8V16H14.5V8ZM16.5 16.667L16.9 17.2C16.9557 17.2743 16.9896 17.3626 16.998 17.4551C17.0063 17.5476 16.9887 17.6406 16.9472 17.7236C16.9057 17.8067 16.8419 17.8765 16.7629 17.9253C16.6839 17.9741 16.5929 18 16.5 18H0.5C0.407144 18 0.316123 17.9741 0.237135 17.9253C0.158147 17.8765 0.094313 17.8067 0.0527866 17.7236C0.0112601 17.6406 -0.0063184 17.5476 0.00202058 17.4551C0.0103596 17.3626 0.0442865 17.2743 0.1 17.2L0.5 16.667V8C0.5 5.87827 1.34286 3.84344 2.84315 2.34315C4.34344 0.842855 6.37827 0 8.5 0C10.6217 0 12.6566 0.842855 14.1569 2.34315C15.6571 3.84344 16.5 5.87827 16.5 8V16.667ZM6 19H11C11 19.663 10.7366 20.2989 10.2678 20.7678C9.79893 21.2366 9.16304 21.5 8.5 21.5C7.83696 21.5 7.20107 21.2366 6.73223 20.7678C6.26339 20.2989 6 19.663 6 19Z"
            fill={client ? "#000" : headerIcons}
          />
        </svg>
        {/* <img src={male_icon} alt="" className="mr-6" /> */}
        {!client && (
          <div
            onClick={() => setShowOption(true)}
            style={{
              borderRadius: 5,
              height: 27,
              width: 105,
              backgroundColor: timerColor,
            }}
            className="text-white text-sm cursor-pointer rounded-lg flex justify-center items-center mr-6"
          >
            <FontAwesomeIcon icon={faStopwatch} />
            <p className="ml-2">
              {hour < 10 && "0"}
              {hour}:{min < 10 && "0"}
              {min}:{sec < 10 && "0"}
              {sec}
            </p>
          </div>
        )}
        {showOption && (
          <Timer
            startTime={startTime}
            description={description}
            setDescription={setDescription}
            hour={hour}
            min={min}
            sec={sec}
            handlePause={handlePause}
            handleStartTimer={handleStartTimer}
            timeData={timeData}
            setShowOption={setShowOption}
            index={index}
            setIndex={setIndex}
          />
        )}
        <div
          onClick={() => {
            localStorage.removeItem("User");
            navigate("/");
          }}
          style={{
            borderRadius: 5,
            height: 27,
            width: 85,
            backgroundColor: logoutColor,
          }}
          className="mr-4 cursor-pointer text-white flex items-center justify-center !text-sm p-0 font-medium"
        >
          Logout
        </div>
        <div
          onClick={() => navigate("/app/account")}
          className="cursor-pointer border-l-2 border-[rgba(255,255,255,.5)] flex items-center"
        >
          {/* <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
            placement="top"
          >
            <img
              src={profile_pic}
              alt=""
              className="ml-4 cursor-pointer"
              width={37}
            />
          </Dropdown> */}
          <img src={profile_pic} alt="" className="ml-4" width={37} />
          <div className="ml-2">
            <p className={`font-semibold ${!client && "text-white"} text-sm`}>
              Sabtain
            </p>
            <p
              className={`font-semibold ${
                !client && "text-[rgba(255,255,255,.61)]"
              } text-sm`}
            >
              info@gmail.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
