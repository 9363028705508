import { useState } from "react";
import { MobContainer } from "components/components";
import MobSummary from "./summary/mobSummary";
import { useNavigate } from "react-router-dom";

const CompanyDetails = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const tabs = ["Summary", "Invoice", "Proposal", "Project", "Tickets"];
  return (
    <MobContainer
      className={"bg-[#F5F5F5]"}
      tab={tab}
      tabs={tabs}
      setTab={(e) => setTab(e)}
      onAdd={() => navigate("/company/add")}
    >
      {tab === 0 && <MobSummary />}
    </MobContainer>
  );
};

export default CompanyDetails;
