import { useState } from "react";
import { Input, Modal } from "antd";
import { DeleteOutlined, CheckOutlined } from "@ant-design/icons";
import { SketchPicker } from "react-color";

const AddLabelModal = ({
  isModalOpen,
  setIsModalOpen,
  data,
  setData,
  eventsData,
  setEventsData,
}) => {
  const [label, setLabel] = useState("");
  const [color, setColor] = useState("");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
    setColor("");
    setShowColorPicker(false);
  };
  const handleDelete = (i, e) => {
    let showEvents = eventsData.map((val) => {
      if (val.color === e.color) {
        return { ...val, isChoosen: false };
      } else {
        return { ...val };
      }
    });
    setEventsData(showEvents);
    let update = [...data];
    update.splice(i, 1);
    setData(update);
  };
  const handleAdd = () => {
    if (label && color) {
      let update = [...data];
      update.push({
        title: label,
        color: color,
        isChoosen: true,
      });
      setData(update);
      setLabel("");
      setColor("");
      setIsModalOpen(false);
      setShowColorPicker(false);
    }
  };

  return (
    <Modal
      onCancel={handleCancel}
      footer={false}
      title="Edit Labels"
      open={isModalOpen}
    >
      <Input
        prefix={
          <div
            onClick={() => setShowColorPicker(true)}
            className={`h-4 w-4 cursor-pointer rounded-full mr-3 ${
              !color && "border-2"
            } border-[rgba(0,0,0,.2)]`}
            style={{ backgroundColor: color }}
          ></div>
        }
        value={label}
        onChange={(e) => setLabel(e.target.value)}
        className="h-12"
        suffix={
          <CheckOutlined
            onClick={handleAdd}
            style={{ fontSize: 18, cursor: "pointer" }}
          />
        }
      />
      {showColorPicker && (
        <div className="relative">
          <SketchPicker
            className="absolute"
            color={color}
            onChangeComplete={(e) => setColor(e.hex)}
          />
        </div>
      )}
      {data.map((e, i) => {
        return (
          <div
            key={i}
            className="flex items-center px-3 h-12 border-2 border-[rgba(0,0,0,.2)] rounded-lg mt-3 justify-between"
          >
            <div className="flex items-center">
              <div
                className={`h-4 w-4 rounded-full`}
                style={{ backgroundColor: e.color }}
              ></div>
              <p className="ml-3">{e.title}</p>
            </div>
            <DeleteOutlined
              onClick={() => handleDelete(i, e)}
              style={{ fontSize: 18, cursor: "pointer" }}
            />
          </div>
        );
      })}
    </Modal>
  );
};

export default AddLabelModal;
