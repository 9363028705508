const ExpenseData = [
  {
    key: 1,
    id: `0127`,
    category: "ABC Category",
    client: `Jacobi-Windler`,
    project: `ABC Project`,
    amount: "$2000",
    tax: "10%",
    date: "15-05-2023",
  },
  {
    key: 2,
    id: `0127`,
    category: "ABC Category",
    client: `Jacobi-Windler`,
    project: `ABC Project`,
    amount: "$2000",
    tax: "10%",
    date: "15-05-2023",
  },
  {
    key: 3,
    id: `0127`,
    category: "ABC Category",
    client: `Jacobi-Windler`,
    project: `ABC Project`,
    amount: "$2000",
    tax: "10%",
    date: "15-05-2023",
  },
  {
    key: 4,
    id: `0127`,
    category: "ABC Category",
    client: `Jacobi-Windler`,
    project: `ABC Project`,
    amount: "$2000",
    tax: "10%",
    date: "15-05-2023",
  },
  {
    key: 5,
    id: `0127`,
    category: "ABC Category",
    client: `Jacobi-Windler`,
    project: `ABC Project`,
    amount: "$2000",
    tax: "10%",
    date: "15-05-2023",
  },
  {
    key: 6,
    id: `0127`,
    category: "ABC Category",
    client: `Jacobi-Windler`,
    project: `ABC Project`,
    amount: "$2000",
    tax: "10%",
    date: "15-05-2023",
  },
  {
    key: 7,
    id: `0127`,
    category: "ABC Category",
    client: `Jacobi-Windler`,
    project: `ABC Project`,
    amount: "$2000",
    tax: "10%",
    date: "15-05-2023",
  },
  {
    key: 8,
    id: `0127`,
    category: "ABC Category",
    client: `Jacobi-Windler`,
    project: `ABC Project`,
    amount: "$2000",
    tax: "10%",
    date: "15-05-2023",
  },
  {
    key: 9,
    id: `0127`,
    category: "ABC Category",
    client: `Jacobi-Windler`,
    project: `ABC Project`,
    amount: "$2000",
    tax: "10%",
    date: "15-05-2023",
  },
  {
    key: 10,
    id: `0127`,
    category: "ABC Category",
    client: `Jacobi-Windler`,
    project: `ABC Project`,
    amount: "$2000",
    tax: "10%",
    date: "15-05-2023",
  },
  {
    key: 11,
    id: `0127`,
    category: "ABC Category",
    client: `Jacobi-Windler`,
    project: `ABC Project`,
    amount: "$2000",
    tax: "10%",
    date: "15-05-2023",
  },
  {
    key: 12,
    id: `0127`,
    category: "ABC Category",
    client: `Jacobi-Windler`,
    project: `ABC Project`,
    amount: "$2000",
    tax: "10%",
    date: "15-05-2023",
  },
  {
    key: 13,
    id: `0127`,
    category: "ABC Category",
    client: `Jacobi-Windler`,
    project: `ABC Project`,
    amount: "$2000",
    tax: "10%",
    date: "15-05-2023",
  },
  {
    key: 14,
    id: `0127`,
    category: "ABC Category",
    client: `Jacobi-Windler`,
    project: `ABC Project`,
    amount: "$2000",
    tax: "10%",
    date: "15-05-2023",
  },
  {
    key: 15,
    id: `0127`,
    category: "ABC Category",
    client: `Jacobi-Windler`,
    project: `ABC Project`,
    amount: "$2000",
    tax: "10%",
    date: "15-05-2023",
  },
  {
    key: 16,
    id: `0127`,
    category: "ABC Category",
    client: `Jacobi-Windler`,
    project: `ABC Project`,
    amount: "$2000",
    tax: "10%",
    date: "15-05-2023",
  },
  {
    key: 17,
    id: `0127`,
    category: "ABC Category",
    client: `Jacobi-Windler`,
    project: `ABC Project`,
    amount: "$2000",
    tax: "10%",
    date: "15-05-2023",
  },
  {
    key: 18,
    id: `0127`,
    category: "ABC Category",
    client: `Jacobi-Windler`,
    project: `ABC Project`,
    amount: "$2000",
    tax: "10%",
    date: "15-05-2023",
  },
  {
    key: 19,
    id: `0127`,
    category: "ABC Category",
    client: `Jacobi-Windler`,
    project: `ABC Project`,
    amount: "$2000",
    tax: "10%",
    date: "15-05-2023",
  },
  {
    key: 20,
    id: `0127`,
    category: "ABC Category",
    client: `Jacobi-Windler`,
    project: `ABC Project`,
    amount: "$2000",
    tax: "10%",
    date: "15-05-2023",
  },
];

const ExpenseMobData = [
  {
    key: 1,
    title: `Support lead`,
    id: "Quinn Flynn",
    status: `Complete`,
  },
  {
    key: 2,
    title: `Integration Specialist`,
    id: "Rhona Davidson",
    status: `Working on`,
  },
  {
    key: 3,
    title: `Support Engineer`,
    id: "Sakuura Yamamto",
    status: `Backlog`,
  },
  {
    key: 11,
    title: `Data Coordinator`,
    id: "Serge Baldwin",
    status: `Open`,
  },
  {
    key: 4,
    title: `Regional Director`,
    id: "Shad Decker",
    status: `Complete`,
  },
  {
    key: 5,
    title: `Regional Marketing`,
    id: "Shou ltous",
    status: `Working on`,
  },
  {
    key: 6,
    title: `Developer`,
    id: "Thor walton",
    status: `Backlog`,
  },
  {
    key: 7,
    title: `Regional Director`,
    id: "Shad Decker",
    status: `Complete`,
  },
  {
    key: 8,
    title: `Regional Marketing`,
    id: "Shou ltous",
    status: `Working on`,
  },
  {
    key: 9,
    title: `Developer`,
    id: "Thor walton",
    status: `Backlog`,
  },
];

export { ExpenseData, ExpenseMobData };
