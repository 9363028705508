import { useRef, useState } from "react";
import { Button, Input, Checkbox } from "antd";
import { cross_icon } from "assets/icons/icons";
import { profile_pic } from "assets/images/images";

const ListView = ({ onClick, data, setData, onMob }) => {
  const [text, setText] = useState("");
  const [list, setList] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [showListDesc, setShowListDesc] = useState(false);
  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragAnotherColItem = useRef();
  const dragAnotherColOverItem = useRef();
  const dragStart = (e, position, i) => {
    dragItem.current = position;
    dragAnotherColItem.current = i;
  };
  const dragEnter = (e, position, i) => {
    dragOverItem.current = position;
    dragAnotherColOverItem.current = i;
  };
  const drop = (e, i) => {
    let update = [...data];
    const dragItemContent = update[i].data[dragItem.current];
    update[dragAnotherColItem.current].data.splice(dragItem.current, 1);
    update[dragAnotherColOverItem.current].data.splice(
      dragOverItem.current,
      0,
      dragItemContent
    );
    dragItem.current = null;
    dragOverItem.current = null;
    setData(update);
  };
  const addList = () => {
    setList("");
    if (list) {
      let update = [...data];
      update.push({
        title: list,
        data: [],
      });
      setData(update);
    }
  };
  const addCard = (i) => {
    setText("");
    if (text) {
      let update = [...data];
      update[i].data.push({
        status: "bg-green-400",
        title: text,
      });
      setData(update);
    }
  };
  const handleTitle = (val, i) => {
    let update = [...data];
    update[i].title = val.target.value;
    setData(update);
  };
  return (
    <div>
      {data.map((e, i) => {
        return (
          <div key={i} className="my-3 mx-5 cursor-pointer">
            <Input
              className={`${
                onMob && "!bg-transparent"
              } !border-none font-medium !px-0`}
              value={e.title}
              onChange={(val) => handleTitle(val, i)}
            />
            {e.data.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex text-sm items-center border-b-2 border-[rgba(0,0,0,.2)] py-2"
                  draggable
                  onDragStart={(e) => dragStart(e, index, i)}
                  onDragEnter={(e) => dragEnter(e, index, i)}
                  onDragEnd={() => drop(e, i)}
                >
                  <div className={`${item.status} w-1 h-10 mr-3`}></div>
                  <Checkbox />
                  <div
                    className="flex items-center justify-between ml-3 w-full"
                    onClick={onClick}
                  >
                    <p>{item.title}</p>
                    <div className="flex items-center">
                      <p>Jul 30</p>
                      <img src={profile_pic} alt="" className="ml-3" />
                    </div>
                  </div>
                </div>
              );
            })}
            {selectedId === i ? (
              <div className="mt-5">
                <Input.TextArea
                  rows={1}
                  className="w-full"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  placeholder="Enter Title for card"
                />
                <div className="flex items-center mt-2">
                  <Button type="primary" onClick={() => addCard(i)}>
                    Add Card
                  </Button>
                  <img
                    src={cross_icon}
                    onClick={() => setSelectedId("")}
                    alt=""
                    className="ml-3 cursor-pointer opacity-50"
                    width={16}
                  />
                </div>
              </div>
            ) : (
              <p
                onClick={() => setSelectedId(i)}
                className="text-themeGray-dark font-medium text-sm pt-3 cursor-pointer"
              >
                + Add Task
              </p>
            )}
          </div>
        );
      })}
      {showListDesc ? (
        <div className="px-4 mb-3">
          <Input.TextArea
            value={list}
            onChange={(e) => setList(e.target.value)}
            placeholder="Enter list title"
            className="w-full"
            rows={1}
          />
          <div className="flex items-center mt-2">
            <Button type="primary" onClick={addList}>
              Add List
            </Button>
            <img
              src={cross_icon}
              onClick={() => setShowListDesc(false)}
              alt=""
              className="ml-3 cursor-pointer opacity-50"
              width={16}
            />
          </div>
        </div>
      ) : (
        <Button
          onClick={() => setShowListDesc(true)}
          className="h-10 !bg-themeGray-light200 hover:!text-black !border-none mx-4 w-48 text-sm font-medium mt-1"
        >
          Add another list
        </Button>
      )}
    </div>
  );
};

export default ListView;
