import { useState } from "react";
import { Button, Dropdown } from "antd";
import {
  cancel_icon,
  client_portal_blue,
  cross_icon,
  download_icon,
  earth,
  filter_icon,
  home_icon,
  mark_paid,
  plus_black,
  plus_icon,
  print_icon,
  save_icon,
  send_mail,
  submenu_icon,
  view_pdf,
} from "assets/icons/icons";
import { Container, ThemeSelect, ThemeTable } from "components/components";
import items from "./optionItems/optionItems";
import OverView from "./overView/overView";
import { QuotesData } from "constant/constant";
import SendQuotes from "./sendQuotes/sendQuotes";
import CreateQuotes from "./createQuotes/createQuotes";
import { addnewColor, cancelColor, saveColor } from "constant/themeColors";

const QuotesDesktop = () => {
  const [data, setData] = useState(QuotesData);
  const [densityOpt, setDensityOpt] = useState(false);
  const [density, setDensity] = useState("middle");
  const [addModal, setAddModal] = useState("addInvoiceClose");
  const [tablesView, setTablesView] = useState("tablesViewOpen");
  const [detailsModal, setDetailsModal] = useState("detailsInvoiceModalClose");
  const [minHeight, setMinHeight] = useState(81.5);
  const [medHeight, setMedHeight] = useState(85.5);
  const [maxHeight, setMaxHeight] = useState(89.5);
  const [sendInvoiceModal, setSendInvoiceModal] = useState(
    "sendInvoiceModalClose"
  );
  const handleAddCompany = () => {
    setMinHeight(72.5);
    setMedHeight(78.5);
    setMaxHeight(84.5);
    setAddModal("addInvoiceOpen");
    setDetailsModal("detailsInvoiceModalOpen");
    setTablesView("tablesViewClose");
    if (detailsModal === "detailsInvoiceModalOpen") {
      setSendInvoiceModal("sendInvoiceModalClose");
    }
  };
  const handleCompanyDetails = () => {
    if (addModal === "addInvoiceOpen") {
      setDetailsModal("detailsInvoiceModalOpen");
    } else {
      setAddModal("addInvoiceClose");
      setDetailsModal("detailsInvoiceModalOpen");
    }
  };
  const handleBack = () => {
    setTablesView("tablesViewOpen");
    setAddModal("addInvoiceClose");
    setDetailsModal("detailsInvoiceModalClose");
    setMinHeight(81.5);
    setMedHeight(85.5);
    setMaxHeight(89.5);
  };
  const columns = [
    {
      title: "",
      dataIndex: "client",
      width: "5%",
      render: () => (
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomLeft"
          trigger={["click"]}
        >
          <div className="flex justify-center">
            <img src={submenu_icon} alt="" className="cursor-pointer" />
          </div>
        </Dropdown>
      ),
    },
    {
      title: "Id",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      width: "11%",
      filters: [
        {
          text: "0127",
          value: "0127",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.id.includes(value),
    },
    {
      title: "Client",
      dataIndex: "client",
      width: "21%",
      sorter: (a, b) => a.client - b.client,
      filters: [
        {
          text: "Jacobi-Windler",
          value: "Jacobi-Windler",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.client.includes(value),
    },
    {
      title: "Total amount",
      dataIndex: "amount",
      width: "16%",
      sorter: (a, b) => a.amount - b.amount,
      filters: [
        {
          text: "$2000",
          value: "$2000",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.amount.includes(value),
    },
    {
      title: "Date created",
      dataIndex: "date",
      width: "16%",
      sorter: (a, b) => a.date - b.date,
      filters: [
        {
          text: "15-05-2023",
          value: "15-05-2023",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.date.includes(value),
    },
    {
      title: "Due Date",
      dataIndex: "date",
      sorter: (a, b) => a.date - b.date,
      width: "16%",
      filters: [
        {
          text: "15-05-2023",
          value: "15-05-2023",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.date.includes(value),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "11%",
      filters: [
        {
          text: "Paid",
          value: "Paid",
        },
        {
          text: "Sent",
          value: "Sent",
        },
        {
          text: "Draft",
          value: "Draft",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.status.includes(value),
      render: (text) => (
        <div
          className={`${
            text === "Paid"
              ? "bg-themeGreen"
              : text === "Sent"
              ? "bg-primary"
              : text === "Draft" && "bg-themeGray-dark"
          } text-white w-3/4 h-7 text-sm flex justify-center items-center rounded-[5px]`}
        >
          <p>{text}</p>
        </div>
      ),
    },
  ];
  const handleDensity = () => {
    setDensityOpt(!densityOpt);
  };
  const buttons = [
    "Send Invoice",
    "Download",
    "Print PDF",
    "Mark sent",
    "Enter Payment",
    "Mark Paid",
  ];
  console.log(minHeight, medHeight, maxHeight);
  return (
    <Container onClick={() => densityOpt && setDensityOpt(false)}>
      {addModal === "addInvoiceOpen" && (
        <div className="flex items-center justify-between px-5 py-3 shadow-md border-b-2 border-[rgba(0,0,0,.2)] h-[9vh] desktop:h-[7vh] bigDesktop:h-[5vh]">
          <div className="flex items-center">
            <p className="font-medium text-sm">Quotes</p>
            <img src={home_icon} alt="" className="mr-3 ml-8" />
            <p className="font-medium text-sm">
              Home
              <span className="text-bgBlue"> / </span>
              <a href="#" className="text-bgBlue">
                Quotes
              </a>
              <span className="text-bgBlue"> / </span>
              <a href="#" className="text-bgBlue">
                create new quotes
              </a>
            </p>
          </div>
          <div className="flex items-center">
            <Button
              className="!text-sm px-[10px] !border-none flex items-center justify-center font-medium hover:!text-black"
              style={{
                borderRadius: 5,
                height: 27,
                backgroundColor: cancelColor,
              }}
              onClick={handleBack}
            >
              <img src={cancel_icon} alt="" className="mr-2" width={16} />
              Back
            </Button>
            <Button
              className="bg-themeGreen px-[10px] !border-none text-white ml-2 font-medium !text-sm flex items-center justify-center hover:!text-white"
              style={{ borderRadius: 5, height: 27 }}
            >
              <img src={save_icon} alt="" className="mr-2" width={18} />
              Save
            </Button>
            <Button
              className="ml-2 px-[10px] !border-none !text-sm flex items-center justify-center font-medium hover:!text-black"
              style={{ borderRadius: 5, height: 27 }}
            >
              <img src={send_mail} alt="" className="mr-2" width={16} />
              Send Email
            </Button>
            <Button
              className="ml-2 px-[10px] !border-none !text-sm flex items-center justify-center font-medium hover:!text-black"
              style={{ borderRadius: 5, height: 27 }}
            >
              <img src={send_mail} alt="" className="mr-2" width={16} />
              Schedule
            </Button>
            <Button
              className="ml-2 px-[10px] !border-none !text-sm flex items-center justify-center font-medium hover:!text-black"
              style={{ borderRadius: 5, height: 27 }}
            >
              <img src={view_pdf} alt="" className="mr-2" width={16} />
              View PDF
            </Button>
            <Button
              className="ml-2 px-[10px] !border-none !text-sm flex items-center justify-center font-medium hover:!text-black"
              style={{ borderRadius: 5, height: 27 }}
            >
              <img src={print_icon} alt="" className="mr-2" width={16} />
              Print PDF
            </Button>
            <Button
              className="ml-2 px-[10px] !border-none !text-sm flex items-center justify-center font-medium hover:!text-black"
              style={{ borderRadius: 5, height: 27 }}
            >
              <img src={download_icon} alt="" className="mr-2" width={16} />
              Download
            </Button>
            <Button
              className="ml-2 px-[10px] !border-none !text-sm flex items-center justify-center font-medium hover:!text-black"
              style={{ borderRadius: 5, height: 27 }}
            >
              <img src={earth} alt="" className="mr-2" width={16} />
              Mark Sent
            </Button>
            <Button
              className="ml-2 px-[10px] !border-none !text-sm flex items-center justify-center font-medium hover:!text-black"
              style={{ borderRadius: 5, height: 27 }}
            >
              <img src={plus_black} alt="" className="mr-2" width={16} />
              Approve
            </Button>
            <Button
              className="ml-2 px-[10px] !border-none !text-sm flex items-center justify-center font-medium hover:!text-black"
              style={{ borderRadius: 5, height: 27 }}
            >
              <img src={mark_paid} alt="" className="mr-2" width={16} />
              Convert to Invoice
            </Button>
            <Button
              className="ml-2 px-[10px] !border-none !text-sm flex items-center justify-center font-medium hover:!text-black"
              style={{ borderRadius: 5, height: 27 }}
            >
              <img
                src={client_portal_blue}
                alt=""
                className="mr-2"
                width={16}
              />
              Contact to Project
            </Button>
          </div>
        </div>
      )}

      <div className="flex">
        <div
          className={`w-full ${tablesView} overflow-y-scroll h-[${minHeight}vh] desktop:h-[${medHeight}vh] bigDesktop:h-[${maxHeight}vh] scrol`}
        >
          {addModal === "addInvoiceClose" && (
            <>
              <div className="bg-white" style={{ top: 54 }}>
                <div className="flex items-center justify-between px-5 py-3">
                  <div className="flex items-center">
                    <p className="font-medium text-sm">Quotes</p>
                    <img src={home_icon} alt="" className="mr-3 ml-8" />
                    <p className="font-medium text-sm">
                      Home
                      <span className="text-bgBlue"> / </span>
                      <a href="#" className="text-bgBlue">
                        Quotes
                      </a>
                      <span className="text-bgBlue"> / </span>
                      <a href="#" className="text-bgBlue">
                        create new quotes
                      </a>
                    </p>
                  </div>
                  <div className="flex items-center">
                    <div>
                      <img
                        src={filter_icon}
                        onClick={handleDensity}
                        alt=""
                        className="mr-4 cursor-pointer"
                      />
                      {densityOpt && (
                        <div className="relative">
                          <div className="text-sm absolute my-2 bg-white px-3 py-1 z-20 top-0 leading-7 rounded-md shadow-lg">
                            <p
                              className="cursor-pointer"
                              onClick={() => setDensity("small")}
                            >
                              Compact
                            </p>
                            <p
                              className="cursor-pointer"
                              onClick={() => setDensity("middle")}
                            >
                              Standard
                            </p>
                            <p
                              className="cursor-pointer"
                              onClick={() => setDensity("large")}
                            >
                              Comfortable
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    <img src={download_icon} alt="" className="mr-4" />
                    <img src={print_icon} alt="" className="mr-8" />
                    <Button
                      onClick={handleAddCompany}
                      className="!text-sm p-0 flex items-center justify-center font-medium hover:!text-white text-white"
                      style={{
                        borderRadius: 5,
                        height: 27,
                        width: 85,
                        backgroundColor: addnewColor,
                      }}
                    >
                      Add
                      <img src={plus_icon} alt="" className="ml-2" />
                    </Button>
                  </div>
                </div>
                <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
              </div>
              <div className="-mt-3">
                <ThemeTable
                  onClick={handleCompanyDetails}
                  columns={columns}
                  data={data}
                  setData={(e) => setData(e)}
                  density={density}
                />
              </div>
            </>
          )}
        </div>

        <div
          className={`bg-white ${addModal} overflow-y-scroll h-[${minHeight}vh] desktop:h-[${medHeight}vh] bigDesktop:h-[${maxHeight}vh] scrol`}
        >
          {addModal === "addInvoiceOpen" && (
            <div className="mx-2 my-4">
              <CreateQuotes />
            </div>
          )}
        </div>

        <div
          className={`bg-white ${detailsModal} ${
            addModal === "addInvoiceOpen" && "detailInvoiceHeight"
          } overflow-y-scroll h-[${minHeight}vh] desktop:h-[${medHeight}vh] bigDesktop:h-[${maxHeight}vh] scrol`}
        >
          {detailsModal === "detailsInvoiceModalOpen" && (
            <>
              {addModal === "addInvoiceClose" && (
                <div className="z-10 w-full shadow-md border-b-2 border-[rgba(0,0,0,.2)] bg-white sticky top-0">
                  <div className="flex items-center pr-3 justify-between ">
                    <div className="h-11 items-center w-[432px] flex">
                      <div className="pl-3">
                        <p className="font-semibold">Quote 0004</p>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <Dropdown
                        menu={{
                          items,
                        }}
                        placement="bottomLeft"
                        trigger={["click"]}
                      >
                        <div className="flex justify-center">
                          <img
                            src={submenu_icon}
                            alt=""
                            className="cursor-pointer"
                          />
                        </div>
                      </Dropdown>
                      <img
                        src={cross_icon}
                        alt=""
                        className="ml-4 cursor-pointer opacity-70"
                        width={16}
                        onClick={() => {
                          setDetailsModal("detailsInvoiceModalClose");
                          setSendInvoiceModal("sendInvoiceModalClose");
                        }}
                      />
                    </div>
                  </div>
                  <div className="mb-2">
                    {buttons.map((e, i) => {
                      return (
                        <Button
                          key={i}
                          onClick={() =>
                            i === 0 &&
                            setSendInvoiceModal("sendInvoiceModalOpen")
                          }
                          className={`!border-none mt-1 font-medium`}
                        >
                          {e}
                        </Button>
                      );
                    })}
                  </div>
                </div>
              )}
              <OverView />
            </>
          )}
        </div>

        <div
          className={`bg-white ${sendInvoiceModal} overflow-y-scroll h-[${minHeight}vh] desktop:h-[${medHeight}vh] bigDesktop:h-[${maxHeight}vh] scrol`}
        >
          {sendInvoiceModal === "sendInvoiceModalOpen" && (
            <>
              <div className="z-10 w-full shadow-md border-b-2 border-[rgba(0,0,0,.2)] bg-white sticky top-0">
                <div className="flex items-center justify-between p-3">
                  <div>
                    <p>Send invoice to client</p>
                  </div>
                  <div className="flex items-center">
                    <Button
                      className="!text-sm p-0 flex items-center justify-center font-medium hover:!text-black"
                      style={{ borderRadius: 5, height: 27, width: 85 }}
                      onClick={() =>
                        setSendInvoiceModal("sendInvoiceModalClose")
                      }
                    >
                      <img
                        src={cancel_icon}
                        alt=""
                        className="mr-1"
                        width={16}
                      />
                      Back
                    </Button>
                    <Button
                      className="text-white ml-2 font-medium !text-sm p-0 flex items-center justify-center hover:!text-white"
                      style={{
                        borderRadius: 5,
                        height: 27,
                        width: 85,
                        backgroundColor: saveColor,
                      }}
                    >
                      <img src={save_icon} alt="" className="mr-1" width={18} />
                      Send
                    </Button>
                  </div>
                </div>
              </div>
              <SendQuotes />
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default QuotesDesktop;
