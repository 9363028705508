import { Button, Col, Row } from "antd";
import React from "react";
import { BiSolidHelpCircle } from "react-icons/bi";
import { IoIosSettings } from "react-icons/io";
import {
  Container,
  InvoicesExpenses,
  OverdueInvoices,
  MyTasks,
  Projects,
  MyCalendar,
  CardText,
  CardProgress,
  CardRedArrow,
} from "components/components";

const items = [
  {
    label: <a href="https://www.antgroup.com">1st menu item</a>,
    key: "0",
  },
  {
    label: <a href="https://www.aliyun.com">2nd menu item</a>,
    key: "1",
  },
  {
    type: "divider",
  },
  {
    label: "3rd menu item",
    key: "3",
  },
];

const Overview = () => {
  return (
    <Container>
      <div className="px-8 mx-auto py-4 overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
        <div className="flex items-center flex-wrap justify-between">
          <div className="flex items-center">
            <h3>OverView</h3>
          </div>
          <div className="flex items-center">
            <Button
              type="Add to Dashboard"
              className="flex items-center font-medium"
            >
              <IoIosSettings fill="#494F59" size={20} className="mr-2" />{" "}
              Settings
            </Button>
            <Button
              type="Add to Dashboard"
              className="flex items-center font-medium"
            >
              <BiSolidHelpCircle fill="#494F59" size={20} className="mr-2" />{" "}
              Help
            </Button>
          </div>
        </div>
        <section className="cards-sec">
          <Row gutter={[16, 16]} className="mt-3">
            <CardText
              heading={"All invoices issued last month"}
              title={"Excl tax 6 invoices"}
              description={"70,65"}
              subValue={"k URL"}
            />
            <CardText
              heading={"Profit"}
              title={"Sum (Excl tax)"}
              description={"320,6"}
              subValue={"k URL"}
            />
            <CardProgress
              heading={"Revenue last month"}
              progress={"118"}
              title={"Sum (Excl tax)"}
              description={"70,65"}
              subValue={"k EUR"}
            />
            <CardText
              heading={"Tasks - needs attention"}
              title={"Total"}
              description={"1"}
            />
            <CardProgress
              heading={"My overdue tasks"}
              progress={"0"}
              title={"Total"}
              description={"5"}
            />
            <CardProgress
              heading={"Deals closing this month"}
              progress={"92"}
              title={"Sum"}
              description={"70,65"}
              subValue={"k EUR"}
            />
            <CardText
              heading={"Quoted this month"}
              title={"Sum (Excl tax)"}
              description={"7.300"}
              subValue={"EUR"}
            />
            <CardText
              heading={"New leads this month"}
              title={"Total"}
              description={"0"}
              subValue={"quotes"}
            />
            <CardText
              heading={"My projects by income, cost and profit"}
              title={"Income with tax"}
              description={"199,7"}
              subValue={"k EUR"}
            />
            <CardText
              heading={"Top customers this month"}
              title={"Income with tax"}
              description={"9.480"}
              subValue={"k EUR"}
            />
            <CardRedArrow
              heading={"Revenue this month vs last month"}
              title={"Sum (Excl tax)"}
              percent={"-86,58"}
              description={"9.480"}
              subValue={"k EUR"}
            />
            <CardText heading={"My Task"} title={"Total"} description={5} />
          </Row>
          <Row gutter={[16, 16]} className="mt-3">
            <Col span={24} lg={12} xl={8}>
              <MyCalendar />
            </Col>

            <Col span={24} lg={12} xl={16}>
              <OverdueInvoices />
            </Col>
            <Col span={24} lg={12} xl={8}>
              <MyTasks />
            </Col>
            <Col span={24} lg={12} xl={8}>
              <Projects />
            </Col>
            <Col span={24} lg={12} xl={8}>
              <InvoicesExpenses />
            </Col>
          </Row>
        </section>
      </div>
    </Container>
  );
};

export default Overview;
