const ProductData = [
  {
    key: 1,
    id: `01`,
    product: "Chief Executive",
    description: `Chief Executive Officer (CEO)`,
    price: "$1000",
    quantity: "2000",
    category: "Example",
  },
  {
    key: 2,
    id: `01`,
    product: "Chief Executive",
    description: `Chief Executive Officer (CEO)`,
    price: "$1000",
    quantity: "2000",
    category: "Example",
  },
  {
    key: 3,
    id: `01`,
    product: "Chief Executive",
    description: `Chief Executive Officer (CEO)`,
    price: "$1000",
    quantity: "2000",
    category: "Example",
  },
  {
    key: 4,
    id: `01`,
    product: "Chief Executive",
    description: `Chief Executive Officer (CEO)`,
    price: "$1000",
    quantity: "2000",
    category: "Example",
  },
  {
    key: 5,
    id: `01`,
    product: "Chief Executive",
    description: `Chief Executive Officer (CEO)`,
    price: "$1000",
    quantity: "2000",
    category: "Example",
  },
  {
    key: 6,
    id: `01`,
    product: "Chief Executive",
    description: `Chief Executive Officer (CEO)`,
    price: "$1000",
    quantity: "2000",
    category: "Example",
  },
  {
    key: 7,
    id: `01`,
    product: "Chief Executive",
    description: `Chief Executive Officer (CEO)`,
    price: "$1000",
    quantity: "2000",
    category: "Example",
  },
  {
    key: 8,
    id: `01`,
    product: "Chief Executive",
    description: `Chief Executive Officer (CEO)`,
    price: "$1000",
    quantity: "2000",
    category: "Example",
  },
  {
    key: 9,
    id: `01`,
    product: "Chief Executive",
    description: `Chief Executive Officer (CEO)`,
    price: "$1000",
    quantity: "2000",
    category: "Example",
  },
  {
    key: 10,
    id: `01`,
    product: "Chief Executive",
    description: `Chief Executive Officer (CEO)`,
    price: "$1000",
    quantity: "2000",
    category: "Example",
  },
  {
    key: 11,
    id: `01`,
    product: "Chief Executive",
    description: `Chief Executive Officer (CEO)`,
    price: "$1000",
    quantity: "2000",
    category: "Example",
  },
  {
    key: 12,
    id: `01`,
    product: "Chief Executive",
    description: `Chief Executive Officer (CEO)`,
    price: "$1000",
    quantity: "2000",
    category: "Example",
  },
  {
    key: 13,
    id: `01`,
    product: "Chief Executive",
    description: `Chief Executive Officer (CEO)`,
    price: "$1000",
    quantity: "2000",
    category: "Example",
  },
  {
    key: 14,
    id: `01`,
    product: "Chief Executive",
    description: `Chief Executive Officer (CEO)`,
    price: "$1000",
    quantity: "2000",
    category: "Example",
  },
  {
    key: 15,
    id: `01`,
    product: "Chief Executive",
    description: `Chief Executive Officer (CEO)`,
    price: "$1000",
    quantity: "2000",
    category: "Example",
  },
  {
    key: 16,
    id: `01`,
    product: "Chief Executive",
    description: `Chief Executive Officer (CEO)`,
    price: "$1000",
    quantity: "2000",
    category: "Example",
  },
  {
    key: 17,
    id: `01`,
    product: "Chief Executive",
    description: `Chief Executive Officer (CEO)`,
    price: "$1000",
    quantity: "2000",
    category: "Example",
  },
  {
    key: 18,
    id: `01`,
    product: "Chief Executive",
    description: `Chief Executive Officer (CEO)`,
    price: "$1000",
    quantity: "2000",
    category: "Example",
  },
  {
    key: 19,
    id: `01`,
    product: "Chief Executive",
    description: `Chief Executive Officer (CEO)`,
    price: "$1000",
    quantity: "2000",
    category: "Example",
  },
  {
    key: 20,
    id: `01`,
    product: "Chief Executive",
    description: `Chief Executive Officer (CEO)`,
    price: "$1000",
    quantity: "2000",
    category: "Example",
  },
];

const ProductMobData = [
  {
    key: 1,
    title: `Support lead`,
    id: "Quinn Flynn",
    status: `Complete`,
  },
  {
    key: 2,
    title: `Integration Specialist`,
    id: "Rhona Davidson",
    status: `Working on`,
  },
  {
    key: 3,
    title: `Support Engineer`,
    id: "Sakuura Yamamto",
    status: `Backlog`,
  },
  {
    key: 11,
    title: `Data Coordinator`,
    id: "Serge Baldwin",
    status: `Open`,
  },
  {
    key: 4,
    title: `Regional Director`,
    id: "Shad Decker",
    status: `Complete`,
  },
  {
    key: 5,
    title: `Regional Marketing`,
    id: "Shou ltous",
    status: `Working on`,
  },
  {
    key: 6,
    title: `Developer`,
    id: "Thor walton",
    status: `Backlog`,
  },
  {
    key: 7,
    title: `Regional Director`,
    id: "Shad Decker",
    status: `Complete`,
  },
  {
    key: 8,
    title: `Regional Marketing`,
    id: "Shou ltous",
    status: `Working on`,
  },
  {
    key: 9,
    title: `Developer`,
    id: "Thor walton",
    status: `Backlog`,
  },
];

export { ProductData, ProductMobData };
