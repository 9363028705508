import { ThemeInput } from "components/components";

const Message = () => {
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        <ThemeInput multiline rows={4} placeholder={"Unpaid Invoice"} />
        <ThemeInput multiline rows={4} placeholder={"Paid Invoice"} />
        <ThemeInput multiline rows={4} placeholder={"Unapproved Quote"} />
      </div>
    </div>
  );
};

export default Message;
