import { Button, Input } from "antd";
import { ThemeTable } from "components/components";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const data = [
  {
    key: 1,
    description: `Two friends reunited at a café, sharing laughter and heartfelt conversations that deepened their bond. Time seemed to freeze as they exchanged stories, support, and advice.`,
    added_from: "Chelsey Shanahan",
    date_added: "15-05-2023",
    status: `Sent`,
  },
  {
    key: 2,
    description: `Two friends reunited at a café, sharing laughter and heartfelt conversations that deepened their bond. Time seemed to freeze as they exchanged stories, support, and advice.`,
    added_from: "Chelsey Shanahan",
    date_added: "15-05-2023",
    status: `Sent`,
  },
];

const columns = [
  {
    title: "Description",
    dataIndex: "description",
    width: "45%",
  },
  {
    title: "Added From",
    dataIndex: "added_from",
    render: (text) => <p className="text-primary">{text}</p>,
  },
  {
    title: "Date Added",
    dataIndex: "date_added",
    width: "22.5%",
    filters: [
      {
        text: "26/Mar/2023",
        value: "26/Mar/2023",
      },
    ],
    filterMode: "tree",
    filterSearch: true,
    onFilter: (value, record) => record.date_added.includes(value),
  },
  {
    title: "Options",
    dataIndex: "status",
    width: "10%",
    render: () => (
      <div className="flex justify-around">
        <EditOutlined />
        <DeleteOutlined />
      </div>
    ),
  },
];

const Notes = () => {
  return (
    <div className="m-4">
      <p className="mb-1">Note Description</p>
      <Input.TextArea rows={5} />
      <div className="mt-3 pb-3 flex justify-end">
        <Button type="primary" className="bg-primary">
          Save
        </Button>
      </div>
      <ThemeTable
        noSelectRows
        columns={columns}
        data={data}
        density={"middle"}
      />
    </div>
  );
};

export default Notes;
