import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Card = ({ title, desc, onClick, deleteBtn }) => {
  return (
    <div className="my-3 bg-gray-100 py-3 px-4 rounded-lg">
      <p className="">{title}</p>
      <div className="flex text-sm items-center justify-between">
        <p>{desc}</p>
        <div className="flex items-center">
          <a onClick={onClick} className="cursor-pointer">
            View
          </a>
          {deleteBtn && (
            <FontAwesomeIcon className="ml-5 text-lg" icon={faTrashCan} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
