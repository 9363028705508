import React from "react";
import { profile_pic } from "assets/images/images";
import { Col, Row } from "antd";

const Pipeline = () => {
  return (
    <Row>
      <Col span={6}>
        <div className="bg-[#e3d0ff33] border-b-2 border-b-[#e3d0ff] p-3">
          <span className="text-[#1F2226] opacity-[0.9] mb-2 block">
            Opportunity
          </span>
          <p className="text-[12px]">
            <strong className="font-semibold mr-3">6 quotes</strong> 100.060,00
            (Total sum)
          </p>
        </div>

        <Row gutter={[16, 16]} className="margin-0 bg-[#F7F9FC] py-4">
          <Col span={24}>
            <div className="shadow p-3 rounded-sm bg-white">
              <div className="flex justify-between flex-wrap">
                <span className="font-bold">Client</span>
                <img
                  className="rounded-full"
                  width={"20px"}
                  src={profile_pic}
                  alt=""
                />
              </div>
              <div className="flex justify-between flex-wrap mt-2">
                <span className=" text-[#717A89]">23d</span>
                <span className=" text-[12px]">50.000,00</span>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div className="shadow p-3 rounded-sm bg-white">
              <div className="flex justify-between flex-wrap">
                <span className="font-bold">Client</span>
                <span className="bg-[#969FAE] px-1 text-white text-[12px] rounded-sm leading-[21px]">
                  SP
                </span>
              </div>
              <div className="flex justify-between flex-wrap mt-2">
                <span className=" text-[#717A89]">23d</span>
                <span className=" text-[12px]">50.000,00</span>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={6}>
        <div className="bg-[#b7ecec33] border-b-2 border-b-[#b7ecec] p-3">
          <span className="text-[#1F2226] opacity-[0.9] mb-2 block">
            Scoping
          </span>
          <p className="text-[12px]">
            <strong className="font-semibold mr-3">6 quotes</strong> 100.060,00
            (Total sum)
          </p>
        </div>

        <Row gutter={[16, 16]} className="margin-0 bg-[#F7F9FC] py-4">
          <Col span={24}>
            <div className="shadow p-3 rounded-sm bg-white">
              <div className="flex justify-between flex-wrap">
                <span className="font-bold">Client</span>
                <img
                  className="rounded-full"
                  width={"20px"}
                  src={profile_pic}
                  alt=""
                />
              </div>
              <div className="flex justify-between flex-wrap mt-2">
                <span className=" text-[#717A89]">23d</span>
                <span className=" text-[12px]">50.000,00</span>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div className="shadow p-3 rounded-sm bg-white">
              <div className="flex justify-between flex-wrap">
                <span className="font-bold">Client</span>
                <span className="bg-[#969FAE] px-1 text-white text-[12px] rounded-sm leading-[21px]">
                  SP
                </span>
              </div>
              <div className="flex justify-between flex-wrap mt-2">
                <span className=" text-[#717A89]">23d</span>
                <span className=" text-[12px]">50.000,00</span>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={6}>
        <div className="bg-[#ffc58b33] border-b-2 border-b-[#ffc58b] p-3">
          <span className="text-[#1F2226] opacity-[0.9] mb-2 block">
            Proposal
          </span>
          <p className="text-[12px]">
            <strong className="font-semibold mr-3">6 quotes</strong> 100.060,00
            (Total sum)
          </p>
        </div>

        <Row gutter={[16, 16]} className="margin-0 bg-[#F7F9FC] py-4">
          <Col span={24}>
            <div className="shadow p-3 rounded-sm bg-white">
              <div className="flex justify-between flex-wrap">
                <span className="font-bold">Client</span>
                <img
                  className="rounded-full"
                  width={"20px"}
                  src={profile_pic}
                  alt=""
                />
              </div>
              <div className="flex justify-between flex-wrap mt-2">
                <span className=" text-[#717A89]">23d</span>
                <span className=" text-[12px]">50.000,00</span>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div className="shadow p-3 rounded-sm bg-white">
              <div className="flex justify-between flex-wrap">
                <span className="font-bold">Client</span>
                <span className="bg-[#969FAE] px-1 text-white text-[12px] rounded-sm leading-[21px]">
                  SP
                </span>
              </div>
              <div className="flex justify-between flex-wrap mt-2">
                <span className=" text-[#717A89]">23d</span>
                <span className=" text-[12px]">50.000,00</span>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div className="shadow p-3 rounded-sm bg-white">
              <div className="flex justify-between flex-wrap">
                <span className="font-bold">Client</span>
                <img
                  className="rounded-full"
                  width={"20px"}
                  src={profile_pic}
                  alt=""
                />
              </div>
              <div className="flex justify-between flex-wrap mt-2">
                <span className=" text-[#717A89]">23d</span>
                <span className=" text-[12px]">50.000,00</span>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div className="shadow p-3 rounded-sm bg-white">
              <div className="flex justify-between flex-wrap">
                <span className="font-bold">Client</span>
                <span className="bg-[#969FAE] px-1 text-white text-[12px] rounded-sm leading-[21px]">
                  SP
                </span>
              </div>
              <div className="flex justify-between flex-wrap mt-2">
                <span className=" text-[#717A89]">23d</span>
                <span className=" text-[12px]">50.000,00</span>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div className="shadow p-3 rounded-sm bg-white">
              <div className="flex justify-between flex-wrap">
                <span className="font-bold">Client</span>
                <img
                  className="rounded-full"
                  width={"20px"}
                  src={profile_pic}
                  alt=""
                />
              </div>
              <div className="flex justify-between flex-wrap mt-2">
                <span className=" text-[#717A89]">23d</span>
                <span className=" text-[12px]">50.000,00</span>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div className="shadow p-3 rounded-sm bg-white">
              <div className="flex justify-between flex-wrap">
                <span className="font-bold">Client</span>
                <span className="bg-[#969FAE] px-1 text-white text-[12px] rounded-sm leading-[21px]">
                  SP
                </span>
              </div>
              <div className="flex justify-between flex-wrap mt-2">
                <span className=" text-[#717A89]">23d</span>
                <span className=" text-[12px]">50.000,00</span>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={6}>
        <div className="bg-[#50b83c33] border-b-2 border-b-[#50b83c] p-3">
          <span className="text-[#1F2226] opacity-[0.9] mb-2 block">
            Confirmed
          </span>
          <p className="text-[12px]">
            <strong className="font-semibold mr-3">6 quotes</strong> 100.060,00
            (Total sum)
          </p>
        </div>

        <Row gutter={[16, 16]} className="margin-0 bg-[#F7F9FC] py-4">
          <Col span={24}>
            <div className="shadow p-3 rounded-sm bg-white">
              <div className="flex justify-between flex-wrap">
                <span className="font-bold">Client</span>
                <img
                  className="rounded-full"
                  width={"20px"}
                  src={profile_pic}
                  alt=""
                />
              </div>
              <div className="flex justify-between flex-wrap mt-2">
                <span className=" text-[#717A89]">23d</span>
                <span className=" text-[12px]">50.000,00</span>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div className="shadow p-3 rounded-sm bg-white">
              <div className="flex justify-between flex-wrap">
                <span className="font-bold">Client</span>
                <span className="bg-[#969FAE] px-1 text-white text-[12px] rounded-sm leading-[21px]">
                  SP
                </span>
              </div>
              <div className="flex justify-between flex-wrap mt-2">
                <span className=" text-[#717A89]">23d</span>
                <span className=" text-[12px]">50.000,00</span>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Pipeline;
