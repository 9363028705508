import MobFooter from "../mobFooter/mobFooter";
import MobHeader from "../mobHeader/mobHeader";

const MobContainer = ({
  children,
  addCompany,
  tab,
  setTab,
  tabs,
  className,
  onAdd,
  heading,
}) => {
  return (
    <div className={`flex flex-col justify-between min-h-screen ${className}`}>
      <div>
        <MobHeader
          addCompany={addCompany}
          tab={tab}
          setTab={setTab}
          tabs={tabs}
          heading={heading}
        />
        {children}
      </div>
      <MobFooter onAdd={onAdd} />
    </div>
  );
};

export default MobContainer;
