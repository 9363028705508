import { ThemeProvider, createTheme } from "@mui/material";
import { ThemeInput } from "components/components";
import Inter from "assets/font/Inter.ttf";

const theme = createTheme({
  typography: {
    fontFamily: Inter,
  },
  palette: {
    primary: {
      main: "#00A1E4",
    },
  },
});

const BasicInfo = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
        <div className="bg-white p-3 shadow-md">
          <ThemeInput placeholder={"Company Name"} />
          <ThemeInput placeholder={"Email"} />
          <ThemeInput placeholder={"Address"} />
        </div>
        <div className="bg-white p-3 mt-3 shadow-md">
          <ThemeInput placeholder={"City"} />
          <ThemeInput placeholder={"Postal Code"} />
          <ThemeInput placeholder={"Province / State"} />
          <ThemeInput placeholder={"Country"} />
          <ThemeInput placeholder={"Website"} />
          <ThemeInput placeholder={"Phone"} />
          <ThemeInput placeholder={"Fax Machine"} />
          <ThemeInput placeholder={"Tax number"} />
          <ThemeInput placeholder={"Language"} />
          <ThemeInput placeholder={"Status"} />
          <ThemeInput placeholder={"Company Address"} />
        </div>
      </div>
    </ThemeProvider>
  );
};

const ContactPerson = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[69.5vh] desktop:h-[75.5vh] bigDesktop:h-[82.5vh]">
        <div className="bg-white p-3 shadow-md">
          <ThemeInput placeholder={"First Name"} />
          <ThemeInput placeholder={"Last Name"} />
          <ThemeInput placeholder={"Phone"} />
          <ThemeInput placeholder={"Email"} />
        </div>
      </div>
    </ThemeProvider>
  );
};

export { BasicInfo, ContactPerson };
