import { Button, Checkbox } from "antd";
import { ThemeInput, ThemeSelect } from "components/components";
import { FileTwoTone } from "@ant-design/icons";
import { Editor } from "react-draft-wysiwyg";

const SendInvoice = () => {
  return (
    <div className="my-3">
      <div className="mx-3 py-1">
        <p className="text-sm">Email to</p>
        <ThemeSelect
          label={"Initial Email"}
          data={[
            "example@gmail.com",
            "example2@gmail.com",
            "example3@gmail.com",
          ]}
        />
        <ThemeInput placeholder={"CC"} />
        <div className="my-3 flex flex-col">
          <Checkbox defaultChecked className="mb-1">
            Attach Invoice PDF
          </Checkbox>
          <Checkbox>
            Attach Customer Statement <FileTwoTone />
          </Checkbox>
        </div>
        <p className="text-sm pt-2">Preview Email Template</p>
        <ThemeInput placeholder={"Subject"} />
        <div
          style={{ minHeight: 350 }}
          className="border-[1px] mb-3 border-[rgba(0,0,0,.1)]"
        >
          <Editor
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName px-3"
          />
          <div className="relative">
            <div className="flex flex-col items-center py-8 absolute -top-10 left-28">
              <p className="text-center font-semibold">
                let's do business b.v <br /> Klik op onderstaande link om ww
                factuur <br /> van $100.00 in te zien.
              </p>
              <Button type="primary" className="mt-5 bg-primary h-10">
                Bekijk factuur
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendInvoice;
