import { useState, useEffect } from "react";
import {
  faArrowsRotate,
  faBasketShopping,
  faDownload,
  faEllipsisVertical,
  faEnvelope,
  faPrint,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Progress } from "antd";
import { Container, ThemeTable } from "components/components";
import { menu_icon } from "assets/icons/icons";
import ReactApexChart from "react-apexcharts";
import { Chart } from "react-google-charts";

export const mapoptions = {
  Popularity: "400", // Africa
  colorAxis: { colors: ["#00853f", "black", "#e31b23"] },
  datalessRegionColor: "#f8bbd0",
  defaultColor: "#f5f5f5",
};

const Dashboard = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    let update = [];
    for (let i = 0; i < 5; i++) {
      let tableData = {
        contact_name: "Buck Roberts",
        phone_number: "(11) 3885-1022",
        leads_score: "234",
        location: "Guarulhos, Brazil",
        create_date: "17 Mar, 2023",
      };
      update.push(tableData);
      setData(update);
    }
  }, []);
  const mapdata = [
    ["Country", "Popularity"],
    ["Germany", 200],
    ["United States", 300],
    ["Brazil", 400],
    ["Canada", 500],
    ["France", 600],
    ["RU", 700],
  ];
  const columns = [
    {
      title: "Contact Name",
      dataIndex: "contact_name",
      sorter: (a, b) => a.contact_name - b.contact_name,
      width: "20%",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      sorter: (a, b) => a.phone_number - b.phone_number,
      width: "20%",
    },
    {
      title: "Leads Score",
      dataIndex: "leads_score",
      sorter: (a, b) => a.leads_score - b.leads_score,
      width: "20%",
    },
    {
      title: "Location",
      dataIndex: "location",
      sorter: (a, b) => a.location - b.location,
      width: "20%",
    },
    {
      title: "Create Date",
      dataIndex: "create_date",
      width: "11%",
      sorter: (a, b) => a.create_date - b.create_date,
      width: "20%",
    },
  ];
  const chartData = {
    series: [
      {
        name: "2022 invoices",
        data: [10, 20, 30, 40, 50, 60, 30, 40, 60],
      },
      {
        name: "2021 invoices",
        data: [0, 0, 0, 0, 20, 45, 67, 23, 27],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      colors: ["#50b83c", "#dee5f1"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      legend: {
        position: "right",
        offsetX: -30,
        offsetY: 0,
        markers: {
          radius: 0,
        },
      },
      toolbar: {
        show: true, // Set this to false to remove the toolbar
      },
      xaxis: {
        categories: [
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
        ],
      },

      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
    },
  };
  const reportsData = [
    {
      title: "United States",
      count: "15,364",
      bg: "green",
    },
    {
      title: "Ru",
      count: "12,387",
      bg: "red",
    },
    {
      title: "Canada",
      count: "9,123",
      bg: "darkgreen",
    },
    {
      title: "France",
      count: "7,108",
      bg: "darkred",
    },
  ];
  return (
    <Container superAdmin active={"Dashboard"}>
      <div className="w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
        <div className="bg-white" style={{ top: 54 }}>
          <div className="flex items-center justify-between px-5 py-3">
            <div className="flex items-center">
              <p className="font-medium text-sm">Dashoard</p>
            </div>
            <div className="text-end pe-5">
              <div className="flex items-center">
                <div className="me-4">
                  <FontAwesomeIcon icon={faArrowsRotate} />
                </div>
                <p className="text-sm p-0 m-0 text-gray-700">
                  Data refreshed at jul 18, 2023 04:06 PM
                </p>
                <div className="flex ">
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faEnvelope} className="fs-1" />
                  </div>
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faDownload} />
                  </div>
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faPrint} />
                  </div>
                </div>
                <FontAwesomeIcon
                  icon={faEllipsisVertical}
                  className="fs-1 mx-4"
                />
                <FontAwesomeIcon icon={faXmark} className="fs-1" />
              </div>
            </div>
          </div>
          <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
        </div>

        <div className="grid grid-cols-7 gap-3 mx-3">
          <div className="col-span-3">
            <div className="grid grid-cols-2 gap-3">
              <div className="col-span-2 shadow-md p-3">
                <p className="text-sm">Nice to see you, Sandjai</p>
                <p className="text-[10px]">
                  Get proposals from the most relevant sellers
                </p>
                <p className="text-[10px]">
                  Simply create a project brief and let us do the searching for
                  you.
                </p>
                <div className="grid grid-cols-2 mt-3">
                  <div className="bg-gray-100 p-2">
                    <p className="text-xs">Active Companies</p>
                    <p className="text-sm font-semibold">34,696</p>
                  </div>
                  <div className="bg-gray-100 p-2">
                    <p className="text-xs">Inactive Companies</p>
                    <p className="text-sm font-semibold">34,696</p>
                  </div>
                </div>
              </div>
              <div className="shadow-md mt-2 p-3">
                <div className="flex mb-3 items-center justify-between w-full">
                  <div className="bg-blue-100 text-blue-700 h-10 w-10 rounded-md flex items-center justify-center">
                    <FontAwesomeIcon icon={faBasketShopping} />
                  </div>
                  <p className="text-red-600">-8.4%</p>
                </div>
                <p className="text-xs">Income</p>
                <p className="text-sm font-semibold">23,480</p>
                <Progress percent={50} />
              </div>
              <div className="shadow-md mt-2 p-3">
                <div className="flex mb-3 items-center justify-between w-full">
                  <div className="bg-blue-100 text-blue-700 h-10 w-10 rounded-md flex items-center justify-center">
                    <FontAwesomeIcon icon={faBasketShopping} />
                  </div>
                  <p className="text-red-600">-8.4%</p>
                </div>
                <p className="text-xs">Products Sold</p>
                <p className="text-sm font-semibold">23,480</p>
                <Progress percent={50} />
              </div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="shadow-md p-3">
              <p className="text-sm">Revenue</p>
              <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="bar"
                height={243}
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-3 mx-3 mt-3">
          <div className="shadow-md p-3">
            <div className="flex items-end justify-between mb-2">
              <div className="mb-1">
                <p className="text-xs">Recent Registered Companies</p>
              </div>
            </div>
            <ThemeTable
              density={"small"}
              columns={columns}
              data={data}
              noSelectRows
            />
          </div>
          <div className="shadow-md p-3">
            <div className="flex items-end justify-between mb-2">
              <div className="mb-1">
                <p className="text-xs">Recent Paid Subscriptions</p>
              </div>
            </div>
            <ThemeTable
              density={"small"}
              columns={columns}
              data={data}
              noSelectRows
            />
          </div>
        </div>

        <div className="shadow-md p-3 mx-3 mt-3">
          <div className="border-b-2 border-[rgba(0,0,0,.1)] flex items-center justify-between py-3">
            <p className="text-xs">Register user from:</p>
            <img src={menu_icon} />
          </div>
          <div className="grid grid-cols-2 gap-5">
            <div>
              <p className="font-semibold text-lg mt-2">
                53,736{" "}
                <span className="text-xs text-themeGray-medium300 font-medium">
                  Sales
                </span>
              </p>
              <div className="my-3">
                {reportsData.map((e, i) => {
                  return (
                    <div
                      key={i}
                      className="flex items-center justify-between py-3 px-2"
                    >
                      <div className="flex items-center">
                        <div
                          style={{ backgroundColor: e.bg }}
                          className="h-2 w-2 rounded-full"
                        ></div>
                        <p className="ml-2 text-sm">{e.title}</p>
                      </div>
                      <p className="text-sm">{e.count}</p>
                    </div>
                  );
                })}
              </div>
              <Button className="h-10 w-full" type="primary">
                Show Full Report
              </Button>
            </div>
            <div className="my-5">
              <Chart
                chartEvents={[
                  {
                    eventName: "select",
                    callback: ({ chartWrapper }) => {
                      const chart = chartWrapper.getChart();
                      const selection = chart.getSelection();
                      if (selection.length === 0) return;
                      const region = data[selection[0].row + 1];
                      console.log("Selected : " + region);
                    },
                  },
                ]}
                chartType="GeoChart"
                width="100%"
                height="400px"
                data={mapdata}
                options={mapoptions}
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-3 mx-3 my-3">
          <div className="shadow-md p-3">
            <div className="flex items-end justify-between mb-2">
              <div className="mb-1">
                <p className="text-xs">Recent Registered Companies</p>
              </div>
            </div>
            <ThemeTable
              density={"small"}
              columns={columns}
              data={data}
              noSelectRows
            />
          </div>
          <div className="shadow-md p-3">
            <div className="flex items-end justify-between mb-2">
              <div className="mb-1">
                <p className="text-xs">Company Regstrations</p>
              </div>
            </div>
            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="bar"
              height={305}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Dashboard;
