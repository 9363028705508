import { Button, Col, Row, Table } from "antd";
import React from "react";
import { BiSolidHelpCircle } from "react-icons/bi";
import { IoIosSettings } from "react-icons/io";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { Container, CardProgress, CardText } from "components/components";
import ReactApexChart from "react-apexcharts";

const chartData = {
  series: [
    {
      data: [
        {
          x: "Team A",
          y: [1, 5],
        },
        {
          x: "Team B",
          y: [4, 6],
        },
        {
          x: "Team C",
          y: [5, 8],
        },
        {
          x: "Team D",
          y: [3, 11],
        },
      ],
    },
    {
      data: [
        {
          x: "Team A",
          y: [2, 6],
        },
        {
          x: "Team B",
          y: [1, 3],
        },
        {
          x: "Team C",
          y: [7, 8],
        },
        {
          x: "Team D",
          y: [5, 9],
        },
      ],
    },
  ],
  options: {
    chart: {
      type: "rangeBar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: false,
    },
  },
};

const chartData2 = {
  series: [44, 55, 20],
  options: {
    chart: {
      type: "donut",
      minHeight: 500,
      height: 500,
    },
    legend: {
      position: "right",
      offsetX: -30,
      offsetY: 150,
      markers: {
        radius: 0,
      },
    },
    colors: ["#bb005d", "#fd8f8e", "#90aacb"],
    plotOptions: {
      pie: {
        donut: {
          size: "40%",
        },
      },
    },
  },
};

const columns = [
  {
    title: "Project or contact",
    dataIndex: "project",
    key: "project",
    className: "min-width-column2",

    render: (text) => <div>{text}</div>,
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    className: "min-width-column2",

    render: (text) => (
      <div className="flex">
        <FontAwesomeIcon
          icon={faCheck}
          color="#E43958"
          fontSize={12}
          className="mt-1 mr-2"
        />{" "}
        {text}
      </div>
    ),
  },

  {
    title: "Progress",
    dataIndex: "Progress",
    key: "Progress",
    render: (text) => (
      <div className="">
        {text ? (
          <div class="relative pt-1">
            <div class="overflow-hidden h-1 mb-4 text-xs flex rounded bg-emerald-200">
              <div
                style={{ width: "50%" }}
                class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-sky-500"
              ></div>
              <div
                style={{ width: "50%" }}
                class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"
              ></div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    ),
  },

  {
    title: "Due Date",
    dataIndex: "dueDate",
    key: "dueDate",
    className: "min-width-100",

    render: (text) => <div>{text}</div>,
  },
  {
    title: "Duration",
    dataIndex: "duration",
    key: "duration",
  },
  {
    title: "action",
    dataIndex: "action",
    key: "action",
    render: (text) => (
      <div>
        {text && (
          <FontAwesomeIcon icon={faCircleCheck} color="#B4BCCA" fontSize={18} />
        )}
      </div>
    ),
  },
];
const data = [
  {
    project: "Time and Material Project (example)",
    Progress: "yes",
    dueDate: "Today",
    title: "Strategy creation",
    action: "action",
    duration: "80:00",
  },
  {
    project: "Time and Material Project (example)",
    Progress: "yes",
    dueDate: "Today",
    title: "Strategy creation",
    action: "action",
    duration: "80:00",
  },
  {
    project: "Time and Material Project (example)",
    Progress: "yes",
    dueDate: "Today",
    title: "Strategy creation",
    action: "action",
    duration: "80:00",
  },
];

const columns2 = [
  {
    title: "Project",
    dataIndex: "Project",
    key: "Project",
    className: "min-width-column2",

    render: (text) => <div>{text}</div>,
  },
  {
    title: "Income (Budget)",
    dataIndex: "Income",
    key: "Income",
  },

  {
    title: "Duration",
    dataIndex: "Duration",
    key: "Duration",
  },

  {
    title: "Project progress",
    dataIndex: "ProjectProgress",
    key: "ProjectProgress",
    className: "min-width-100",
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
  },
  {
    title: "% Completed",
    dataIndex: "Completed",
    key: "Completed",
    className: "min-width-column",
  },
];
const data2 = [
  {
    Project: "Time and Material Project (example)",
    Income: "20000",
    Duration: "6:00 h",
    ProjectProgress: "61,00%",
    Status: "InProgress",
    Completed: "53,200 %",
  },
  {
    Project: "Time and Material Project (example)",
    Income: "20000",
    Duration: "6:00 h",
    ProjectProgress: "61,00%",
    Status: "InProgress",
    Completed: "53,200 %",
  },
  {
    Project: "Time and Material Project (example)",
    Income: "20000",
    Duration: "6:00 h",
    ProjectProgress: "61,00%",
    Status: "InProgress",
    Completed: "53,200 %",
  },
];

const ProjectManagement = () => {
  return (
    <Container>
      <div className="px-8 mx-auto py-4 overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
        <div className="flex items-center flex-wrap justify-between">
          <div className="flex items-center">
            <h3>Project Management</h3>
          </div>
          <div className="flex items-center">
            <Button
              type="Add to Dashboard"
              className="flex items-center font-medium"
            >
              <IoIosSettings fill="#494F59" size={20} className="mr-2" />{" "}
              Settings
            </Button>
            <Button
              type="Add to Dashboard"
              className="flex items-center font-medium"
            >
              <BiSolidHelpCircle fill="#494F59" size={20} className="mr-2" />{" "}
              Help
            </Button>
          </div>
        </div>
        <section className="cards-sec">
          <Row gutter={[16, 16]} className="mt-3">
            <CardText
              heading={"Completed projects with a deadline this month"}
              title={"Total"}
              description={"3"}
            />
            <CardProgress
              heading={"Projects over deadline"}
              progress={"300"}
              title={"Total"}
              description={"3"}
            />
            <CardText
              heading={"Tasks over deadline"}
              title={"Total"}
              description={"16"}
            />
            <CardText
              heading={"Tasks - needs attention"}
              title={"Total"}
              description={"1"}
            />
            <CardProgress
              heading={"My hours this week"}
              progress={"8"}
              title={"Duration"}
              description={"3:00"}
              subValue={"h"}
            />
            <CardProgress
              heading={"Team planned this week"}
              progress={"12"}
              title={"Planned"}
              description={"12:00"}
              subValue={"h"}
            />
          </Row>
          <Row gutter={[16, 16]} className="mt-3">
            <Col span={24} xl={16}>
              <div className="shadow bg-white rounded-md p-3 h-[380px] overflow-auto">
                <span class="mb-2 text-[12px] text-[#000] font-medium">
                  Task due this week
                </span>
                <Table columns={columns} dataSource={data} pagination={false} />
              </div>
            </Col>
            <Col span={24} lg={12} xl={8}>
              <div className="shadow bg-white rounded-md p-3 h-[380px] overflow-auto">
                <span class="mb-2 text-[12px] text-[#000] font-medium">
                  Task by source app
                </span>

                <div id="chart">
                  <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="rangeBar"
                    height={320}
                  />
                </div>
              </div>
            </Col>
            <Col span={24} lg={12} xl={8}>
              <div className="shadow bg-white rounded-md p-3 h-[380px] overflow-auto">
                <span class="mb-2 text-[12px] text-[#000] font-medium">
                  Projects based on completed Task
                </span>
                <ReactApexChart
                  options={chartData2.options}
                  series={chartData2.series}
                  type="donut"
                  width={380}
                />
              </div>
            </Col>
            <Col span={24} xl={16}>
              <div className="shadow bg-white rounded-md p-3 h-[380px] overflow-auto">
                <span class="mb-2 text-[12px] text-[#000] font-medium">
                  Projects in Progress
                </span>
                <Table
                  columns={columns2}
                  dataSource={data2}
                  pagination={false}
                />
              </div>
            </Col>
          </Row>
        </section>
      </div>
    </Container>
  );
};

export default ProjectManagement;
