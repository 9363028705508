import {
  faq_icon,
  message_icon,
  notification_colored_icon,
  plus_outlined_icon,
  support_icon,
} from "assets/icons/icons";

const MobFooter = ({ onAdd }) => {
  return (
    <div
      className="sticky w-full bottom-0 bg-white h-[54px] px-3 flex items-center justify-between"
      style={{ boxShadow: "0px -1px 4px 0px rgba(0, 0, 0, 0.25)" }}
    >
      <div className="flex flex-col items-center">
        <img src={notification_colored_icon} alt="" width={20} height={20} />
        <p className="text-primary text-[10px] mt-1">Notification</p>
      </div>
      <div className="flex flex-col items-center">
        <img src={message_icon} alt="" width={20} height={20} />
        <p className="text-primary text-[10px] mt-1">Messages</p>
      </div>
      <img
        onClick={onAdd}
        src={plus_outlined_icon}
        alt=""
        width={45}
        height={45}
        className="-mt-14"
      />
      <div className="flex flex-col items-center">
        <img src={support_icon} alt="" width={20} height={20} />
        <p className="text-primary text-[10px] mt-1">Support</p>
      </div>
      <div className="flex flex-col items-center">
        <img src={faq_icon} alt="" width={20} height={20} />
        <p className="text-primary text-[10px] mt-1">FAQs</p>
      </div>
    </div>
  );
};

export default MobFooter;
