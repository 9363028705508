import React from "react";
import { Button } from "antd";
import { google_icon } from "assets/icons/icons";

const Security = () => {
  return (
    <>
      <div className="">
        <div className="p-4 rounded shadow-lg border ">
          <div className="fw-500 text-xl mb-2">Sign-in method</div>
          <hr />
          <div className="flex my-4 justify-between">
            <div>
              <div className="font-bold text-md fw-500">Email Address</div>
              <div className="font-bold text-gray-500 text-sm mb-2">
                Support33@gmail.com
              </div>
            </div>
            <div>
              <Button
                className="bg-gray-100 mr-4 !text-sm px-2 text-center p-0 font-medium"
                style={{ borderRadius: 5, height: 27, width: 120 }}
              >
                Change Email
              </Button>
            </div>
          </div>
          <hr />
          <div className="flex my-4 justify-between">
            <div>
              <div className="fw-500 text-md ">Password</div>
              <div className="font-bold text-gray-500 text-sm mb-2">
                ***********
              </div>
            </div>
            <div>
              <Button
                className="bg-gray-100 mr-4 !text-sm px-2 text-center p-0 font-medium"
                style={{ borderRadius: 5, height: 27, width: 120 }}
              >
                Reset Password
              </Button>
            </div>
          </div>
          <div className="secure-accoutn-card p-4 flex justify-between items-center">
            <div style={{ width: "650px" }}>
              <div className="font-bold text-md ">Secure Your Account</div>
              <p className="text-gray-700 text-sm mb-2">
                Two factor authentication adds an extra layer of security to
                your account. To log in, in addition you'll need to provide a 6
                digits code
              </p>
            </div>
            <div>
              <Button
                type="primary"
                className="bg-bgBlue mr-4 p-0 !text-sm  font-medium"
                style={{ borderRadius: 5, height: 27, width: 85 }}
              >
                Enabled
              </Button>
            </div>
          </div>
        </div>
        <div className="p-4 rounded shadow-lg mt-4 border mb-4">
          <div className="fw-500 text-xl mb-2">Connected Accounts</div>
          <hr />
          <div className="secure-accoutn-card p-4 flex justify-between items-center my-4">
            <div>
              <p className="text-gray-700 text-sm mb-2">
                Two factor authentication adds an extra layer of security to
                your account. To log in, in addition you'll need to provide a 6
                digits code
              </p>
            </div>
          </div>
          <div className="mt-8">
            <div className="flex items-center justify-between my-3">
              <div className="flex items-center">
                <img src={google_icon} width={35} />
                <div className="ms-4">
                  <div className="font-bold text-md">Google</div>
                  <p className="text-gray-700 text-sm mb-2">
                    Plan property your workflow
                  </p>
                </div>
              </div>
              <label
                for="default-toggle"
                className="inline-flex relative items-center cursor-pointer"
              >
                <input
                  type="checkbox"
                  value=""
                  id="default-toggle"
                  className="sr-only peer"
                />
                <div className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 bg-bgBlue rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
            <hr />
            <div className="flex items-center justify-between my-3">
              <div className="flex items-center">
                <img src={google_icon} width={35} />
                <div className="ms-4">
                  <div className="font-bold text-md">Google</div>
                  <p className="text-gray-700 text-sm mb-2">
                    Plan property your workflow
                  </p>
                </div>
              </div>
              <label
                for="default-toggle2"
                className="inline-flex relative items-center cursor-pointer"
              >
                <input
                  type="checkbox"
                  value=""
                  id="default-toggle2"
                  className="sr-only peer"
                  checked
                />
                <div className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 bg-bgBlue rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Security;
