import { useState } from "react";
import { MobContainer } from "components/components";
import {
  BoardView,
  ListView,
} from "../../projectsDesktop/projectDetails/projectDetails";
import { useNavigate } from "react-router-dom";
import { ProjectDetails } from "constant/constant";

const ProjectDetailsAdmin = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const [projectDetails, setProjectDetails] = useState(ProjectDetails);
  const tabs = ["Board", "List", "Gantt"];
  const handleProjectCard = () => {
    navigate("/app/projects/card-details");
  };
  return (
    <MobContainer
      className={"bg-[#F5F5F5]"}
      tab={tab}
      tabs={tabs}
      setTab={(e) => setTab(e)}
      onAdd={() => navigate("/app/projects/add")}
    >
      {tab === 0 && (
        <BoardView
          onMob
          data={projectDetails}
          setData={(e) => setProjectDetails(e)}
          onClick={handleProjectCard}
        />
      )}
      {tab === 1 && (
        <ListView
          onMob
          data={projectDetails}
          setData={(e) => setProjectDetails(e)}
          onClick={handleProjectCard}
        />
      )}
    </MobContainer>
  );
};

export default ProjectDetailsAdmin;
