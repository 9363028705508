import React from "react";
import { domy_profile } from "assets/images/images";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faUserPlus } from "@fortawesome/free-solid-svg-icons";

const ProfileCard = () => {
  return (
    <div
      className="rounded border shadow-lg w-full p-4"
      style={{ height: "100%" }}
    >
      <div className="relative rounded-lg w-1/2 mx-auto">
        <img
          className="rounded-lg"
          width={"100%"}
          src={domy_profile}
          alt="Sunset in the mountains"
        />
        <div className="absolute top-36" style={{ right: "-10px" }}>
          <div className="w-6 border border-white h-6 bg-green-500 rounded-full"></div>
        </div>
      </div>
      <div className="py-4 text-center">
        <div className="font-bold text-xl mb-2">Max Smith</div>
        <p className="text-gray-700 text-base">Web Developer</p>
        <div className="flex justify-around mt-4">
          <Button
            type="primary"
            className="bg-bgBlue mr-4 !text-sm px-2 p-0 text-center font-medium"
            style={{ borderRadius: 5, height: 27, width: 120 }}
          >
            <FontAwesomeIcon icon={faUserPlus} className="w-4 h-4 mr-2" />
            Follow
          </Button>
          <Button
            className="bg-gray-100 mr-4 !text-sm px-2 text-center p-0 font-medium"
            style={{ borderRadius: 5, height: 27, width: 120 }}
          >
            <FontAwesomeIcon icon={faPhone} className="w-4 h-4 mr-2" />
            Contact Us
          </Button>
        </div>
      </div>
      <hr className="my-5" />
      <div className="pb-2">
        <div className="font-semibold text-xl mb-7">Information</div>
        <div>
          <div className="flex justify-between my-3">
            <h1 className="fw-500 fs-14">Full Name</h1>
            <h1 className="bg-gray-200 rounded fw-500 fs-14 p-1 px-2">
              Syed Basit
            </h1>
          </div>
          <div className="flex justify-between my-3">
            <h1 className="fw-500 fs-14">Company</h1>
            <h1 className="bg-gray-200 fw-500 fs-14 rounded p-1 px-2">
              Wepronex
            </h1>
          </div>
          <div className="flex justify-between my-3">
            <h1 className="fw-500 fs-14">Contact Phone</h1>
            <h1 className="bg-gray-200 rounded fw-500 fs-14 p-1 px-2">
              044 278 2256
            </h1>
          </div>
          <div className="flex justify-between my-3">
            <h1 className="fw-500 fs-14">Designation</h1>
            <h1 className="text-gray-500 fs-14">Web Developer</h1>
          </div>
          <div className="flex justify-between my-3">
            <h1 className="fw-500 fs-14">Birth of Date</h1>
            <h1 className="text-gray-500 fs-14">17, Jan 2003</h1>
          </div>
          <div className="flex justify-between my-3">
            <h1 className="fw-500 fs-14">Website</h1>
            <h1 className="text-gray-500 fs-14">www.wepronex.com</h1>
          </div>
          <div className="flex justify-between my-3">
            <h1 className="fw-500 fs-14">Email</h1>
            <h1 className="text-gray-500 fs-14">abc@gmail.com</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
