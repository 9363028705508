import React from "react";
import { Modal } from "antd";
import { ThemeInput } from "components/components";

const DeclineModal = ({ setIsModalOpen, isModalOpen }) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      title="Write a reason of decline"
      open={isModalOpen}
      okText={"Save"}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <ThemeInput placeholder={"Reason"} />
    </Modal>
  );
};

export default DeclineModal;
