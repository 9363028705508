import { useState } from "react";
import { Button } from "antd";
import {
  cancel_icon,
  cross_icon,
  filter_icon,
  home_icon,
  plus_icon,
  save_icon,
} from "assets/icons/icons";
import { Container, ThemeTable } from "components/components";
import { PackagesData } from "constant/constant";
import { addnewColor, cancelColor, saveColor } from "constant/themeColors";
import Details from "./details/details";

const Packages = () => {
  const [data, setData] = useState(PackagesData);
  const [densityOpt, setDensityOpt] = useState(false);
  const [density, setDensity] = useState("middle");
  const [addModal, setAddModal] = useState("addModalClose");
  const [detailsModal, setDetailsModal] = useState("detailsModalClose");
  const handleAddCompany = () => {
    if (detailsModal === "detailsModalClose") {
      setAddModal("addModalOpen");
    } else {
      setDetailsModal("detailsModalClose");
      setAddModal("addModalOpen");
    }
  };
  const handleCompanyDetails = () => {
    if (addModal === "addModalClose") {
      setDetailsModal("detailsModalOpen");
    } else {
      setAddModal("addModalClose");
      setDetailsModal("detailsModalOpen");
    }
  };
  const columns = [
    {
      title: <p className="text-center">#</p>,
      dataIndex: "id",
      render: (text) => <p className="text-center">{text}</p>,
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Trial days",
      dataIndex: "trial_days",
    },
    {
      title: "Total sale",
      dataIndex: "total_sale",
    },
    {
      title: "Monthly price",
      dataIndex: "monthly_price",
    },
    {
      title: "Tax",
      dataIndex: "tax",
    },
    {
      title: "Recommended",
      dataIndex: "recommended",
      width: 150,
      render: (text) => (
        <div
          className={`${
            text === "Yes"
              ? "bg-themeGreen"
              : text === "No" && "bg-themeGray-dark"
          } text-white w-[150px] h-7 text-sm flex justify-center items-center rounded-[5px]`}
        >
          <p>{text === "Yes" ? "Recommended" : "Not Recommended"}</p>
        </div>
      ),
    },
    {
      title: "Current Status",
      dataIndex: "status",
      width: 150,
      render: (text) => (
        <div
          className={`bg-bgBlue text-white w-[150px] h-7 text-sm flex justify-center items-center rounded-[5px]`}
        >
          <p>{text}</p>
        </div>
      ),
    },
  ];
  const handleDensity = () => {
    setDensityOpt(!densityOpt);
  };
  return (
    <Container
      superAdmin
      active={"Packages"}
      onClick={() => densityOpt && setDensityOpt(false)}
    >
      <div className="flex">
        <div className="w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
          <div className="bg-white" style={{ top: 54 }}>
            <div className="flex items-center justify-between px-5 py-3">
              <div className="flex items-center">
                <p className="font-medium text-sm">Package</p>
                <img src={home_icon} alt="" className="mr-3 ml-8" />
                <p className="font-medium text-sm">
                  Home
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    Package
                  </a>
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    All packages
                  </a>
                </p>
              </div>
              <div className="flex items-center">
                <div>
                  <img
                    src={filter_icon}
                    onClick={handleDensity}
                    alt=""
                    className="mr-4 cursor-pointer"
                  />
                  {densityOpt && (
                    <div className="relative">
                      <div className="text-sm absolute my-2 bg-white px-3 py-1 z-20 top-0 leading-7 rounded-md shadow-lg">
                        <p
                          className="cursor-pointer"
                          onClick={() => {
                            setDensityOpt(false);
                            setDensity("small");
                          }}
                        >
                          Compact
                        </p>
                        <p
                          className="cursor-pointer"
                          onClick={() => {
                            setDensityOpt(false);
                            setDensity("middle");
                          }}
                        >
                          Standard
                        </p>
                        <p
                          className="cursor-pointer"
                          onClick={() => {
                            setDensityOpt(false);
                            setDensity("large");
                          }}
                        >
                          Comfortable
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <Button
                  onClick={handleAddCompany}
                  className="!text-sm p-0 flex items-center justify-center font-medium hover:!text-white text-white"
                  style={{
                    borderRadius: 5,
                    height: 27,
                    width: 85,
                    backgroundColor: addnewColor,
                  }}
                >
                  Add
                  <img src={plus_icon} alt="" className="ml-2" />
                </Button>
              </div>
            </div>
            <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
          </div>
          <div className="-mt-3">
            <ThemeTable
              onClick={handleCompanyDetails}
              noPagination
              noSelectRows
              columns={columns}
              data={data}
              setData={(e) => setData(e)}
              density={density}
            />
          </div>
        </div>

        <div
          className={`bg-white ${addModal} border-l-2 border-[rgba(0,0,0,.1)] overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
        >
          {addModal === "addModalOpen" && (
            <>
              <div className="flex z-10 h-11 items-center justify-between w-full px-3 shadow-md border-b-2 border-[rgba(0,0,0,.2)] bg-white sticky top-0">
                <p className="font-semibold">Create Package</p>
                <div className="flex items-center">
                  <img
                    src={cross_icon}
                    alt=""
                    className="ml-4 cursor-pointer opacity-70"
                    width={16}
                    onClick={() => setDetailsModal("detailsModalClose")}
                  />
                </div>
              </div>
              <Details />
            </>
          )}
        </div>

        <div
          className={`bg-white ${detailsModal} overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
        >
          {detailsModal === "detailsModalOpen" && (
            <>
              <div className="flex z-10 h-11 items-center justify-between w-full px-3 shadow-md border-b-2 border-[rgba(0,0,0,.2)] bg-white sticky top-0">
                <p className="font-semibold">Package Details</p>
                <div className="flex items-center">
                  <img
                    src={cross_icon}
                    alt=""
                    className="ml-4 cursor-pointer opacity-70"
                    width={16}
                    onClick={() => setDetailsModal("detailsModalClose")}
                  />
                </div>
              </div>
              <Details />
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Packages;
