import { Tabs } from "antd";
import Paypal from "./paypal/paypal";
import Strip from "./strip/strip";
import Razorpay from "./razorpay/razorpay";
import Paystack from "./paystack/paystack";
import Mollie from "./mollie/mollie";
import Payfast from "./payfast/payfast";
import Authorize from "./authorize/authorize";
import Square from "./square/square";
import Flutterwave from "./flutterwave/flutterwave";
import OfflinePayment from "./offlinePayment/offlinePayment";

const Payment = () => {
  const items = [
    {
      key: "1",
      label: (
        <div className="flex items-center">
          <img
            height={15}
            width={13}
            src="https://demo-saas.worksuite.biz/img/paypal.png"
            alt=""
          />
          <p className="mx-2">Paypal</p>
          {/* <div className="h-3 w-3 bg-[#d30000] rounded-full"></div> */}
        </div>
      ),
      children: <Paypal />,
    },
    {
      key: "2",
      label: (
        <div className="flex items-center">
          <img
            height={15}
            width={15}
            src="https://demo-saas.worksuite.biz/img/stripe.png"
            alt=""
          />
          <p className="mx-2">Stripe</p>
          {/* <div className="h-3 w-3 bg-[#d30000] rounded-full"></div> */}
        </div>
      ),
      children: <Strip />,
    },
    {
      key: "3",
      label: (
        <div className="flex items-center">
          <img
            height={15}
            width={15}
            src="https://demo-saas.worksuite.biz/img/razorpay.png"
            alt=""
          />
          <p className="mx-2">Razorpay</p>
          {/* <div className="h-3 w-3 bg-[#d30000] rounded-full"></div> */}
        </div>
      ),
      children: <Razorpay />,
    },
    {
      key: "4",
      label: (
        <div className="flex items-center">
          <img
            height={15}
            width={15}
            src="https://demo-saas.worksuite.biz/img/paystack.jpg"
            alt=""
          />
          <p className="mx-2">Paystack</p>
          {/* <div className="h-3 w-3 bg-[#d30000] rounded-full"></div> */}
        </div>
      ),
      children: <Paystack />,
    },
    {
      key: "5",
      label: (
        <div className="flex items-center">
          <img
            height={20}
            width={20}
            src="https://demo-saas.worksuite.biz/img/mollie.png"
            alt=""
          />
          <p className="mx-2">Mollie</p>
          {/* <div className="h-3 w-3 bg-[#d30000] rounded-full"></div> */}
        </div>
      ),
      children: <Mollie />,
    },
    {
      key: "6",
      label: (
        <div className="flex items-center">
          <img
            height={15}
            width={16}
            src="https://demo-saas.worksuite.biz/img/payfast.png"
            alt=""
          />
          <p className="mx-2">Payfast</p>
          {/* <div className="h-3 w-3 bg-[#d30000] rounded-full"></div> */}
        </div>
      ),
      children: <Payfast />,
    },
    {
      key: "7",
      label: (
        <div className="flex items-center">
          <img
            height={15}
            width={18}
            src="https://demo-saas.worksuite.biz/img/authorize.png"
            alt=""
          />
          <p className="mx-2">Authorize</p>
          {/* <div className="h-3 w-3 bg-[#d30000] rounded-full"></div> */}
        </div>
      ),
      children: <Authorize />,
    },
    {
      key: "8",
      label: (
        <div className="flex items-center">
          <img
            height={15}
            width={15}
            src="https://demo-saas.worksuite.biz/img/square.svg"
            alt=""
          />
          <p className="mx-2">Square</p>
          {/* <div className="h-3 w-3 bg-[#d30000] rounded-full"></div> */}
        </div>
      ),
      children: <Square />,
    },
    {
      key: "9",
      label: (
        <div className="flex items-center w-32">
          <img
            height={15}
            width={14}
            src="https://demo-saas.worksuite.biz/img/flutterwave.png"
            alt=""
          />
          <p className="mx-2">Flutterwave</p>
          {/* <div className="h-3 w-3 bg-[#d30000] rounded-full"></div> */}
        </div>
      ),
      children: <Flutterwave />,
    },
    {
      key: "10",
      label: `Offline Payment Method`,
      children: <OfflinePayment />,
    },
  ];
  return (
    <div className="ml-2">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default Payment;
