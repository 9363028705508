import React, { useState, useEffect } from "react";
import { Table } from "antd";
import ReactApexChart from "react-apexcharts";
import { Container, FilterOptions, ThemeTable } from "components/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faDownload,
  faEllipsisVertical,
  faEnvelope,
  faPrint,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { home_icon } from "assets/icons/icons";
import { useParams } from "react-router-dom";
import { Chart } from "react-google-charts";

export const mapoptions = {
  Popularity: "400", // Africa
  colorAxis: { colors: ["#00853f", "black", "#e31b23"] },
  datalessRegionColor: "#f8bbd0",
  defaultColor: "#f5f5f5",
};

const Report = () => {
  const { type } = useParams();
  const [inddata, setIndData] = useState([]);
  useEffect(() => {
    let update = [...inddata];
    for (let i = 0; i < 5; i++) {
      update.push({
        sum: i + 5,
        country: "Pakistan",
        tax: "179.30,00",
        city: "Karachi",
      });
      setIndData(update);
    }
  }, []);
  const indcolumns = [
    {
      title: <p className="ml-5">Sum</p>,
      dataIndex: "sum",
      render: (text) => <p className="ml-5">{text}</p>,
    },
    {
      title: "Country",
      dataIndex: "country",
    },
    {
      title: "Sum without tax",
      dataIndex: "tax",
    },
    {
      title: "City",
      dataIndex: "city",
    },
  ];
  const chartData = {
    series: [
      {
        name: "",
        data: [200, 100, 50, 25],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "40%",
          endingShape: "rounded",
        },
      },
      colors: ["#e0e4fd"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      legend: {
        position: "right",
        offsetX: -30,
        offsetY: 0,
        markers: {
          radius: 0,
        },
      },
      toolbar: {
        show: true, // Set this to false to remove the toolbar
      },
      xaxis: {
        categories: [
          "John McDunn",
          "Jane Numbers",
          "Kevin Harris",
          "sandjai pr",
        ],
      },

      fill: {
        opacity: 1,
      },
    },
  };

  const columns = [
    {
      title: "User",
      dataIndex: "User",
      key: "User",

      render: (text) => <div className="font-bold">{text}</div>,
    },

    {
      title: "Planned",
      dataIndex: "Planned",
      key: "Planned",
    },
    {
      title: "Duration",
      dataIndex: "Duration",
      key: "Duration",
    },
    {
      title: "Labor cost EUR	",
      dataIndex: "laborCostEUR",
      key: "laborCostEUR",
    },
    {
      title: "Selling Price",
      dataIndex: "sellingPrice",
      key: "sellingPrice",
    },
  ];
  const data = [
    {
      User: "client1",
      Planned: "197:08 h",
      Duration: "177:00 h",
      laborCostEUR: "50.730,00",
      sellingPrice: "6.360,00",
    },
    {
      User: "client1",
      Planned: "197:08 h",
      Duration: "177:00 h",
      laborCostEUR: "50.730,00",
      sellingPrice: "6.360,00",
    },
    {
      User: "client1",
      Planned: "197:08 h",
      Duration: "177:00 h",
      laborCostEUR: "50.730,00",
      sellingPrice: "6.360,00",
    },
  ];

  const getTotal = (data, dataIndex) => {
    return data.reduce((total, item) => {
      const value = parseFloat(
        item[dataIndex].replace(",", "").replace(".", "").replace("€", "")
      );
      return total + value;
    }, 0);
  };
  const totalRow = {
    User: "Total",
    Planned: getTotal(data, "Planned").toLocaleString(),
    Duration: getTotal(data, "Duration").toLocaleString(),
    laborCostEUR: getTotal(data, "laborCostEUR").toLocaleString(),
    sellingPrice: getTotal(data, "sellingPrice").toLocaleString(),
  };
  const mapdata = [
    ["Country", "Popularity"],
    ["Germany", 200],
    ["United States", 300],
    ["Brazil", 400],
    ["Canada", 500],
    ["France", 600],
    ["RU", 700],
  ];

  return (
    <Container superAdmin={type === "backend" && true} active={"Reports"}>
      <div className="w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
        <div className="bg-white" style={{ top: 54 }}>
          <div className="flex items-center justify-between px-5 py-3">
            <div className="flex items-center">
              <p className="font-medium text-sm">Company</p>
              <img src={home_icon} alt="" className="mr-3 ml-8" />
              <p className="font-medium text-sm">
                Home
                <span className="text-bgBlue"> / </span>
                <a href="#" className="text-bgBlue">
                  Report
                </a>
                <span className="text-bgBlue"> / </span>
                <a href="#" className="text-bgBlue">
                  Default
                </a>
              </p>
            </div>
            <div className="text-end pe-5">
              <div className="flex items-center">
                <div className="me-4">
                  <FontAwesomeIcon icon={faArrowsRotate} />
                </div>
                <p className="p-0 m-0 text-gray-700">
                  Data refreshed at jul 18, 2023 04:06 PM
                </p>
                <div className="flex ">
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faEnvelope} className="fs-1" />
                  </div>
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faDownload} />
                  </div>
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faPrint} />
                  </div>
                </div>
                <FontAwesomeIcon
                  icon={faEllipsisVertical}
                  className="fs-1 mx-4"
                />
                <FontAwesomeIcon icon={faXmark} className="fs-1" />
              </div>
            </div>
          </div>
          <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
        </div>
        <FilterOptions onTop />
        {type === "backend" ? (
          <div className="mt-7 mb-5">
            <div className="font-bold text-xl mb-2 pl-4">Location report</div>
            <div className="px-4 my-4">
              <section className="summery-sec border shadow-lg rounded-md">
                <div className="mb-10">
                  <Chart
                    chartEvents={[
                      {
                        eventName: "select",
                        callback: ({ chartWrapper }) => {
                          const chart = chartWrapper.getChart();
                          const selection = chart.getSelection();
                          if (selection.length === 0) return;
                          const region = data[selection[0].row + 1];
                          console.log("Selected : " + region);
                        },
                      },
                    ]}
                    chartType="GeoChart"
                    width="100%"
                    height="400px"
                    data={mapdata}
                    options={mapoptions}
                  />
                </div>
                <ThemeTable
                  data={inddata}
                  noSelectRows
                  noPagination
                  columns={indcolumns}
                />
              </section>
            </div>
          </div>
        ) : (
          <div className="mt-7 mb-5">
            <div className="px-4">
              <div className="font-bold text-xl mb-2">Detailed report</div>
              <section className="summery-sec border shadow-lg rounded-md">
                <div id="chart" className="mb-5">
                  <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="bar"
                    height={400}
                  />
                </div>
                <Table
                  columns={columns}
                  dataSource={[...data, totalRow]}
                  pagination={false}
                />
              </section>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default Report;
