import React from "react";
import { ThemeTable } from "components/components";
import { Button, Popconfirm, Input } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";

const Industry = ({ data, setData }) => {
  const handleDelete = (key) => {
    const newData = data.filter((item) => item.key !== key);
    setData(newData);
  };
  const columns = [
    {
      title: "#",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "tax_name",
      render: (text, record, ind) => (
        <Input
          value={text}
          onChange={(e) => {
            let update = [...data];
            update.splice(ind, 1, {
              key: record.key,
              id: record.id,
              tax_name: e.target.value,
              rate: record.rate,
            });
            setData(update);
          }}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) =>
        data.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <DeleteTwoTone className="text-lg" />
          </Popconfirm>
        ) : null,
    },
  ];
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        <ThemeTable noSelectRows columns={columns} data={data} noPagination />
        <Button
          onClick={() => {
            let update = [...data];
            update.push({
              key: data.length + 1,
              id: data.length + 1,
              tax_name: "Write category name",
            });
            setData(update);
          }}
          type="primary"
          className="h-9 w-full mt-3"
        >
          Add new
        </Button>
      </div>
    </div>
  );
};

export default Industry;
