import { useState, useRef } from "react";
import { Input, Button, Modal, Upload, Progress } from "antd";
import { Checkbox } from "@mui/material";
import { PlusOutlined } from "@ant-design/icons";
import { menu_icon, cross_icon } from "assets/icons/icons";
import { ThemeSelect } from "components/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const AddTask = () => {
  const [showDesc, setShowDesc] = useState(false);
  const [text, setText] = useState("");
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [checklist, setChecklist] = useState([]);
  const checked = checklist.filter((e) => {
    if (e.isChoosen) return e;
  });
  const handleChecklist = (e, i) => {
    let update = [...checklist];
    update[i].isChoosen = !e.isChoosen;
    setChecklist(update);
  };
  const dragStart = (e, position) => {
    dragItem.current = position;
  };
  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };
  const drop = () => {
    const copyListItems = [...checklist];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setChecklist(copyListItems);
  };
  const addCard = () => {
    setText("");
    if (text) {
      let update = [...checklist];
      update.push({
        title: text,
        isChoosen: false,
      });
      setChecklist(update);
    }
  };
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload File
      </div>
    </div>
  );
  const checklistProgress = (checked.length / checklist.length) * 100;
  const removeDecimal = checklistProgress.toFixed(1);
  return (
    <div className="z-0">
      <div className="shadow-md mt-2 px-3 pb-5 mx-2 flex flex-col justify-center">
        <div className="flex items-center justify-between h-14 mb-4 border-b-[1px] border-[rgba(0,0,0,.1)]">
          <p className="font-medium mt-2">Overview</p>
          <div className="flex items-center">
            <div className="w-32 status">
              <ThemeSelect label={"Status"} data={["In Progress"]} />
            </div>
            <div className="w-32 mx-3 priority">
              <ThemeSelect label={"Priority"} data={["High"]} />
            </div>
            <Button type="primary mt-2">Mark as done</Button>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <p
            className="text-themeGray-dark text-sm font-medium hover:bg-themeGray-light200"
            contentEditable
          >
            Add task description
          </p>
          <div className="flex flex-col items-center ml-6">
            <p className="text-xl">00:00</p>
            <div className="flex mt-2">
              <Button className="!bg-themeGray-light200">Schedule</Button>
              <Button className="!bg-themeGray-light200">Track time</Button>
            </div>
          </div>
        </div>
        <div className="border-b-[1px] border-[rgba(0,0,0,.2)]  pt-2 pb-4">
          <div className="flex items-center justify-between my-1">
            <p className="font-medium text-sm">Assignees</p>
            <div className="w-32">
              <ThemeSelect
                label={
                  <p className="font-medium text-sm">
                    <FontAwesomeIcon icon={faUser} /> Unassigned
                  </p>
                }
                data={["Member 1", "Member 2", "Member 3"]}
              />
            </div>
          </div>
          <div className="flex items-center justify-between my-1">
            <p className="font-medium text-sm">Work type</p>
            <div className="w-32">
              <ThemeSelect
                label={
                  <p className="font-medium text-sm text-themeGray-medium300">
                    + Work type
                  </p>
                }
                data={["Type 1", "Type 2", "Type 3"]}
              />
            </div>
          </div>
        </div>
        <div className="border-b-[1px] border-[rgba(0,0,0,.2)] pt-2 pb-4">
          <p className="font-medium mt-2">Planning</p>
          <div className="flex items-center justify-between my-1">
            <p className="font-medium text-sm">Start Date</p>
            <div className="w-32">
              <div className="dateDiv">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD-MM-YYYY"
                    label={
                      <p className="font-medium text-sm text-themeGray-medium300">
                        + Start date
                      </p>
                    }
                    className="!w-full"
                    slotProps={{ textField: { variant: "filled" } }}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between my-1">
            <p className="font-medium text-sm">End Date</p>
            <div className="w-32">
              <div className="dateDiv">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD-MM-YYYY"
                    label={
                      <p className="font-medium text-sm text-themeGray-medium300">
                        + End date
                      </p>
                    }
                    className="!w-full"
                    slotProps={{ textField: { variant: "filled" } }}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b-[1px] border-[rgba(0,0,0,.2)] pt-2 pb-4">
          <p className="font-medium mt-2">Budget</p>
          <div className="flex items-center justify-between my-1">
            <p className="font-medium text-sm">Billing Method</p>
            <div className="w-32">
              <ThemeSelect
                label={<p className="font-medium text-sm">+ Billing Method</p>}
                data={["Method 1", "Method 2", "Method 3"]}
              />
            </div>
          </div>
          <div className="flex items-center justify-between my-1">
            <p className="font-medium text-sm">Price</p>
            <div className="w-32">
              <ThemeSelect
                label={
                  <p className="font-medium text-sm text-themeGray-medium300">
                    + Price
                  </p>
                }
                data={["$100", "$200", "$300"]}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center mt-4">
          <p className="font-semibold">What we need</p>
          <p className="opacity-50">
            {checked.length}/{checklist.length} Completed
          </p>
        </div>
        {checklist.length > 0 && (
          <Progress percent={removeDecimal} className="mt-2" />
        )}
        {checklist.map((e, i) => {
          return (
            <div
              draggable
              key={i}
              className="bg-themeGray-light200 text-sm pr-4 pl-1 justify-between flex items-center rounded-xl my-3"
              onDragStart={(e) => dragStart(e, i)}
              onDragEnter={(e) => dragEnter(e, i)}
              onDragEnd={drop}
            >
              <div className="flex items-center">
                <Checkbox
                  onChange={() => handleChecklist(e, i)}
                  checked={e.isChoosen}
                />
                <p className={`${e.isChoosen && "line-through"}`}>{e.title}</p>
              </div>
              <img
                width={18}
                className="cursor-pointer"
                src={menu_icon}
                alt=""
              />
            </div>
          );
        })}
        {showDesc ? (
          <div className="mt-3">
            <Input.TextArea
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Enter task"
            />
            <div className="flex items-center mt-2">
              <Button type="primary" onClick={addCard}>
                Add Item
              </Button>
              <img
                src={cross_icon}
                onClick={() => setShowDesc(false)}
                alt=""
                className="ml-3 cursor-pointer opacity-50"
                width={16}
              />
            </div>
          </div>
        ) : (
          <p
            onClick={() => setShowDesc(true)}
            className="text-themeGray-dark font-medium py-1 ml-5 cursor-pointer"
          >
            Add an item...
          </p>
        )}
        <Upload
          className="mt-3"
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img
            alt="example"
            style={{
              width: "100%",
            }}
            src={previewImage}
          />
        </Modal>
      </div>
    </div>
  );
};

export default AddTask;
