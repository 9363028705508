import { useState } from "react";
import { Button, Modal, Checkbox } from "antd";
import {
  ThemeTable,
  ThemeInput,
  ThemeSelect,
} from "components/components";
import { BellOutlined } from "@ant-design/icons";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const data = [
  {
    key: 1,
    description: `Two friends reunited at a café, sharing laughter and heartfelt conversations that deepened their bond. Time seemed to freeze as they exchanged stories, support, and advice.`,
    date_added: "26/Mar/2023",
  },
  {
    key: 2,
    description: `Two friends reunited at a café, sharing laughter and heartfelt conversations that deepened their bond. Time seemed to freeze as they exchanged stories, support, and advice.`,
    date_added: "26/Mar/2023",
  },
];

const columns = [
  {
    title: "Description",
    dataIndex: "description",
    width: "50%",
  },
  {
    title: "Date",
    dataIndex: "date_added",
    width: "25%",
    filters: [
      {
        text: "26/Mar/2023",
        value: "26/Mar/2023",
      },
    ],
    filterMode: "tree",
    filterSearch: true,
    onFilter: (value, record) => record.date_added.includes(value),
  },
  {
    title: "",
    width: "25%",
    dataIndex: "remind",
    render: () => (
      <div className="flex">
        <p className="text-primary cursor-pointer">Dante</p>
        <p className="text-primary ml-3 cursor-pointer">Runolfsson</p>
      </div>
    ),
  },
];

const Reminder = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="m-4">
      <Modal
        title="Set Reminder"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="dateDiv">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD-MM-YYYY"
              label="Date to be notified*"
              className="!w-full"
              slotProps={{ textField: { variant: "filled" } }}
            />
          </LocalizationProvider>
        </div>
        <ThemeSelect
          className={"!mt-3 !mb-1"}
          label={"Set reminder to*"}
          data={["10:00", "11:00", "12:00"]}
        />
        <ThemeInput placeholder={"Description"} multiline rows={4} />
        <Checkbox>Save also an email for this reminder</Checkbox>
      </Modal>
      <Button
        onClick={() => setIsModalOpen(true)}
        type="primary"
        className="bg-primary flex items-center mb-3 !text-white hover:!text-white"
      >
        <BellOutlined />
        Set Reminder
      </Button>
      <ThemeTable
        noSelectRows
        columns={columns}
        data={data}
        density={"middle"}
      />
    </div>
  );
};

export default Reminder;
