import { useState } from "react";
import Search from "antd/es/input/Search";
import { ThemeTable } from "components/components";
import { Input, Space } from "antd";

const Translate = () => {
  const [data, setData] = useState([
    {
      key: "1",
      id: "1",
      eng: "Congratulations",
      value: "تهانينا",
    },
    {
      key: "2",
      id: "2",
      eng: "Go to Home",
      value: "اذهب إلى الصفحة الرئيسية",
    },
    {
      key: "3",
      id: "3",
      eng: "I want to Hire",
      value: "أريد أن تأجير",
    },
    {
      key: "4",
      id: "4",
      eng: "I want to Work",
      value: "اريد ان اعمل",
    },
  ]);
  const columns = [
    {
      title: "#",
      dataIndex: "id",
    },
    {
      title: "Key",
      dataIndex: "eng",
    },
    {
      title: "Value",
      dataIndex: "value",
      render: (text, record, ind) => (
        <Input
          value={text}
          onChange={(e) => {
            let update = [...data];
            update.splice(ind, 1, {
              key: record.key,
              id: record.id,
              eng: record.eng,
              value: e.target.value,
            });
            setData(update);
          }}
        />
      ),
    },
  ];
  return (
    <div className="bg-white shadow-md p-3">
      <div className="flex justify-between items-center">
        <p>Arabic</p>
        <Space>
          <Search placeholder="Type key and enter" />
        </Space>
      </div>
      <ThemeTable noSelectRows columns={columns} data={data} noPagination />
    </div>
  );
};

export default Translate;
