import { Switch } from "@mui/material";
import { ThemeInput } from "components/components";
import React from "react";

const Cookie = () => {
  return (
    <div className="bg-themeGray-light200 p-3 mt-4 min-h-[73.8vh] desktop:h-[79vh] bigDesktop:h-[87vh]">
      <div className="bg-white p-3 shadow-md">
        <div className="flex items-center justify-between mb-2">
          <p className="text-sm">Cookie Settings</p>
          <div className="flex items-center">
            <p className="text-sm">Enable cookie</p>
            <Switch />
          </div>
        </div>
        <hr />
        <div className="flex items-center justify-between mt-2">
          <div className="flex items-center">
            <Switch />
            <p className="text-sm">Enable Logging</p>
          </div>
          <div className="flex items-center">
            <Switch />
            <p className="text-sm">Strictly necessary cookies</p>
          </div>
        </div>
        <ThemeInput placeholder={"Cookie Title"} />
        <ThemeInput placeholder={"Cookie Description"} multiline rows={4} />
        <ThemeInput placeholder={"Contact Us Description"} />
        <ThemeInput placeholder={"Strictly Cookie Title"} />
        <ThemeInput
          placeholder={"Strictly Cookie Description"}
          multiline
          rows={4}
        />
        <ThemeInput placeholder={"Contact Us URL"} />
      </div>
    </div>
  );
};

export default Cookie;
