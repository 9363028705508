import { home_icon } from "assets/icons/icons";

const Details = () => {
  return (
    <div>
      <div className="shadow-md mt-2 px-3 mx-2">
        <div className="flex items-center h-14 border-b-[1px] border-[rgba(0,0,0,.1)]">
          <img src={home_icon} alt="" />
          <p className="font-bold text-sm ml-1">Details</p>
        </div>
        <div className="flex pb-4">
          <div className="pt-3 ml-6 w-44">
            <p className="text-lightBlack text-sm">Job Title</p>
            <p className="text-lightBlack text-sm mt-3">Firstname</p>
            <p className="text-lightBlack text-sm mt-3">Lastname</p>
            <p className="text-lightBlack text-sm mt-3">Address</p>
            <p className="text-lightBlack text-sm mt-3">Postal Code</p>
            <p className="text-lightBlack text-sm mt-3">City</p>
            <p className="text-lightBlack text-sm mt-3">Province / state</p>
            <p className="text-lightBlack text-sm mt-3">Country</p>
          </div>
          <div className="pt-3">
            <p className="text-lightBlack text-sm">Engineer</p>
            <p className="text-lightBlack text-sm mt-3">Bert</p>
            <p className="text-lightBlack text-sm mt-3">Van Duivendijk</p>
            <p className="text-lightBlack text-sm mt-3">ABC Address</p>
            <p className="text-lightBlack text-sm mt-3">012345</p>
            <p className="text-lightBlack text-sm mt-3">ABC City</p>
            <p className="text-lightBlack text-sm mt-3">ABC state</p>
            <p className="text-lightBlack text-sm mt-3">Netherlands</p>
          </div>
        </div>
      </div>
      <div className="shadow-md mt-2 mb-5 px-3 mx-2">
        <div className="flex items-center h-14 border-b-[1px] border-[rgba(0,0,0,.1)]">
          <img src={home_icon} alt="" />
          <p className="font-bold text-sm ml-1">Contact Info</p>
        </div>
        <div className="flex pb-4">
          <div className="pt-3 ml-6 w-44">
            <p className="text-lightBlack text-sm mt-3">Website</p>
            <p className="text-lightBlack text-sm mt-3">Phone</p>
            <p className="text-lightBlack text-sm mt-3">Email</p>
            <p className="text-lightBlack text-sm mt-3">Skype address</p>
            <p className="text-lightBlack text-sm mt-3">Linkedin address</p>
            <p className="text-lightBlack text-sm mt-3">Twitter address</p>
            <p className="text-lightBlack text-sm mt-3">Facebook address</p>
          </div>
          <div className="pt-3">
            <p className="text-lightBlack text-sm mt-3">www.example.com</p>
            <p className="text-lightBlack text-sm mt-3">1230456</p>
            <p className="text-lightBlack text-sm mt-3">example@gmail.com</p>
            <p className="text-lightBlack text-sm mt-3">
              www.skype.com/example
            </p>
            <p className="text-lightBlack text-sm mt-3">
              www.linkedin.com/example
            </p>
            <p className="text-lightBlack text-sm mt-3">
              www.twitter.com/example
            </p>
            <p className="text-lightBlack text-sm mt-3">
              www.facebook.com/example
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
