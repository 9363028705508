import { ThemeInput } from "components/components";

const Details = () => {
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[68.3vh] desktop:h-[74.3vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        <ThemeInput placeholder={"First Name"} />
        <ThemeInput placeholder={"Last Name"} />
        <ThemeInput placeholder={"Email"} />
        <ThemeInput placeholder={"Phone"} />
      </div>
    </div>
  );
};

export default Details;
