import * as React from "react";
import { useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { ThemeInput, ThemeSelect } from "components/components";
import { PlusOutlined } from "@ant-design/icons";
import Inter from "assets/font/Inter.ttf";
import { Upload, Modal } from "antd";
import Switch from "@mui/material/Switch";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const theme = createTheme({
  typography: {
    fontFamily: Inter,
  },
  palette: {
    primary: {
      main: "#00A1E4",
    },
  },
});

const TaskInfo = ({ className }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload Receipt
      </div>
    </div>
  );
  return (
    <ThemeProvider theme={theme}>
      <div className={`${className}`}>
        <ThemeSelect
          className={"!my-1"}
          label={"Client*"}
          data={["Client 1", "Client 2", "Client 3", "Client 4"]}
        />
        <ThemeSelect
          className={"!my-1"}
          label={"Invoice*"}
          data={["Invoice 1", "Invoice 2", "Invoice 3", "Invoice 4"]}
        />
        <div className="dateDiv">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD-MM-YYYY"
              label="Payment Date*"
              className="!w-full"
              slotProps={{ textField: { variant: "filled" } }}
            />
          </LocalizationProvider>
        </div>
        <ThemeSelect
          className={"!my-2"}
          label={"Payment Type*"}
          data={["Type 1", "Type 2", "Type 3", "Type 4"]}
        />
        <ThemeInput placeholder={"Amount Received"} />
        <ThemeInput placeholder={"Transaction Reference"} />
        <ThemeInput placeholder={"Private Notes"} multiline rows={4} />
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
          className="mt-2"
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img
            alt="example"
            style={{
              width: "100%",
            }}
            src={previewImage}
          />
        </Modal>
        <div className="flex items-center justify-between mt-3">
          <div>
            <p>Send Email</p>
            <p className="text-themeGray-dark text-sm">
              Email payment receipt to the client
            </p>
          </div>
          <Switch defaultChecked />
        </div>
        <div className="flex items-center justify-between mt-3">
          <p>Convert Currency</p>
          <Switch onChange={(e) => setShowOptions(e.target.checked)} />
        </div>
        {showOptions && (
          <>
            <ThemeInput placeholder={"Currency"} />
            <ThemeInput placeholder={"Exchange Rate"} />
            <ThemeInput placeholder={"Converted Amount"} />
          </>
        )}
      </div>
    </ThemeProvider>
  );
};

export { TaskInfo };
