import { Tabs } from "antd";
import { useState } from "react";
import { Button } from "antd";
import Details from "./details/details";
import Notifications from "./notifications/notifications";
import Permissions from "./permissions/permissions";

const Staff = () => {
  const [configure, setConfigure] = useState(false);
  const items = [
    {
      key: "1",
      label: `Details`,
      children: <Details />,
    },
    {
      key: "2",
      label: `Notifications`,
      children: <Notifications />,
    },
    {
      key: "3",
      label: `Permissions`,
      children: <Permissions />,
    },
  ];
  return (
    <div className="mt-3">
      {configure && (
        <div className="ml-2">
          <Tabs defaultActiveKey="1" items={items} />
        </div>
      )}
      {!configure && (
        <div className="bg-themeGray-light200 p-3 mt-4 min-h-[73.8vh] desktop:h-[79vh] bigDesktop:h-[87vh]">
          <div className="bg-white p-3 shadow-md">
            <div className="mx-5">
              <p className="text-sm">Ms. Arvilla Champlin Jodie Dibbert</p>
              <p className="text-xs opacity-80">small@example.com</p>
            </div>
          </div>
          <Button
            className="w-full mt-4 h-9"
            type="primary"
            onClick={() => setConfigure(true)}
          >
            Add new staff member
          </Button>
        </div>
      )}
    </div>
  );
};

export default Staff;
