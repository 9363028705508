import { useState } from "react";
import { Button } from "antd";
import { sorting_icon } from "assets/icons/icons";
import AcceptModal from "../acceptModal/acceptModal";
import DeclineModal from "../declineModal/declineModal";

const OverView = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  return (
    <div>
      <AcceptModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <DeclineModal
        isModalOpen={isDeclineModalOpen}
        setIsModalOpen={setIsDeclineModalOpen}
      />
      <div className="shadow-md mt-2 mx-2 flex flex-col justify-center px-3 h-[110px]">
        <div className="flex justify-center">
          <div className="w-2/4 bg-green-500 text-white text-center p-4 rounded-lg font-medium mr-2">
            <p>Total Amount</p>
            <p>$237.00</p>
          </div>
        </div>
      </div>
      <div className="shadow-md mt-2 px-3 h-[95px] flex items-center justify-between mx-2">
        <div>
          <p className="font-medium">Ortiz and Sons</p>
          <p className="text-themeGray-dark text-sm font-medium">
            allison.oberbrunner@example.org
          </p>
        </div>
        <img src={sorting_icon} alt="" />
      </div>
      <div className="my-5 flex justify-around mx-2">
        <div className="h-[90px] flex items-center justify-center w-2/4 shadow-md !border-[1px] !border-[rgba(0,0,0,.05)] font-medium">
          <p>
            Date <span className="text-themeGray-dark">15-05-2023</span>
          </p>
        </div>
        <div className="h-[90px] flex items-center justify-center w-2/4 shadow-md !border-[1px] !border-[rgba(0,0,0,.05)] font-medium">
          <p>
            Due Date <span className="text-themeGray-dark">15-05-2023</span>
          </p>
        </div>
      </div>

      <div className="grid grid-cols-8 pl-3 py-3">
        <p className="col-span-4">Description</p>
        <p className="col-span-1 text-center">Quantity</p>
        <p className="col-span-1 text-center">Unit</p>
        <p className="col-span-1 text-center">Price</p>
        <p className="col-span-1 text-center">Amount</p>
      </div>
      <div className="grid grid-cols-8 pl-3 border-y-2 border-[rgba(0,0,0,.2)] py-3">
        <div className="col-span-4">
          <p>Website creation</p>
          <p className="text-themeGray-dark text-sm">
            Corporate website creation and new logo design.
          </p>
        </div>
        <p className="col-span-1 text-center">1</p>
        <p className="col-span-1 text-center">each</p>
        <p className="col-span-1 text-center">750.00</p>
        <p className="col-span-1 text-center">750.00</p>
      </div>
      <div className="flex flex-col items-end mt-4 mr-5">
        <div className="flex items-center mb-2">
          <p>Discount 10%</p>
          <p className="ml-4">-75.00</p>
        </div>
        <div className="flex items-center mb-2">
          <p>Subtotal without Tax</p>
          <p className="ml-4">750.00</p>
        </div>
        <div className="flex font-semibold items-center mb-2">
          <p>Total USD</p>
          <p className="ml-4">675.00</p>
        </div>
      </div>
      <div className="flex flex-col mt-4 mr-5 pl-3">
        <p className="pb-1 font-semibold">Terms & Conditions</p>
        <p className="pb-1">10% discount for new customer.</p>
        <p>This quote expires in 1 month on 07/13/2021</p>
      </div>
      <div className="my-5 flex justify-around mx-2">
        <Button
          onClick={() => setIsModalOpen(true)}
          className="h-[90px] w-2/4 shadow-md !border-[1px] !border-[rgba(0,0,0,.05)] text-lg font-medium"
        >
          Accept
        </Button>
        <Button
          onClick={() => setIsDeclineModalOpen(true)}
          className="h-[90px] w-2/4 shadow-md !border-[1px] !border-[rgba(0,0,0,.05)] text-lg font-medium"
        >
          Decline
        </Button>
      </div>
    </div>
  );
};

export default OverView;
