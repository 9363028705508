import { ExpenseInfo } from "../../expensesDesktop/productInfo/expenseInfo";
const { MobContainer } = require("components/components");

const AddExpensesMob = () => {
  return (
    <MobContainer onCancel={"/products"} addCompany heading={"New Product"}>
      <div className="px-3">
        <ExpenseInfo />
      </div>
    </MobContainer>
  );
};

export default AddExpensesMob;
