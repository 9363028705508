import React from "react";
import ReactApexChart from "react-apexcharts";

const InvoicesExpenses = ({ legendTitle }) => {
  const chartData = {
    series: [
      {
        name: legendTitle === "show" && "2022 invoices",
        data: [10, 20, 30, 40, 50, 60, 30, 40, 60],
      },
      {
        name: legendTitle === "show" && "2021 invoices",
        data: [0, 0, 0, 0, 20, 45, 67, 23, 27],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      colors: ["#50b83c", "#dee5f1"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      legend: {
        position: "right",
        offsetX: -30,
        offsetY: 0,
        markers: {
          radius: 0,
        },
      },
      toolbar: {
        show: true, // Set this to false to remove the toolbar
      },
      xaxis: {
        categories: [
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
        ],
      },

      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
    },
  };
  return (
    <div className="shadow bg-white rounded-md p-3 h-[380px] overflow-auto">
      <span class="mb-2 text-[12px] text-[#000] font-medium">
        {legendTitle ? "" : "Invoices vs expenses"}
      </span>
      <div id="chart">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={317}
        />
      </div>
    </div>
  );
};

export default InvoicesExpenses;
