const ConfigurePayment = () => {
  return (
    <div className="my-3">
      {[0, 7, 10, 14, 15, 30, 60, 90].map((e, i) => {
        return (
          <div
            key={i}
            className="h-16 px-3 hover:bg-themeGray-light200 border-b-2 border-[rgba(0,0,0,.2)] flex items-center"
          >
            <p>Net {e}</p>
          </div>
        );
      })}
    </div>
  );
};

export default ConfigurePayment;
