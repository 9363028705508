import { useNavigate } from "react-router-dom";

const SubMenu = ({ data, icon, title }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex items-center px-2 mb-5">
        <div className="w-10 flex justify-center">
          <img src={icon} alt="" />
        </div>
        <p className="text-primary text-xl ml-3">{title}</p>
      </div>
      {data.map((e, i) => {
        return (
          <div>
            {e.title === "Activities" && (
              <div className={`flex items-center px-2 pt-2`}>
                <div className="w-10 flex justify-center"></div>
                <p className="font-semibold ml-3">Work</p>
              </div>
            )}
            {e.title === "Financial Data" && (
              <div className={`flex items-center px-2 mt-4`}>
                <div className="w-10 flex justify-center"></div>
                <p className="font-semibold ml-3">Finance</p>
              </div>
            )}
            {e.title === "Pipeline" && (
              <div className={`flex items-center px-2 mt-4`}>
                <div className="w-10 flex justify-center"></div>
                <p className="font-semibold ml-3">Sales</p>
              </div>
            )}
            <div
              key={i}
              className={`flex items-center py-3 hover:bg-themeGray-light cursor-pointer rounded-lg px-2`}
              onClick={() => e.navigate && navigate(e.navigate)}
            >
              <div className="w-10 flex justify-center">{e.icon}</div>
              <p className="text-sm ml-3">{e.title}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SubMenu;
