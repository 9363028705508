import { useState } from "react";
import Header from "../header/header";
import Sidebar from "../sidebar/sidebar";
import Footer from "../footer/footer";
import ClientSidebar from "components/clientSidebar/clientSidebar";
import SuperAdminSidebar from "components/superAdminSidebar/superAdminSidebar";
import SuperAdminHeader from "components/superAdminHeader/superAdminHeader";

const Container = ({ children, onClick, client, active, superAdmin }) => {
  const [open, setOpen] = useState(true);
  const [zindex, setZindex] = useState("");
  const [activeKey, setActiveKey] = useState("");
  const onClose = () => {
    setOpen(false);
    setZindex("-z-10");
  };
  const onOpen = () => {
    setOpen(true);
    setZindex("");
  };
  return (
    <div className="flex" onClick={onClick}>
      {client ? (
        <ClientSidebar active={active} />
      ) : superAdmin ? (
        <SuperAdminSidebar active={active} />
      ) : (
        <div
          style={{ boxShadow: "2px 4px 4px 0px rgba(0,0,0,.2)" }}
          className={`relative ${zindex} flex border-r-[1px] z-20 border-[(rgba(0,0,0,.2))]`}
        >
          <Sidebar
            open={open}
            activeKey={activeKey}
            setActiveKey={(e) => setActiveKey(e)}
          />
        </div>
      )}
      <div
        className="w-full flex flex-col justify-between"
        onClick={() => activeKey && setActiveKey("")}
      >
        <div>
          {!client && !superAdmin && (
            <Header
              client={client}
              onClose={onClose}
              onOpen={onOpen}
              open={open}
            />
          )}
          {superAdmin && <SuperAdminHeader />}
          <div>{children}</div>
        </div>
        {!client && (
          <div>
            <Footer superAdmin={superAdmin} client={client} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Container;
