import { Tabs } from "antd";
import Settings from "./settings/settings";

const Localization = () => {
  const items = [
    {
      key: "1",
      label: `Settings`,
      children: <Settings />,
    },
  ];
  return (
    <div className="ml-2">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default Localization;
