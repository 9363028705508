import CompanyDesktop from "./companyDesktop/companyDesktop";
import CompanyMobile from "./companyMobile/basicDefaultsMobile";

const Company = () => {
  return (
    <>
      <div className="hidden xl:block">
        <CompanyDesktop />
      </div>
      <div className="block xl:hidden">
        <CompanyMobile />
      </div>
    </>
  );
};

export default Company;
