import PaymentsDesktop from "./paymentsDesktop/paymentsDesktop";
import PaymentsMobile from "./paymentsMobile/paymentsMobile";

const Payments = () => {
  return (
    <>
      <div className="hidden xl:block">
        <PaymentsDesktop />
      </div>
      <div className="block xl:hidden">
        <PaymentsMobile />
      </div>
    </>
  );
};

export default Payments;
