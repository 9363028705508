import { faBoxArchive, faIdBadge } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch } from "@mui/material";

const Quotes = () => {
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        <div className="flex items-center justify-between mt-2">
          <div className="flex items-center">
            <FontAwesomeIcon
              className="opacity-50 mr-5 text-xl"
              icon={faIdBadge}
            />
            <div>
              <p>Auto Convert</p>
              <p className="text-themeGray-dark text-xs">
                Automatically convert a quote to an invoice when approved by a
                client
              </p>
            </div>
          </div>
          <Switch defaultChecked />
        </div>
        <div className="flex items-center justify-between mt-5">
          <div className="flex items-center">
            <FontAwesomeIcon
              className="opacity-50 mr-5 text-xl"
              icon={faBoxArchive}
            />
            <div>
              <p>Auto Archive</p>
              <p className="text-themeGray-dark text-xs">
                Automatically archive quotes when they are converted
              </p>
            </div>
          </div>
          <Switch />
        </div>
      </div>
    </div>
  );
};

export default Quotes;
