import { Tabs } from "antd";
import Source from "./source/source";
import Status from "./status/status";

const Leads = () => {
  const items = [
    {
      key: "1",
      label: `Source`,
      children: <Source />,
    },
    {
      key: "2",
      label: `Status`,
      children: <Status />,
    },
  ];
  return (
    <div className="ml-2">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default Leads;
