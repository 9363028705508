import { Button } from "antd";
import { useNavigate } from "react-router-dom";
const { Container } = require("components/components");

const Dashboard = () => {
  const navigate = useNavigate();
  return (
    <Container client active={"Dashboard"}>
      <div className="flex h-[80vh] justify-center items-center">
        <div className="grid grid-cols-2 w-[80%]">
          <div>
            <p className="text-3xl font-semibold">Welcome back, Sandjai</p>
            <p className="py-5 text-sm">
              It looks like you have no upcoming <br /> appointments would you
              like to make one?
            </p>
            <Button
              onClick={() => navigate("/client/chat")}
              className="bg-green-500 h-10 mt-1 text-white hover:!text-white hover:!border-white"
            >
              Messages
            </Button>
          </div>
          <div>
            <p className="font-semibold text-lg pb-3">Overview</p>
            <div className="grid grid-cols-4 gap-3">
              <div className="col-span-2 bg-purple-100 text-purple-950 flex flex-col items-center justify-center h-32 rounded-xl">
                <p className="font-bold text-3xl">214</p>
                <p className="text-sm">Tasks</p>
              </div>
              <div className="col-span-2 bg-green-100 text-green-950 flex flex-col items-center justify-center h-32 rounded-xl">
                <p className="font-bold text-3xl">75</p>
                <p className="text-sm">Project</p>
              </div>
              <div className="bg-gray-100 flex flex-col items-center justify-center h-32 rounded-xl">
                <p className="font-bold text-3xl">3</p>
                <p className="text-sm">Invoices</p>
              </div>
              <div className="bg-gray-100 flex flex-col items-center justify-center h-32 rounded-xl">
                <p className="font-bold text-3xl">4</p>
                <p className="text-sm">Quotes</p>
              </div>
              <div className="bg-gray-100 flex flex-col items-center justify-center h-32 rounded-xl">
                <p className="font-bold text-3xl">8</p>
                <p className="text-sm">Re-opened</p>
              </div>
              <div className="bg-gray-100 flex flex-col items-center justify-center h-32 rounded-xl">
                <p className="font-bold text-3xl">6</p>
                <p className="text-sm">Needs attentions</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Dashboard;
