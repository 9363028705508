import { useState } from "react";
import { Container } from "components/components";
import { SettingsData } from "constant/constant";
import { Button, message } from "antd";
import { cancel_icon, save_icon } from "assets/icons/icons";
import CompanyDetails from "./companyDetails/companyDetails";
import UserDetails from "./userDetails/userDetails";
import Localization from "./localization/localization";
import Payment from "./payment/payment";
import Tax from "./tax/tax";
import Product from "./product/product";
import Task from "./task/task";
import Project from "./project/project";
import Expense from "./expense/expense";
import Workflow from "./workflow/workflow";
import ImportExport from "./importExport/importExport";
import Device from "./device/device";
import Account from "./account/account";
import CustomFields from "./customFields/customFields";
import GeneratedNumbers from "./generatedNumbers/generatedNumbers";
import Email from "./email/email";
import ClientPortal from "./clientPortal/clientPortal";
import Google from "./google/google";
import ESign from "./eSign/eSign";
import UserManagement from "./userManagement/userManagement";
import Leads from "./leads/leads";
import Schedules from "./schedules/schedules";
import Quotes from "./quotes/quotes";
import InvoiceDesign from "./invoiceDesign/invoiceDesign";
import Template from "./template/template";
import { cancelColor, saveColor } from "constant/themeColors";

const Settings = () => {
  const [addModal, setAddModal] = useState("addModalClose");
  const [selected, setSelected] = useState("");
  const [selectedKey, setSelectedKey] = useState("");
  const [color, setColor] = useState(
    JSON.parse(localStorage.getItem("color"))
      ? JSON.parse(localStorage.getItem("color"))
      : {
          header: "#2C2C2C",
          addnew: "#00A1E4",
          cancel: "#fff",
          footer: "#fff",
          logout: "#00A1E4",
          menubar: "#fff",
          save: "#4D9A19",
          search: "#1F1F1F",
          submenu: "#fff",
          timer: "#00A1E4",
          headIco:"#fff",
          sideIco:"#000",
          submenuIco:"#000",
          footIco:"#000",
        }
  );
  const handleSelection = (e) => {
    setAddModal("addModalOpen");
    setSelectedKey(e.key);
    setSelected(e.title);
  };
  const handleSave = () => {
    if (selectedKey === 11) {
      localStorage.setItem("color", JSON.stringify(color));
      window.location.reload();
    } else {
      message.success("Successfully saved settings");
    }
  };
  return (
    <Container>
      <div className="flex">
        <div className="w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
          <div>
            <div className="px-5 mt-5 mb-2">
              <p className="text-lg font-semibold">Settings</p>
            </div>
            <div className="grid grid-cols-3 gap-5 w-full px-5 pt-2 pb-5">
              {SettingsData.map((e, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => handleSelection(e)}
                    className={`opacity-80 col-span-1 flex border-2 cursor-pointer border-[rgba(0,0,0,.1)] items-center h-16 justify-center ${
                      e.title === selected
                        ? "bg-themeGray-medium"
                        : "hover:bg-themeGray-light200"
                    }`}
                  >
                    <div className="w-7 opacity-70">{e.icon}</div>
                    <p>{e.title}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div
          className={`bg-white ${addModal} border-l-2 border-[rgba(0,0,0,.1)] overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
        >
          <div className="px-5 pt-3 pb-1 sticky top-0 z-10 bg-white">
            <div className="flex items-center justify-between">
              <p className="font-semibold -mb-2 text-lg">{selected}</p>
              <div className="flex items-center">
                <Button
                  className="!text-sm p-0 flex items-center justify-center font-medium hover:!text-black"
                  style={{
                    borderRadius: 5,
                    height: 27,
                    width: 85,
                    backgroundColor: cancelColor,
                  }}
                  onClick={() => {
                    setSelected("");
                    setAddModal("addModalClose");
                  }}
                >
                  <img src={cancel_icon} alt="" className="mr-1" width={16} />
                  Cancel
                </Button>
                <Button
                  className="text-white ml-4 font-medium !text-sm p-0 flex items-center justify-center hover:!text-white"
                  style={{
                    borderRadius: 5,
                    height: 27,
                    width: 85,
                    backgroundColor: saveColor,
                  }}
                  onClick={handleSave}
                >
                  <img src={save_icon} alt="" className="mr-1" width={18} />
                  Save
                </Button>
              </div>
            </div>
          </div>
          {selectedKey === 0 && <CompanyDetails />}
          {selectedKey === 1 && <UserDetails />}
          {selectedKey === 2 && <Localization />}
          {selectedKey === 3 && <Payment />}
          {selectedKey === 4 && <Tax />}
          {selectedKey === 5 && <Product />}
          {selectedKey === 6 && <Task />}
          {selectedKey === 7 && <Project />}
          {selectedKey === 8 && <Expense />}
          {selectedKey === 9 && <Workflow />}
          {selectedKey === 10 && <ImportExport />}
          {selectedKey === 11 && <Device color={color} setColor={setColor} />}
          {selectedKey === 12 && <Account />}
          {selectedKey === 13 && <InvoiceDesign />}
          {selectedKey === 14 && <CustomFields />}
          {selectedKey === 15 && <GeneratedNumbers />}
          {selectedKey === 16 && <Email />}
          {selectedKey === 17 && <ClientPortal />}
          {selectedKey === 18 && <Template />}
          {selectedKey === 19 && <UserManagement />}
          {selectedKey === 20 && <Schedules />}
          {selectedKey === 21 && <Leads />}
          {selectedKey === 22 && <Quotes />}
          {selectedKey === 23 && <ESign />}
          {selectedKey === 24 && <Google />}
        </div>
      </div>
    </Container>
  );
};

export default Settings;
