import { useState } from "react";
import { Checkbox } from "@mui/material";
import { ThemeInput, ThemeSelect } from "components/components";
import { Button, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

const Square = () => {
  const [showFields, setShowFields] = useState(false);
  const onChange = (e) => {
    setShowFields(e.target.checked);
  };
  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      "https://demo-saas.worksuite.biz/square-webhook/3a835e0ccbbd218f87c62a440878d64d"
    );
    message.success("Webhook URL Copied!");
  };
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        <div className="flex items-center">
          <Checkbox onChange={onChange} />
          <p>Square Status</p>
        </div>
        {showFields && (
          <div>
            <ThemeSelect
              className={"!my-2"}
              label={"Select Environment"}
              data={["Sandbox", "Live"]}
            />
            <ThemeInput placeholder={"Square Application Id"} password />
            <ThemeInput placeholder={"Square Access Token"} password />
            <ThemeInput placeholder={"Square Location Id"} />
            <div className="mt-3">
              <p className="opacity-90 pb-1">Webhook URL</p>
              <p>
                https://demo-saas.worksuite.biz/square-webhook/3a835e0ccbbd218f87c62a440878d64d
              </p>
              <Button
                onClick={copyToClipboard}
                className="flex items-center opacity-80 h-9 mt-2"
              >
                <FontAwesomeIcon icon={faCopy} />
                <p className="ml-2">Copy</p>
              </Button>
              <p className="mt-3 text-red-500">
                (Add this webhook url on your square app settings.)
              </p>
              <Button
                type="primary"
                className="mt-2 w-full"
                onClick={() => message.success("Successfully saved settings")}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Square;
