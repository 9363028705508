import { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Button, Input, Modal } from "antd";
import { ThemeInput } from "components/components";

const localizer = momentLocalizer(moment);

const ThemeCalendar = () => {
  const [events, setEvents] = useState([
    {
      id: 1,
      title: "User Experience",
      start: new Date("06/17/2023"),
      end: new Date("06/17/2023"),
      desc: "Pre-meeting meeting, to prepare for the meeting",
    },
  ]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [start, setStart] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleEvents = (e) => {
    setIsModalOpen(true);
    setStart(e);
  };
  const handleAddEvents = () => {
    if (title) {
      let update = [...events];
      update.push({
        id: Math.random,
        title: title,
        start: start,
        end: start,
        desc: description,
      });
      setEvents(update);
      setIsModalOpen(false);
      setTitle("");
      setDescription("");
    }
  };
  return (
    <>
      <Modal
        title="Add Event"
        open={isModalOpen}
        footer={false}
        onCancel={handleCancel}
      >
        <ThemeInput
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder={"Title"}
        />
        {/* <ThemeInput placeholder={"Description"} multiline rows={4} /> */}
        <Input.TextArea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Description"
          rows={4}
          className="placeholder:!text-black"
        />
        <div className="flex justify-end mt-3">
          <Button type="primary" onClick={handleAddEvents}>
            Add
          </Button>
        </div>
      </Modal>
      <div className="red">
        <Calendar
          onDrillDown={handleEvents}
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: "75vh" }}
        />
      </div>
    </>
  );
};

export default ThemeCalendar;
