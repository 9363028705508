import Inter from "./assets/font/Inter.ttf";
import { ConfigProvider } from "antd";
import AppRouter from "./config/router";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          primary: "#1975D2",
          fontFamily: Inter,
        },
      }}
    >
      <AppRouter />
    </ConfigProvider>
  );
}

export default App;
