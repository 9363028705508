import { Button } from "antd";
import { home_icon, sorting_icon } from "assets/icons/icons";

const OverView = () => {
  return (
    <div>
      <div className="shadow-md mt-2 mx-2 flex flex-col justify-center px-3 h-[110px]">
        <div className="flex items-center">
          <div className="w-2/4 bg-green-500 text-white text-center p-4 rounded-lg font-medium mr-2">
            <p>Total Amount</p>
            <p>$237.00</p>
          </div>
          <div className="w-2/4 bg-bgBlue text-white text-center p-4 rounded-lg font-medium ml-2">
            <p>Applied</p>
            <p>$237.00</p>
          </div>
        </div>
      </div>
      <div className="shadow-md mt-2 px-3 h-[95px] flex items-center justify-between mx-2">
        <div>
          <p className="font-medium">Ortiz and Sons</p>
          <p className="text-themeGray-dark text-sm font-medium">
            allison.oberbrunner@example.org
          </p>
        </div>
        <img src={sorting_icon} alt="" />
      </div>
      <div className="shadow-md mt-2 px-3 h-[95px] flex items-center mx-2 justify-between">
        <div>
          <p className="font-medium">Invoice 0022</p>
          <p className="text-themeGray-dark text-sm font-medium">
            $66.00 * 15-05-2023
          </p>
        </div>
        <img src={sorting_icon} alt="" />
      </div>
      <div className="shadow-md mt-2 px-3 h-[95px] flex items-center justify-between mx-2">
        <div>
          <p className="font-medium">Date</p>
          <p className="text-themeGray-dark text-sm font-medium">15-05-2023</p>
        </div>
        <div>
          <p className="font-medium">Transaction ID</p>
          <p className="text-themeGray-dark text-sm font-medium">
            aab3238922bcc25a6f606eb525ffdc56
          </p>
        </div>
        <div>
          <p className="font-medium">Transaction Reference</p>
          <p className="text-themeGray-dark text-sm font-medium">
            Manual Entry
          </p>
        </div>
      </div>
      <div className="my-5 flex justify-around mx-2">
        <Button className="h-[90px] w-2/4 shadow-md !border-[1px] !border-[rgba(0,0,0,.05)] text-lg font-medium">
          Send Email
        </Button>
        <Button className="h-[90px] w-2/4 shadow-md !border-[1px] !border-[rgba(0,0,0,.05)] text-lg font-medium">
          Refund Payment
        </Button>
      </div>
    </div>
  );
};

export default OverView;
