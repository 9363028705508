import { Button, Col, Row, Table } from "antd";
import React from "react";
import { BiPlusMedical, BiSolidHelpCircle } from "react-icons/bi";
import {
  Container,
  RevenueChart,
  Pipeline,
  CardProgress,
  CardRedArrow,
  CardTextBg,
} from "components/components";

const columns = [
  {
    title: "#",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "Client",
    dataIndex: "client",
    key: "client",

    render: (text) => <div className="font-bold">{text}</div>,
  },

  {
    title: "Invoices",
    dataIndex: "invoices",
    key: "invoices",
  },
  {
    title: "Sum without tax",
    dataIndex: "sumWithoutTax",
    key: "sumWithoutTax",
  },
  {
    title: "Outstanding",
    dataIndex: "outstanding",
    key: "outstanding",
  },
];
const data = [
  {
    number: "1",
    client: "client1",
    invoices: "11 invoices",
    sumWithoutTax: "179.30,00",
    outstanding: "50.730,00",
  },
  {
    number: "2",
    client: "client1",
    invoices: "11 invoices",
    sumWithoutTax: "179.30,00",
    outstanding: "50.730,00",
  },
  {
    number: "3",
    client: "client1",
    invoices: "11 invoices",
    sumWithoutTax: "179.30,00",
    outstanding: "50.730,00",
  },
];

const columns2 = [
  {
    title: "Period",
    dataIndex: "Period",
    key: "Period",

    render: (text) => <div className="font-bold">{text}</div>,
  },
  {
    title: "Actual",
    dataIndex: "Actual",
    key: "Actual",
  },

  {
    title: "Budget",
    dataIndex: "Budget",
    key: "Budget",
    className: "min-width-100",
  },

  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    className: "min-width-100",
    render: (text) => (
      <div className="bg-[#EEF1FF] text-[10px] text-right p-1 rounded-md">
        {text}
      </div>
    ),
  },
  {
    title: "Difference",
    dataIndex: "Difference",
    key: "Difference",
    render: (text) => <div className="text-red-500 ">{text}</div>,
  },
];
const data2 = [
  {
    Period: "2023",
    Actual: "0",
    Budget: "35 000",
    Status: "0%",
    Difference: "-35000",
  },
  {
    Period: "2023",
    Actual: "0",
    Budget: "35 000",
    Status: "0%",
    Difference: "-35000",
  },
  {
    Period: "2023",
    Actual: "0",
    Budget: "35 000",
    Status: "0%",
    Difference: "-35000",
  },
];

const Sales = () => {
  const getTotal = (data, dataIndex) => {
    return data.reduce((total, item) => {
      const value = parseFloat(
        item[dataIndex].replace(",", "").replace(".", "").replace("€", "")
      );
      return total + value;
    }, 0);
  };

  const totalRow = {
    number: "Total",
    client: "",
    invoices: "26 Invoices",
    sumWithoutTax: getTotal(data, "sumWithoutTax").toLocaleString(),
    outstanding: getTotal(data, "outstanding").toLocaleString(),
  };
  return (
    <Container>
      <div className="px-8 mx-auto py-4 overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
        <div className="flex items-center flex-wrap justify-between">
          <div className="flex items-center">
            <h3>Sales Dashboard</h3>
          </div>
          <div className="flex items-center">
            <Button
              type="Add to Dashboard"
              className="flex items-center font-medium"
            >
              <BiPlusMedical fill="#494F59" size={15} className="mr-2" /> Add to
              Dashboard
            </Button>

            <Button
              type="Add to Dashboard"
              className="flex items-center font-medium"
            >
              <BiSolidHelpCircle fill="#494F59" size={20} className="mr-2" />{" "}
              Help
            </Button>
          </div>
        </div>
        <section className="cards-sec">
          <Row gutter={[16, 16]} className="mt-3">
            <CardProgress
              heading={"New leads this month"}
              progress={"0"}
              title={"Total"}
              description={"0"}
              subValue={"quotes"}
            />
            <CardProgress
              heading={"Deals closing this month"}
              progress={"92"}
              title={"Sum"}
              description={"92,46"}
              subValue={"k EUR"}
            />
            <CardRedArrow
              heading={"Successful quotes this month"}
              title={"Sum (Excl tax)"}
              percent={"-86,58"}
              description={"9.480"}
              subValue={" EUR"}
            />
            <CardTextBg
              heading={"All invoices issued last month"}
              paragraph1={"651,6 K"}
              paragraph2={"54,4%"}
              paragraph3={"354,4"}
            />
            <CardRedArrow
              heading={"Revenue this month vs last month"}
              title={"Sum (Excl tax)"}
              percent={"-86,58"}
              description={"9.480"}
              subValue={" EUR"}
            />
            <CardRedArrow
              heading={"Revenue YTD vs same period last year"}
              title={"Sum (Excl tax)"}
              percent={"-86,58"}
              description={"9.480"}
              subValue={" EUR"}
              green={"green"}
              rotate={"rotate"}
            />
          </Row>
          <Row gutter={[16, 16]} className="mt-3">
            <Col span={24} lg={12} xl={16}>
              <div className="shadow bg-white rounded-md p-3 h-[380px] overflow-auto">
                <span class="mb-2 text-[12px] text-[#000] font-medium">
                  Revenue by client YTD
                </span>
                <Table
                  columns={columns}
                  dataSource={[...data, totalRow]}
                  pagination={false}
                />
              </div>
            </Col>
            <Col span={24} lg={12} xl={8}>
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <div className="shadow bg-white rounded-md p-3 h-[300px] md:h-[182px] overflow-auto scrol">
                    <span class="mb-2 text-[12px] text-[#000] font-medium">
                      Active budget
                    </span>
                    <Table
                      columns={columns2}
                      dataSource={data2}
                      pagination={false}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="shadow bg-white rounded-md p-3 h-[300px] md:h-[182px] overflow-auto scrol">
                    <span class="mb-2 text-[12px] text-[#000] font-medium">
                      Revenue 2021 vs 2020
                    </span>
                    <RevenueChart height={"150px"} />
                  </div>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <div className="shadow bg-white rounded-md p-3 h-[380px] overflow-auto scrol">
                <span class="mb-2 text-[12px] text-[#000] font-medium">
                  Active pipeline
                </span>
                <Pipeline />
              </div>
            </Col>
          </Row>
        </section>
      </div>
    </Container>
  );
};

export default Sales;
