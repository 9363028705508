import { useState, useEffect } from "react";
import { Button, Dropdown } from "antd";
import {
  cancel_icon,
  cross_icon,
  download_icon,
  filter_icon,
  home_icon,
  plus_icon,
  print_icon,
  save_icon,
  submenu_icon,
} from "assets/icons/icons";
import { Container, ThemeTable } from "components/components";
import items from "./optionItems/optionItems";
import OverView from "./overView/overView";
import { PaymentsData } from "constant/constant";
import { PaymentInfo } from "./paymentInfo/paymentInfo";
import { addnewColor, cancelColor, saveColor } from "constant/themeColors";

const PaymentsDesktop = () => {
  const [data, setData] = useState(PaymentsData);
  const [densityOpt, setDensityOpt] = useState(false);
  const [density, setDensity] = useState("middle");
  const [addModal, setAddModal] = useState("addModalHandle");
  useEffect(() => {
    setAddModal("addModalCloseHandle");
  }, []);
  const [detailsModal, setDetailsModal] = useState("detailsModalClose");
  const handleAddCompany = () => {
    if (detailsModal === "detailsModalClose") {
      setAddModal("addModalOpen");
    } else {
      setDetailsModal("detailsModalClose");
      setAddModal("addModalOpen");
    }
  };
  const handleCompanyDetails = () => {
    if (addModal === "addModalClose") {
      setDetailsModal("detailsModalOpen");
    } else {
      setAddModal("addModalClose");
      setDetailsModal("detailsModalOpen");
    }
  };
  const onChange = (key) => {
    console.log(key);
  };
  const columns = [
    {
      title: "",
      dataIndex: "status",
      width: "5%",
      render: () => (
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomLeft"
          trigger={["click"]}
        >
          <div className="flex justify-center">
            <img src={submenu_icon} alt="" className="cursor-pointer" />
          </div>
        </Dropdown>
      ),
    },
    {
      title: "Id",
      dataIndex: "id",
      width: "7%",
      sorter: (a, b) => a.id - b.id,
      filters: [
        {
          text: "0014",
          value: "0014",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.id.includes(value),
    },
    {
      title: "Invoice",
      dataIndex: "invoice",
      sorter: (a, b) => a.invoice - b.invoice,
      width: "17%",
      filters: [
        {
          text: "ABC Invoice",
          value: "ABC Invoice",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.invoice.includes(value),
    },
    {
      title: "Customer",
      dataIndex: "customer",
      width: "17%",
      sorter: (a, b) => a.customer - b.customer,
      filters: [
        {
          text: "ABC Customer",
          value: "ABC Customer",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.customer.includes(value),
    },
    {
      title: "Payment method",
      dataIndex: "payment_method",
      sorter: (a, b) => a.payment_method - b.payment_method,
      width: "17%",
      filters: [
        {
          text: "ABC Method",
          value: "ABC Method",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.payment_method.includes(value),
    },
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      width: "11%",
      sorter: (a, b) => a.transaction_id - b.transaction_id,
      filters: [
        {
          text: "0014",
          value: "0014",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.transaction_id.includes(value),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: "11%",
      sorter: (a, b) => a.amount - b.amount,
      filters: [
        {
          text: "$2000",
          value: "$2000",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.amount.includes(value),
    },
    {
      title: "Date",
      dataIndex: "date",
      width: "11%",
      sorter: (a, b) => a.date - b.date,
      filters: [
        {
          text: "15/May/2023",
          value: "15/May/2023",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.date.includes(value),
    },
  ];
  const handleDensity = () => {
    setDensityOpt(!densityOpt);
  };
  return (
    <Container onClick={() => densityOpt && setDensityOpt(false)}>
      <div className="flex">
        <div className="w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
          <div className="bg-white" style={{ top: 54 }}>
            <div className="flex items-center justify-between px-5 py-3">
              <div className="flex items-center">
                <p className="font-medium text-sm">Payments</p>
                <img src={home_icon} alt="" className="mr-3 ml-8" />
                <p className="font-medium text-sm">
                  Home
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    Payments
                  </a>
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    All payments
                  </a>
                </p>
              </div>
              <div className="flex items-center">
                <div>
                  <img
                    src={filter_icon}
                    onClick={handleDensity}
                    alt=""
                    className="mr-4 cursor-pointer"
                  />
                  {densityOpt && (
                    <div className="relative">
                      <div className="text-sm absolute my-2 bg-white px-3 py-1 z-20 top-0 leading-7 rounded-md shadow-lg">
                        <p
                          className="cursor-pointer"
                          onClick={() => setDensity("small")}
                        >
                          Compact
                        </p>
                        <p
                          className="cursor-pointer"
                          onClick={() => setDensity("middle")}
                        >
                          Standard
                        </p>
                        <p
                          className="cursor-pointer"
                          onClick={() => setDensity("large")}
                        >
                          Comfortable
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <img src={download_icon} alt="" className="mr-4" />
                <img src={print_icon} alt="" className="mr-8" />
                <Button
                  onClick={handleAddCompany}
                  className="!text-sm p-0 flex items-center justify-center font-medium hover:!text-white text-white"
                  style={{
                    borderRadius: 5,
                    height: 27,
                    width: 85,
                    backgroundColor: addnewColor,
                  }}
                >
                  Add
                  <img src={plus_icon} alt="" className="ml-2" />
                </Button>
              </div>
            </div>
            <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
          </div>
          <div className="-mt-3">
            <ThemeTable
              onClick={handleCompanyDetails}
              columns={columns}
              data={data}
              setData={(e) => setData(e)}
              density={density}
            />
          </div>
        </div>

        <div
          className={`bg-white ${addModal} ${
            addModal === "addModalHandle" && "-z-10"
          } border-l-2 border-[rgba(0,0,0,.1)] overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
        >
          <div className="pt-3">
            <div className="px-5 flex items-center justify-between">
              <div>
                <p className="font-semibold -mb-2 text-lg">New product</p>
                <a href="#" className="text-xs text-bgBlue">
                  Product Details
                </a>
              </div>
              <div className="flex items-center">
                <Button
                  className="!text-sm p-0 flex items-center justify-center font-medium hover:!text-black"
                  style={{
                    borderRadius: 5,
                    height: 27,
                    width: 85,
                    backgroundColor: cancelColor,
                  }}
                  onClick={() => setAddModal("addModalClose")}
                >
                  <img src={cancel_icon} alt="" className="mr-1" width={16} />
                  Cancel
                </Button>
                <Button
                  className="text-white ml-4 font-medium !text-sm p-0 flex items-center justify-center hover:!text-white"
                  style={{
                    borderRadius: 5,
                    height: 27,
                    width: 85,
                    backgroundColor: saveColor,
                  }}
                  onClick={() => setAddModal("addModalClose")}
                >
                  <img src={save_icon} alt="" className="mr-1" width={18} />
                  Save
                </Button>
              </div>
            </div>
            <PaymentInfo />
          </div>
        </div>

        <div
          className={`bg-white ${detailsModal} overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
        >
          {detailsModal === "detailsModalOpen" && (
            <>
              <div className="flex items-center justify-between w-full pr-5 shadow-md border-b-2 border-[rgba(0,0,0,.2)] bg-white sticky top-0">
                <div className="h-11 items-center w-[432px] flex">
                  <div className="pl-3">
                    <p className="font-semibold">Payment 0014</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement="bottomLeft"
                    trigger={["click"]}
                  >
                    <div className="flex justify-center">
                      <img
                        src={submenu_icon}
                        alt=""
                        className="cursor-pointer"
                      />
                    </div>
                  </Dropdown>
                  <img
                    src={cross_icon}
                    alt=""
                    className="ml-4 cursor-pointer opacity-70"
                    width={16}
                    onClick={() => setDetailsModal("detailsModalClose")}
                  />
                </div>
              </div>
              <OverView />
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default PaymentsDesktop;
