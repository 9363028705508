import React from "react";
import { useState } from "react";
import { home_icon } from "assets/icons/icons";
import { Container, ThemeSelect, ThemeTable } from "components/components";
import ExpensesChart from "./charts/expensesChart";
import IncomeChart from "./charts/incomeChart";
import TurnOverChart from "./charts/turnOverChart";
import { DatePicker, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import locale from "antd/locale/zh_CN";
import {
  faArrowsRotate,
  faDownload,
  faEnvelope,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
const { RangePicker } = DatePicker;

const FinanceMonitor = () => {
  const { type } = useParams();
  const allUsers = ["User 1", "User 2", "User 3", "User 4"];
  const invoiceExpenseOptions = [
    "Invoice 1",
    "Invoice 2",
    "Expense 1",
    "Expense 2",
  ];
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedInvoiceExpense, setSelectedInvoiceExpense] = useState("");
  const handleUserChange = (value) => {
    setSelectedUser(value);
  };
  const handleInvoiceExpenseChange = (value) => {
    setSelectedInvoiceExpense(value);
  };

  //Table
  const columns = [
    {
      title: "",
      dataIndex: "staffMember",
      key: "staffMember",
    },
    {
      title: "2013",
      dataIndex: "task",
      key: "task",
    },
    {
      title: "2014",
      dataIndex: "timesheetTags",
      key: "timesheetTags",
    },
    {
      title: "verschill",
      dataIndex: "related",
      key: "related",
    },
  ];

  const data = [
    {
      key: "1",
      staffMember: "2024",
      task: "11,000",
      timesheetTags: "$900,000",
      related: "800,000",
    },
    {
      key: "2",
      staffMember: "2022",
      task: "11,000",
      timesheetTags: "$900,000",
      related: "800,000",
    },
    {
      key: "3",
      staffMember: "2022",
      task: "11,000",
      timesheetTags: "$900,000",
      related: "800,000",
    },
  ];

  const handleRowClick = (record) => {
    console.log("Clicked row:", record);
  };

  const handleDataChange = (updatedData) => {
    console.log("Updated data:", updatedData);
  };
  const startDate = dayjs("2023-09-01", "YYYY-MM-DD");
  const endDate = dayjs();
  const defaultDateRange = [startDate, endDate];

  return (
    <Container superAdmin={type === "backend" && true} active={"Reports"}>
      <div className="flex ps-4">
        <div className="w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
          <div className="bg-white" style={{ top: 54 }}>
            <div className="flex items-center justify-between px-5 py-3">
              <div className="flex items-center">
                <p className="font-medium text-sm">Company</p>
                <img src={home_icon} alt="" className="mr-3 ml-8" />
                <p className="font-medium text-sm">
                  Home
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    Finance
                  </a>
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    Finance Monitor
                  </a>
                </p>
              </div>
              <div className="flex items-center">
                <div className="me-4">
                  <FontAwesomeIcon icon={faArrowsRotate} />
                </div>
                <p className="p-0 m-0 text-gray-700">
                  Data refreshed at jul 18, 2023 04:06 PM
                </p>
                <div className="flex ">
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faEnvelope} className="fs-1" />
                  </div>
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faDownload} />
                  </div>
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faPrint} />
                  </div>
                </div>
              </div>
            </div>
            <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
          </div>
          <div className="flex justify-between mt-8 pe-4">
            <div>
              <h3 className="font-bold text-xl ">Finance Monitor</h3>
              <p className="font-medium text-sm text-gray-500">
                Keep track of your financial status
              </p>
            </div>
            <div className="flex items-end">
              <Space direction="vertical">
                <RangePicker defaultValue={defaultDateRange} locale={locale} />
              </Space>
              <div className="mx-4">
                <ThemeSelect
                  label="Select User"
                  data={[...allUsers]}
                  value={selectedUser}
                  setValue={handleUserChange}
                  width="150px"
                />
              </div>
              <ThemeSelect
                label="Select Invoice/Expense"
                data={[...invoiceExpenseOptions]}
                value={selectedInvoiceExpense}
                setValue={handleInvoiceExpenseChange}
                width="150px"
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row my-8 pe-4">
            <div className="sm:w-1/3 my-3">
              <div
                className="border rounded px-2 mx-2"
                style={{ position: "relative" }}
              >
                <div
                  className="bg-white"
                  style={{
                    position: "absolute",
                    background: "white",
                    top: "-12px",
                  }}
                >
                  <h3>Turn over perquater</h3>
                </div>
                <TurnOverChart />
              </div>
              <div className="mt-8">
                <ThemeTable
                  columns={columns}
                  data={data}
                  onClick={handleRowClick}
                  setData={handleDataChange}
                  density="default"
                  noSelectRows={true}
                  noPagination={true}
                />
              </div>
            </div>
            <div className="sm:w-1/3 my-3 mx-4">
              <div
                className="border rounded px-2"
                style={{ position: "relative" }}
              >
                <div
                  className="bg-white mx-2"
                  style={{
                    position: "absolute",
                    background: "white",
                    top: "-12px",
                  }}
                >
                  <h3>Income</h3>
                </div>
                <IncomeChart />
              </div>
              <div className="mt-8">
                <ThemeTable
                  columns={columns}
                  data={data}
                  onClick={handleRowClick}
                  setData={handleDataChange}
                  density="default"
                  noSelectRows={true}
                  noPagination={true}
                />
              </div>
            </div>
            <div className="sm:w-1/3 my-3">
              <div
                className="border rounded px-2"
                style={{ position: "relative" }}
              >
                <div
                  className="bg-white mx-2"
                  style={{
                    position: "absolute",
                    background: "white",
                    top: "-12px",
                  }}
                >
                  <h3>Expenses</h3>
                </div>
                <ExpensesChart />
              </div>
              <div className="mt-8">
                <ThemeTable
                  columns={columns}
                  data={data}
                  onClick={handleRowClick}
                  setData={handleDataChange}
                  density="default"
                  noSelectRows={true}
                  noPagination={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default FinanceMonitor;
