import { Col } from "antd";
import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const CardProgress = ({ heading, progress, title, description, subValue }) => {
  return (
    <Col span={24} md={8} lg={6} xl={4}>
      <a
        href="/"
        class="block max-w-sm h-[110px] p-4 pt-2  bg-white rounded-md shadow "
      >
        <span class="mb-2 text-[12px] text-[#000] font-medium truncate w-[160px] block m-0">
          {heading}
        </span>
        <div className="flex items-center justify-center mt-3 mb-1">
          <div style={{ width: 50, height: 50, position: "relative" }}>
            <CircularProgressbar
              value={progress}
              strokeWidth={11}
              // text={`${progress}%`}
              styles={buildStyles({
                rotation: 0.25,

                strokeLinecap: "butt",

                textSize: "20px",

                pathTransitionDuration: 0.5,

                pathColor: `#50b83c`,
                textColor: "#717A89",
                trailColor: "#bbe5b3",
                backgroundColor: "#3e98c7",
                trail: {
                  strokeLinecap: "butt",
                  transform: "translate(0%, 50%)",
                },
                text: {
                  fill: "#717A89",
                  fontSize: "20px",
                  dominantBaseline: "central",
                  textAnchor: "middle",
                },
              })}
            />
            <div
              style={{
                fontSize: "10px",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
              }}
            >
              {`${progress}%`}
            </div>
          </div>
          <div className="ml-3">
            <p class="text-[12px] text-[#494F59] font-medium">{title}</p>
            <p className="text-[#1F2226] text-[23px] hover:text-[#E43958] leading-[25px]">
              {description}
              <sub class="text-[12px] text-[#494F59] font-medium">
                {subValue}
              </sub>
            </p>
          </div>
        </div>
      </a>
    </Col>
  );
};

export default CardProgress;
