import { faFilter, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select, DatePicker } from "antd";
import dayjs from "dayjs";

const FilterOptions = ({ onTop }) => {
  const { RangePicker } = DatePicker;
  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      console.log("From: ", dates[0], ", to: ", dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
    } else {
      console.log("Clear");
    }
  };
  const rangePresets = [
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "Last 90 Days",
      value: [dayjs().add(-90, "d"), dayjs()],
    },
  ];

  return (
    <div className={`bg-gray-100 p-3 ${onTop && "-mt-4"}`}>
      <div className="flex items-center">
        <div>
          <Select
            showSearch
            style={{
              width: 120,
            }}
            placeholder="My Actions"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={[
              {
                value: "1",
                label: "Not Identified",
              },
              {
                value: "2",
                label: "Closed",
              },
              {
                value: "3",
                label: "Communicated",
              },
            ]}
          />
        </div>
        <div className="ms-4">
          <Select
            showSearch
            style={{
              width: 120,
            }}
            placeholder="All Users"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={[
              {
                value: "1",
                label: "User 1",
              },
              {
                value: "2",
                label: "User 1",
              },
              {
                value: "3",
                label: "User 2",
              },
            ]}
          />
        </div>
        <div className="ms-5">
          <RangePicker presets={rangePresets} onChange={onRangeChange} />
        </div>
        <div className="ms-4">
          <Select
            showSearch
            style={{
              width: 120,
            }}
            placeholder="Priority"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={[
              {
                value: "1",
                label: "proprity 1",
              },
              {
                value: "2",
                label: "proprity 1",
              },
              {
                value: "3",
                label: "proprity 2",
              },
            ]}
          />
        </div>
        <div className="ms-4">
          <Select
            showSearch
            style={{
              width: 120,
            }}
            placeholder="Status"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={[
              {
                value: "1",
                label: "Status 1",
              },
              {
                value: "2",
                label: "Status 1",
              },
              {
                value: "3",
                label: "Status 2",
              },
            ]}
          />
        </div>
        <div className="ms-4">
          <Select
            showSearch
            style={{
              width: 120,
            }}
            placeholder="Grouped By"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={[
              {
                value: "1",
                label: "Grouped 1",
              },
              {
                value: "2",
                label: "Grouped 1",
              },
              {
                value: "3",
                label: "Grouped 2",
              },
            ]}
          />
        </div>
        <div className="ms-4 flex items-center">
          <FontAwesomeIcon
            icon={faFilter}
            className="fs-1 me-2 text-gray-400"
          />
          <Select
            showSearch
            style={{
              width: 80,
            }}
            placeholder="Grouped By"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={[
              {
                value: "1",
                label: "Grouped 1",
              },
              {
                value: "2",
                label: "Grouped 1",
              },
              {
                value: "3",
                label: "Grouped 2",
              },
            ]}
          />
        </div>
        <div className="ms-4 flex items-center">
          <FontAwesomeIcon icon={faList} className="fs-1 me-2 text-gray-400" />
          <Select
            showSearch
            style={{
              width: 80,
            }}
            placeholder="Grouped By"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={[
              {
                value: "1",
                label: "Grouped 1",
              },
              {
                value: "2",
                label: "Grouped 1",
              },
              {
                value: "3",
                label: "Grouped 2",
              },
            ]}
          />
          {/* <FontAwesomeIcon icon={faList} className="fs-1" /> */}
        </div>
      </div>
    </div>
  );
};

export default FilterOptions;
