import { useState } from "react";
import { faDollarSign, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Radio, Input } from "antd";
import { menu_icon, right_arrow } from "assets/icons/icons";

const Details = ({ status }) => {
  const [pay, setPay] = useState(false);
  return (
    <div className="z-0 p-3">
      <div className="flex justify-between items-center my-3">
        <div className="">
          <p className="text-sm">Invoice Amount</p>
          <p className="text-2xl py-1">$4,995.00</p>
        </div>
        <div>
          <p className="text-sm">Balance Due</p>
          <p className="text-2xl">$4,995.00</p>
        </div>
        {status !== "Paid" && status !== "Cancel" ? (
          <Button
            onClick={() => setPay(true)}
            className="bg-green-500 hover:!text-white text-white h-9"
          >
            Pay Now
          </Button>
        ) : (
          <div></div>
        )}
      </div>
      <div
        className={`${
          status === "Paid"
            ? "bg-green-500"
            : status === "Unpaid"
            ? "bg-red-500"
            : status === "Cancel"
            ? "bg-gray-500"
            : status === "Send" && "bg-blue-500"
        } text-white w-32 h-7 text-sm flex justify-center items-center rounded-[5px]`}
      >
        <p>{status}</p>
      </div>
      <div className="flex items-center justify-between border-y-2 mt-5 border-[rgba(0,0,0,.2)] py-3">
        <div className="flex items-center">
          <FontAwesomeIcon className="opacity-50" icon={faUserGroup} />
          <p className="ml-4">Golden Infotech</p>
        </div>
        <img src={menu_icon} alt="" />
      </div>
      <div className="flex items-center flex-wrap my-3">
        <div className="w-2/4">
          <p className="text-sm">Date</p>
          <p>09/May/2023</p>
        </div>
        <div className="w-2/4">
          <p className="text-sm">Due Date</p>
          <p>26/May/2023</p>
        </div>
        <div className="w-2/4 mt-4">
          <p className="text-sm">Partial Due</p>
          <p>$500.00</p>
        </div>
        <div className="w-2/4 mt-4">
          <p className="text-sm">Partial Due Date</p>
          <p>31/May/2023</p>
        </div>
        <div className="w-2/4 mt-4">
          <p className="text-sm">PO Number</p>
          <p>3333</p>
        </div>
        <div className="w-2/4 mt-4">
          <p className="text-sm">Discount</p>
          <p>$5.00</p>
        </div>
      </div>
      <div className="flex items-center justify-between border-y-2 mt-5 border-[rgba(0,0,0,.2)] py-3">
        <div>
          <p>Test product</p>
          <p className="text-xs text-themeGray-medium300">100 x $50.00</p>
          <p className="text-xs text-themeGray-medium300">loream ispum</p>
        </div>
        <div className="flex items-center">
          <p>$5,000.00</p>
          <img src={right_arrow} alt="" className="ml-4" />
        </div>
      </div>
      <div className="flex flex-col items-end mt-4 mr-5">
        <div className="flex items-center mb-2">
          <p>Subtotal</p>
          <p className="ml-4">$5,000.00</p>
        </div>
        <div className="flex items-center mb-2">
          <p>Paid to Date</p>
          <p className="ml-4">$0.00</p>
        </div>
        <div className="flex items-center mb-2">
          <p>Balance Due</p>
          <p className="ml-4">$4,995.00</p>
        </div>
        <div className="flex items-center">
          <p>Partial Due</p>
          <p className="ml-4">$500.00</p>
        </div>
      </div>

      {pay && status !== "Paid" && status !== "Cancel" && (
        <div>
          <div className="border-y-[1px] border-[rgba(0,0,0,.1)] py-4 mt-2">
            <p className="font-semibold pb-1">Terms & Conditions</p>
            <p className="text-sm text-themeGray-medium300">
              Alice did not venture to say than his first remark, 'It was the
              first to speak. 'What size do you know the way YOU manage?' Alice
              asked. 'We called him Tortoisa because ha taught us,' said the
              Caterpillar. Alice thought decidedly uncivil. 'But perhaps it was
              quite surprised to see what would be of.
            </p>
          </div>
          <div className="border-b-[1px] border-[rgba(0,0,0,.1)] py-4">
            <p className="font-semibold pb-1">Transactions</p>
            <p className="text-sm text-themeGray-medium300">
              No payment found for this invoice
            </p>
          </div>
          <div className="py-4">
            <p className="font-semibold pb-1">Online Payment</p>
            <Radio checked>Stripe Checkout</Radio>
          </div>
          <div className="py-4">
            <p className="font-semibold pb-1">Amount</p>
            <Input
              type="number"
              className="h-10"
              suffix={<FontAwesomeIcon icon={faDollarSign} />}
            />
            <Button className="bg-green-500 hover:!text-white text-white h-9 mt-3">
              Pay Now
            </Button>
          </div>
          <div className="flex flex-col items-end">
            <p className="pb-2">Offline Payment</p>
            <p className="pb-2">Bank</p>
            <p className="pb-2">Bankuberweisung</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Details;
