import { useState } from "react";
import {
  faArrowsRotate,
  faDownload,
  faEllipsisVertical,
  faEnvelope,
  faPrint,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const { Container, FilterOptions } = require("components/components");

const TaxReport = () => {
  const data = [
    {
      month: "January",
      invoices: "7,800.00",
      tax: "0,00",
      bills: "5.000,00",
      billtax: "0,00",
      totaltax1: "2.800,00",
      totaltax2: "0,00",
    },
    {
      month: "February",
      invoices: "7,800.00",
      tax: "0,00",
      bills: "5.000,00",
      billtax: "0,00",
      totaltax1: "2.800,00",
      totaltax2: "0,00",
    },
    {
      month: "March",
      invoices: "7,800.00",
      tax: "0,00",
      bills: "5.000,00",
      billtax: "0,00",
      totaltax1: "2.800,00",
      totaltax2: "0,00",
    },
    {
      month: "April",
      invoices: "7,800.00",
      tax: "0,00",
      bills: "5.000,00",
      billtax: "0,00",
      totaltax1: "2.800,00",
      totaltax2: "0,00",
    },
    {
      month: "May",
      invoices: "7,800.00",
      tax: "0,00",
      bills: "5.000,00",
      billtax: "0,00",
      totaltax1: "2.800,00",
      totaltax2: "0,00",
    },
    {
      month: "June",
      invoices: "7,800.00",
      tax: "0,00",
      bills: "5.000,00",
      billtax: "0,00",
      totaltax1: "2.800,00",
      totaltax2: "0,00",
    },
    {
      month: "July",
      invoices: "7,800.00",
      tax: "0,00",
      bills: "5.000,00",
      billtax: "0,00",
      totaltax1: "2.800,00",
      totaltax2: "0,00",
    },
    {
      month: "August",
      invoices: "7,800.00",
      tax: "0,00",
      bills: "5.000,00",
      billtax: "0,00",
      totaltax1: "2.800,00",
      totaltax2: "0,00",
    },
    {
      month: "September",
      invoices: "7,800.00",
      tax: "0,00",
      bills: "5.000,00",
      billtax: "0,00",
      totaltax1: "2.800,00",
      totaltax2: "0,00",
    },
    {
      month: "October",
      invoices: "7,800.00",
      tax: "0,00",
      bills: "5.000,00",
      billtax: "0,00",
      totaltax1: "2.800,00",
      totaltax2: "0,00",
    },
    {
      month: "November",
      invoices: "7,800.00",
      tax: "0,00",
      bills: "5.000,00",
      billtax: "0,00",
      totaltax1: "2.800,00",
      totaltax2: "0,00",
    },
    {
      month: "December",
      invoices: "7,800.00",
      tax: "0,00",
      bills: "5.000,00",
      billtax: "0,00",
      totaltax1: "2.800,00",
      totaltax2: "0,00",
    },
  ];
  return (
    <Container superAdmin active={"Reports"}>
      <div className="w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
        <div className="bg-white" style={{ top: 54 }}>
          <div className="flex items-center justify-between px-5 py-3">
            <div className="flex items-center">
              <p className="font-medium text-sm">Tax Report</p>
            </div>
            <div className="text-end pe-5">
              <div className="flex items-center">
                <div className="me-4">
                  <FontAwesomeIcon icon={faArrowsRotate} />
                </div>
                <p className="p-0 m-0 text-gray-700">
                  Data refreshed at jul 18, 2023 04:06 PM
                </p>
                <div className="flex ">
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faEnvelope} className="fs-1" />
                  </div>
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faDownload} />
                  </div>
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faPrint} />
                  </div>
                </div>
                <FontAwesomeIcon
                  icon={faEllipsisVertical}
                  className="fs-1 mx-4"
                />
                <FontAwesomeIcon icon={faXmark} className="fs-1" />
              </div>
            </div>
          </div>
          <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
        </div>
        <FilterOptions onTop />
        <section className="m-4 text-sm summery-sec border shadow-lg rounded-md">
          <div className="grid border-b-[1px] border-[rgba(0,0,0,.1)] grid-cols-9 p-3 font-medium">
            <p className="col-span-3">Month</p>
            <p>Invoices</p>
            <p>Tax</p>
            <p>Bills/Expenses</p>
            <p>Tax</p>
            <p>Total Tax</p>
            <p>Total Tax</p>
          </div>
          <div className="grid grid-cols-9 p-3 font-medium">
            <p className="col-span-3"></p>
            <p>2022</p>
            <p>2021</p>
            <p>2022</p>
            <p>2021</p>
            <p>2022</p>
            <p>2021</p>
          </div>
          {data.map((e, i) => {
            return (
              <>
                <div className="grid grid-cols-9 p-3">
                  <p className="col-span-3 font-medium">{e.month}</p>
                  <p>{e.invoices}</p>
                  <p>{e.tax}</p>
                  <p>{e.bills}</p>
                  <p>{e.billtax}</p>
                  <p className="text-themeLightGreen">{e.totaltax1}</p>
                  <p className="text-themeLightGreen">{e.totaltax2}</p>
                </div>
                {(e.month === "March" ||
                  e.month === "June" ||
                  e.month === "September" ||
                  e.month === "December") && (
                  <div className="grid grid-cols-9 p-3">
                    <p className="col-span-3 font-medium text-end mr-5 text-gray-400">
                      Quarter{" "}
                      {e.month === "March"
                        ? 1
                        : e.month === "June"
                        ? 2
                        : e.month === "September"
                        ? 3
                        : e.month === "December" && 4}
                    </p>
                    <p>23.800,00</p>
                    <p>0,00</p>
                    <p>14.000,00</p>
                    <p>0,00 </p>
                    <p className="text-themeLightGreen">9.800,00</p>
                    <p className="text-themeLightGreen">0,00</p>
                  </div>
                )}
              </>
            );
          })}
          <div className="grid grid-cols-9 p-3 font-medium">
            <p className="col-span-3 text-end mr-5">Total</p>
            <p>179.000,00</p>
            <p>36.400,00</p>
            <p>89.000,00</p>
            <p>40.600,00</p>
            <p className="text-themeLightGreen">90.500,00</p>
            <p className="text-themeLightGreen">4.200,00</p>
          </div>
        </section>
      </div>
    </Container>
  );
};

export default TaxReport;
