import { EditOutlined } from "@ant-design/icons";
import { Checkbox } from "@mui/material";

const Labels = ({ data, onClick, setData, eventsData, setEventsData }) => {
  const handleChoosen = (i, e, checked) => {
    let update = [...data];
    update[i].isChoosen = !e.isChoosen;
    setData(update);
    let showEvents = eventsData.map((val) => {
      if (val.color === e.color) {
        return { ...val, isChoosen: checked };
      } else {
        return { ...val };
      }
    });
    setEventsData(showEvents);
  };
  return (
    <div className="pr-5">
      <p className="text-[28px] font-extrabold">Calendar</p>
      <div className="flex items-center justify-between mt-5 mb-3">
        <p className="font-semibold">Labels</p>
        <EditOutlined
          onClick={onClick}
          style={{ fontSize: 18, cursor: "pointer" }}
        />
      </div>
      {data.map((e, i) => {
        return (
          <div key={i} className="flex items-center mb-1">
            <Checkbox
              size="small"
              className="!-ml-3"
              onChange={(val) => handleChoosen(i, e, val.target.checked)}
              checked={e.isChoosen}
            />
            <div
              className={`h-[14px] w-[14px] rounded-full`}
              style={{ backgroundColor: e.color }}
            ></div>
            <p className="ml-3 text-sm">{e.title}</p>
          </div>
        );
      })}
    </div>
  );
};

export default Labels;
