import { useState } from "react";
import {
  BasicInfo,
  ContactPerson,
} from "../../companyDesktop/basicInfo/basicInfo";

const { MobContainer } = require("components/components");

const AddCompanyMob = () => {
  const [tab, setTab] = useState(0);
  const tabs = ["Basic Info", "Contact Person"];
  return (
    <MobContainer
      onCancel={"/company"}
      heading={"New Company"}
      addCompany
      tab={tab}
      tabs={tabs}
      setTab={(e) => setTab(e)}
    >
      <div className="px-3">
        {tab === 0 && <BasicInfo className={"!mt-0"} />}
        {tab === 1 && <ContactPerson className={"!mt-0"} />}
      </div>
    </MobContainer>
  );
};

export default AddCompanyMob;
