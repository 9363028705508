import React, { useRef } from "react";
import { Button, Modal } from "antd";
import { ThemeInput } from "components/components";
import SignatureCanvas from "react-signature-canvas";

const AcceptModal = ({ setIsModalOpen, isModalOpen }) => {
  const sigCanvas = useRef();
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onClear = () => {
    sigCanvas.current.clear();
  };
  return (
    <Modal
      title="Signature & Confirmation Of Identity"
      open={isModalOpen}
      okText={"Sign"}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <ThemeInput placeholder={"First Name"} />
      <ThemeInput placeholder={"Last Name"} />
      <ThemeInput placeholder={"Email"} />
      <p className="pb-1">Signature</p>
      <div className="border-2 border-[rgba(0,0,0,.4)] rounded-md">
        <SignatureCanvas
          ref={sigCanvas}
          penColor="black"
          canvasProps={{
            width: 480,
            height: 150,
            className: "sigCanvas",
          }}
        />
      </div>
      <div className="my-3">
        <Button onClick={onClear}>Clear</Button>
        {/* <Button className="ml-3">Undo</Button> */}
      </div>
      <p className="text-sm text-themeGray-dark">
        By clicking on "Sign", I consent to be legally bound by this electronic
        representation of my signature.
      </p>
    </Modal>
  );
};

export default AcceptModal;
