import { Col } from "antd";
import React from "react";

const CardTextBg = ({ heading, paragraph1, paragraph2, paragraph3 }) => {
  return (
    <Col span={24} md={8} lg={6} xl={4}>
      <a
        href="/#"
        class="block max-w-sm h-[110px] p-4 pt-2  bg-white rounded-md shadow "
      >
        <span class="mb-2 text-[12px] text-[#000] font-medium truncate w-[160px] block m-0">
          {heading}
        </span>
        <div className="flex items-center justify-center mt-5 mb-3">
          <p class="text-[12px] text-[#494F59] text-center">{paragraph1}</p>
          <p className="text-[#1F2226] text-[23px] text-center hover:text-[#E43958] bg-[#F0F3FA] px-2 mx-1 rounded-md leading-[33px]">
            {paragraph2}
          </p>
          <p class="text-[12px] text-[#494F59] text-center">{paragraph3}</p>
        </div>
      </a>
    </Col>
  );
};

export default CardTextBg;
