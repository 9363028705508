import { useState } from "react";
import { ThemeTable, ThemeInput } from "components/components";
import { Button, Popconfirm } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";

const OfflinePayment = () => {
  const [method, setMethod] = useState("");
  const [descripiton, setDescription] = useState("");
  const [data, setData] = useState([]);
  const handleDelete = (key) => {
    const newData = data.filter((item) => item.key !== key);
    setData(newData);
  };
  const columns = [
    {
      title: "#",
      dataIndex: "id",
    },
    {
      title: "Method",
      dataIndex: "method",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) =>
        data.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <DeleteTwoTone className="text-lg" />
          </Popconfirm>
        ) : null,
    },
  ];
  const handleMethod = () => {
    if (method && descripiton) {
      let update = [...data];
      update.push({
        id: data.length + 1,
        key: data.length + 1,
        method: method,
        description: descripiton,
        status: "",
        action: "",
      });
      setData(update);
      setMethod("");
      setDescription("");
    }
  };
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        <ThemeInput
          placeholder={"Method"}
          value={method}
          onChange={(e) => setMethod(e.target.value)}
        />
        <ThemeInput
          placeholder={"Description"}
          multiline
          rows={4}
          value={descripiton}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Button type="primary" className="w-full" onClick={handleMethod}>
          Add new
        </Button>
      </div>
      <div className="bg-white p-3 shadow-md mt-3">
        <ThemeTable columns={columns} noSelectRows data={data} noPagination />
      </div>
    </div>
  );
};

export default OfflinePayment;
