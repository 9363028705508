import { useState } from "react";
import { Gantt, ViewMode } from "gantt-task-react";
import Inter from "assets/font/Inter.ttf";
import { Button } from "antd";
import { Checkbox } from "@mui/material";

const ViewSwitcher = ({
  onViewModeChange,
  onViewListChange,
  isChecked,
  extraBtns,
}) => {
  return (
    <div className="flex items-center justify-end py-3 mr-5">
      {extraBtns && (
        <>
          <Button className="mr-2">Status</Button>
          <Button className="mr-2">Asssigd</Button>
        </>
      )}
      <Button className="mr-2" onClick={() => onViewModeChange(ViewMode.Day)}>
        Day
      </Button>
      <Button className="mr-2" onClick={() => onViewModeChange(ViewMode.Week)}>
        Week
      </Button>
      <Button className="mr-2" onClick={() => onViewModeChange(ViewMode.Month)}>
        Month
      </Button>
      <Button onClick={() => onViewModeChange(ViewMode.Year)}>Year</Button>
      <div className="flex items-center">
        <Checkbox
          defaultChecked={isChecked}
          onChange={(e) => onViewListChange(e.target.checked)}
        />
        <p className="-ml-1">Show Task List</p>
      </div>
    </div>
  );
};

const getStartEndDateForProject = (tasks, projectId) => {
  const projectTasks = tasks.filter((t) => t.project === projectId);
  let start = projectTasks[0].start;
  let end = projectTasks[0].end;

  for (let i = 0; i < projectTasks.length; i++) {
    const task = projectTasks[i];
    if (start.getTime() > task.start.getTime()) {
      start = task.start;
    }
    if (end.getTime() < task.end.getTime()) {
      end = task.end;
    }
  }
  return [start, end];
};

const GanttView = ({ tasks, setTasks, extraBtns }) => {
  const [isChecked, setIsChecked] = useState(true);
  const [view, setView] = useState(ViewMode.Day);
  let columnWidth = 65;
  if (view === ViewMode.Year) {
    columnWidth = 350;
  } else if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }
  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    console.log("On expander click Id:" + task.id);
  };
  const handleTaskChange = (task) => {
    console.log("On date change Id:" + task.id);
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project =
        newTasks[newTasks.findIndex((t) => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map((t) =>
          t.id === task.project ? changedProject : t
        );
      }
    }
    setTasks(newTasks);
  };
  const handleTaskDelete = (task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };
  const handleProgressChange = async (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    console.log("On progress change Id:" + task.id);
  };
  const handleDblClick = (task) => {
    alert("On Double Click event Id:" + task.id);
  };

  const handleClick = (task) => {
    console.log("On Click event Id:" + task.id);
  };

  const handleSelect = (task, isSelected) => {
    console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };
  return (
    <div>
      <ViewSwitcher
        onViewModeChange={(viewMode) => setView(viewMode)}
        onViewListChange={setIsChecked}
        isChecked={isChecked}
        extraBtns={extraBtns}
      />
      <Gantt
        viewMode={view}
        tasks={tasks}
        fontFamily={Inter}
        onExpanderClick={handleExpanderClick}
        onDateChange={handleTaskChange}
        onDelete={handleTaskDelete}
        onProgressChange={handleProgressChange}
        onDoubleClick={handleDblClick}
        onClick={handleClick}
        onSelect={handleSelect}
        columnWidth={columnWidth}
        listCellWidth={isChecked ? "155px" : ""}
      />
    </div>
  );
};

export default GanttView;
