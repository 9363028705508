import { useState } from "react";
import { Checkbox } from "@mui/material";
import { Button, message, Radio } from "antd";
import { ThemeInput } from "components/components";

const ProjectSettings = () => {
  const [showFields, setShowFields] = useState(false);
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    setShowFields(e.target.checked);
  };
  const onChangeRadio = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        <div className="flex items-center">
          <Checkbox onChange={onChange} />
          <p>Send Reminder</p>
        </div>
        {showFields && (
          <div>
            <p className="mt-2">Send Reminder To</p>
            <Radio.Group
              className="my-3"
              onChange={onChangeRadio}
              value={value}
            >
              <Radio value={1}>Administrators</Radio>
              <Radio value={2}>Project Members</Radio>
              <Radio value={3}>All</Radio>
            </Radio.Group>
            <div className="flex items-center">
              <ThemeInput placeholder={"Remind before"} />
              <p>Day(s)</p>
            </div>
            <div>
              <Button
                type="primary"
                className="mt-6"
                onClick={() => message.success("Successfully saved settings")}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectSettings;
