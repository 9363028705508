import {
  archive_icon,
  client_portal_icon,
  delete_icon,
  pdf_icon,
  plus_blue_icon,
  settings_blue_icon,
} from "assets/icons/icons";

const items = [
  {
    key: "1",
    label: (
      <div className="flex items-center pt-2 pr-5">
        <img src={pdf_icon} alt="" className="mr-2" width={20} />
        <p>Send Email</p>
      </div>
    ),
  },
  {
    key: "2",
    label: (
      <div className="flex items-center pt-2 pr-5">
        <img src={client_portal_icon} alt="" className="mr-2" width={20} />
        <p>Schedule</p>
      </div>
    ),
  },
  {
    key: "3",
    label: (
      <div className="flex items-center pt-2 pr-5 pb-1">
        <img src={pdf_icon} alt="" className="mr-2" width={20} />
        <p>View PDF</p>
      </div>
    ),
  },
  {
    key: "4",
    label: (
      <div className="flex items-center pt-2 pr-5 pb-1">
        <img src={pdf_icon} alt="" className="mr-2" width={20} />
        <p>Print PDF</p>
      </div>
    ),
  },
  {
    key: "5",
    label: (
      <div className="flex items-center pt-2 pr-5 pb-1">
        <img src={settings_blue_icon} alt="" className="mr-2" width={20} />
        <p>Download</p>
      </div>
    ),
  },
  {
    key: "6",
    label: (
      <div className="flex items-center pt-2 pr-5 pb-1">
        <img src={settings_blue_icon} alt="" className="mr-2" width={20} />
        <p>Apply Credit</p>
      </div>
    ),
  },
  {
    key: "13",
    label: (
      <div className="flex items-center pt-2 pr-5 pb-1">
        <img src={settings_blue_icon} alt="" className="mr-2" width={20} />
        <p>Client Portal</p>
      </div>
    ),
  },
  {
    key: "9",
    label: (
      <div className="flex items-center pt-2 pr-5 border-t-[1px] border-[rgba(0,0,0,.1)]">
        <img src={plus_blue_icon} alt="" className="mr-2" width={20} />
        <p>Clone to Credit</p>
      </div>
    ),
  },
  {
    key: "10",
    label: (
      <div className="flex items-center pt-2 pr-5 pb-1">
        <img src={plus_blue_icon} alt="" className="mr-2" width={20} />
        <p>Clone to Other</p>
      </div>
    ),
  },
  {
    key: "11",
    label: (
      <div className="flex items-center pt-3 pr-5 border-t-[1px] border-[rgba(0,0,0,.1)]">
        <img src={archive_icon} alt="" className="mr-2" width={20} />
        <p>Archive</p>
      </div>
    ),
  },
  {
    key: "12",
    label: (
      <div className="flex items-center py-2 pr-5">
        <img src={delete_icon} alt="" className="mr-2" width={15} />
        <p>Delete</p>
      </div>
    ),
  },
];

export default items;
