const ProjectsData = [
  {
    key: 1,
    id: `01`,
    project: "Support lead",
    assignee: `Quinn Flynn`,
    start_date: "03-12-2023",
    due_date: "03-15-2023",
    status: "Open",
    task_progress: "20%",
  },
  {
    key: 2,
    id: `01`,
    project: "Support lead",
    assignee: `Quinn Flynn`,
    start_date: "03-12-2023",
    due_date: "03-15-2023",
    status: "Planning",
    task_progress: "20%",
  },
  {
    key: 3,
    id: `01`,
    project: "Support lead",
    assignee: `Quinn Flynn`,
    start_date: "03-12-2023",
    due_date: "03-15-2023",
    status: "Backlog",
    task_progress: "20%",
  },
  {
    key: 4,
    id: `01`,
    project: "Support lead",
    assignee: `Quinn Flynn`,
    start_date: "03-12-2023",
    due_date: "03-15-2023",
    status: "Complete",
    task_progress: "20%",
  },
  {
    key: 5,
    id: `01`,
    project: "Support lead",
    assignee: `Quinn Flynn`,
    start_date: "03-12-2023",
    due_date: "03-15-2023",
    status: "Working",
    task_progress: "20%",
  },
  {
    key: 6,
    id: `01`,
    project: "Support lead",
    assignee: `Quinn Flynn`,
    start_date: "03-12-2023",
    due_date: "03-15-2023",
    status: "Open",
    task_progress: "20%",
  },
  {
    key: 7,
    id: `01`,
    project: "Support lead",
    assignee: `Quinn Flynn`,
    start_date: "03-12-2023",
    due_date: "03-15-2023",
    status: "Planning",
    task_progress: "20%",
  },
  {
    key: 8,
    id: `01`,
    project: "Support lead",
    assignee: `Quinn Flynn`,
    start_date: "03-12-2023",
    due_date: "03-15-2023",
    status: "Backlog",
    task_progress: "20%",
  },
  {
    key: 9,
    id: `01`,
    project: "Support lead",
    assignee: `Quinn Flynn`,
    start_date: "03-12-2023",
    due_date: "03-15-2023",
    status: "Complete",
    task_progress: "20%",
  },
  {
    key: 10,
    id: `01`,
    project: "Support lead",
    assignee: `Quinn Flynn`,
    start_date: "03-12-2023",
    due_date: "03-15-2023",
    status: "Working",
    task_progress: "20%",
  },
  {
    key: 11,
    id: `01`,
    project: "Support lead",
    assignee: `Quinn Flynn`,
    start_date: "03-12-2023",
    due_date: "03-15-2023",
    status: "Open",
    task_progress: "20%",
  },
  {
    key: 12,
    id: `01`,
    project: "Support lead",
    assignee: `Quinn Flynn`,
    start_date: "03-12-2023",
    due_date: "03-15-2023",
    status: "Planning",
    task_progress: "20%",
  },
  {
    key: 13,
    id: `01`,
    project: "Support lead",
    assignee: `Quinn Flynn`,
    start_date: "03-12-2023",
    due_date: "03-15-2023",
    status: "Backlog",
    task_progress: "20%",
  },
  {
    key: 14,
    id: `01`,
    project: "Support lead",
    assignee: `Quinn Flynn`,
    start_date: "03-12-2023",
    due_date: "03-15-2023",
    status: "Complete",
    task_progress: "20%",
  },
  {
    key: 15,
    id: `01`,
    project: "Support lead",
    assignee: `Quinn Flynn`,
    start_date: "03-12-2023",
    due_date: "03-15-2023",
    status: "Working",
    task_progress: "20%",
  },
  {
    key: 16,
    id: `01`,
    project: "Support lead",
    assignee: `Quinn Flynn`,
    start_date: "03-12-2023",
    due_date: "03-15-2023",
    status: "Open",
    task_progress: "20%",
  },
  {
    key: 17,
    id: `01`,
    project: "Support lead",
    assignee: `Quinn Flynn`,
    start_date: "03-12-2023",
    due_date: "03-15-2023",
    status: "Planning",
    task_progress: "20%",
  },
  {
    key: 18,
    id: `01`,
    project: "Support lead",
    assignee: `Quinn Flynn`,
    start_date: "03-12-2023",
    due_date: "03-15-2023",
    status: "Backlog",
    task_progress: "20%",
  },
  {
    key: 19,
    id: `01`,
    project: "Support lead",
    assignee: `Quinn Flynn`,
    start_date: "03-12-2023",
    due_date: "03-15-2023",
    status: "Complete",
    task_progress: "20%",
  },
  {
    key: 20,
    id: `01`,
    project: "Support lead",
    assignee: `Quinn Flynn`,
    start_date: "03-12-2023",
    due_date: "03-15-2023",
    status: "Working",
    task_progress: "20%",
  },
];

const ProjectsMobData = [
  {
    key: 1,
    title: `Support lead`,
    id: "Quinn Flynn",
    status: `Complete`,
  },
  {
    key: 2,
    title: `Integration Specialist`,
    id: "Rhona Davidson",
    status: `Working on`,
  },
  {
    key: 3,
    title: `Support Engineer`,
    id: "Sakuura Yamamto",
    status: `Backlog`,
  },
  {
    key: 11,
    title: `Data Coordinator`,
    id: "Serge Baldwin",
    status: `Open`,
  },
  {
    key: 4,
    title: `Regional Director`,
    id: "Shad Decker",
    status: `Complete`,
  },
  {
    key: 5,
    title: `Regional Marketing`,
    id: "Shou ltous",
    status: `Working on`,
  },
  {
    key: 6,
    title: `Developer`,
    id: "Thor walton",
    status: `Backlog`,
  },
  {
    key: 7,
    title: `Regional Director`,
    id: "Shad Decker",
    status: `Complete`,
  },
  {
    key: 8,
    title: `Regional Marketing`,
    id: "Shou ltous",
    status: `Working on`,
  },
  {
    key: 9,
    title: `Developer`,
    id: "Thor walton",
    status: `Backlog`,
  },
];

export { ProjectsData, ProjectsMobData };
