import { faCheck, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "antd";
import React from "react";

const columns2 = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    className: "min-width-column2",

    render: (text) => (
      <div className="flex">
        <FontAwesomeIcon
          icon={faCheck}
          color="#E43958"
          fontSize={12}
          className="mt-1 mr-2"
        />{" "}
        {text}
      </div>
    ),
  },

  {
    title: "Progress",
    dataIndex: "Progress",
    key: "Progress",
    render: (text) => (
      <div className="">
        {text ? (
          <div class="relative pt-1">
            <div class="overflow-hidden h-1 mb-4 text-xs flex rounded bg-emerald-200">
              <div
                style={{ width: "50%" }}
                class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-sky-500"
              ></div>
              <div
                style={{ width: "50%" }}
                class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"
              ></div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    ),
  },

  {
    title: "Due Date",
    dataIndex: "dueDate",
    key: "dueDate",
    className: "min-width-100",

    render: (text) => <div className="text-red-500">{text}</div>,
  },
  {
    title: "action",
    dataIndex: "action",
    key: "action",
    render: (text) => (
      <div>
        {text && (
          <FontAwesomeIcon icon={faCircleCheck} color="#B4BCCA" fontSize={18} />
        )}
      </div>
    ),
  },
];
const data2 = [
  {
    title: "Approve materials",
    Progress: "yes",
    dueDate: "20 d",
    action: "action",
  },
  {
    title: "Approve materials",
    Progress: "yes",
    dueDate: "20 d",
    action: "action",
  },
  {
    title: "Approve materials",
    Progress: "yes",
    dueDate: "20 d",
    action: "action",
  },
];

const MyTasks = () => {
  return (
    <div className="shadow bg-white rounded-md p-3 h-[380px] overflow-auto">
      <span class="mb-2 text-[12px] text-[#000] font-medium">My tasks</span>
      <Table columns={columns2} dataSource={data2} pagination={false} />
    </div>
  );
};

export default MyTasks;
