import ExpensesDesktop from "./expensesDesktop/expensesDesktop";
import ExpensesMobile from "./expensesMobile/expensesMobile";

const Expenses = () => {
  return (
    <>
      <div className="hidden xl:block">
        <ExpensesDesktop />
      </div>
      <div className="block xl:hidden">
        <ExpensesMobile />
      </div>
    </>
  );
};

export default Expenses;
