import { useState } from "react";
import { Button, Modal, Upload } from "antd";
import { Container, ThemeInput } from "components/components";
import { PlusOutlined } from "@ant-design/icons";
import { Editor } from "react-draft-wysiwyg";
import { useParams } from "react-router-dom";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const Support = () => {
  const { type } = useParams();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  return (
    <Container client={type === "client" ? true : false} active={"Support"}>
      <div
        className={`flex justify-center overflow-y-scroll ${
          type === "client"
            ? "h-screen"
            : "h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh]"
        } scrol`}
      >
        <div className="py-8 w-3/4">
          <p className="text-[28px] pb-8 font-extrabold">Contact Support</p>
          <div className="shadow-md mb-5 rounded-lg px-8 py-6">
            <p className="text-lg font-semibold">Submit your request</p>
            <p className="text-sm opacity-70 pb-2">
              Your request will be processed and our support staff will get back
              to you in 24 hours.
            </p>
            <ThemeInput placeholder={"Name"} />
            <ThemeInput placeholder={"Email"} />
            <ThemeInput placeholder={"Subject"} />
            <p className="text-themeGray-medium300 my-3">Description</p>
            <div
              style={{ minHeight: 192 }}
              className="border-[1px] mb-3 border-[rgba(0,0,0,.1)]"
            >
              <Editor
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName px-3"
              />
            </div>
            <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="example"
                style={{
                  width: "100%",
                }}
                src={previewImage}
              />
            </Modal>
            <div className="flex justify-end mt-3">
              <Button>Cancel</Button>
              <Button type="primary" className="bg-primary ml-4">
                Save
              </Button>
            </div>
          </div>
          <div className="h-1"></div>
        </div>
      </div>
    </Container>
  );
};

export default Support;
