import { useState } from "react";
import { Switch } from "@mui/material";
import { Radio } from "antd";

const Product = () => {
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  return (
    <div className="bg-themeGray-light200 p-3 mt-4 min-h-[73.8vh] desktop:min-h-[79vh] bigDesktop:min-h-[87vh]">
      <div className="bg-white p-3 shadow-md">
        <div className="flex items-center justify-between my-5">
          <div>
            <p className="text-sm">Track Inventory</p>
            <p className="text-themeGray-dark text-xs">
              Display a product stock field and update when invoices are sent
            </p>
          </div>
          <Switch />
        </div>
        <div className="flex items-center justify-between my-5">
          <div>
            <p className="text-sm">Stock Notifications</p>
            <p className="text-themeGray-dark text-xs">
              Send an email when the stock reaches the threshold
            </p>
          </div>
          <Switch defaultChecked />
        </div>
        <div className="flex items-center justify-between my-5">
          <div>
            <p className="text-sm">Show Product Discount</p>
            <p className="text-themeGray-dark text-xs">
              Display a line item discount field
            </p>
          </div>
          <Switch />
        </div>
        <div className="flex items-center justify-between my-5">
          <div>
            <p className="text-sm">Show Product Cost</p>
            <p className="text-themeGray-dark text-xs">
              Display a product cost field to track the markup/profit
            </p>
          </div>
          <Switch />
        </div>
        <div className="flex items-center justify-between my-5">
          <div>
            <p className="text-sm">Show Product Quantity</p>
            <p className="text-themeGray-dark text-xs">
              Display a product quantity field, otherwise default to one
            </p>
          </div>
          <Switch defaultChecked />
        </div>
        <div className="flex items-center justify-between my-5">
          <div>
            <p className="text-sm">Default Quantity</p>
            <p className="text-themeGray-dark text-xs">
              Automatically set the line item quantity to one
            </p>
          </div>
          <Switch defaultChecked />
        </div>
        <div className="flex items-center justify-between my-5">
          <div>
            <p className="text-sm">Show Product Description</p>
            <p className="text-themeGray-dark text-xs">
              Include the description in the product dropdown
            </p>
          </div>
          <Switch defaultChecked />
        </div>
        <div className="flex items-center justify-between my-5">
          <div>
            <p className="text-sm">Fill Products</p>
            <p className="text-themeGray-dark text-xs">
              Selecting a product will automatically fill in the description and
              cost
            </p>
          </div>
          <Switch defaultChecked />
        </div>
        <div className="flex items-center justify-between my-5">
          <div>
            <p className="text-sm">Update Products</p>
            <p className="text-themeGray-dark text-xs">
              Updating an invoice will automatically update the product library
            </p>
          </div>
          <Switch defaultChecked />
        </div>
        <div className="flex items-center justify-between my-5">
          <div>
            <p className="text-sm">Convert Products</p>
            <p className="text-themeGray-dark text-xs">
              Automatically convert product prices using current exchange rate
            </p>
          </div>
          <Switch />
        </div>
        <p className="text-xs mt-4">Convert To</p>
        <Radio.Group className="mt-2" onChange={onChange} value={value}>
          <Radio value={1}>Company Currency</Radio>
          <Radio value={2}>Client Currency</Radio>
        </Radio.Group>
      </div>
    </div>
  );
};

export default Product;
