import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { cross_icon } from "assets/icons/icons";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

const ThemeInput = ({
  placeholder,
  value,
  onChange,
  noPadding,
  type,
  multiline,
  rows,
  width,
  simple,
  textAlign,
  noRequired,
  endIcon,
  clearBtn,
  name,
  onClear,
  password,
  defaultValue,
  disabled,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      {clearBtn && (
        <div className="relative z-10">
          <img
            src={cross_icon}
            className="absolute right-0 top-3 opacity-70 cursor-pointer"
            width={15}
            alt=""
            onClick={onClear}
          />
        </div>
      )}
      {password && (
        <div className="relative z-10">
          {showPassword ? (
            <EyeOutlined
              onClick={() => setShowPassword(false)}
              className="text-xl absolute right-0 top-3 opacity-70
          cursor-pointer"
            />
          ) : (
            <EyeInvisibleOutlined
              onClick={() => setShowPassword(true)}
              className="text-xl absolute right-0 top-3 opacity-70 cursor-pointer"
            />
          )}
        </div>
      )}
      <TextField
        name={name}
        InputProps={{
          endAdornment: endIcon && (
            <InputAdornment position="end">{endIcon}</InputAdornment>
          ),
        }}
        inputProps={{ style: { fontSize: 15, textAlign: textAlign } }}
        InputLabelProps={{ style: { fontSize: 15 } }}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        id="standard-basic"
        label={
          simple ? null : noRequired ? `${placeholder}` : `${placeholder}*`
        }
        variant="standard"
        className={`${width ? width : "w-full"} ${
          !noPadding ? "!mb-3 !mt-1" : noPadding
        }`}
        type={!showPassword && password ? "password" : type}
        multiline={multiline}
        rows={rows}
        disabled={disabled}
      />
    </>
  );
};

export default ThemeInput;
