import {
  InputColorPicker,
  ThemeInput,
  ThemeSelect,
} from "components/components";
import { useState } from "react";

const Settings = () => {
  const [primColor, setPrimColor] = useState("red");
  const [seconColor, setSeconColor] = useState("blue");
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2">
      <div className="bg-white p-3 shadow-md">
        <ThemeInput noRequired placeholder={"Name"} />
        <ThemeSelect label={"Invoice Design"} data={["Clean", "Dirty"]} />
        <ThemeSelect
          label={"Invoice Design"}
          data={["Clean", "Dirty"]}
          className={"!mt-2"}
        />
        <ThemeSelect
          label={"Quote Design"}
          data={["Wave Accounting", "Dirty"]}
          className={"!mt-2"}
        />
        <ThemeSelect
          label={"Credit Design"}
          data={["Visme", "Dirty"]}
          className={"!mt-2"}
        />
        <ThemeSelect
          label={"Purhcase Order Design"}
          data={["Clean", "Dirty"]}
          className={"!mt-2"}
        />
        <ThemeSelect
          label={"Page Layout"}
          data={["Portrait", "Landscape"]}
          className={"!mt-2"}
        />
        <ThemeSelect
          label={"Page Size"}
          data={["A4", "A3"]}
          className={"!mt-2"}
        />
        <ThemeSelect
          label={"Font Size"}
          data={["16", "20"]}
          className={"!mt-2"}
        />
        <div className="flex items-center mt-2">
          <ThemeInput placeholder={"Primary Font"} noRequired />
          <a className="text-bgBlue ml-6 w-28" target="_blank">
            Learn More
          </a>
        </div>
        <ThemeInput placeholder={"Secondary Font"} noRequired />
        <InputColorPicker
          color={primColor}
          setColor={(e) => setPrimColor(e)}
          label={"Primary Color"}
        />
        <InputColorPicker
          color={seconColor}
          setColor={(e) => setSeconColor(e)}
          label={"Secondary Color"}
        />
      </div>
    </div>
  );
};

export default Settings;
