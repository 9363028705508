import {
  faCircleInfo,
  faEnvelopeOpenText,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import React from "react";
import { GoogleOutlined } from "@ant-design/icons";

const Security = () => {
  return (
    <div className="bg-themeGray-light200 p-3 mt-4 min-h-[73.8vh] desktop:h-[79vh] bigDesktop:h-[87vh]">
      <div className="bg-white p-3 shadow-md">
        <div className="flex items-center">
          <p>Two-Factor Authentication</p>
          <div className="ml-3 w-4 h-4 rounded-full bg-red-600"></div>
        </div>
        <div className="text-sm text-gray-600 bg-gray-200 rounded-lg border-2 border-gray-400 my-3 flex items-center p-3">
          <FontAwesomeIcon icon={faCircleInfo} />
          <p className="ml-3">
            Increase your account's security by enabling Two-Factor
            Authentication (2FA)
          </p>
        </div>
        <div className="text-sm bg-red-200 text-red-600 rounded-lg border-2 border-red-400 my-3 flex items-center p-3 justify-between">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faCircleInfo} />
            <p className="ml-3">Email SMTP settings not configured</p>
          </div>
          <Button type="primary">Verify</Button>
        </div>
        <div className="flex mt-6 border-2 border-gray-200 rounded-lg p-5">
          <FontAwesomeIcon
            className="text-lg text-gray-600"
            icon={faEnvelopeOpenText}
          />
          <div className="ml-5">
            <p>Setup Using Email</p>
            <p className="text-sm pt-1 text-gray-600">
              Enabling this feature will send code on your email account{" "}
              <span className="font-semibold">superadmin@example.com</span> fro
              log in.
            </p>
          </div>
        </div>
        <div className="flex mt-3 items-start border-2 border-gray-200 rounded-lg p-5">
          <GoogleOutlined className="text-lg text-gray-600" />
          <div className="ml-5">
            <p>Setup Using Google Authenticator</p>
            <p className="text-sm pt-1 text-gray-600">
              Use the Authenticator app to get free verification codes, even
              when your phone is offline. Available for Adroid and iPhone.
            </p>
            <Button className="mt-3" type="primary">
              Enable
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
