import { Switch } from "@mui/material";
import { ThemeSelect } from "components/components";

const Security = () => {
  const data = [
    "30 Minutes",
    "2 Hours",
    "8 Hours",
    "1 Day",
    "7 Days",
    "30 Days",
  ];
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        <ThemeSelect label={"Select"} data={data} />
        <ThemeSelect label={"Select"} data={data} className={"!mt-2"} />
        <div className="flex items-center justify-between mt-3">
          <p className="pl-2">Require Password with Social Login</p>
          <Switch />
        </div>
      </div>
    </div>
  );
};

export default Security;
