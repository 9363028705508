import { canva_icon } from "assets/icons/icons";
import {
  invoice_design_1,
  invoice_design_3,
  invoice_design_4,
} from "assets/images/images";

const Design = () => {
  const data = [
    invoice_design_1,
    invoice_design_3,
    invoice_design_4,
    invoice_design_1,
    invoice_design_3,
    invoice_design_4,
    invoice_design_1,
    invoice_design_3,
    invoice_design_4,
    invoice_design_1,
    invoice_design_3,
    invoice_design_4,
  ];
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2">
      <div className="bg-white p-3 shadow-md">
        <div className="grid grid-cols-3 gap-7 mb-3">
          {data.map((e, i) => {
            return (
              <div key={i} className="mb-3">
                <img
                  src={e}
                  className="cursor-pointer shadow-md rounded-md overflow-hidden"
                  width={"100%"}
                  height={100}
                />
                <div className="flex items-end mt-3">
                  <img src={canva_icon} width={25} />
                  <p className="ml-2">Canva</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Design;
