import { bitcoin } from "assets/images/images";

const OverView = () => {
  return (
    <div>
      <img src={bitcoin} alt="" width={250} className="m-2" />
      <div className="shadow-md mt-2 mx-2 flex flex-col justify-center px-3 h-[90px]">
        <p className="text-2xl font-medium text-[rgba(0,0,0,.8)]">Gamecube</p>
      </div>
      <div className="shadow-md mt-2 mx-2 flex flex-col pt-2 px-3 h-[90px]">
        <p className="text-sm font-medium mb-1">
          Product items have no direct connection to the product catalog. These
          are items that you can add, for example, in a deal or an invoice from
          without creating a product.
        </p>
      </div>
      <div className="shadow-md mt-2 mx-2 flex flex-col justify-center px-3 h-[120px]">
        <p className="text-base text-themeGray-dark mb-1">Price</p>
        <p className="text-3xl font-medium text-[rgba(0,0,0,.8)]">$792.00</p>
      </div>
      <div className="shadow-md my-2 mx-2 flex items-center py-7 px-3">
        <div className="w-32">
          <p className="text-base font-medium text-[rgba(0,0,0,.8)] mb-1">
            Product name
          </p>
          <p className="text-base font-medium text-[rgba(0,0,0,.8)] mb-1">
            Product code
          </p>
          <p className="text-base font-medium text-[rgba(0,0,0,.8)] mb-1">
            Category
          </p>
          <p className="text-base font-medium text-[rgba(0,0,0,.8)] mb-1">
            Tax
          </p>
          <p className="text-base font-medium text-[rgba(0,0,0,.8)]">
            SKU code
          </p>
        </div>
        <div>
          <p className="text-base text-themeGray-dark mb-1">Hihello</p>
          <p className="text-base text-themeGray-dark mb-1">P652342</p>
          <p className="text-base text-themeGray-dark mb-1">General</p>
          <p className="text-base text-themeGray-dark mb-1">21%</p>
          <p className="text-base text-themeGray-dark">P652342</p>
        </div>
      </div>
    </div>
  );
};

export default OverView;
