import { useState } from "react";
import {
  faChevronDown,
  faChevronUp,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThemeSelect } from "components/components";
import { Editor } from "react-draft-wysiwyg";

const Details = () => {
  const [showHistory, setShowHistory] = useState(true);
  return (
    <div className="p-3">
      <div className="shadow-md p-3 flex">
        <div>
          <p className="text-2xl font-bold text-[rgba(0,0,0,.8)]">
            Cannot create new invoice
          </p>
          <p className="text-lg font-semibold text-[rgba(0,0,0,.8)]">
            Robert Andrews
          </p>
        </div>
        <div className="border-2 ml-5 text-white bg-themeGreen w-16 h-8 flex items-center justify-center rounded-md">
          <p>New</p>
        </div>
      </div>
      <div className="shadow-md p-3 mt-2">
        <div className="flex items-center justify-between">
          <div className="w-[120px]">
            <ThemeSelect label={"Priority"} data={["Low", "Medium", "High"]} />
          </div>
          <div className="w-[120px]">
            <ThemeSelect label={"Status"} data={["Open", "Close"]} />
          </div>
          <div className="w-[120px]">
            <ThemeSelect label={"Type"} data={["Problem", "Administration"]} />
          </div>
        </div>
        <p className="text-sm pt-2">Requested On 16-09-2023 04:13 am</p>
      </div>
      <div className="shadow-md p-3 mt-2">
        <div className="flex items-center">
          <p>Description</p>
          <FontAwesomeIcon icon={faPen} className="ml-4 opacity-50" />
        </div>
        <p className="text-sm pt-2">
          The detailed work report gives a complete overview of all the events
          in the calender and all the tasks that have been marked as done (or a
          time entry under a task marked as done) during the selected period.
          This allows you to analyze and evaluate your team's time usage in
          countless ways
        </p>
      </div>
      <div className="shadow-md p-3 mt-2">
        <div
          className="flex items-center justify-between pb-2 cursor-pointer"
          onClick={() => setShowHistory(!showHistory)}
        >
          <p>View history</p>
          {showHistory ? (
            <FontAwesomeIcon icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </div>
        {showHistory && (
          <>
            <hr />
            <div className="pt-2">
              <div className="flex justify-between text-sm">
                <div className="w-full">
                  <p>Support team</p>
                  <p className="pt-1">15-02-2023</p>
                </div>
                <p>
                  We've sent you a verification email. To verify your account
                  click on the link in the email or enter the 4-digit code here.
                </p>
              </div>
              <div className="flex justify-between text-sm mt-3">
                <div className="w-full">
                  <p>Robert Amdrews</p>
                  <p className="pt-1">19-02-2023</p>
                </div>
                <p>
                  We've sent you a verification email. To verify your account
                  click on the link in the email or enter the 4-digit code here.
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="shadow-md p-3 mt-2">
        <p className="pb-2">Reply</p>
        <div
          style={{ minHeight: 260 }}
          className="border-[1px] border-[rgba(0,0,0,.1)]"
        >
          <Editor
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName px-3"
          />
        </div>
      </div>
    </div>
  );
};

export default Details;
