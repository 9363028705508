import { useState } from "react";
import { Button, Tabs } from "antd";
import Details from "./details/details";
import Logo from "./logo/logo";
import Defaults from "./defaults/defaults";
import ConfigurePayment from "./configurePayment/configurePayment";
import { plus_icon, save_icon } from "assets/icons/icons";
import CreatePayment from "./createPayment/createPayment";

const CompanyDetails = () => {
  const [selectedId, setSelectedId] = useState(0);
  const [configure, setConfigure] = useState(false);
  const [createPayment, setCreatePayment] = useState(false);
  const items = [
    {
      key: "1",
      label: `Details`,
      children: <Details />,
    },
    {
      key: "2",
      label: `Logo`,
      children: <Logo />,
    },
    {
      key: "3",
      label: `Default`,
      children: <Defaults setConfigure={setConfigure} />,
    },
  ];
  const onChange = (key) => {
    setSelectedId(key);
  };
  return (
    <>
      <div className="w-full sticky top-[55px] z-10 pr-5 bg-white">
        <>
          {configure ? (
            <div className="pl-5 flex items-center justify-between w-full">
              <Button
                onClick={() => {
                  setConfigure(false);
                  setCreatePayment(true);
                }}
                className="flex items-center bg-themeGreen text-white hover:!text-white"
              >
                <img src={plus_icon} className="mr-2" />
                Create
              </Button>
              <Button onClick={() => setConfigure(false)}>Back</Button>
            </div>
          ) : (
            createPayment && (
              <div className="pl-5 flex items-center w-full">
                <p>New Payment Term</p>
                <Button
                  onClick={() => {
                    setConfigure(true);
                    setCreatePayment(false);
                  }}
                  className="flex items-center ml-5 mr-3 bg-themeGreen text-white hover:!text-white"
                >
                  <img src={save_icon} className="mr-2" />
                  Save
                </Button>
                <Button
                  onClick={() => {
                    setConfigure(true);
                    setCreatePayment(false);
                  }}
                >
                  Back
                </Button>
              </div>
            )
          )}
        </>
      </div>
      {!configure && !createPayment && (
        <div className="ml-2">
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>
      )}
      {selectedId == 3 && configure && <ConfigurePayment />}
      {selectedId == 3 && createPayment && <CreatePayment />}
    </>
  );
};

export default CompanyDetails;
