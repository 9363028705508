import { useState } from "react";
import { Button, Dropdown } from "antd";
import {
  cancel_icon,
  cross_icon,
  download_icon,
  filter_icon,
  home_icon,
  plus_icon,
  print_icon,
  right_arrow,
  save_icon,
  submenu_icon,
} from "assets/icons/icons";
import { Container, ThemeTable } from "components/components";
import { BasicInfo } from "./basicInfo/basicInfo";
import items from "./optionItems/optionItems";
import Summary from "./summary/summary";
import { LeadsData, ProjectDetails } from "constant/constant";
import Details from "./details/details";
import Invoices from "./invoices/invoices";
import Notes from "./notes/notes";
import Tasks from "./tasks/tasks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChessBoard } from "@fortawesome/free-solid-svg-icons";
import BoardView from "./boardView/boardView";
import { addnewColor, cancelColor, saveColor } from "constant/themeColors";

const LeadsDesktop = () => {
  const [data, setData] = useState(LeadsData);
  const [densityOpt, setDensityOpt] = useState(false);
  const [density, setDensity] = useState("middle");
  const [addModal, setAddModal] = useState("addModalClose");
  const [projectDetails, setProjectDetails] = useState(ProjectDetails);
  const [tableModal, setTableModal] = useState("projectTableModalOpen");
  const [detailsModal, setDetailsModal] = useState("projectDetailsModalClose");
  const [boardModal, setBoardModal] = useState("projectDetailsModalClose");
  const [selectedId, setSelectedId] = useState(0);
  const handleTabs = (i) => {
    let update = [...projectDetails];
    update.map((val, ind) =>
      ind === i ? (update[i].selected = true) : (val.selected = false)
    );
    setProjectDetails(update);
  };
  const handleAddCompany = () => {
    if (detailsModal === "detailsModalClose") {
      setAddModal("addModalOpen");
    } else {
      setDetailsModal("detailsModalClose");
      setAddModal("addModalOpen");
    }
  };
  const handleCompanyDetails = () => {
    if (addModal === "addModalClose") {
      setDetailsModal("detailsModalOpen");
    } else {
      setAddModal("addModalClose");
      setDetailsModal("detailsModalOpen");
    }
  };
  const columns = [
    {
      title: "",
      dataIndex: "client",
      width: "5%",
      render: () => (
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomLeft"
          trigger={["click"]}
        >
          <div className="flex justify-center">
            <img src={submenu_icon} alt="" className="cursor-pointer" />
          </div>
        </Dropdown>
      ),
    },
    {
      title: "Id",
      dataIndex: "id",
      width: "6%",
      sorter: (a, b) => a.id - b.id,
      filters: [
        {
          text: "01",
          value: "01",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.id.includes(value),
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name - b.name,
      width: "14%",
      filters: [
        {
          text: "ABC name",
          value: "ABC name",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.name.includes(value),
    },
    {
      title: "Company",
      dataIndex: "company",
      sorter: (a, b) => a.company - b.company,
      width: "14%",
      filters: [
        {
          text: "ABC Company",
          value: "ABC Company",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.company.includes(value),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email - b.email,
      width: "14%",
      filters: [
        {
          text: "example@gmail.com",
          value: "example@gmail.com",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.email.includes(value),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      width: "11%",
      sorter: (a, b) => a.phone - b.phone,
      filters: [
        {
          text: "02345678",
          value: "02345678",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.phone.includes(value),
    },
    {
      title: "Stage",
      dataIndex: "stage",
      width: "11%",
      sorter: (a, b) => a.stage - b.stage,
      filters: [
        {
          text: "ABC Stage",
          value: "ABC Stage",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.stage.includes(value),
    },
    {
      title: "Source",
      dataIndex: "source",
      width: "11%",
      sorter: (a, b) => a.source - b.source,
      filters: [
        {
          text: "ABC Source",
          value: "ABC Source",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.source.includes(value),
    },
    {
      title: "Last contact",
      dataIndex: "last_contact",
      width: "11%",
      sorter: (a, b) => a.Last_contact - b.Last_contact,
      filters: [
        {
          text: "15-05-2023",
          value: "15-05-2023",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.Last_contact.includes(value),
    },
  ];
  const handleDensity = () => {
    setDensityOpt(!densityOpt);
  };
  const tabs = ["Summary", "Details", "Invoices", "Quotes", "Tasks", "Notes"];
  return (
    <Container onClick={() => densityOpt && setDensityOpt(false)}>
      <div className="flex">
        <div
          className={`${tableModal} w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
        >
          <div className="bg-white" style={{ top: 54 }}>
            <div className="flex items-center justify-between px-5 py-3">
              <div className="flex items-center">
                <p className="font-medium text-sm">Leads</p>
                <img src={home_icon} alt="" className="mr-3 ml-8" />
                <p className="font-medium text-sm">
                  Home
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    Leads
                  </a>
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    All leads
                  </a>
                </p>
              </div>
              <div className="flex items-center">
                <FontAwesomeIcon
                  icon={faChessBoard}
                  className="mr-4 cursor-pointer text-xl"
                  onClick={() => {
                    setTableModal("projectTableModalClose");
                    setBoardModal("projectDetailsModalOpen");
                  }}
                />
                <div>
                  <img
                    src={filter_icon}
                    onClick={handleDensity}
                    alt=""
                    className="mr-4 cursor-pointer"
                  />
                  {densityOpt && (
                    <div className="relative">
                      <div className="text-sm absolute my-2 bg-white px-3 py-1 z-20 top-0 leading-7 rounded-md shadow-lg">
                        <p
                          className="cursor-pointer"
                          onClick={() => {
                            setDensityOpt(false);
                            setDensity("small");
                          }}
                        >
                          Compact
                        </p>
                        <p
                          className="cursor-pointer"
                          onClick={() => {
                            setDensityOpt(false);
                            setDensity("middle");
                          }}
                        >
                          Standard
                        </p>
                        <p
                          className="cursor-pointer"
                          onClick={() => {
                            setDensityOpt(false);
                            setDensity("large");
                          }}
                        >
                          Comfortable
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <img src={download_icon} alt="" className="mr-4" />
                <img src={print_icon} alt="" className="mr-8" />
                <Button
                  onClick={handleAddCompany}
                  className="!text-sm p-0 flex items-center justify-center font-medium hover:!text-white text-white"
                  style={{
                    borderRadius: 5,
                    height: 27,
                    width: 85,
                    backgroundColor: addnewColor,
                  }}
                >
                  Add
                  <img src={plus_icon} alt="" className="ml-2" />
                </Button>
              </div>
            </div>
            <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
          </div>
          <div className="-mt-3">
            <ThemeTable
              onClick={handleCompanyDetails}
              columns={columns}
              data={data}
              setData={(e) => setData(e)}
              density={density}
            />
          </div>
        </div>

        <div
          className={`bg-white grid grid-cols-1 overflow-scroll ${boardModal} h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
        >
          <div className="flex col-span-1 sticky left-0 items-center border-b-2 border-[rgba(0,0,0,.2)] justify-between px-5 py-3">
            <div className="flex items-center">
              <p className="font-medium text-sm">Leads</p>
              <img src={home_icon} alt="" className="mr-3 ml-8" />
              <p className="font-medium text-sm">
                Home
                <span className="text-bgBlue"> / </span>
                <a href="#" className="text-bgBlue">
                  Leads
                </a>
                <span className="text-bgBlue"> / </span>
                <a href="#" className="text-bgBlue">
                  New app webshop
                </a>
              </p>
            </div>
            <Button
              type="primary"
              className="!border-none flex items-center justify-center font-medium hover:!text-white"
              onClick={() => {
                setTableModal("projectTableModalOpen");
                setBoardModal("projectDetailsModalClose");
              }}
            >
              GO BACK
            </Button>
          </div>
          <BoardView
            data={projectDetails}
            setData={(e) => setProjectDetails(e)}
            onClick={handleCompanyDetails}
          />
        </div>

        <div
          className={`bg-white ${addModal} border-l-2 border-[rgba(0,0,0,.1)] overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
        >
          {addModal === "addModalOpen" && (
            <div className="pt-3">
              <div className="flex px-5 items-center justify-between">
                <div>
                  <p className="font-semibold -mb-2 text-lg">New lead</p>
                  <a href="#" className="text-xs text-bgBlue">
                    Company Details
                  </a>
                </div>
                <div className="flex items-center">
                  <Button
                    className="!text-sm p-0 flex items-center justify-center font-medium hover:!text-black"
                    style={{
                      borderRadius: 5,
                      height: 27,
                      width: 85,
                      backgroundColor: cancelColor,
                    }}
                    onClick={() => setAddModal("addModalClose")}
                  >
                    <img src={cancel_icon} alt="" className="mr-1" width={16} />
                    Cancel
                  </Button>
                  <Button
                    className="text-white ml-4 font-medium !text-sm p-0 flex items-center justify-center hover:!text-white"
                    style={{
                      borderRadius: 5,
                      height: 27,
                      width: 85,
                      backgroundColor: saveColor,
                    }}
                    onClick={() => setAddModal("addModalClose")}
                  >
                    <img src={save_icon} alt="" className="mr-1" width={18} />
                    Save
                  </Button>
                </div>
              </div>
              <div className="mt-4 px-5 py-3 bg-themeGray-light200">
                <p className="text-sm pb-1 font-medium">Stage</p>
                <div className="bg-white p-1 rounded-md">
                  {projectDetails.map((e, i) => {
                    return (
                      <Button
                        onClick={() => handleTabs(i)}
                        key={i}
                        className={`h-10 ${
                          i === 0
                            ? "!rounded-tr-none !rounded-br-none"
                            : i === projectDetails.length - 1
                            ? "!rounded-tl-none !rounded-bl-none"
                            : "!rounded-tr-none !rounded-br-none !rounded-tl-none !rounded-bl-none"
                        }`}
                        type={e.selected ? "primary" : "default"}
                      >
                        {e.title}
                      </Button>
                    );
                  })}
                </div>
                <BasicInfo />
              </div>
            </div>
          )}
        </div>

        <div
          className={`bg-white ${detailsModal} overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
        >
          {detailsModal === "detailsModalOpen" && (
            <>
              <div className="flex items-center justify-between w-full pr-5 shadow-md border-b-2 border-[rgba(0,0,0,.2)] bg-white sticky top-0 z-10">
                <div className="h-11 items-center w-[432px] flex">
                  {tabs.map((e, i) => {
                    return (
                      <Button
                        onClick={() => setSelectedId(i)}
                        key={i}
                        className={`${
                          selectedId === i &&
                          "bg-bgBlue text-white hover:!text-white "
                        } !border-none text-sm font-medium !rounded-none h-11 w-[108px]`}
                      >
                        {e}
                      </Button>
                    );
                  })}
                </div>
                <div className="flex items-center">
                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement="bottomLeft"
                    trigger={["click"]}
                  >
                    <div className="flex justify-center">
                      <img
                        src={submenu_icon}
                        alt=""
                        className="cursor-pointer"
                      />
                    </div>
                  </Dropdown>
                  <img
                    src={cross_icon}
                    alt=""
                    className="ml-4 cursor-pointer opacity-70"
                    width={16}
                    onClick={() => setDetailsModal("detailsModalClose")}
                  />
                </div>
              </div>
              {selectedId === 0 && (
                <Summary
                  data={projectDetails}
                  setData={(e) => setProjectDetails(e)}
                />
              )}
              {selectedId === 1 && <Details />}
              {selectedId === 2 && <Invoices />}
              {selectedId === 3 && <Invoices />}
              {selectedId === 4 && <Tasks />}
              {selectedId === 5 && <Notes />}
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default LeadsDesktop;
