import { Col } from "antd";
import React from "react";

const CardText = ({ heading, title, description, subValue }) => {
  return (
    <Col span={24} md={8} lg={6} xl={4}>
      <a
        href="/#"
        class="block max-w-sm h-[110px] p-4 pt-2  bg-white rounded-md shadow "
      >
        <span class="mb-2 text-[12px] text-[#000] font-medium truncate w-[160px] block m-0">
          {heading}
        </span>
        <p class="text-[12px] text-[#494F59] text-center mt-3">{title}</p>
        <p className="text-[#1F2226] text-[23px] text-center hover:text-[#E43958]">
          {description} <sub className="text-[12px]">{subValue}</sub>
        </p>
      </a>
    </Col>
  );
};

export default CardText;
