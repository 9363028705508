import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { domy_profile, media } from "assets/images/images";

const ContactInfo = ({ onContactsInfo }) => {
  return (
    <div>
      <div className="flex items-center p-4 border-b-2 border-[rgba(0,0,0,.2)]">
        <FontAwesomeIcon
          icon={faXmark}
          onClick={onContactsInfo}
          className="cursor-pointer text-lg"
        />
        <p className="ml-3 text-lg font-semibold">Contact Info</p>
      </div>
      <div className="flex justify-center mt-6">
        <div className="relative">
          <img src={domy_profile} alt="" className="rounded-full" />
          <div className="h-3 w-3 absolute right-6 bottom-6 bg-green-500 border-[1px] border-white rounded-full"></div>
        </div>
      </div>
      <div className="text-center mt-3">
        <p>Bernard Langley</p>
        <p className="text-themeGray-medium300 text-sm">
          Hi there! I'm using FuseChat.
        </p>
      </div>
      <div className="p-4 font-medium">
        <p>Media</p>
        <div className="grid grid-cols-4 gap-1 mt-1">
          {[0, 1, 2, 3, 4, 5, 6, 7].map((e, i) => {
            return (
              <img
                className="col-span-1 h-[75px] rounded-md"
                style={{ objectFit: "cover", backgroundRepeat: "no-repeat" }}
                src={media}
                alt=""
              />
            );
          })}
        </div>
        <div className="mt-4">
          <p>Details</p>
          <div className="mt-3">
            <p className="text-sm mb-[2px] text-gray-500">Emails</p>
            <div className="text-sm flex items-center">
              <p className="text-gray-800">bernardlangley@gmail.com</p>
              <p className="text-gray-500">
                <span className="mx-3">•</span>Personal
              </p>
            </div>
            <div className="text-sm flex items-center">
              <p className="text-gray-800">langley.bernard@boilcat.name</p>
              <p className="text-gray-500">
                <span className="mx-3">•</span>Work
              </p>
            </div>
          </div>
          <div className="mt-3">
            <p className="text-sm mb-[2px] text-gray-500">Phone numbers</p>
            <div className="text-sm flex items-center">
              <p className="text-gray-800">893 548 2862</p>
              <p className="text-gray-500">
                <span className="mx-3">•</span>Mobile
              </p>
            </div>
          </div>
          <div className="mt-3">
            <p className="text-sm mb-[2px] text-gray-500">Title</p>
            <div className="text-sm flex items-center">
              <p className="text-gray-800">
                Electromedical Equipment Technician
              </p>
            </div>
          </div>
          <div className="mt-3">
            <p className="text-sm mb-[2px] text-gray-500">Company</p>
            <div className="text-sm flex items-center">
              <p className="text-gray-800">Boilcat</p>
            </div>
          </div>
          <div className="mt-3">
            <p className="text-sm mb-[2px] text-gray-500">Birthday</p>
            <div className="text-sm flex items-center">
              <p className="text-gray-800">05/26/1988</p>
            </div>
          </div>
          <div className="mt-3">
            <p className="text-sm mb-[2px] text-gray-500">Address</p>
            <div className="text-sm flex items-center">
              <p className="text-gray-800">943 Adler Place, Hamilton, South Dakota, PO5592</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
