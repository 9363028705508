import { useState, useEffect, useRef } from "react";
import { Button, Dropdown, Input } from "antd";
import {
  cancel_icon,
  cross_icon,
  download_icon,
  home_icon,
  menu_icon,
  plus_icon,
  print_icon,
  save_icon,
  submenu_icon,
} from "assets/icons/icons";
import { Container } from "components/components";
import items from "./optionItems/optionItems";
import OverView from "./overView/overView";
import { TasksData } from "constant/constant";
import { TaskInfo } from "./taskInfo/taskInfo";
import { Checkbox } from "@mui/material";
import { profile_pic } from "assets/images/images";
import { addnewColor, cancelColor, saveColor } from "constant/themeColors";
import AddTask from "./addTask/addTask";

const TasksDesktop = () => {
  const [densityOpt, setDensityOpt] = useState(false);
  const [addModal, setAddModal] = useState("addModalHandle");
  const [tasks, setTasks] = useState(TasksData);
  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragAnotherColItem = useRef();
  const dragAnotherColOverItem = useRef();
  useEffect(() => {
    setAddModal("addModalCloseHandle");
  }, []);
  const [detailsModal, setDetailsModal] = useState("detailsModalClose");
  const handleAddCompany = () => {
    if (detailsModal === "detailsModalClose") {
      setAddModal("addModalOpen");
    } else {
      setDetailsModal("detailsModalClose");
      setAddModal("addModalOpen");
    }
  };
  const handleCompanyDetails = () => {
    if (addModal === "addModalClose") {
      setDetailsModal("detailsModalOpen");
    } else {
      setAddModal("addModalClose");
      setDetailsModal("detailsModalOpen");
    }
  };
  const onChange = (key) => {
    console.log(key);
  };
  const dragStart = (e, position, i) => {
    dragItem.current = position;
    dragAnotherColItem.current = i;
  };
  const dragEnter = (e, position, i) => {
    dragOverItem.current = position;
    dragAnotherColOverItem.current = i;
  };
  const drop = (e, i) => {
    let update = [...tasks];
    const dragItemContent = update[i].data[dragItem.current];
    update[dragAnotherColItem.current].data.splice(dragItem.current, 1);
    update[dragAnotherColOverItem.current].data.splice(
      dragOverItem.current,
      0,
      dragItemContent
    );
    dragItem.current = null;
    dragOverItem.current = null;
    setTasks(update);
  };
  return (
    <Container onClick={() => densityOpt && setDensityOpt(false)}>
      <div className="flex">
        <div className="w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
          <div className="bg-white" style={{ top: 54 }}>
            <div className="flex items-center justify-between px-5 py-3">
              <div className="flex items-center">
                <p className="font-medium text-sm">Tasks</p>
                <img src={home_icon} alt="" className="mr-3 ml-8" />
                <p className="font-medium text-sm">
                  Home
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    Tasks
                  </a>
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    All tasks
                  </a>
                </p>
              </div>
              <div className="flex items-center">
                <img src={download_icon} alt="" className="mr-4" />
                <img src={print_icon} alt="" className="mr-8" />
                <Button
                  onClick={handleAddCompany}
                  className="!text-sm p-0 flex items-center justify-center font-medium hover:!text-white text-white"
                  style={{
                    borderRadius: 5,
                    height: 27,
                    width: 85,
                    backgroundColor: addnewColor,
                  }}
                >
                  Add
                  <img src={plus_icon} alt="" className="ml-2" />
                </Button>
              </div>
            </div>
            <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
          </div>
          <div className="-mt-3">
            {tasks.map((e, i) => {
              return (
                <div key={i} className="cursor-pointer">
                  <div className="bg-themeGray-light200 p-3 border-b-2 border-[rgba(0,0,0,.2)]">
                    <p>{e.title}</p>
                  </div>
                  {e.data.map((val, ind) => {
                    return (
                      <div
                        key={ind}
                        draggable
                        onDragStart={(e) => dragStart(e, ind, i)}
                        onDragEnter={(e) => dragEnter(e, ind, i)}
                        onDragEnd={() => drop(e, i)}
                        onClick={handleCompanyDetails}
                      >
                        <div className="flex items-center justify-between p-3">
                          <div className="flex items-center">
                            <Checkbox className="!-ml-2" />
                            <img src={profile_pic} alt="" width={50} />
                            <div className="ml-3">
                              <p className="text-sm">{val.title}</p>
                              <p className="text-xs text-themeGray-medium300">
                                {val.name}
                              </p>
                              <div
                                className={`${
                                  val.status === "IMPORTANT"
                                    ? "bg-blue-400"
                                    : val.status === "NEW"
                                    ? "bg-red-400"
                                    : val.status === "LOW" && "bg-orange-400"
                                } text-white w-20 h-4 mt-[2px] flex items-center justify-center rounded-[5px]`}
                              >
                                <p className="text-[10px]">{val.status}</p>
                              </div>
                            </div>
                          </div>
                          <img
                            width={18}
                            className="cursor-pointer"
                            src={menu_icon}
                            alt=""
                          />
                        </div>
                      </div>
                    );
                  })}
                  <div className="m-3">
                    <Input placeholder="Add New" />
                  </div>
                  <div className="m-3">
                    <Button type="primary mr-3">Save</Button>
                    <Button>Cancel</Button>
                  </div>
                  <p className="px-3 pb-3">5 tasks left</p>
                </div>
              );
            })}
          </div>
        </div>

        <div
          className={`bg-white ${addModal} ${
            addModal === "addModalHandle" && "-z-10"
          } border-l-2 border-[rgba(0,0,0,.1)] overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
        >
          <div className="flex z-[5] items-center justify-between w-full pr-5 shadow-md border-b-2 border-[rgba(0,0,0,.2)] bg-white sticky top-0">
            <div className="h-11 items-center w-[432px] flex">
              <div className="pl-3">
                <p
                  className="font-semibold hover:bg-themeGray-light200"
                  contentEditable
                >
                  Type task name
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <Dropdown
                menu={{
                  items,
                }}
                placement="bottomLeft"
                trigger={["click"]}
              >
                <div className="flex justify-center">
                  <img src={submenu_icon} alt="" className="cursor-pointer" />
                </div>
              </Dropdown>
              <img
                src={cross_icon}
                alt=""
                className="ml-4 cursor-pointer opacity-70"
                width={16}
                onClick={() => setDetailsModal("detailsModalClose")}
              />
            </div>
          </div>
          <AddTask />
        </div>

        <div
          className={`bg-white ${detailsModal} overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
        >
          {detailsModal === "detailsModalOpen" && (
            <>
              <div className="flex z-[5] items-center justify-between w-full pr-5 shadow-md border-b-2 border-[rgba(0,0,0,.2)] bg-white sticky top-0">
                <div className="h-11 items-center w-[432px] flex">
                  <div className="pl-3">
                    <p
                      className="font-semibold hover:bg-themeGray-light200"
                      contentEditable
                    >
                      #23: Support tickets list doesn't support commas
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement="bottomLeft"
                    trigger={["click"]}
                  >
                    <div className="flex justify-center">
                      <img
                        src={submenu_icon}
                        alt=""
                        className="cursor-pointer"
                      />
                    </div>
                  </Dropdown>
                  <img
                    src={cross_icon}
                    alt=""
                    className="ml-4 cursor-pointer opacity-70"
                    width={16}
                    onClick={() => setDetailsModal("detailsModalClose")}
                  />
                </div>
              </div>
              <OverView />
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default TasksDesktop;
