const QuotesData = [
  {
    key: 1,
    id: `0127`,
    client: `Jacobi-Windler`,
    amount: "$2000",
    date: "15-05-2023",
    status: "Paid",
  },
  {
    key: 2,
    id: `0127`,
    client: `Jacobi-Windler`,
    amount: "$2000",
    date: "15-05-2023",
    status: "Sent",
  },
  {
    key: 3,
    id: `0127`,
    client: `Jacobi-Windler`,
    amount: "$2000",
    date: "15-05-2023",
    status: "Draft",
  },
  {
    key: 4,
    id: `0127`,
    client: `Jacobi-Windler`,
    amount: "$2000",
    date: "15-05-2023",
    status: "Paid",
  },
  {
    key: 5,
    id: `0127`,
    client: `Jacobi-Windler`,
    amount: "$2000",
    date: "15-05-2023",
    status: "Sent",
  },
  {
    key: 6,
    id: `0127`,
    client: `Jacobi-Windler`,
    amount: "$2000",
    date: "15-05-2023",
    status: "Draft",
  },
  {
    key: 7,
    id: `0127`,
    client: `Jacobi-Windler`,
    amount: "$2000",
    date: "15-05-2023",
    status: "Paid",
  },
  {
    key: 8,
    id: `0127`,
    client: `Jacobi-Windler`,
    amount: "$2000",
    date: "15-05-2023",
    status: "Sent",
  },
  {
    key: 9,
    id: `0127`,
    client: `Jacobi-Windler`,
    amount: "$2000",
    date: "15-05-2023",
    status: "Draft",
  },
  {
    key: 10,
    id: `0127`,
    client: `Jacobi-Windler`,
    amount: "$2000",
    date: "15-05-2023",
    status: "Paid",
  },
  {
    key: 11,
    id: `0127`,
    client: `Jacobi-Windler`,
    amount: "$2000",
    date: "15-05-2023",
    status: "Sent",
  },
  {
    key: 12,
    id: `0127`,
    client: `Jacobi-Windler`,
    amount: "$2000",
    date: "15-05-2023",
    status: "Draft",
  },
  {
    key: 13,
    id: `0127`,
    client: `Jacobi-Windler`,
    amount: "$2000",
    date: "15-05-2023",
    status: "Paid",
  },
  {
    key: 14,
    id: `0127`,
    client: `Jacobi-Windler`,
    amount: "$2000",
    date: "15-05-2023",
    status: "Sent",
  },
  {
    key: 15,
    id: `0127`,
    client: `Jacobi-Windler`,
    amount: "$2000",
    date: "15-05-2023",
    status: "Draft",
  },
  {
    key: 16,
    id: `0127`,
    client: `Jacobi-Windler`,
    amount: "$2000",
    date: "15-05-2023",
    status: "Paid",
  },
  {
    key: 17,
    id: `0127`,
    client: `Jacobi-Windler`,
    amount: "$2000",
    date: "15-05-2023",
    status: "Sent",
  },
  {
    key: 18,
    id: `0127`,
    client: `Jacobi-Windler`,
    amount: "$2000",
    date: "15-05-2023",
    status: "Draft",
  },
  {
    key: 19,
    id: `0127`,
    client: `Jacobi-Windler`,
    amount: "$2000",
    date: "15-05-2023",
    status: "Paid",
  },
  {
    key: 20,
    id: `0127`,
    client: `Jacobi-Windler`,
    amount: "$2000",
    date: "15-05-2023",
    status: "Sent",
  },
];

const QuotesMobData = [];

export { QuotesData, QuotesMobData };
