import { Button, Checkbox, Divider, Input, Menu, Space } from "antd";
import React from "react";
import { useState } from "react";
import { FilterDropdown } from "components/components";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";

const FilterCheckboxDropdown = ({
  option,
  buttonText,
  title,
  subMenu,
  search,
}) => {
  const [checkedList, setCheckedList] = useState([]);
  const [visible, setVisible] = useState(false); // State to manage visibility of the main menu
  const [visibleSubmenu, setVisibleSubmenu] = useState(false); // State to manage visibility of the submenu

  const checkAll = option.length === checkedList.length;
  const indeterminate =
    checkedList.length > 0 && checkedList.length < option.length;

  const handleMenuClick = (e) => {
    setVisible(true); // Keep the main menu open when interacting with items
    if (e.key === "checkAll") {
      setCheckedList(checkedList.length === option.length ? [] : option);
    } else {
      const newCheckedList = checkedList.includes(e.key)
        ? checkedList.filter((item) => item !== e.key)
        : [...checkedList, e.key];
      setCheckedList(newCheckedList);
    }
  };

  const handleSubmenuClick = () => {
    setVisibleSubmenu(true); // Keep the submenu open when interacting with checkboxes
  };

  const rootSubmenuKeys = ["sub1", "sub2", "sub4"];
  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const items = [
    {
      label: "LeaderShip",
      key: "1",
    },
    {
      label: "Project Manager",
      key: "2",
    },
    {
      label: "LeaderShip",
      key: "3",
    },
  ];
  return (
    <Menu
      mode="inline"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      onClick={handleMenuClick}
      className="mx-2 rounded-[8px] border-none my-1"
      style={{
        width: 150,
      }}
    >
      <Menu.SubMenu
        title={title}
        popupOffset={[-145, 45]}
        visible={visibleSubmenu}
        onTitleClick={handleSubmenuClick}
      >
        {search && (
          <Space className="m-4">
            <Space.Compact size="large" style={{ width: "250px" }}>
              <Input placeholder="Search" />
            </Space.Compact>
          </Space>
        )}
        <Menu.Item key="checkAll">
          <div className="flex justify-between items-center">
            <Checkbox indeterminate={indeterminate} checked={checkAll}>
              Select All
            </Checkbox>
            {subMenu && <FilterDropdown items={items} icon={faUserGroup} />}
          </div>
        </Menu.Item>
        <Divider className="my-1" />

        {option.map((item) => (
          <Menu.Item key={item}>
            <Checkbox checked={checkedList.includes(item)}>{item}</Checkbox>
          </Menu.Item>
        ))}
        <Divider className="my-1" />
        <Button className="my-2 block mx-auto">{buttonText}</Button>
      </Menu.SubMenu>
    </Menu>
  );
};

export default FilterCheckboxDropdown;
