import { Tabs } from "antd";
import ProjectSettings from "./projectSettings/projectSettings";
import StatusSettings from "./statusSettings/statusSettings";
import ProjectCategory from "./projectCategory/projectCategory";

const Project = () => {
  const items = [
    {
      key: "1",
      label: "Project Settings",
      children: <ProjectSettings />,
    },
    {
      key: "2",
      label: "Project Status Settings",
      children: <StatusSettings />,
    },
    {
      key: "3",
      label: "Project Category",
      children: <ProjectCategory />,
    },
  ];
  return (
    <div className="ml-2">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default Project;
