import LeadsDesktop from "./leadsDesktop/leadsDesktop";
import LeadsMobile from "./leadsMobile/leadsMobile";

const Leads = () => {
  return (
    <>
      <div className="hidden xl:block">
        <LeadsDesktop />
      </div>
      <div className="block xl:hidden">
        <LeadsMobile />
      </div>
    </>
  );
};

export default Leads;
