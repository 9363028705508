import { FileDoneOutlined } from "@ant-design/icons";

const Activity = () => {
  return (
    <div className="m-3">
      {[1, 2, 3].map((e, i) => {
        return (
          <div
            key={i}
            className={`${
              i > 0 && "border-t-2 border-[rgba(0,0,0,.2)]"
            } flex items-center py-3`}
          >
            <FileDoneOutlined style={{ fontSize: 28 }} />
            <div className="ml-5 text-sm">
              <p>System created invoice 0024</p>
              <p className="text-sm">19/Jun/2023 9:02 PM * 127.0.0.1</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Activity;
