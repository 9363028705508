import { Button } from "antd";
import { EditOutlined, LogoutOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";

const Summary = () => {
  return (
    <div className="z-0">
      <div className="shadow-md mt-2 mx-2 p-3 flex">
        <div>
          <p className="text-2xl font-bold text-[rgba(0,0,0,.8)]">
            Lets do international Business BV
          </p>
          <p className="text-lg font-semibold text-[rgba(0,0,0,.8)]">
            Robert Andrews
          </p>
        </div>
        <div className="border-2 border-red-100 ml-5 text-red-600 bg-red-50 w-16 h-8 flex items-center justify-center rounded-md">
          <p>Trial</p>
        </div>
      </div>
      <div className="shadow-md mt-2 mx-2 p-3 flex items-center justify-between">
        <div>
          <p className="text-sm">Register: 30-03-2023 04:30 am</p>
          <p className="text-sm">Last login at 28-08-2023</p>
          <p className="text-sm">
            Expires on <span className="font-semibold">29-10-2023</span> (1
            month from now)
          </p>
        </div>
        <div className="">
          <Button>Email</Button>
          <Button>Call</Button>
          <Button type="primary ml-3">Login as company</Button>
        </div>
      </div>
      <div className="shadow-md mt-2 px-3 mx-2">
        <div className="flex items-center h-14 border-b-[1px] border-[rgba(0,0,0,.1)]">
          <FontAwesomeIcon icon={faHouse} />
          <p className="font-bold text-sm ml-2">Details</p>
        </div>
        <div className="flex pb-4">
          <div className="pt-3 ml-6 w-[180px]">
            <p className="text-lightBlack text-sm">Company name</p>
            <p className="text-lightBlack text-sm mt-3">Address</p>
            <p className="text-lightBlack text-sm mt-3">Postal Code</p>
            <p className="text-lightBlack text-sm mt-3">City</p>
            <p className="text-lightBlack text-sm mt-3">Province / State</p>
            <p className="text-lightBlack text-sm mt-3">Country</p>
          </div>
          <div className="pt-3">
            <p className="text-lightBlack text-sm">Let do Business</p>
            <p className="text-lightBlack text-sm mt-3">My street 16</p>
            <p className="text-lightBlack text-sm mt-3 mb-2">8535 OK</p>
            <p className="text-lightBlack text-sm mt-3 mb-2">Amsterdam</p>
            <p className="text-lightBlack text-sm mt-3 mb-2">Zuid holland</p>
            <p className="text-lightBlack text-sm mt-3 mb-2">Netherlands</p>
          </div>
        </div>
      </div>
      <div className="shadow-md mt-2 mb-5 px-3 mx-2">
        <div className="flex items-center h-14 border-b-[1px] border-[rgba(0,0,0,.1)]">
          <FontAwesomeIcon icon={faHouse} />
          <p className="font-bold text-sm ml-2">Contact Info</p>
        </div>
        <div className="flex pb-4 items-center">
          <div className="pt-3 ml-6 w-[180px]">
            <p className="text-sm">Email</p>
            <p className="text-sm mt-3">Website</p>
            <p className="text-sm mt-3">Phone</p>
            <p className="text-sm mt-3">Fax machine</p>
            <p className="text-sm mt-3">Coc</p>
            <p className="text-sm mt-3">Vat number</p>
            <p className="text-sm mt-3">Industry</p>
            <p className="text-sm mt-3">Sector</p>
            <p className="text-sm mt-3">Size</p>
          </div>
          <div className="pt-3">
            <p className="text-sm">test@gmail.nl</p>
            <p className="text-sm mt-3">www.google.nl</p>
            <p className="text-sm mt-3">+31 020-32647874</p>
            <p className="text-sm mt-3">+31 020-5741126</p>
            <p className="text-sm mt-3">123345356</p>
            <p className="text-sm mt-3">142342435356</p>
            <p className="text-sm mt-3">Media</p>
            <p className="text-sm mt-3">Marketing</p>
            <p className="text-sm mt-3">25</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
