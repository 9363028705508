import { Button } from "antd";
import { Container, ThemeSelect, ThemeTable } from "components/components";
import Chart from "react-apexcharts";

const TimeTracking = () => {
  const columns = [
    {
      title: "Task",
      dataIndex: "task",
    },
    {
      title: "Timesheet Tags",
      dataIndex: "tags",
    },
    {
      title: "Start Time",
      dataIndex: "start",
    },
    {
      title: "End Time",
      dataIndex: "end",
    },
    {
      title: "Note",
      dataIndex: "note",
    },
    {
      title: "Related",
      dataIndex: "related",
    },
    {
      title: "Time (h)",
      dataIndex: "h",
    },
    {
      title: "Time (decimal)",
      dataIndex: "decimal",
    },
  ];
  const data = [];
  // const data = [
  //   {
  //     task:"",
  //     tags:"",
  //     start:"",
  //     end:"",
  //     note:"",
  //     related:"",
  //     h:"",
  //     decimal:"",
  //   }
  // ]
  const options = {
    chart: {
      id: "apexchart-example",
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
    },
  };
  const series = [
    {
      name: "series-1",
      data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
    },
  ];
  return (
    <Container>
      <div className="flex">
        <div className="w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
          <div className="m-5">
            {/* <p className="text-[28px] font-extrabold">Timesheets</p> */}
            <div className="mt-3 mb-6 grid grid-cols-5 gap-5">
              <div className="rounded-md shadow-md px-3 py-2 text-sm">
                <p className="text-themeGreen">Total Logged Time</p>
                <p className="text-primary">00:00</p>
              </div>
              <div className="rounded-md shadow-md px-3 py-2 text-sm">
                <p className="text-bgBlue">Last Month Logged Time</p>
                <p className="text-primary">00:00</p>
              </div>
              <div className="rounded-md shadow-md px-3 py-2 text-sm">
                <p className="text-themeGreen">This Month Logged Time</p>
                <p className="text-primary">00:00</p>
              </div>
              <div className="rounded-md shadow-md px-3 py-2 text-sm">
                <p className="text-bgBlue">Last Week Logged Time</p>
                <p className="text-primary">00:00</p>
              </div>
              <div className="rounded-md shadow-md px-3 py-2 text-sm">
                <p className="text-themeGreen">This Week Logged Time</p>
                <p className="text-primary">00:00</p>
              </div>
            </div>
            <Chart
              options={options}
              series={series}
              type="bar"
              width={"100%"}
              height={500}
            />
            <div className="grid grid-cols-4 gap-5 w-[60%] mb-6 mt-3">
              <ThemeSelect label={"Select Day"} data={["Today", "Yesterday"]} />
              <ThemeSelect
                label={"Select Customer"}
                data={["Customer 1", "Customer 2"]}
              />
              <ThemeSelect
                label={"Select Project"}
                data={["Project 1", "Project 2"]}
              />
              <Button type="primary w-24 h-9 mt-2">Apply</Button>
            </div>
            <ThemeTable noPagination columns={columns} data={data} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TimeTracking;
