import React from "react";
import { Menu, Dropdown } from "antd";
import { RiArrowDownSFill } from "react-icons/ri";

const CustomMenu = ({ children, name }) => {
  const menu = (
    <Menu mode="vertical" className="custom-dropdown">
      {children}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      className="min-w-[100px] bg-[#fff] border px-2 py-1 flex items-center rounded-md justify-between"
    >
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <span>{name}</span> <RiArrowDownSFill />
      </a>
    </Dropdown>
  );
};

export default CustomMenu;
