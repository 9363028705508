import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input } from "antd";
import { useState } from "react";

const ActivityTabs = () => {
  const [clientData, setClientData] = useState([
    "Consultation",
    "Copywriting",
    "Design",
  ]);
  const [adminData, setAdminData] = useState([
    "Admin",
    "Holiday",
    "Internal meeting",
  ]);
  const onChange = (val, i) => {
    let update = [...clientData];
    update[i] = val.target.value;
    setClientData(update);
  };
  const handleAddStatus = () => {
    let update = [...clientData];
    update.push("Write text");
    setClientData(update);
  };
  const handleDeleteStatus = (i) => {
    let update = [...clientData];
    update.splice(i, 1);
    setClientData(update);
  };
  const onChangeAdmin = (val, i) => {
    let update = [...adminData];
    update[i] = val.target.value;
    setAdminData(update);
  };
  const handleAddStatusAdmin = () => {
    let update = [...adminData];
    update.push("Write text");
    setAdminData(update);
  };
  const handleDeleteStatusAdmin = (i) => {
    let update = [...adminData];
    update.splice(i, 1);
    setAdminData(update);
  };
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2">
      <div className="bg-white p-3 shadow-md">
        <p className="text-bgBlue mt-3">1. Billable / client work</p>
        <div className="border-y-2 mt-2 border-[rgba(0,0,0,.2)] py-3 px-2">
          <p className="font-medium">Activity type</p>
        </div>
        {clientData.map((e, i) => {
          return (
            <div
              key={i}
              className="py-3 px-2 border-b-[1px] flex items-center justify-between border-[rgba(0,0,0,.1)]"
            >
              <Input
                value={e}
                className="w-full mr-4"
                onChange={(val) => onChange(val, i)}
              />
              <FontAwesomeIcon
                icon={faTrashCan}
                className="opacity-50 cursor-pointer mr-3 text-lg"
                onClick={() => handleDeleteStatus(i)}
              />
            </div>
          );
        })}
      </div>
      <Button type="primary w-full my-4 h-9" onClick={handleAddStatus}>
        New Activity
      </Button>
      <div className="bg-white p-3 shadow-md">
        <p className="text-bgBlue mt-7">2. Non-billable / admin</p>
        <div className="border-y-2 mt-2 border-[rgba(0,0,0,.2)] py-3 px-2">
          <p className="font-medium">Activity type</p>
        </div>
        {adminData.map((e, i) => {
          return (
            <div
              key={i}
              className="py-3 px-2 border-b-[1px] flex items-center justify-between border-[rgba(0,0,0,.1)]"
            >
              <Input
                value={e}
                className="w-full mr-4"
                onChange={(val) => onChangeAdmin(val, i)}
              />
              <FontAwesomeIcon
                icon={faTrashCan}
                className="opacity-50 cursor-pointer mr-3 text-lg"
                onClick={() => handleDeleteStatusAdmin(i)}
              />
            </div>
          );
        })}
      </div>
      <Button type="primary w-full my-4 h-9" onClick={handleAddStatusAdmin}>
        New Activity
      </Button>
    </div>
  );
};

export default ActivityTabs;
