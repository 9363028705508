const PackagesData = [
  {
    key: 1,
    id: `1`,
    title: "Professional",
    trial_days: `30`,
    total_sale: `8 times`,
    monthly_price: `$25.00`,
    tax: `21%`,
    recommended: `Yes`,
    status: `Active`,
  },
  {
    key: 2,
    id: `2`,
    title: "Standard",
    trial_days: `30`,
    total_sale: `3 times`,
    monthly_price: `$10.00`,
    tax: `21%`,
    recommended: `Yes`,
    status: `Active`,
  },
  {
    key: 3,
    id: `3`,
    title: "Basic",
    trial_days: `30`,
    total_sale: `0 times`,
    monthly_price: `$0.00`,
    tax: `21%`,
    recommended: `No`,
    status: `Active`,
  },
];

export default PackagesData;
