import React, { useState } from "react";
import { Button, Radio, Input } from "antd";
import { ThemeInput } from "components/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Checkbox } from "@mui/material";

const Details = () => {
  const [value, setValue] = useState(1);
  const onChangeRadio = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const [clientData, setClientData] = useState([
    "20 Staff members",
    "50 Contacts",
    "100 invoice send monthly",
  ]);
  const onChange = (val, i) => {
    let update = [...clientData];
    update[i] = val.target.value;
    setClientData(update);
  };
  const handleAddStatus = () => {
    let update = [...clientData];
    update.push("Write text");
    setClientData(update);
  };
  const handleDeleteStatus = (i) => {
    let update = [...clientData];
    update.splice(i, 1);
    setClientData(update);
  };
  return (
    <div>
      <div className="shadow-md mt-2 mx-2 p-3 flex items-center justify-between">
        <Radio.Group onChange={onChangeRadio} value={value} className="flex">
          <Radio
            value={1}
            className="bg-themeGray-light200 h-9 w-32 flex justify-center items-center border-[1px] rounded-md border-[rgba(0,0,0,.1)]"
          >
            Paid plan
          </Radio>
          <Radio
            value={2}
            className="bg-themeGray-light200 h-9 w-32 flex justify-center items-center border-[1px] rounded-md border-[rgba(0,0,0,.1)]"
          >
            Free plan
          </Radio>
        </Radio.Group>
        <Button type="primary" className="h-9">
          Add
        </Button>
      </div>
      <div className="shadow-md mt-2 mx-2 p-3">
        <ThemeInput placeholder={"Name"} />
        <ThemeInput placeholder={"Type"} />
        <ThemeInput placeholder={"Monthly Price"} />
        <ThemeInput placeholder={"Yearly Price"} />
        <ThemeInput placeholder={"Lifetime Price"} />
        <ThemeInput placeholder={"Time period (Days)"} />
      </div>
      <div className="shadow-md mt-2 mx-2 p-3 flex items-center">
        <div className="flex items-center w-1/2">
          <Checkbox />
          <p>Recommended ?</p>
        </div>
        <div className="flex items-center">
          <Checkbox />
          <p>Published ?</p>
        </div>
      </div>
      <div className="shadow-md mt-2 mx-2 p-3">
        {clientData.map((e, i) => {
          return (
            <div
              key={i}
              className="py-3 px-2 border-b-[1px] flex items-center justify-between border-[rgba(0,0,0,.1)]"
            >
              <Input
                value={e}
                className="w-full mr-4"
                onChange={(val) => onChange(val, i)}
              />
              <FontAwesomeIcon
                icon={faTrashCan}
                className="opacity-50 cursor-pointer mr-3 text-lg"
                onClick={() => handleDeleteStatus(i)}
              />
            </div>
          );
        })}
        <Button type="primary w-full my-4 h-9" onClick={handleAddStatus}>
          Add new options
        </Button>
      </div>
    </div>
  );
};

export default Details;
