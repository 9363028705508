import { lines } from "assets/icons/icons";
import { tag } from "assets/images/images";

const Preview = () => {
  const detailsData = [
    {
      id: 1,
      product: ".Net Consultancy Services",
      quantity: 49,
      price: 25,
      unit: "Hour(s)",
      vot: 0,
      total: "1,223",
    },
    {
      id: 2,
      product: ".Net Consultancy Services",
      quantity: 49,
      price: 25,
      unit: "Hour(s)",
      vot: 0,
      total: "1,223",
    },
    {
      id: 3,
      product: ".Net Consultancy Services",
      quantity: 49,
      price: 25,
      unit: "Hour(s)",
      vot: 0,
      total: "1,223",
    },
    {
      id: 4,
      product: ".Net Consultancy Services",
      quantity: 49,
      price: 25,
      unit: "Hour(s)",
      vot: 0,
      total: "1,223",
    },
  ];
  return (
    <div className="-mt-3 -ml-2">
      <div className="z-0 relative">
        <img
          src={tag}
          alt=""
          className="absolute -left-8 -top-20"
          width={136.74}
          height={42.55}
        />
      </div>
      <div className="mx-11 mt-11 mb-4">
        <div className="flex justify-between">
          <div>
            <img
              className="ml-6"
              width={35}
              height={26.79}
              src={lines}
              alt=""
            />
            <p className="text-xl mb-3 font-extrabold text-themeBlack">
              ild<span>i</span>esign
            </p>
            <div className="text-sm">
              <p>Ildiesign Digital Agency</p>
              <p className="mt-1">Vat. No. 6877009</p>
              <p className="mt-1">1270 Fidler Drive</p>
              <p className="mt-1">San Antonio, TX 78238</p>
            </div>
          </div>
          <div>
            <p className="text-bgBlue font-bold text-[32px]">INVOICE</p>
            <div className="text-sm mt-1">
              <p># INV-00089</p>
              <p className="mt-1">Date: 07 Mar 2018</p>
              <p className="mt-1">
                Due Date: <span className="font-bold">31 Mar 2018</span>
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6 bg-themeLightBlue py-6 px-8 flex justify-between rounded-md">
          <div>
            <p className="text-sm mb-3 font-bold text-themeBlack">Bill to</p>
            <div className="text-sm">
              <p>Reactial</p>
              <p className="mt-1">Vat. No. 8879090</p>
              <p className="mt-1">11 Stonepat Road</p>
              <p className="mt-1">Clinton (Hunterdon), NJ 08809</p>
            </div>
          </div>
          <div>
            <p className="text-sm mb-3 font-bold text-themeBlack">
              Shipping Address
            </p>
            <div className="text-sm">
              <p>11 Stonepot Road</p>
              <p className="mt-1">Clinton (Hunterdan), NJ 08809</p>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="font-bold text-sm flex h-12 items-center">
            <p className="w-[10%]"></p>
            <p className="w-[30%]"># Services/Product</p>
            <p className="w-[12%] text-center">Quantity</p>
            <p className="w-[12%] text-center">Price</p>
            <p className="w-[12%] text-center">Unit</p>
            <p className="w-[12%] text-center">Vot%</p>
            <p className="w-[12%] text-center">Total</p>
          </div>
          {detailsData.map((e, i) => {
            return (
              <div
                key={i}
                className="text-sm flex h-12 items-center border-t-2 border-[rgba(0,0,0,.2)]"
              >
                <p className="w-[10%] font-bold text-center">{e.id}</p>
                <p className="w-[30%] font-bold">{e.product}</p>
                <p className="w-[12%] text-center">{e.quantity}</p>
                <p className="w-[12%] text-center">{e.price}</p>
                <p className="w-[12%] text-center">{e.unit}</p>
                <p className="w-[12%] text-center">{e.vot}</p>
                <p className="w-[12%] text-center">{e.total}</p>
              </div>
            );
          })}
        </div>
        <div className="flex justify-end">
          <div className="w-2/6 pt-3 border-t-2 flex flex-col items-end border-[rgba(0,0,0,.2)]">
            <div className="font-bold text-sm flex">
              <p>Sub Total($)</p>
              <p className="w-20 text-end">$2.564</p>
            </div>
            <div className="font-bold text-sm flex mt-1">
              <p>Total ($)</p>
              <p className="w-20 text-end">$2.564</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;
