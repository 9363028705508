import React, { useState } from "react";
import { ThemeInput, ThemeSelect } from "components/components";
import { Button, message, Upload } from "antd";
import { settings_white_icon } from "assets/icons/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faFileImport } from "@fortawesome/free-solid-svg-icons";
const props = {
  name: "file",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const ImportExport = () => {
  const [importType, setImportType] = useState("CSV");
  const [exportType, setExportType] = useState("CSV");
  const importFormats = [
    "CSV",
    "JSON",
    "FreshBooks",
    "Invoice2go",
    "Invoicely",
    "Wave Accounting",
    "Zoho",
  ];
  return (
    <div className="bg-themeGray-light200 p-3 mt-4 min-h-[73.8vh] desktop:min-h-[79vh] bigDesktop:min-h-[87vh]">
      <div className="bg-white p-3 shadow-md">
        <ThemeSelect
          label={"Import Format"}
          className={"!mb-2"}
          data={importFormats}
          setValue={setImportType}
          value={importType}
        />
        {
          //   {importType === "CSV" && (
          <>
            <div className="flex items-center">
              <ThemeInput
                placeholder={"Clients"}
                defaultValue={"No File Selected"}
                value={"No File Selected"}
              />
              <Upload {...props} className="ml-5" showUploadList={false}>
                <Button>Select File</Button>
              </Upload>
            </div>
            <div className="flex items-center">
              <ThemeInput
                placeholder={"Invoices"}
                defaultValue={"No File Selected"}
                value={"No File Selected"}
              />
              <Upload {...props} showUploadList={false} className="ml-5">
                <Button>Select File</Button>
              </Upload>
            </div>
            <div className="flex items-center">
              <ThemeInput
                placeholder={"Recurring Invoices"}
                defaultValue={"No File Selected"}
                value={"No File Selected"}
              />
              <Upload {...props} showUploadList={false} className="ml-5">
                <Button>Select File</Button>
              </Upload>
            </div>
            <div className="flex items-center">
              <ThemeInput
                placeholder={"Payments"}
                defaultValue={"No File Selected"}
                value={"No File Selected"}
              />
              <Upload {...props} showUploadList={false} className="ml-5">
                <Button>Select File</Button>
              </Upload>
            </div>
            <div className="flex items-center">
              <ThemeInput
                placeholder={"Products"}
                defaultValue={"No File Selected"}
                value={"No File Selected"}
              />
              <Upload {...props} showUploadList={false} className="ml-5">
                <Button>Select File</Button>
              </Upload>
            </div>
            <div className="flex items-center">
              <ThemeInput
                placeholder={"Vendors"}
                defaultValue={"No File Selected"}
                value={"No File Selected"}
              />
              <Upload {...props} showUploadList={false} className="ml-5">
                <Button>Select File</Button>
              </Upload>
            </div>
            <div className="flex items-center">
              <ThemeInput
                placeholder={"Expenses"}
                defaultValue={"No File Selected"}
                value={"No File Selected"}
              />
              <Upload {...props} showUploadList={false} className="ml-5">
                <Button>Select File</Button>
              </Upload>
            </div>
            <div className="flex items-center">
              <ThemeInput
                placeholder={"Transactions"}
                defaultValue={"No File Selected"}
                value={"No File Selected"}
              />
              <Upload {...props} showUploadList={false} className="ml-5">
                <Button>Select File</Button>
              </Upload>
            </div>
          </>
        }
      </div>
      <Button
        type="primary"
        className="w-full my-4 h-10 flex items-center justify-center"
      >
        <FontAwesomeIcon icon={faFileImport} className="mr-2" />
        IMPORT
      </Button>
      <div className="bg-white p-3 shadow-md">
        <ThemeSelect
          label={"Export Format"}
          className={"!mb-2 !mt-4"}
          data={["CSV", "JSON"]}
          setValue={setExportType}
          value={exportType}
        />
        {
          //   {exportType === "CSV" && (
          <>
            <ThemeSelect
              label={"Select"}
              data={["Activites", "Clients", "Client Contacts"]}
            />
            <ThemeSelect label={"Select"} data={["Created At"]} />
          </>
        }
      </div>
      <Button
        type="primary"
        className="w-full mt-4 h-10 flex items-center justify-center"
      >
        <FontAwesomeIcon icon={faFileExport} className="mr-2" />
        EXPORT
      </Button>
    </div>
  );
};

export default ImportExport;
