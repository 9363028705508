import LoginDesktop from "./loginDesktop/loginDesktop";
import LoginMob from "./loginMob/loginMob";

const Login = () => {
  return (
    <>
      <div className="hidden xl:block">
        <LoginDesktop />
      </div>
      <div className="block xl:hidden">
        <LoginMob />
      </div>
    </>
  );
};

export default Login;
