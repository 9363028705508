import { useState } from "react";
import { ThemeInput } from "components/components";

const Contact = () => {
  const [data, setData] = useState({});
  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        <ThemeInput
          noRequired
          placeholder={"Phone"}
          onChange={onChange}
          value={data?.phone}
          name={"phone"}
          clearBtn={data.phone}
          onClear={() => setData({ ...data, phone: "" })}
        />
        <ThemeInput
          noRequired
          placeholder={"Email"}
          onChange={onChange}
          value={data?.email}
          name={"email"}
          clearBtn={data.email}
          onClear={() => setData({ ...data, email: "" })}
        />
        <ThemeInput
          noRequired
          placeholder={"Address"}
          onChange={onChange}
          value={data?.address}
          name={"address"}
          clearBtn={data.address}
          onClear={() => setData({ ...data, address: "" })}
        />
        <ThemeInput
          noRequired
          placeholder={"City"}
          onChange={onChange}
          value={data?.city}
          name={"city"}
          clearBtn={data.city}
          onClear={() => setData({ ...data, city: "" })}
        />
        <ThemeInput
          noRequired
          placeholder={"Postal Code"}
          onChange={onChange}
          value={data?.postal_code}
          name={"postal_code"}
          clearBtn={data.postal_code}
          onClear={() => setData({ ...data, postal_code: "" })}
        />
        <ThemeInput
          noRequired
          placeholder={"Province / state"}
          onChange={onChange}
          value={data?.province}
          name={"province"}
          clearBtn={data.province}
          onClear={() => setData({ ...data, province: "" })}
        />
        <ThemeInput
          noRequired
          placeholder={"Country"}
          onChange={onChange}
          value={data?.country}
          name={"country"}
          clearBtn={data.country}
          onClear={() => setData({ ...data, country: "" })}
        />
        <ThemeInput
          noRequired
          placeholder={"Website"}
          onChange={onChange}
          value={data?.website}
          name={"website"}
          clearBtn={data.website}
          onClear={() => setData({ ...data, website: "" })}
        />
        {/* <ThemeInput
          noRequired
          multiline
          rows={4}
          placeholder={"Html/Embed for contact page"}
        /> */}
      </div>
    </div>
  );
};

export default Contact;
