import { useState } from "react";
import { Button, Dropdown } from "antd";
import {
  cancel_icon,
  cross_icon,
  download_icon,
  filter_icon,
  home_icon,
  left_arrow_icon,
  plus_icon,
  print_icon,
  right_arrow,
  save_icon,
  submenu_icon,
} from "assets/icons/icons";
import { Container, ThemeTable } from "components/components";
import { BasicInfo } from "./basicInfo/basicInfo";
import items from "./optionItems/optionItems";
import Summary from "./summary/summary";
import { ContactPersonData } from "constant/constant";
import Details from "./details/details";
import Notes from "./notes/notes";
import Tasks from "./tasks/tasks";
import Activity from "./activity/activity";
import { addnewColor, cancelColor, saveColor } from "constant/themeColors";

const ContactPersonDesktop = () => {
  const [data, setData] = useState(ContactPersonData);
  const [startTab, setStartTab] = useState(0);
  const [endTab, setEndTab] = useState(5);
  const [densityOpt, setDensityOpt] = useState(false);
  const [density, setDensity] = useState("middle");
  const [addModal, setAddModal] = useState("addModalClose");
  const [detailsModal, setDetailsModal] = useState("detailsModalClose");
  const [selectedId, setSelectedId] = useState(0);
  const handleAddCompany = () => {
    if (detailsModal === "detailsModalClose") {
      setAddModal("addModalOpen");
    } else {
      setDetailsModal("detailsModalClose");
      setAddModal("addModalOpen");
    }
  };
  const handleCompanyDetails = () => {
    if (addModal === "addModalClose") {
      setDetailsModal("detailsModalOpen");
    } else {
      setAddModal("addModalClose");
      setDetailsModal("detailsModalOpen");
    }
  };
  const onChange = (key) => {
    console.log(key);
  };
  const columns = [
    {
      title: "",
      dataIndex: "client",
      width: "5%",
      render: () => (
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomLeft"
          trigger={["click"]}
        >
          <div className="flex justify-center">
            <img src={submenu_icon} alt="" className="cursor-pointer" />
          </div>
        </Dropdown>
      ),
    },
    {
      title: "First name",
      dataIndex: "first_name",
      width: "16%",
      sorter: (a, b) => a.first_name - b.first_name,
      filters: [
        {
          text: "Antonia",
          value: "Antonia",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.first_name.includes(value),
    },
    {
      title: "Last name",
      dataIndex: "last_name",
      sorter: (a, b) => a.last_name - b.last_name,
      width: "16%",
      filters: [
        {
          text: "Emmerich",
          value: "Emmerich",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.last_name.includes(value),
    },
    {
      title: "Company",
      dataIndex: "company",
      width: "16%",
      sorter: (a, b) => a.company - b.company,
      filters: [
        {
          text: "ABC Company",
          value: "ABC Company",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.company.includes(value),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email - b.email,
      width: "18%",
      filters: [
        {
          text: "example@gmail.com",
          value: "example@gmail.com",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.email.includes(value),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      width: "13%",
      sorter: (a, b) => a.phone - b.phone,
      filters: [
        {
          text: "02345678",
          value: "02345678",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.phone.includes(value),
    },
    {
      title: "Date",
      dataIndex: "date",
      width: "13%",
      sorter: (a, b) => a.date - b.date,
      filters: [
        {
          text: "15/May/2023",
          value: "15/May/2023",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.date.includes(value),
    },
  ];
  const handleDensity = () => {
    setDensityOpt(!densityOpt);
  };
  const tabs = [
    "Summary",
    "Details",
    "Tasks",
    "Notes",
    "Activity",
    "Reminders",
  ];
  const handleStartTabs = () => {
    setStartTab(startTab - 1);
    setEndTab(endTab - 1);
  };
  const handleEndTabs = () => {
    setStartTab(startTab + 1);
    setEndTab(endTab + 1);
  };
  return (
    <Container onClick={() => densityOpt && setDensityOpt(false)}>
      <div className="flex">
        <div className="w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
          <div className="bg-white" style={{ top: 54 }}>
            <div className="flex items-center justify-between px-5 py-3">
              <div className="flex items-center">
                <p className="font-medium text-sm">Contact</p>
                <img src={home_icon} alt="" className="mr-3 ml-8" />
                <p className="font-medium text-sm">
                  Home
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    Contact
                  </a>
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    All Contacts
                  </a>
                </p>
              </div>
              <div className="flex items-center">
                <div>
                  <img
                    src={filter_icon}
                    onClick={handleDensity}
                    alt=""
                    className="mr-4 cursor-pointer"
                  />
                  {densityOpt && (
                    <div className="relative">
                      <div className="text-sm absolute my-2 bg-white px-3 py-1 z-20 top-0 leading-7 rounded-md shadow-lg">
                        <p
                          className="cursor-pointer"
                          onClick={() => setDensity("small")}
                        >
                          Compact
                        </p>
                        <p
                          className="cursor-pointer"
                          onClick={() => setDensity("middle")}
                        >
                          Standard
                        </p>
                        <p
                          className="cursor-pointer"
                          onClick={() => setDensity("large")}
                        >
                          Comfortable
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <img src={download_icon} alt="" className="mr-4" />
                <img src={print_icon} alt="" className="mr-8" />
                <Button
                  onClick={handleAddCompany}
                  className="!text-sm p-0 flex items-center justify-center font-medium hover:!text-white text-white"
                  style={{
                    borderRadius: 5,
                    height: 27,
                    width: 85,
                    backgroundColor: addnewColor,
                  }}
                >
                  Add
                  <img src={plus_icon} alt="" className="ml-2" />
                </Button>
              </div>
            </div>
            <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
          </div>
          <div className="-mt-3">
            <ThemeTable
              onClick={handleCompanyDetails}
              columns={columns}
              data={data}
              setData={(e) => setData(e)}
              density={density}
            />
          </div>
        </div>

        <div
          className={`bg-white ${addModal} border-l-2 border-[rgba(0,0,0,.1)] overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
        >
          {addModal === "addModalOpen" && (
            <div className="pt-3">
              <div className="flex items-center justify-between px-5">
                <div>
                  <p className="font-semibold -mb-2 text-lg">New contact</p>
                  <a href="#" className="text-xs text-bgBlue">
                    Contact Details
                  </a>
                </div>
                <div className="flex items-center">
                  <Button
                    className="!text-sm p-0 flex items-center justify-center font-medium hover:!text-black"
                    style={{
                      borderRadius: 5,
                      height: 27,
                      width: 85,
                      backgroundColor: cancelColor,
                    }}
                    onClick={() => setAddModal("addModalClose")}
                  >
                    <img src={cancel_icon} alt="" className="mr-1" width={16} />
                    Cancel
                  </Button>
                  <Button
                    className="text-white ml-4 font-medium !text-sm p-0 flex items-center justify-center hover:!text-white"
                    style={{
                      borderRadius: 5,
                      height: 27,
                      width: 85,
                      backgroundColor: saveColor,
                    }}
                    onClick={() => setAddModal("addModalClose")}
                  >
                    <img src={save_icon} alt="" className="mr-1" width={18} />
                    Save
                  </Button>
                </div>
              </div>
              <BasicInfo />
            </div>
          )}
        </div>

        <div
          className={`bg-white ${detailsModal} overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
        >
          {detailsModal === "detailsModalOpen" && (
            <>
              <div className="flex items-center justify-between w-full pr-5 shadow-md border-b-2 border-[rgba(0,0,0,.2)] bg-white sticky top-0">
                <div className="h-11 items-center w-[432px] flex">
                  {tabs.map((e, i) => {
                    return (
                      <>
                        {i >= startTab && i < endTab && (
                          <Button
                            onClick={() => setSelectedId(i)}
                            key={i}
                            className={`${
                              selectedId === i &&
                              "bg-bgBlue text-white hover:!text-white "
                            } !border-none text-sm font-medium !rounded-none h-11 w-[108px]`}
                          >
                            {e}
                          </Button>
                        )}
                      </>
                    );
                  })}
                </div>
                <div className="flex items-center">
                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement="bottomLeft"
                    trigger={["click"]}
                  >
                    <div className="flex justify-center">
                      <img
                        src={submenu_icon}
                        alt=""
                        className="cursor-pointer"
                      />
                    </div>
                  </Dropdown>
                  <img
                    src={cross_icon}
                    alt=""
                    className="ml-4 cursor-pointer opacity-70"
                    width={16}
                    onClick={() => setDetailsModal("detailsModalClose")}
                  />
                </div>
              </div>
              {selectedId === 0 && <Summary />}
              {selectedId === 1 && <Details />}
              {selectedId === 2 && <Tasks />}
              {selectedId === 3 && <Notes />}
              {selectedId === 4 && <Activity />}
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default ContactPersonDesktop;
