import { ThemeTable } from "components/components";
import { Dropdown } from "antd";
import { submenu_icon } from "assets/icons/icons";
import items from "../optionItems/optionItems";
import { PlayCircleFilled } from "@ant-design/icons";

const data = [
  {
    key: 1,
    number: `0024`,
    name: "Odit sapiente et animi",
    date: "26/Mar/2023",
    time: `0:00:00`,
    status: `Done`,
  },
  {
    key: 2,
    number: `0024`,
    name: "Odit sapiente et animi",
    date: "26/Mar/2023",
    time: `0:00:00`,
    status: `Done`,
  },
  {
    key: 3,
    number: `0024`,
    name: "Odit sapiente et animi",
    date: "26/Mar/2023",
    time: `0:00:00`,
    status: `In Progress`,
  },
  {
    key: 4,
    number: `0024`,
    name: "Odit sapiente et animi",
    date: "26/Mar/2023",
    time: `0:00:00`,
    status: `Done`,
  },
  {
    key: 5,
    number: `0024`,
    name: "Odit sapiente et animi",
    date: "26/Mar/2023",
    time: `0:00:00`,
    status: `Done`,
  },
  {
    key: 6,
    number: `0024`,
    name: "Odit sapiente et animi",
    date: "26/Mar/2023",
    time: `0:00:00`,
    status: `Done`,
  },
];

const columns = [
  {
    title: "",
    dataIndex: "client",
    width: "5%",
    render: () => (
      <Dropdown
        menu={{
          items,
        }}
        placement="bottomLeft"
        trigger={["click"]}
      >
        <div className="flex justify-center">
          <img src={submenu_icon} alt="" className="cursor-pointer" />
        </div>
      </Dropdown>
    ),
  },
  {
    title: "Number",
    dataIndex: "number",
    width: "6%",
  },
  {
    title: "Name",
    dataIndex: "name",
    width: "25%",
    render: (text) => (
      <div>
        <p className="font-medium">{text}</p>
        <p className="text-xs">D'Amore-Mayer</p>
      </div>
    ),
  },
  {
    title: "Time",
    dataIndex: "time",
    width: "15%",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "12%",
    render: (text) => (
      <div className="flex justify-center">
        <div
          className={`bg-themeGray-dark text-white h-7 w-full text-sm flex justify-center items-center rounded-[5px]`}
        >
          <p>{text}</p>
        </div>
      </div>
    ),
  },
  {
    title: "",
    dataIndex: "status",
    width: "5%",
    render: () => (
      <div className="flex justify-center">
        <PlayCircleFilled
          className="cursor-pointer"
          style={{ color: "#757474", fontSize: 20 }}
        />
      </div>
    ),
  },
];

const Tasks = () => {
  return (
    <ThemeTable noPagination noSelectRows columns={columns} data={data} density={"middle"} />
  );
};

export default Tasks;
