import { Button } from "antd";
import { ThemeInput } from "components/components";
import React from "react";

const Recapcha = () => {
  return (
    <div className="bg-themeGray-light200 p-3 mt-4 min-h-[73.8vh] desktop:h-[79vh] bigDesktop:h-[87vh]">
      <div className="bg-white p-3 shadow-md">
        <div className="flex items-center justify-between mb-2">
          <div>
            <p>ReCaptcha Settings</p>
            <p className="text-xs pt-1">
              (How to Get Google reCaptcha Site and Secret key)
            </p>
          </div>
          <Button>Off</Button>
        </div>
        <ThemeInput password placeholder={"Google Recaptcha Key"} />
        <ThemeInput password placeholder={"Google Recaptcha Secret"} />
      </div>
    </div>
  );
};

export default Recapcha;
