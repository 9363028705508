import { home_icon } from "assets/icons/icons";

const {
  faArrowsRotate,
  faEnvelope,
  faDownload,
  faPrint,
  faEllipsisVertical,
  faXmark,
} = require("@fortawesome/free-solid-svg-icons");
const { FontAwesomeIcon } = require("@fortawesome/react-fontawesome");
const {
  Container,
  FilterOptions,
  ThemeTable,
} = require("components/components");

const Sources = () => {
  const columns = [
    {
      title: "Lead Name",
      dataIndex: "staffMember",
      key: "staffMember",
    },
    {
      title: "Account",
      dataIndex: "task",
      key: "task",
    },
    {
      title: "Created Date",
      dataIndex: "timesheetTags",
      key: "timesheetTags",
    },
    {
      title: "Modified Date",
      dataIndex: "timeStart",
      key: "timeStart",
    },
    {
      title: "Owner",
      dataIndex: "endTime",
      key: "endTime",
    },
    {
      title: "Company",
      dataIndex: "note",
      key: "note",
    },
    {
      title: "Status",
      dataIndex: "related",
      key: "related",
    },
    {
      title: "State",
      dataIndex: "timeN",
      key: "timeN",
    },
    {
      title: "Merged",
      dataIndex: "timeDecimal",
      key: "timeDecimal",
    },
  ];

  // Define table data with row data
  const data = [
    {
      key: "1",
      staffMember: "Lead_1",
      task: "Occanic Airlines",
      timesheetTags: "1-23-2023",
      timeStart: "12-23-2022",
      endTime: "owner_1",
      note: "company_1",
      related: "Last",
      timeN: "Disqualified",
      timeDecimal: "No",
    },
    {
      key: "2",
      staffMember: "Lead_2",
      task: "Occanic Airlines",
      timesheetTags: "1-23-2023",
      timeStart: "12-23-2022",
      endTime: "owner_2",
      note: "company_2",
      related: "Cancelled",
      timeN: "Disqualified",
      timeDecimal: "No",
    },
    {
      key: "3",
      staffMember: "Lead_3",
      task: "Occanic Airlines",
      timesheetTags: "1-23-2023",
      timeStart: "12-23-2022",
      endTime: "owner_3",
      note: "company_3",
      related: "New",
      timeN: "Open",
      timeDecimal: "No",
    },
    {
      key: "4",
      staffMember: "Lead_4",
      task: "Occanic Airlines",
      timesheetTags: "1-23-2023",
      timeStart: "12-23-2022",
      endTime: "owner_4",
      note: "company_4",
      related: "No Longer Intrested",
      timeN: "Disqualified",
      timeDecimal: "No",
    },
    {
      key: "5",
      staffMember: "Lead_5",
      task: "Occanic Airlines",
      timesheetTags: "1-23-2023",
      timeStart: "12-23-2022",
      endTime: "owner_5",
      note: "company_5",
      related: "Conracted",
      timeN: "Open",
      timeDecimal: "No",
    },
    {
      key: "6",
      staffMember: "Lead_6",
      task: "Occanic Airlines",
      timesheetTags: "1-23-2023",
      timeStart: "12-23-2022",
      endTime: "owner_6",
      note: "company_6",
      related: "Cancelled",
      timeN: "Disqualified",
      timeDecimal: "No",
    },
    {
      key: "7",
      staffMember: "Lead_7",
      task: "Occanic Airlines",
      timesheetTags: "1-23-2023",
      timeStart: "12-23-2022",
      endTime: "owner_7",
      note: "company_7",
      related: "Last",
      timeN: "Disqualified",
      timeDecimal: "No",
    },
    {
      key: "8",
      staffMember: "Lead_8",
      task: "Occanic Airlines",
      timesheetTags: "1-23-2023",
      timeStart: "12-23-2022",
      endTime: "owner_8",
      note: "company_8",
      related: "Cancelled",
      timeN: "Disqualified",
      timeDecimal: "No",
    },
    {
      key: "9",
      staffMember: "Lead_9",
      task: "Occanic Airlines",
      timesheetTags: "1-23-2023",
      timeStart: "12-23-2022",
      endTime: "owner_9",
      note: "company_9",
      related: "Cancelled",
      timeN: "Disqualified",
      timeDecimal: "No",
    },
  ];

  const handleRowClick = (record) => {
    console.log("Clicked row:", record);
  };

  const handleDataChange = (updatedData) => {
    console.log("Updated data:", updatedData);
  };
  return (
    <Container>
      <div className="flex">
        <div className="w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
          <div className="bg-white" style={{ top: 54 }}>
            <div className="flex items-center justify-between px-5 py-3">
              <div className="flex items-center">
                <p className="font-medium text-sm">Company</p>
                <img src={home_icon} alt="" className="mr-3 ml-8" />
                <p className="font-medium text-sm">
                  Home
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    Report
                  </a>
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    Default
                  </a>
                </p>
              </div>
              <div className="text-end pe-5">
                <div className="flex items-center">
                  <div className="me-4">
                    <FontAwesomeIcon icon={faArrowsRotate} />
                  </div>
                  <p className="p-0 m-0 text-gray-700">
                    Data refreshed at jul 18, 2023 04:06 PM
                  </p>
                  <div className="flex ">
                    <div className="ms-4">
                      <FontAwesomeIcon icon={faEnvelope} className="fs-1" />
                    </div>
                    <div className="ms-4">
                      <FontAwesomeIcon icon={faDownload} />
                    </div>
                    <div className="ms-4">
                      <FontAwesomeIcon icon={faPrint} />
                    </div>
                  </div>
                  <FontAwesomeIcon
                    icon={faEllipsisVertical}
                    className="fs-1 mx-4"
                  />
                  <FontAwesomeIcon icon={faXmark} className="fs-1" />
                </div>
              </div>
            </div>
            <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
          </div>
          <FilterOptions onTop/>
          <div className="mt-7">
            <div className="px-4">
              <div className="font-bold text-xl mb-2">Leads summary</div>
              <div className="rounded border shadow-lg w-full mb-5">
                <ThemeTable
                  columns={columns}
                  data={data}
                  onClick={handleRowClick}
                  setData={handleDataChange}
                  density="default"
                  noSelectRows={true}
                  noPagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Sources;
