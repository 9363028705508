import InvoicesDesktop from "./invoicesDesktop/invoicesDesktop";
import InvoicesMobile from "./invoicesMobile/invoicesMobile";

const Invoices = () => {
  return (
    <>
      <div className="hidden xl:block">
        <InvoicesDesktop />
      </div>
      <div className="block xl:hidden">
        <InvoicesMobile />
      </div>
    </>
  );
};

export default Invoices;
