import { useState, useEffect } from "react";
import { Button, Dropdown } from "antd";
import {
  cancel_icon,
  download_icon,
  filter_icon,
  home_icon,
  plus_icon,
  print_icon,
  save_icon,
  submenu_icon,
  plus_white_icon,
  cross_icon,
} from "assets/icons/icons";
import { Container, ThemeTable } from "components/components";
import ProjectsInfo from "./projectsInfo/projectsInfo";
import { items, detailItems } from "./optionItems/optionItems";
import {
  ProjectsData,
  ProjectDetails,
  GanttTasks,
  GanttProjectTasks,
} from "constant/constant";
import {
  BoardView,
  GanttView,
  ListView,
} from "./projectDetails/projectDetails";
import Summary from "./summary/summary";
import CardDetails from "./cardDetails/cardDetails";
import Notes from "./notes/notes";
import Documents from "./documents/documents";
import Activity from "./activity/activity";
import { addnewColor, cancelColor, saveColor } from "constant/themeColors";

const ProjectsDesktop = () => {
  const [data, setData] = useState(ProjectsData);
  const [gantt, setGantt] = useState(GanttTasks);
  const [ganttProject, setGanttProject] = useState(GanttProjectTasks);
  const [densityOpt, setDensityOpt] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [density, setDensity] = useState("middle");
  const [addModal, setAddModal] = useState("addModalHandle");
  const [selectedId, setSelectedId] = useState(0);
  const [tableModal, setTableModal] = useState("projectTableModalOpen");
  const [projectDetails, setProjectDetails] = useState(ProjectDetails);
  const [details, setDetails] = useState(false);
  const [cardDetails, setCardDetails] = useState(false);
  const [addCard, setAddCard] = useState(false);
  const [span, setSpan] = useState("col-span-2");
  const [cardDetailsModal, setCardDetailsModal] = useState("block");
  const [detailsView, setDetailsView] = useState("board");
  useEffect(() => {
    setAddModal("addModalCloseHandle");
  }, []);
  const [detailsModal, setDetailsModal] = useState("projectDetailsModalClose");
  const handleAddCompany = () => {
    if (detailsModal === "projectDetailsModalOpen") {
      setDetailsModal("projectDetailsModalClose");
    }
    setAddModal("addModalOpen");
  };
  const handleCompanyDetails = () => {
    if (addModal === "addModalOpen") {
      setAddModal("addModalClose");
    }
    setShowOptions(true);
    setDetailsView("board");
    setDetailsModal("projectDetailsModalOpen");
    setTableModal("projectTableModalClose");
  };
  const handleProjects = () => {
    setDetailsModal("projectDetailsModalClose");
    setTableModal("projectTableModalOpen");
    setDetailsView("board");
  };
  const columns = [
    {
      title: "",
      dataIndex: "client",
      width: "5%",
      render: () => (
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomLeft"
        >
          <div className="flex justify-center">
            <img src={submenu_icon} alt="" className="cursor-pointer" />
          </div>
        </Dropdown>
      ),
    },
    {
      title: "Id",
      dataIndex: "id",
      width: "6%",
      sorter: (a, b) => a.id - b.id,
      filters: [
        {
          text: "01",
          value: "01",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.id.includes(value),
    },
    {
      title: "Project",
      dataIndex: "project",
      sorter: (a, b) => a.project - b.project,
      width: "18%",
      filters: [
        {
          text: "Chief Executive",
          value: "Chief Executive",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.project.includes(value),
    },
    {
      title: "Assignee",
      dataIndex: "assignee",
      width: "18%",
      sorter: (a, b) => a.assignee - b.assignee,
      filters: [
        {
          text: "Chief Executive Officer (CEO)",
          value: "Chief Executive Officer (CEO)",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.assignee.includes(value),
    },
    {
      title: "Start date",
      dataIndex: "start_date",
      sorter: (a, b) => a.start_date - b.start_date,
      width: "14%",
      filters: [
        {
          text: "$1000",
          value: "$1000",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.start_date.includes(value),
    },
    {
      title: "Due date",
      dataIndex: "due_date",
      width: "14%",
      sorter: (a, b) => a.quantity - b.quantity,
      filters: [
        {
          text: "2000",
          value: "2000",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.quantity.includes(value),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "10%",
      filters: [
        {
          text: "Paid",
          value: "Paid",
        },
        {
          text: "Sent",
          value: "Sent",
        },
        {
          text: "Draft",
          value: "Draft",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.status.includes(value),
      render: (text) => (
        <div
          className={`${
            text === "Complete"
              ? "bg-themeGreen"
              : text === "Open" || text === "Planning"
              ? "bg-primary"
              : text === "Working"
              ? "bg-themePink"
              : text === "Backlog" && "bg-themeGreen100"
          } text-white w-3/4 h-7 text-sm flex justify-center items-center rounded-[5px]`}
        >
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Task Progress",
      dataIndex: "task_progress",
      width: "10%",
      filters: [
        {
          text: "Paid",
          value: "Paid",
        },
        {
          text: "Sent",
          value: "Sent",
        },
        {
          text: "Draft",
          value: "Draft",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.task_progress.includes(value),
      render: (text) => (
        <div
          className={`progress text-white w-3/4 h-7 text-sm flex justify-center items-center rounded-[5px]`}
        >
          <p>{text}</p>
        </div>
      ),
    },
  ];
  const handleDensity = () => {
    setDensityOpt(!densityOpt);
  };
  const tabs = ["Summary", "Notes", "Files", "Acivites"];
  const handleProjectCard = () => {
    setCardDetailsModal("hidden");
    setSpan("col-span-1");
    setCardDetails(true);
    setDetails(false);
    setAddCard(false);
  };
  return (
    <Container onClick={() => densityOpt && setDensityOpt(false)}>
      <div className="flex">
        <div
          className={`${tableModal} w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
        >
          <div className="bg-white" style={{ top: 54 }}>
            <div className="flex items-center justify-between px-5 py-3">
              <div className="flex items-center">
                <p className="font-medium text-sm">Projects</p>
                <img src={home_icon} alt="" className="mr-3 ml-8" />
                <p className="font-medium text-sm">
                  Home
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    Projects
                  </a>
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    All projects
                  </a>
                </p>
              </div>
              <div className="flex items-center">
                <div>
                  <img
                    src={filter_icon}
                    onClick={handleDensity}
                    alt=""
                    className="mr-4 cursor-pointer"
                  />
                  {densityOpt && (
                    <div className="relative">
                      <div className="text-sm absolute my-2 bg-white px-3 py-1 z-20 top-0 leading-7 rounded-md shadow-lg">
                        <p
                          className="cursor-pointer"
                          onClick={() => setDensity("small")}
                        >
                          Compact
                        </p>
                        <p
                          className="cursor-pointer"
                          onClick={() => setDensity("middle")}
                        >
                          Standard
                        </p>
                        <p
                          className="cursor-pointer"
                          onClick={() => setDensity("large")}
                        >
                          Comfortable
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <img src={download_icon} alt="" className="mr-4" />
                <img src={print_icon} alt="" className="mr-4" />
                <Button
                  onClick={() => {
                    if (addModal === "addModalOpen") {
                      setAddModal("addModalClose");
                    }
                    setDetailsModal("projectDetailsModalOpen");
                    setTableModal("projectTableModalClose");
                    // handleCompanyDetails();
                    setTimeout(() => {
                      setDetailsView("gantt");
                    }, 10);
                    // setDetailsView("board");
                    // setShowOptions(false);
                    setGantt(GanttProjectTasks);
                  }}
                  className="!border-none flex items-center justify-center font-medium hover:!text-black mr-4"
                >
                  GANTT
                </Button>
                <Button
                  onClick={handleAddCompany}
                  className="!text-sm p-0 flex items-center justify-center font-medium hover:!text-white text-white"
                  style={{
                    borderRadius: 5,
                    height: 27,
                    width: 85,
                    backgroundColor: addnewColor,
                  }}
                >
                  Add
                  <img src={plus_icon} alt="" className="ml-2" />
                </Button>
              </div>
            </div>
            <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
          </div>
          <div className="-mt-3">
            <ThemeTable
              onClick={handleCompanyDetails}
              columns={columns}
              data={data}
              setData={(e) => setData(e)}
              density={density}
            />
          </div>
        </div>

        <div
          className={`bg-white ${addModal} ${
            addModal === "addModalHandle" && "-z-10"
          } border-l-2 border-[rgba(0,0,0,.1)] overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
        >
          <div className="pt-3">
            <div className="px-5 flex items-center justify-between">
              <div>
                <p className="font-semibold -mb-2 text-lg">New project</p>
                <a href="#" className="text-xs text-bgBlue">
                  Project Details
                </a>
              </div>
              <div className="flex items-center">
                <Button
                  className="!text-sm p-0 flex items-center justify-center font-medium hover:!text-black"
                  style={{
                    borderRadius: 5,
                    height: 27,
                    width: 85,
                    backgroundColor: cancelColor,
                  }}
                  onClick={() => setAddModal("addModalClose")}
                >
                  <img src={cancel_icon} alt="" className="mr-1" width={16} />
                  Cancel
                </Button>
                <Button
                  className="text-white ml-4 font-medium !text-sm p-0 flex items-center justify-center hover:!text-white"
                  style={{
                    borderRadius: 5,
                    height: 27,
                    width: 85,
                    backgroundColor: saveColor,
                  }}
                  onClick={() => setAddModal("addModalClose")}
                >
                  <img src={save_icon} alt="" className="mr-1" width={18} />
                  Save
                </Button>
              </div>
            </div>
            <ProjectsInfo />
          </div>
        </div>

        <div
          className={`bg-white ${detailsModal} h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
        >
          {detailsModal === "projectDetailsModalOpen" && (
            // <div className="projectGrid">
            <div className="grid grid-cols-2">
              <div
                className={`${span} w-full ${
                  detailsView === "board"
                    ? "overflow-scroll"
                    : "overflow-y-scroll"
                } h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
              >
                <div className="flex sticky left-0 items-center border-b-2 border-[rgba(0,0,0,.2)] justify-between px-5 py-3">
                  <div className="flex items-center">
                    <p className="font-medium text-sm">Projects</p>
                    <img src={home_icon} alt="" className="mr-3 ml-8" />
                    <p className="font-medium text-sm">
                      Home
                      <span className="text-bgBlue"> / </span>
                      <a href="#" className="text-bgBlue">
                        Projects
                      </a>
                      <span className="text-bgBlue"> / </span>
                      <a href="#" className="text-bgBlue">
                        New app webshop
                      </a>
                    </p>
                  </div>
                  <div className="flex items-center">
                    {showOptions && (
                      <>
                        <div
                          className="bg-themeGreen rounded-full mr-2 h-6 w-6 flex items-center justify-center cursor-pointer"
                          onClick={() => {
                            setCardDetailsModal("hidden");
                            setSpan("col-span-1");
                            setCardDetails(true);
                            setAddCard(true);
                            setDetails(false);
                          }}
                        >
                          <img src={plus_white_icon} alt="" />
                        </div>
                        <Button
                          onClick={() => {
                            setSpan("col-span-1");
                            setCardDetailsModal("");
                            setDetails(true);
                            setCardDetails(false);
                          }}
                          className="!border-none flex items-center justify-center font-medium hover:!text-black"
                        >
                          DETAILS
                        </Button>
                        <Button
                          onClick={() => setDetailsView("board")}
                          className="!border-none flex items-center justify-center font-medium hover:!text-black"
                        >
                          BOARD
                        </Button>
                        <Button
                          onClick={() => setDetailsView("list")}
                          className="!border-none flex items-center justify-center font-medium hover:!text-black"
                        >
                          LIST
                        </Button>
                        <Button
                          onClick={() => {
                            setGantt(GanttTasks);
                            setDetailsView("gantt");
                          }}
                          className="!border-none flex items-center justify-center font-medium hover:!text-black"
                        >
                          GANTT
                        </Button>
                      </>
                    )}
                    <Button
                      type="primary"
                      className="!border-none flex items-center justify-center font-medium hover:!text-white"
                      onClick={handleProjects}
                    >
                      GO BACK
                    </Button>
                  </div>
                </div>
                {detailsView === "board" && (
                  <BoardView
                    data={projectDetails}
                    setData={(e) => setProjectDetails(e)}
                    onClick={handleProjectCard}
                  />
                )}
                {detailsView === "list" && (
                  <ListView
                    data={projectDetails}
                    setData={(e) => setProjectDetails(e)}
                    onClick={handleProjectCard}
                  />
                )}
                {detailsView === "gantt" && (
                  <GanttView
                    extraBtns={showOptions}
                    setTasks={(e) => setGantt(e)}
                    tasks={gantt}
                  />
                )}
              </div>
              {details && (
                <div
                  className={`${cardDetailsModal} overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
                >
                  <div>
                    <div className="flex items-center justify-between w-full pr-5 shadow-md border-b-2 border-[rgba(0,0,0,.2)] bg-white sticky top-0">
                      <div className="h-11 items-center w-[432px] flex">
                        {tabs.map((e, i) => {
                          return (
                            <Button
                              onClick={() => setSelectedId(i)}
                              key={i}
                              className={`${
                                selectedId === i &&
                                "bg-bgBlue text-white hover:!text-white "
                              } !border-none text-sm font-medium !rounded-none h-11 w-[108px]`}
                            >
                              {e}
                            </Button>
                          );
                        })}
                      </div>
                      <div className="flex items-center">
                        <Dropdown
                          menu={{
                            items: detailItems,
                          }}
                          placement="bottomLeft"
                          trigger={["click"]}
                        >
                          <div className="flex justify-center">
                            <img
                              src={submenu_icon}
                              alt=""
                              className="cursor-pointer"
                            />
                          </div>
                        </Dropdown>
                        <img
                          src={cross_icon}
                          alt=""
                          className="ml-4 cursor-pointer opacity-70"
                          width={16}
                          onClick={() => {
                            setSpan("col-span-2");
                            setDetails(false);
                          }}
                        />
                      </div>
                    </div>
                    {selectedId === 0 && <Summary />}
                    {selectedId === 1 && <Notes />}
                    {selectedId === 2 && <Documents />}
                    {selectedId === 3 && <Activity />}
                  </div>
                </div>
              )}
              {cardDetails && (
                <div
                  className={`overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
                >
                  <div>
                    <div className="flex z-10 items-center justify-between w-full px-5 shadow-md border-b-2 border-[rgba(0,0,0,.2)] bg-white sticky top-0">
                      <div className="h-11 items-center w-[432px] flex">
                        <p className="font-semibold" contentEditable>
                          Add a Test Case for the GCI Website
                        </p>
                      </div>
                      <div className="flex items-center">
                        <Dropdown
                          menu={{
                            items,
                          }}
                          placement="bottomLeft"
                          trigger={["click"]}
                        >
                          <div className="flex justify-center">
                            <img
                              src={submenu_icon}
                              alt=""
                              className="cursor-pointer"
                            />
                          </div>
                        </Dropdown>
                        <img
                          src={cross_icon}
                          alt=""
                          className="ml-4 cursor-pointer opacity-70"
                          width={16}
                          onClick={() => {
                            setSpan("col-span-2");
                            setCardDetails(false);
                          }}
                        />
                      </div>
                    </div>
                    <CardDetails add={addCard} />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default ProjectsDesktop;
