import React, { useState } from "react";
import { Radio } from "antd";
import { ThemeInput } from "components/components";

const Google = () => {
  const [value, setValue] = useState(1);
  const [value2, setValue2] = useState(1);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  return (
    <div className="bg-themeGray-light200 p-3 mt-4 min-h-[73.8vh] desktop:min-h-[79vh] bigDesktop:min-h-[87vh]">
      <div className="bg-white p-3 shadow-md">
        <ThemeInput placeholder={"Google API Key"} />
        <ThemeInput placeholder={"Google API Client ID"} />
        <p className="mt-4">reCAPTCHA</p>
        <ThemeInput placeholder={"Site key"} />
        <ThemeInput placeholder={"Secret key"} />
        <p className="mt-2">
          Enable reCAPTCHA on customers area (Login/Register)
        </p>
        <Radio.Group onChange={onChange} value={value} className="mb-2 mt-1">
          <Radio value={1}>Yes</Radio>
          <Radio value={2}>No</Radio>
        </Radio.Group>
        <ThemeInput multiline rows={4} placeholder={"Ignored IP Addresses"} />
        <p className="text-sm">
          Enter coma separated IP addresses that you want the reCaptcha to skip
          validation.
        </p>
        <p className="mt-4">Calender</p>
        <ThemeInput placeholder={"Google Calendar ID"} />
        <p className="mt-4">Google Picker</p>
        <p className="text-sm mt-1">Enable Google Picker</p>
        <Radio.Group
          onChange={(e) => setValue2(e.target.value)}
          value={value2}
          className="mb-2 mt-1"
        >
          <Radio value={1}>Yes</Radio>
          <Radio value={2}>No</Radio>
        </Radio.Group>
      </div>
    </div>
  );
};

export default Google;
