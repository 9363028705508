import { ProductInfo } from "../../productsDesktop/productInfo/productInfo";
const { MobContainer } = require("components/components");

const AddProductMob = () => {
  return (
    <MobContainer onCancel={"/products"} addCompany heading={"New Product"}>
      <div className="px-3">
        <ProductInfo />
      </div>
    </MobContainer>
  );
};

export default AddProductMob;
