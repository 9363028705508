import { home_icon } from "assets/icons/icons";
import { useParams } from "react-router-dom";

const {
  faArrowsRotate,
  faEnvelope,
  faDownload,
  faPrint,
  faEllipsisVertical,
  faXmark,
} = require("@fortawesome/free-solid-svg-icons");
const { FontAwesomeIcon } = require("@fortawesome/react-fontawesome");
const {
  Container,
  FilterOptions,
  ThemeTable,
} = require("components/components");

const AgedDebtors = () => {
  const { type } = useParams();
  const columns = [
    {
      title: "Customers",
      dataIndex: "staffMember",
      key: "staffMember",
    },
    {
      title: "Invoice",
      dataIndex: "task",
      key: "task",
    },
    {
      title: "Date",
      dataIndex: "timesheetTags",
      key: "timesheetTags",
    },
    {
      title: "Total",
      dataIndex: "timeStart",
      key: "timeStart",
    },
    {
      title: "Vat",
      dataIndex: "endTime",
      key: "endTime",
    },
    {
      title: "0-30 Days",
      dataIndex: "note",
      key: "note",
    },
    {
      title: "31-60 Days",
      dataIndex: "related",
      key: "related",
    },
    {
      title: "61-90 Days",
      dataIndex: "timeN",
      key: "timeN",
    },
    {
      title: ">90 Days",
      dataIndex: "timeDecimal",
      key: "timeDecimal",
    },
    {
      title: "as",
      dataIndex: "timeDecimal",
      key: "timeDecimal",
    },
  ];

  // Define table data with row data
  const data = [
    {
      key: "1",
      staffMember: "Lets do business",
      task: "INV123002023",
      timesheetTags: "12-05-2023",
      timeStart: "$5,400,00",
      endTime: "$825",
      note: "$0.00",
      related: "$0.00",
      timeN: "$5,400,00",
      timeDecimal: "$0.00",
    },
    {
      key: "2",
      staffMember: "Lewis inc",
      task: "INV123002023",
      timesheetTags: "12-05-2023",
      timeStart: "$1,400,00",
      endTime: "$400",
      note: "$1,400,00",
      related: "$0.00",
      timeN: "$0.00",
      timeDecimal: "$0.00",
    },
    {
      key: "3",
      staffMember: "Lets do business",
      task: "INV123002023",
      timesheetTags: "12-05-2023",
      timeStart: "$5,400,00",
      endTime: "$825",
      note: "$0.00",
      related: "$0.00",
      timeN: "$5,400,00",
      timeDecimal: "$0.00",
    },
    {
      key: "4",
      staffMember: "Lewis inc",
      task: "INV123002023",
      timesheetTags: "12-05-2023",
      timeStart: "$1,400,00",
      endTime: "$400",
      note: "$1,400,00",
      related: "$0.00",
      timeN: "$0.00",
      timeDecimal: "$0.00",
    },
    {
      key: "5",
      staffMember: "Lets do business",
      task: "INV123002023",
      timesheetTags: "12-05-2023",
      timeStart: "$5,400,00",
      endTime: "$825",
      note: "$0.00",
      related: "$0.00",
      timeN: "$5,400,00",
      timeDecimal: "$0.00",
    },
    {
      key: "6",
      staffMember: "Lewis inc",
      task: "INV123002023",
      timesheetTags: "12-05-2023",
      timeStart: "$1,400,00",
      endTime: "$400",
      note: "$1,400,00",
      related: "$0.00",
      timeN: "$0.00",
      timeDecimal: "$0.00",
    },
    {
      key: "7",
      staffMember: "",
      task: "Grand Total",
      timesheetTags: "",
      timeStart: "5,400,00",
      endTime: "6,954,00",
      note: "8,600,00",
      related: "8,600,00",
      timeN: "4,575,00",
      timeDecimal: "100.00",
    },
  ];

  const handleRowClick = (record) => {
    console.log("Clicked row:", record);
  };

  const handleDataChange = (updatedData) => {
    console.log("Updated data:", updatedData);
  };
  return (
    <Container superAdmin={type === "backend" && true} active={"Reports"}>
      <div className="flex">
        <div className="w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
          <div className="bg-white" style={{ top: 54 }}>
            <div className="flex items-center justify-between px-5 py-3">
              <div className="flex items-center">
                <p className="font-medium text-sm">Company</p>
                <img src={home_icon} alt="" className="mr-3 ml-8" />
                <p className="font-medium text-sm">
                  Home
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    Report
                  </a>
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    Default
                  </a>
                </p>
              </div>
              <div className="text-end pe-5">
                <div className="flex items-center">
                  <div className="me-4">
                    <FontAwesomeIcon icon={faArrowsRotate} />
                  </div>
                  <p className="p-0 m-0 text-gray-700">
                    Data refreshed at jul 18, 2023 04:06 PM
                  </p>
                  <div className="flex ">
                    <div className="ms-4">
                      <FontAwesomeIcon icon={faEnvelope} className="fs-1" />
                    </div>
                    <div className="ms-4">
                      <FontAwesomeIcon icon={faDownload} />
                    </div>
                    <div className="ms-4">
                      <FontAwesomeIcon icon={faPrint} />
                    </div>
                  </div>
                  <FontAwesomeIcon
                    icon={faEllipsisVertical}
                    className="fs-1 mx-4"
                  />
                  <FontAwesomeIcon icon={faXmark} className="fs-1" />
                </div>
              </div>
            </div>
            <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
          </div>
          <FilterOptions onTop />
          <div className="mt-7">
            <div className="px-4">
              <div className="font-bold text-xl mb-2">Aged Debtors</div>
              <div className="rounded border shadow-lg w-full mb-5">
                <ThemeTable
                  columns={columns}
                  data={data}
                  onClick={handleRowClick}
                  setData={handleDataChange}
                  density="default"
                  noSelectRows={true}
                  noPagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AgedDebtors;
