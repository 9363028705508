import { useState } from "react";
import { Button, Dropdown, Tabs } from "antd";
import {
  cancel_icon,
  cross_icon,
  download_icon,
  filter_icon,
  home_icon,
  plus_icon,
  print_icon,
  save_icon,
  submenu_icon,
} from "assets/icons/icons";
import { Container, ThemeTable } from "components/components";
import { BasicInfo, ContactPerson } from "./basicInfo/basicInfo";
import Summary from "./summary/summary";
import { CompanySuperData } from "constant/constant";
import Invoices from "./invoices/invoices";
import Notes from "./notes/notes";
import { addnewColor, cancelColor, saveColor } from "constant/themeColors";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import options from "./optionItems/optionItems";

const CompanyDesktop = () => {
  const [data, setData] = useState(CompanySuperData);
  const [densityOpt, setDensityOpt] = useState(false);
  const [density, setDensity] = useState("middle");
  const [addModal, setAddModal] = useState("addModalClose");
  const [detailsModal, setDetailsModal] = useState("detailsModalClose");
  const [selectedId, setSelectedId] = useState(0);
  const handleAddCompany = () => {
    if (detailsModal === "detailsModalClose") {
      setAddModal("addModalOpen");
    } else {
      setDetailsModal("detailsModalClose");
      setAddModal("addModalOpen");
    }
  };
  const handleCompanyDetails = () => {
    if (addModal === "addModalClose") {
      setDetailsModal("detailsModalOpen");
    } else {
      setAddModal("addModalClose");
      setDetailsModal("detailsModalOpen");
    }
  };
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      width: "6%",
      sorter: (a, b) => a.id - b.id,
      filters: [
        {
          text: "01",
          value: "01",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.id.includes(value),
    },
    {
      title: "Company",
      dataIndex: "company",
      sorter: (a, b) => a.company - b.company,
      width: "12%",
      filters: [
        {
          text: "ABC Company",
          value: "ABC Company",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.company.includes(value),
    },
    {
      title: "Contact",
      dataIndex: "contact",
      width: "13%",
      sorter: (a, b) => a.contact - b.contact,
      filters: [
        {
          text: "+92 321 2345678",
          value: "+92 321 2345678",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.contact.includes(value),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email - b.email,
      width: "14%",
      filters: [
        {
          text: "example@gmail.com",
          value: "example@gmail.com",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.email.includes(value),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      width: "11%",
      sorter: (a, b) => a.phone - b.phone,
      filters: [
        {
          text: "02345678",
          value: "02345678",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.phone.includes(value),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 150,
      filters: [
        {
          text: "Active",
          value: "Active",
        },
        {
          text: "deactivate bt them",
          value: "deactivate bt them",
        },
        {
          text: "email not verificatie",
          value: "email not verificatie",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.status.includes(value),
      render: (text) => (
        <div
          className={`${
            text === "Active"
              ? "bg-themeGreen"
              : text === "deactivate bt them"
              ? "bg-red-600"
              : text === "email not verificatie" && "bg-themeGray-dark"
          } text-white w-[150px] h-7 text-sm flex justify-center items-center rounded-[5px]`}
        >
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Register",
      dataIndex: "created",
      width: "11%",
      sorter: (a, b) => a.created - b.created,
      filters: [
        {
          text: "15/May/2023",
          value: "15/May/2023",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.created.includes(value),
    },
    {
      title: "",
      dataIndex: "",
      width: 140,
      render: () => (
        <div className="flex justify-end">
          <div
            className={`h-7 w-[130px] text-sm flex bg-primary text-white justify-center items-center rounded-[5px]`}
          >
            <FontAwesomeIcon icon={faArrowRightToBracket} />
            <p className="ml-2">Login as client</p>
          </div>
        </div>
      ),
    },
  ];
  const handleDensity = () => {
    setDensityOpt(!densityOpt);
  };
  const items = [
    {
      key: "1",
      label: `Basic Info`,
      children: <BasicInfo />,
    },
    {
      key: "2",
      label: `Contact Person`,
      children: <ContactPerson />,
    },
  ];
  const onChange = (key) => {
    setSelectedId(key);
  };
  const tabs = ["Summary", "Invoices", "Notes"];
  return (
    <Container
      superAdmin
      active={"Companies"}
      onClick={() => densityOpt && setDensityOpt(false)}
    >
      <div className="flex">
        <div className="w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
          <div className="bg-white" style={{ top: 54 }}>
            <div className="flex items-center justify-between px-5 py-3">
              <div className="flex items-center">
                <p className="font-medium text-sm">Company</p>
                <img src={home_icon} alt="" className="mr-3 ml-8" />
                <p className="font-medium text-sm">
                  Home
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    Company
                  </a>
                  <span className="text-bgBlue"> / </span>
                  <a href="#" className="text-bgBlue">
                    All companies
                  </a>
                </p>
              </div>
              <div className="flex items-center">
                <div>
                  <img
                    src={filter_icon}
                    onClick={handleDensity}
                    alt=""
                    className="mr-4 cursor-pointer"
                  />
                  {densityOpt && (
                    <div className="relative">
                      <div className="text-sm absolute my-2 bg-white px-3 py-1 z-20 top-0 leading-7 rounded-md shadow-lg">
                        <p
                          className="cursor-pointer"
                          onClick={() => {
                            setDensityOpt(false);
                            setDensity("small");
                          }}
                        >
                          Compact
                        </p>
                        <p
                          className="cursor-pointer"
                          onClick={() => {
                            setDensityOpt(false);
                            setDensity("middle");
                          }}
                        >
                          Standard
                        </p>
                        <p
                          className="cursor-pointer"
                          onClick={() => {
                            setDensityOpt(false);
                            setDensity("large");
                          }}
                        >
                          Comfortable
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <img src={download_icon} alt="" className="mr-4" />
                <img src={print_icon} alt="" className="mr-8" />
                <Button
                  onClick={handleAddCompany}
                  className="!text-sm p-0 flex items-center justify-center font-medium hover:!text-white text-white"
                  style={{
                    borderRadius: 5,
                    height: 27,
                    width: 85,
                    backgroundColor: addnewColor,
                  }}
                >
                  Add
                  <img src={plus_icon} alt="" className="ml-2" />
                </Button>
              </div>
            </div>
            <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
          </div>
          <div className="-mt-3">
            <ThemeTable
              onClick={handleCompanyDetails}
              columns={columns}
              data={data}
              setData={(e) => setData(e)}
              density={density}
            />
          </div>
        </div>

        <div
          className={`bg-white ${addModal} border-l-2 border-[rgba(0,0,0,.1)] overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
        >
          {addModal === "addModalOpen" && (
            <div className="">
              <div className="flex items-center justify-between px-3 pt-1">
                <div>
                  <p className="font-semibold -mb-2 text-lg">New company</p>
                  <a href="#" className="text-xs text-bgBlue">
                    Company Details
                  </a>
                </div>
                <div className="flex items-center">
                  <Button
                    className="!text-sm p-0 flex items-center justify-center font-medium hover:!text-black"
                    style={{
                      borderRadius: 5,
                      height: 27,
                      width: 85,
                      backgroundColor: cancelColor,
                    }}
                    onClick={() => setAddModal("addModalClose")}
                  >
                    <img src={cancel_icon} alt="" className="mr-1" width={16} />
                    Cancel
                  </Button>
                  <Button
                    className="text-white ml-4 font-medium !text-sm p-0 flex items-center justify-center hover:!text-white"
                    style={{
                      borderRadius: 5,
                      height: 27,
                      width: 85,
                      backgroundColor: saveColor,
                    }}
                    onClick={() => setAddModal("addModalClose")}
                  >
                    <img src={save_icon} alt="" className="mr-1" width={18} />
                    Save
                  </Button>
                </div>
              </div>
              <div className="ml-2">
                <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
              </div>
            </div>
          )}
        </div>

        <div
          className={`bg-white ${detailsModal} overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
        >
          {detailsModal === "detailsModalOpen" && (
            <>
              <div className="flex z-10 items-center justify-between w-full pr-5 shadow-md border-b-2 border-[rgba(0,0,0,.2)] bg-white sticky top-0">
                <div className="h-11 items-center w-[432px] flex">
                  {tabs.map((e, i) => {
                    return (
                      <Button
                        onClick={() => setSelectedId(i)}
                        key={i}
                        className={`${
                          selectedId === i &&
                          "bg-bgBlue text-white hover:!text-white "
                        } !border-none text-sm font-medium !rounded-none h-11 w-[108px]`}
                      >
                        {e}
                      </Button>
                    );
                  })}
                </div>
                <div className="flex items-center">
                  <Dropdown
                    menu={{
                      items: options,
                    }}
                    placement="bottomLeft"
                    trigger={["click"]}
                  >
                    <div className="flex justify-center">
                      <img
                        src={submenu_icon}
                        alt=""
                        className="cursor-pointer"
                      />
                    </div>
                  </Dropdown>
                  <img
                    src={cross_icon}
                    alt=""
                    className="ml-4 cursor-pointer opacity-70"
                    width={16}
                    onClick={() => setDetailsModal("detailsModalClose")}
                  />
                </div>
              </div>
              {selectedId === 0 && <Summary />}
              {selectedId === 1 && <Invoices />}
              {selectedId === 2 && <Notes />}
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default CompanyDesktop;
