import { SketchPicker } from "react-color";
import { useState, useRef, useEffect } from "react";

const InputColorPicker = ({ color, setColor, label, top }) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const colorRef = useRef();
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowColorPicker(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(colorRef);
  return (
    <div>
      {label && <p className="text-sm mt-3 opacity-80 -mb-2">{label}</p>}
      <div className="flex justify-between items-center border-b-2 border-[rgba(0,0,0,.2)]">
        <p>{color}</p>
        <div>
          <div
            onClick={() => setShowColorPicker(true)}
            className="cursor-pointer w-28 h-7 mb-3 border-[1px] border-[rgba(0,0,0,.2)]"
            style={{ backgroundColor: color }}
          ></div>
          {showColorPicker && (
            <div className="relative z-10" ref={colorRef}>
              <SketchPicker
                className={`absolute right-20 ${top && "bottom-10"}`}
                color={color}
                onChangeComplete={(e) => setColor(e.hex)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InputColorPicker;
