import TasksDesktop from "./tasksDesktop/tasksDesktop";
import TasksMobile from "./tasksMobile/tasksMobile";

const Tasks = () => {
  return (
    <>
      <div className="hidden xl:block">
        <TasksDesktop />
      </div>
      <div className="block xl:hidden">
        <TasksMobile />
      </div>
    </>
  );
};

export default Tasks;
