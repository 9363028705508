import { client_portal_blue, company, leads } from "assets/icons/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faGlobe,
  faPercent,
  faSitemap,
  faListCheck,
  faCoins,
  faArrowsUpDown,
  faTabletScreenButton,
  faIdBadge,
  faDiagramProject,
  faPaintbrush,
  faTextWidth,
  faListOl,
  faEnvelope,
  faBell,
  faUserGroup,
  faCalendarDays,
  faQuoteLeft,
  faFileSignature,
  faGear,
  faSliders,
  faRightToBracket,
  faQrcode,
  faCookie,
  faMagnifyingGlass,
  faUsers,
  faTableCellsLarge,
  faShieldHalved,
  faShapes,
} from "@fortawesome/free-solid-svg-icons";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";

const SettingsData = [
  {
    key: 0,
    title: "Company Details",
    icon: <img src={company} width={17} />,
  },
  {
    key: 1,
    title: "User Details",
    icon: <FontAwesomeIcon icon={faUser} />,
  },
  {
    key: 2,
    title: "Localization",
    icon: <FontAwesomeIcon icon={faGlobe} />,
  },
  {
    key: 3,
    title: "Payment settings",
    icon: <FontAwesomeIcon icon={faCreditCard} />,
  },
  {
    key: 4,
    title: "Tax Settings",
    icon: <FontAwesomeIcon icon={faPercent} />,
  },
  {
    key: 5,
    title: "Product Settings",
    icon: <FontAwesomeIcon icon={faSitemap} />,
  },
  {
    key: 6,
    title: "Task Settings",
    icon: <FontAwesomeIcon icon={faListCheck} />,
  },
  {
    key: 7,
    title: "Project Settings",
    icon: <FontAwesomeIcon icon={faDiagramProject} />,
  },
  {
    key: 8,
    title: "Expense Settings",
    icon: <FontAwesomeIcon icon={faCoins} />,
  },
  {
    key: 9,
    title: "Workflow Settings",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 496 512"
      >
        <path d="M247.6 8C389.4 8 496 118.1 496 256c0 147.1-118.5 248-248.4 248C113.6 504 0 394.5 0 256 0 123.1 104.7 8 247.6 8zm.8 44.7C130.2 52.7 44.7 150.6 44.7 256c0 109.8 91.2 202.8 203.7 202.8 103.2 0 202.8-81.1 202.8-202.8.1-113.8-90.2-203.3-202.8-203.3zm94 144.3v42.5H162.1V197h180.3zm0 79.8v42.5H162.1v-42.5h180.3z" />
      </svg>
    ),
  },
  {
    key: 10,
    title: "Import | Export",
    icon: <FontAwesomeIcon icon={faArrowsUpDown} />,
  },
  {
    key: 11,
    title: "Device Settings",
    icon: <FontAwesomeIcon icon={faTabletScreenButton} />,
  },
  {
    key: 12,
    title: "Account Management",
    icon: <FontAwesomeIcon icon={faIdBadge} />,
  },
  {
    key: 13,
    title: "Invoice Design",
    icon: <FontAwesomeIcon icon={faPaintbrush} />,
  },
  {
    key: 14,
    title: "Custom Fields",
    icon: <FontAwesomeIcon icon={faTextWidth} />,
  },
  {
    key: 15,
    title: "Generated Numbers",
    icon: <FontAwesomeIcon icon={faListOl} />,
  },
  {
    key: 16,
    title: "Email Settings",
    icon: <FontAwesomeIcon icon={faEnvelope} />,
  },
  {
    key: 17,
    title: "Client Portal",
    icon: <img src={client_portal_blue} width={17} />,
  },
  {
    key: 18,
    title: "Template and Reminders",
    icon: <FontAwesomeIcon icon={faBell} />,
  },
  {
    key: 19,
    title: "User Management",
    icon: <FontAwesomeIcon icon={faUserGroup} />,
  },

  {
    key: 20,
    title: "Schedules",
    icon: <FontAwesomeIcon icon={faCalendarDays} />,
  },
  {
    key: 21,
    title: "Leads settings",
    icon: <img src={leads} width={17} />,
  },
  {
    key: 22,
    title: "Quotes settings",
    icon: <FontAwesomeIcon icon={faQuoteLeft} />,
  },
  {
    key: 23,
    title: "E sign",
    icon: <FontAwesomeIcon icon={faFileSignature} />,
  },
  {
    key: 24,
    title: "Google",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 488 512"
      >
        <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
      </svg>
    ),
  },
];

const SettingsSuperData = [
  {
    key: 0,
    title: "General Settings",
    icon: <FontAwesomeIcon icon={faGear} />,
  },
  {
    key: 1,
    title: "App Settings",
    icon: <FontAwesomeIcon icon={faSliders} />,
  },
  {
    key: 2,
    title: "Languages",
    icon: <FontAwesomeIcon icon={faGlobe} />,
  },
  {
    key: 3,
    title: "Payment settings",
    icon: <FontAwesomeIcon icon={faCreditCard} />,
  },
  {
    key: 4,
    title: "Tax Settings",
    icon: <FontAwesomeIcon icon={faPercent} />,
  },
  {
    key: 5,
    title: "Template and Reminders",
    icon: <FontAwesomeIcon icon={faBell} />,
  },
  {
    key: 6,
    title: "Expense Settings",
    icon: <FontAwesomeIcon icon={faCoins} />,
  },
  {
    key: 7,
    title: "Social Login Settings",
    icon: <FontAwesomeIcon icon={faRightToBracket} />,
  },
  {
    key: 8,
    title: "Custom Fields",
    icon: <FontAwesomeIcon icon={faTextWidth} />,
  },
  {
    key: 9,
    title: "ReCaptcha Settings",
    icon: <FontAwesomeIcon icon={faQrcode} />,
  },
  {
    key: 10,
    title: "Invoice Design",
    icon: <FontAwesomeIcon icon={faPaintbrush} />,
  },
  {
    key: 11,
    title: "Cookie Settings",
    icon: <FontAwesomeIcon icon={faCookie} />,
  },
  {
    key: 12,
    title: "SEO Settings",
    icon: <FontAwesomeIcon icon={faMagnifyingGlass} />,
  },
  {
    key: 13,
    title: "Generated Numbers",
    icon: <FontAwesomeIcon icon={faListOl} />,
  },
  {
    key: 14,
    title: "Staff",
    icon: <FontAwesomeIcon icon={faUsers} />,
  },
  {
    key: 15,
    title: "Header / Footer",
    icon: <FontAwesomeIcon icon={faTableCellsLarge} />,
  },
  {
    key: 16,
    title: "Email Settings",
    icon: <FontAwesomeIcon icon={faEnvelope} />,
  },
  {
    key: 17,
    title: "Schedules",
    icon: <FontAwesomeIcon icon={faCalendarDays} />,
  },
  {
    key: 18,
    title: "Security Settings",
    icon: <FontAwesomeIcon icon={faShieldHalved} />,
  },
  {
    key: 19,
    title: "Rest API Settings",
    icon: <FontAwesomeIcon icon={faShapes} />,
  },
];

export { SettingsData, SettingsSuperData };
