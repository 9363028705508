const LeadsCard = ({ title, onClick, statusColor }) => {
  return (
    <div
      onClick={onClick}
      className="flex rounded-tl-lg rounded-bl-lg overflow-hidden shadow-lg cursor-pointer"
    >
      <div className={`${statusColor} w-1`}></div>
      <div className="bg-white p-3 w-full">
        <div className="flex justify-between">
          <div>
            <p className="ml-1 text-sm">{title}</p>
            <p className="text-xs ml-1">test</p>
          </div>
          <div className="w-7 h-7 rounded-full flex items-center justify-center bg-bgBlue text-white">
            <p className="text-sm">MA</p>
          </div>
        </div>
        <p className="text-end text-sm mt-1">$1,000</p>
        <div className="flex items-center justify-between mt-2">
          <div className="border-2 border-red-600 bg-red-200 px-2 rounded-lg text-red-600">
            <p className="text-sm">30-06-2023</p>
          </div>
          <div className="border-2 border-gray-600 bg-gray-200 px-2 rounded-lg text-gray-600">
            <p className="text-sm">35%</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadsCard;
