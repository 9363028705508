import { Tabs } from "antd";
import Fields from "./fields/fields";

const CustomFields = () => {
  const items = [
    {
      key: "1",
      label: `Company`,
      children: <Fields />,
    },
    {
      key: "2",
      label: `Clients`,
      children: <Fields />,
    },
    {
      key: "3",
      label: `Products`,
      children: <Fields />,
    },
    {
      key: "4",
      label: `Invoices`,
      children: <Fields />,
    },
    {
      key: "5",
      label: `Payments`,
      children: <Fields />,
    },
    {
      key: "6",
      label: `Projects`,
      children: <Fields />,
    },
    {
      key: "7",
      label: `Tasks`,
      children: <Fields />,
    },
    {
      key: "8",
      label: `Vendors`,
      children: <Fields />,
    },
    {
      key: "9",
      label: `Expenses`,
      children: <Fields />,
    },
    {
      key: "10",
      label: `Users`,
      children: <Fields />,
    },
  ];
  return (
    <div className="ml-2">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default CustomFields;
