import { useNavigate } from "react-router-dom";

const MobOverView = () => {
  const navigate = useNavigate();
  return (
    // <img src={left_arrow_icon} alt="" onClick={() => navigate("/company")} className="cursor-pointer"/>
    <div>
      <div className="shadow-md mt-2 bg-white mx-2 flex flex-col justify-center px-3 h-[120px]">
        <p className="text-base text-themeGray-dark mb-1">Price</p>
        <p className="text-3xl font-medium text-[rgba(0,0,0,.8)]">$792.00</p>
      </div>
      <div className="shadow-md bg-white mt-2 px-3 h-[95px] flex items-center mx-2">
        <p className="text-lightBlack text-lg cursor-pointer">
          Quis alias nam qui.
        </p>
      </div>
      <div className="shadow-md bg-white mt-2 px-3 h-[95px] flex items-center mx-2">
        <p className="text-lightBlack text-lg">Nulla ab aliquid et.</p>
      </div>
    </div>
  );
};

export default MobOverView;
