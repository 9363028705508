const CompanyData = [
  {
    key: 1,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Paid`,
  },
  {
    key: 2,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Sent`,
  },
  {
    key: 3,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Draft`,
  },
  {
    key: 4,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Paid`,
  },
  {
    key: 5,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Sent`,
  },
  {
    key: 6,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Draft`,
  },
  {
    key: 7,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Paid`,
  },
  {
    key: 8,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Sent`,
  },
  {
    key: 9,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Draft`,
  },
  {
    key: 10,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Paid`,
  },
  {
    key: 11,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Sent`,
  },
  {
    key: 12,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Draft`,
  },
  {
    key: 13,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Paid`,
  },
  {
    key: 14,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Sent`,
  },
  {
    key: 15,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Draft`,
  },
  {
    key: 16,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Paid`,
  },
  {
    key: 17,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Sent`,
  },
  {
    key: 18,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Draft`,
  },
  {
    key: 19,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Paid`,
  },
  {
    key: 20,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Draft`,
  },
];

const CompanySuperData = [
  {
    key: 1,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Active`,
  },
  {
    key: 2,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `deactivate bt them`,
  },
  {
    key: 3,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `email not verificatie`,
  },
  {
    key: 4,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Active`,
  },
  {
    key: 5,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `deactivate bt them`,
  },
  {
    key: 6,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `email not verificatie`,
  },
  {
    key: 7,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Active`,
  },
  {
    key: 8,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `deactivate bt them`,
  },
  {
    key: 9,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `email not verificatie`,
  },
  {
    key: 10,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Active`,
  },
  {
    key: 11,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `deactivate bt them`,
  },
  {
    key: 12,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `email not verificatie`,
  },
  {
    key: 13,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Active`,
  },
  {
    key: 14,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `deactivate bt them`,
  },
  {
    key: 15,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `email not verificatie`,
  },
  {
    key: 16,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Active`,
  },
  {
    key: 17,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `deactivate bt them`,
  },
  {
    key: 18,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `email not verificatie`,
  },
  {
    key: 19,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `Active`,
  },
  {
    key: 20,
    id: `01`,
    company: "ABC Company",
    contact: `+92 321 2345678`,
    email: `example@gmail.com`,
    phone: `02345678`,
    created: `15-05-2023`,
    status: `email not verificatie`,
  },
];

const CompanyMobData = [
  {
    key: 1,
    title: `Support lead`,
    client: "Quinn Flynn",
    status: `Complete`,
  },
  {
    key: 2,
    title: `Integration Specialist`,
    client: "Rhona Davidson",
    status: `Working on`,
  },
  {
    key: 3,
    title: `Support Engineer`,
    client: "Sakuura Yamamto",
    status: `Backlog`,
  },
  {
    key: 11,
    title: `Data Coordinator`,
    client: "Serge Baldwin",
    status: `Open`,
  },
  {
    key: 4,
    title: `Regional Director`,
    client: "Shad Decker",
    status: `Complete`,
  },
  {
    key: 5,
    title: `Regional Marketing`,
    client: "Shou ltous",
    status: `Working on`,
  },
  {
    key: 6,
    title: `Developer`,
    client: "Thor walton",
    status: `Backlog`,
  },
  {
    key: 7,
    title: `Regional Director`,
    client: "Shad Decker",
    status: `Complete`,
  },
  {
    key: 8,
    title: `Regional Marketing`,
    client: "Shou ltous",
    status: `Working on`,
  },
  {
    key: 9,
    title: `Developer`,
    client: "Thor walton",
    status: `Backlog`,
  },
];

export { CompanyData, CompanySuperData, CompanyMobData };
