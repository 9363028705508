const { ThemeInput } = require("components/components");

const CreatePayment = () => {
  return (
    <div className="m-3">
      <ThemeInput placeholder={"Number of days"} />
    </div>
  );
};

export default CreatePayment;
