import { Button, Input } from "antd";
import { ThemeInput, ThemeTable } from "components/components";
import { useState } from "react";

const HeaderFooter = () => {
  const [data, setData] = useState([]);
  const [addNew, setAddNew] = useState(false);
  const columns = [
    {
      title: "#",
      dataIndex: "id",
    },
    {
      title: "Widget Name",
      dataIndex: "widget_name",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];
  const handleNew = () => {
    setAddNew(!addNew);
  };
  return (
    <div className="bg-themeGray-light200 p-3 mt-4 min-h-[73.8vh] desktop:min-h-[79vh] bigDesktop:min-h-[87vh]">
      {addNew ? (
        <>
          <div className="bg-white shadow-md p-3">
            <ThemeInput placeholder={"Widget Name"} />
            <div className="mt-2">
              <p className="text-[15px] text-themeGray-medium300">
                Script in Header*
              </p>
              <p className="py-2 text-[15px]">
                The following script, if any, will be inserted into the
                &lt;head&gt; section.
              </p>
              <Input.TextArea rows={6} />
            </div>
            <div className="mt-4">
              <p className="text-[15px] text-themeGray-medium300">
                Script in Footer*
              </p>
              <p className="py-2 text-[15px]">
                The following script, if any, will be inserted into the
                &lt;/body&gt; tag.
              </p>
              <Input.TextArea rows={6} />
            </div>
          </div>
          <Button
            onClick={handleNew}
            type="primary"
            className="h-10 mt-3 w-full"
          >
            Add
          </Button>
        </>
      ) : (
        <>
          <div className="bg-white shadow-md">
            <ThemeTable
              columns={columns}
              data={data}
              noPagination
              noSelectRows
            />
          </div>
          <Button
            onClick={handleNew}
            type="primary"
            className="h-10 mt-3 w-full"
          >
            Add New
          </Button>
        </>
      )}
    </div>
  );
};

export default HeaderFooter;
