import {
  faCloud,
  faCopy,
  faHashtag,
  faStore,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch } from "@mui/material";
import { Button, message } from "antd";
import { ThemeInput } from "components/components";

const Settings = () => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      "https://demo-saas.worksuite.biz/paypal-webhook/3a835e0ccbbd218f87c62a440878d64d"
    );
    message.success("Webhook URL Copied!");
  };
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        <ThemeInput placeholder={"Domain URL"} />
        <div className="flex items-center justify-between mt-3">
          <div>
            <p className="opacity-90">Login URL</p>
            <p>https://demo.invoiceninja.com/client/login</p>
          </div>
          <Button
            onClick={copyToClipboard}
            className="flex items-center opacity-80 h-9 mt-2"
          >
            <FontAwesomeIcon icon={faCopy} />
            <p className="ml-2">Copy</p>
          </Button>
        </div>
        <div className="flex items-center justify-between mt-5 mb-2">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faCloud} className="opacity-50 mr-6" />
            <p>Client Portal</p>
          </div>
          <Switch defaultChecked />
        </div>
        <div className="flex items-center justify-between mt-5">
          <div className="flex items-center">
            <FontAwesomeIcon
              icon={faUpload}
              className="opacity-50 mr-5 text-xl"
            />
            <div>
              <p>Client Document Upload</p>
              <p className="text-themeGray-dark text-xs">
                Enable clients to upload documents
              </p>
            </div>
          </div>
          <Switch />
        </div>
        <div className="flex items-center justify-between mt-5">
          <div className="flex items-center">
            <FontAwesomeIcon
              icon={faUpload}
              className="opacity-50 mr-5 text-xl"
            />
            <div>
              <p>Vendor Document Upload</p>
              <p className="text-themeGray-dark text-xs">
                Enable vendors to upload documents
              </p>
            </div>
          </div>
          <Switch />
        </div>
        <div className="flex items-center justify-between mt-5">
          <div className="flex items-center">
            <FontAwesomeIcon
              icon={faHashtag}
              className="opacity-50 mr-5 text-xl"
            />
            <div>
              <p>Accept Purchase Order Number</p>
              <p className="text-themeGray-dark text-xs">
                Enable clients to provide a PO number when approving a quote
              </p>
            </div>
          </div>
          <Switch />
        </div>
        <div className="flex items-center justify-between mt-5">
          <div className="flex items-center">
            <FontAwesomeIcon
              icon={faStore}
              className="opacity-50 mr-5 text-xl"
            />
            <div>
              <p>Storefront</p>
              <p className="text-themeGray-dark text-xs">
                Enable third-party apps to create invoices
              </p>
            </div>
          </div>
          <Switch defaultChecked />
        </div>
      </div>
    </div>
  );
};

export default Settings;
