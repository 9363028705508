import { useState } from "react";
import {
  clock_icon,
  menu_white_icon,
  search_black_icon,
} from "assets/icons/icons";
import { NavLink, useNavigate } from "react-router-dom";
import MobSidebar from "../mobSidebar/mobSidebar";

const MobHeader = ({ addCompany, tab, setTab, tabs, heading }) => {
  const [open, setOpen] = useState("mobSidebarClose");
  const navigate = useNavigate();
  const handleSidebar = () => {
    setOpen("mobSidebarOpen");
  };
  const onClose = () => {
    setOpen("mobSidebarClose");
  };
  return (
    <>
      <MobSidebar open={open} onClose={onClose} />
      <div
        className="sticky top-0 z-10 bg-primary px-3 flex flex-col justify-between"
        style={{ boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.25)" }}
      >
        <div className="flex items-center justify-between py-5">
          {addCompany ? (
            <>
              <p onClick={() => navigate(-1)} className="text-white">
                Cancel
              </p>
              <p className="text-white font-semibold">{heading}</p>
              <p onClick={() => navigate(-1)} className="text-white">
                Save
              </p>
            </>
          ) : (
            <>
              <img
                src={menu_white_icon}
                onClick={handleSidebar}
                alt=""
                width={22}
                height={22}
              />
              <div className="mx-6 w-full h-10 flex items-center justify-between px-3 rounded-md bg-white">
                <input
                  type="text"
                  name=""
                  id=""
                  className="w-full bg-transparent placeholder:!text-sm text-sm focus:!outline-none placeholder:!text-lightBlack800 text-lightBlack800"
                  placeholder="Search here"
                />
                <img src={search_black_icon} alt="" />
              </div>
              <img src={clock_icon} alt="" width={20} height={22} />
            </>
          )}
        </div>
        {tabs && (
          <div className="flex">
            {tabs.map((e, i) => {
              return (
                <div
                  key={i}
                  onClick={() => setTab(i)}
                  className={`${tab === i && "border-white border-b-2"} ${
                    i > 0 && "ml-4"
                  } text-white pb-2 font-medium text-xs`}
                >
                  {e}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default MobHeader;
