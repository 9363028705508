import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const MyCalendar = () => {
  return (
    <div className="shadow bg-white rounded-md p-3 h-[380px] overflow-auto scrol">
      <span class=" text-[12px] text-[#000] font-medium">My Calendar</span>
      <div className="calendar-main-box mt-6">
        <h6 className="font-semibold text-sm mb-2">Today, 17/08</h6>
        <div className="calendar-box border-y flex py-3">
          <div className="text-center">
            <p className="bg-[#E43958] text-white px-2 rounded-sm text-[12px]">
              10:00
            </p>
            <p className="text-[12px] text-[#261f26b3]">10:00</p>
          </div>
          <div className="mx-3">
            <p className="text-[12px]">JN</p>
            <p className="text-[12px]">JM</p>
            <p className="text-[12px]">KH</p>
            <p className="text-[12px]">SP</p>
          </div>
          <div>
            <h5 className="text-[12px] font-semibold mb-1">
              Review meeting (weekly)
            </h5>
            <p className="mb-1">
              <FontAwesomeIcon fontSize={12} color="#261f26b3" icon={faBolt} />
              <span className="bg-slate-100 p-1 text-[12px] text-[#261f26b3] ml-2">
                Meeting Room
              </span>
            </p>
            <p className="mb-1">
              <FontAwesomeIcon fontSize={12} color="#261f26b3" icon={faBolt} />
              <span className="text-[12px] text-[#261f26b3] ml-2">
                Internal meeting
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="calendar-main-box mt-6">
        <h6 className="font-semibold text-sm mb-2">Today, 17/08</h6>
        <div className="calendar-box border-y flex py-3">
          <div className="text-center">
            <p className="bg-[#E43958] text-white px-2 rounded-sm text-[12px]">
              10:00
            </p>
            <p className="text-[12px] text-[#261f26b3]">10:00</p>
          </div>
          <div className="mx-3">
            <p className="text-[12px]">JN</p>
            <p className="text-[12px]">JM</p>
            <p className="text-[12px]">KH</p>
            <p className="text-[12px]">SP</p>
          </div>
          <div>
            <h5 className="text-[12px] font-semibold mb-1">
              Review meeting (weekly)
            </h5>
            <p className="mb-1">
              <FontAwesomeIcon fontSize={12} color="#261f26b3" icon={faBolt} />
              <span className="bg-slate-100 p-1 text-[12px] text-[#261f26b3] ml-2">
                Meeting Room
              </span>
            </p>
            <p className="mb-1">
              <FontAwesomeIcon fontSize={12} color="#261f26b3" icon={faBolt} />
              <span className="text-[12px] text-[#261f26b3] ml-2">
                Internal meeting
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="calendar-main-box mt-6">
        <h6 className="font-semibold text-sm mb-2">Today, 17/08</h6>
        <div className="calendar-box border-y flex py-3">
          <div className="text-center">
            <p className="bg-[#E43958] text-white px-2 rounded-sm text-[12px]">
              10:00
            </p>
            <p className="text-[12px] text-[#261f26b3]">10:00</p>
          </div>
          <div className="mx-3">
            <p className="text-[12px]">JN</p>
            <p className="text-[12px]">JM</p>
            <p className="text-[12px]">KH</p>
            <p className="text-[12px]">SP</p>
          </div>
          <div>
            <h5 className="text-[12px] font-semibold mb-1">
              Review meeting (weekly)
            </h5>
            <p className="mb-1">
              <FontAwesomeIcon fontSize={12} color="#261f26b3" icon={faBolt} />
              <span className="bg-slate-100 p-1 text-[12px] text-[#261f26b3] ml-2">
                Meeting Room
              </span>
            </p>
            <p className="mb-1">
              <FontAwesomeIcon fontSize={12} color="#261f26b3" icon={faBolt} />
              <span className="text-[12px] text-[#261f26b3] ml-2">
                Internal meeting
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="calendar-main-box mt-6">
        <h6 className="font-semibold text-sm mb-2">Today, 17/08</h6>
        <div className="calendar-box border-y flex py-3">
          <div className="text-center">
            <p className="bg-[#E43958] text-white px-2 rounded-sm text-[12px]">
              10:00
            </p>
            <p className="text-[12px] text-[#261f26b3]">10:00</p>
          </div>
          <div className="mx-3">
            <p className="text-[12px]">JN</p>
            <p className="text-[12px]">JM</p>
            <p className="text-[12px]">KH</p>
            <p className="text-[12px]">SP</p>
          </div>
          <div>
            <h5 className="text-[12px] font-semibold mb-1">
              Review meeting (weekly)
            </h5>
            <p className="mb-1">
              <FontAwesomeIcon fontSize={12} color="#261f26b3" icon={faBolt} />
              <span className="bg-slate-100 p-1 text-[12px] text-[#261f26b3] ml-2">
                Meeting Room
              </span>
            </p>
            <p className="mb-1">
              <FontAwesomeIcon fontSize={12} color="#261f26b3" icon={faBolt} />
              <span className="text-[12px] text-[#261f26b3] ml-2">
                Internal meeting
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCalendar;
