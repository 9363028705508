import { useRef, useEffect } from "react";
import {
  faArrowLeft,
  faArrowRight,
  faCirclePause,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input } from "antd";

const Timer = ({
  startTime,
  description,
  setDescription,
  hour,
  min,
  sec,
  handlePause,
  handleStartTimer,
  timeData,
  setShowOption,
  index,
  setIndex,
}) => {
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowOption(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const timerRef = useRef();
  useOutsideAlerter(timerRef);
  const handleBack = () => {
    index > 0 && setIndex(index - 1);
  };
  const handleForward = () => {
    index < timeData.length - 1 && setIndex(index + 1);
  };
  let totalHour = 0;
  let findhour = timeData[index].data
    .map((a) => a.hour)
    .forEach((num) => {
      totalHour += num;
    });
  let totalSec = 0;
  let findsecond = timeData[index].data
    .map((a) => a.sec)
    .forEach((num) => {
      totalSec += num;
    });
  let totalMin = 0;
  let findminute = timeData[index].data
    .map((a) => a.min)
    .forEach((num) => {
      totalMin += num;
    });
  return (
    <div className="relative" ref={timerRef}>
      <div className="absolute bg-white -right-9 shadow-sm rounded-md top-5 w-72">
        <div className="flex items-center justify-between h-12 px-3">
          <FontAwesomeIcon
            onClick={handleBack}
            className={`${
              index > 0 ? "opacity-70 cursor-pointer" : "opacity-30"
            }`}
            icon={faArrowLeft}
          />
          {timeData[index].date === "Today" ? (
            <p className="text-sm">{timeData[index].date}</p>
          ) : (
            <div className="text-center text-sm">
              <p
                className="text-bgBlue cursor-pointer"
                onClick={() => setIndex(2)}
              >
                Today
              </p>
              <p>{timeData[index].date}</p>
            </div>
          )}
          <FontAwesomeIcon
            className={`${
              index < timeData.length - 1
                ? "opacity-70 cursor-pointer"
                : "opacity-30"
            }`}
            onClick={handleForward}
            icon={faArrowRight}
          />
        </div>
        {startTime || localStorage.getItem("timer") === "true" ? (
          <div>
            <div className="p-3 border-y-2 border-[rgba(0,0,0,.1)] flex items-center justify-between">
              <Input
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <div className="flex items-center">
                <p className="px-3">
                  {hour < 10 && "0"}
                  {hour}:{min < 10 && "0"}
                  {min}:{sec < 10 && "0"}
                  {sec}
                </p>
                <FontAwesomeIcon
                  icon={faCirclePause}
                  onClick={handlePause}
                  className="cursor-pointer text-lg"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="p-3 border-y-2 border-[rgba(0,0,0,.1)]">
            <Button type="primary w-full" onClick={handleStartTimer}>
              Start new timer
            </Button>
          </div>
        )}
        {timeData[index].data?.length > 0 ? (
          <div>
            {timeData[index].data?.map((val, ind) => {
              return (
                <div
                  key={ind}
                  className={`${
                    ind > 0 && "border-t-2 border-[rgba(0,0,0,.1)]"
                  } flex items-center justify-between p-3`}
                >
                  <p>{val.title}</p>
                  <p className="px-3">
                    {val.hour < 10 && "0"}
                    {val.hour}:{val.min < 10 && "0"}
                    {val.min}:{val.sec < 10 && "0"}
                    {val.sec}
                  </p>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex justify-center items-center flex-col h-20 bg-themeGray-light">
            <p className="text-sm">No activity</p>
            <p className="text-xs mt-1">
              No time registrations yet on this day
            </p>
          </div>
        )}
        <div className="flex justify-center items-center flex-col h-12 text-themeGray-dark border-t-[1px] border-[rgba(0,0,0,.2)] bg-themeGray-light">
          <p className="text-sm">
            Total:
            {totalHour < 10 && "0"}
            {totalHour}:{totalMin < 10 && "0"}
            {totalMin}:{totalSec < 10 && "0"}
            {totalSec}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Timer;
