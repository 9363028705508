import { useState } from "react";
import { Button } from "antd";
import {
  application_icon,
  applicationmenu,
  dashboardmenu,
  helpcenter_icon,
  home_icon,
  reports_icon,
  reportsmenu,
  customermenu,
  settingsmenu,
} from "assets/icons/icons";
import SubMenu from "./subMenu/subMenu";
import { MenuData } from "./subMenuItems/subMenuItems";
import { menubarColor, sidebarIcons, submenuColor } from "constant/themeColors";

const Sidebar = ({ open, activeKey, setActiveKey }) => {
  const [subMenuData, setSubMenuData] = useState([]);
  const [subMenuIcon, setSubMenuIcon] = useState();
  const [subMenuTitle, setSubMenuTitle] = useState("");
  const filterData = MenuData.find((e) => {
    if (e.userType === localStorage.getItem("User")) return e;
  });
  const handleSubMenu = (e, i) => {
    if (e === "Home") {
      setSubMenuData(filterData.items.Dashboard);
      setSubMenuIcon(dashboardmenu);
      setSubMenuTitle("Dashboard");
    }
    if (e === "Application") {
      setSubMenuData(filterData.items.Applications);
      setSubMenuIcon(applicationmenu);
      setSubMenuTitle("Application");
    }
    if (e === "Insights") {
      setSubMenuData(filterData.items.Reports);
      setSubMenuIcon(reportsmenu);
      setSubMenuTitle("Insights");
    }
    if (e === "Settings") {
      setSubMenuData(filterData.items.CustomersCare);
      setSubMenuIcon(customermenu);
      setSubMenuTitle("Customer care");
    }
    if (e === "Help") {
      setSubMenuData(filterData.items.Settings);
      setSubMenuIcon(settingsmenu);
      setSubMenuTitle("Settings");
    }
    if (activeKey - 1 === i) {
      setActiveKey("");
    } else {
      setActiveKey(i + 1);
    }
  };
  const tabs = [
    {
      icon: (
        <svg
          className="w-[20px]"
          viewBox="0 0 19 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.07778 0L0 8.50388L2.21478 9.61596L9.01868 2.78375L15.9408 9.61738L18.1556 8.50529L9.07778 0ZM2.69792 9.83923L9.08664 3.72769L15.4562 9.83923V15.8349H10.8035V11.5999H7.31216V15.8349H2.69792V9.83923Z"
            fill={sidebarIcons}
          />
        </svg>
      ),
      title: "Home",
    },
    {
      icon: (
        <svg
          className="w-[20px]"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 11.6152V2.99956L7.13254 0.533444L13.7651 2.99956V11.6152L7.13254 14.6978L0.5 11.6152Z"
            fill="none"
            stroke={sidebarIcons}
          />
          <line
            x1="6.93799"
            y1="6.396"
            x2="6.93799"
            y2="14.396"
            stroke={sidebarIcons}
          />
          <line
            x1="7.20559"
            y1="6.95329"
            x2="14.0327"
            y2="3.36945"
            stroke={sidebarIcons}
          />
          <line
            x1="0.243193"
            y1="3.37563"
            x2="6.68118"
            y2="6.95946"
            stroke={sidebarIcons}
          />
        </svg>
      ),
      title: "Application",
    },
    {
      icon: (
        <svg
          className="w-[20px]"
          viewBox="0 0 14 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 0.5H9.29684L13.5 6.05252V16.5H0.5V0.5Z"
            fill="none"
            stroke={sidebarIcons}
          />
        </svg>
      ),
      title: "Insights",
    },
    {
      icon: (
        <svg
          className="w-[20px]"
          viewBox="0 0 14 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.97603 0C9.58644 1.72078 11.9448 2.53462 13.9685 2.34248C14.3221 9.77701 11.6819 14.1677 7.00306 16C2.48463 14.2848 -0.187969 10.0832 0.0103136 2.22939C2.38609 2.35873 4.71711 1.82481 6.97603 0ZM5.72505 7.86879C5.88359 8.00859 6.03312 8.15908 6.17268 8.31929C6.61011 7.58699 7.07667 6.91467 7.56907 6.2964C8.96185 4.54719 8.33065 4.88429 10.2624 4.88429L9.99412 5.19452C9.16915 6.14738 8.89095 6.64381 8.21469 7.65947C7.53843 8.67638 6.93397 9.72578 6.3959 10.8058L6.22886 11.1416L6.07504 10.7999C5.79144 10.1663 5.45136 9.58519 5.04638 9.06565C4.6411 8.5461 4.2872 8.20744 3.73772 7.81536C3.98948 6.95591 5.18788 7.39579 5.72505 7.86879ZM6.98053 1.23841C9.14541 2.66521 11.2235 3.46749 12.9017 3.30785C13.1949 9.47335 10.8831 13.2417 7.00336 14.7613C3.25583 13.3392 0.917304 9.72765 1.08164 3.21443C3.25613 3.33284 5.24887 2.6374 6.98053 1.23841Z"
            fill={sidebarIcons}
          />
        </svg>
      ),
      title: "Help",
    },
  ];
  const userTabs = [
    {
      icon: (
        <svg
          className="w-[20px]"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 11.6152V2.99956L7.13254 0.533444L13.7651 2.99956V11.6152L7.13254 14.6978L0.5 11.6152Z"
            fill="none"
            stroke={sidebarIcons}
          />
          <line
            x1="6.93799"
            y1="6.396"
            x2="6.93799"
            y2="14.396"
            stroke={sidebarIcons}
          />
          <line
            x1="7.20559"
            y1="6.95329"
            x2="14.0327"
            y2="3.36945"
            stroke={sidebarIcons}
          />
          <line
            x1="0.243193"
            y1="3.37563"
            x2="6.68118"
            y2="6.95946"
            stroke={sidebarIcons}
          />
        </svg>
      ),
      title: "Application",
    },
  ];
  return (
    <>
      <div
        style={{ backgroundColor: menubarColor }}
        className={`${
          open ? "sidebarOpen" : "sidebarClose"
        } border-b-2 border-[rgba(0,0,0,.2)] sticky top-0 h-screen`}
      >
        {open && (
          <div className="flex flex-col items-center mt-16">
            {localStorage.getItem("User") === "client@gmail.com" &&
              userTabs.map((e, i) => {
                return (
                  <>
                    <Button
                      key={i}
                      className={`mb-3 ${
                        activeKey - 1 === i && "bg-themeGray-medium"
                      } hover:bg-themeGray-medium h-12 !border-none flex justify-center items-center rounded-xl`}
                      onClick={() => handleSubMenu(e.title, i)}
                    >
                      {e.icon}
                    </Button>
                  </>
                );
              })}
            {localStorage.getItem("User") !== "client@gmail.com" &&
              tabs.map((e, i) => {
                return (
                  <>
                    <Button
                      key={i}
                      className={`mb-3 ${
                        activeKey - 1 === i && "bg-themeGray-medium"
                      } hover:bg-themeGray-medium h-12 !border-none flex justify-center items-center rounded-xl`}
                      onClick={() => handleSubMenu(e.title, i)}
                    >
                      {e.icon}
                    </Button>
                  </>
                );
              })}
          </div>
        )}
      </div>
      <div className="relative z-10">
        {activeKey && (
          <div
            style={{ backgroundColor: submenuColor }}
            className="absolute h-screen drop-shadow-lg border-x-2 border-[rgba(0,0,0,.1)] w-64 px-2 py-12"
          >
            <SubMenu
              data={subMenuData}
              icon={subMenuIcon}
              title={subMenuTitle}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Sidebar;
