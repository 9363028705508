import { ThemeProvider, createTheme } from "@mui/material";
import { ThemeInput, ThemeSelect } from "components/components";
import Inter from "assets/font/Inter.ttf";

const theme = createTheme({
  typography: {
    fontFamily: Inter,
  },
  palette: {
    primary: {
      main: "#00A1E4",
    },
  },
});

const BasicInfo = ({ className }) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="bg-themeGray-light200 p-3 mt-2">
        <div className="bg-white px-3 pb-3 pt-2 my-2  shadow-md">
          <ThemeInput placeholder={"Job Title"} />
          <ThemeSelect
            className={"!mb-1"}
            label={"Saluation"}
            data={["Saluation 1", "Saluation 2", "Saluation 3"]}
          />
          <ThemeInput placeholder={"Firstname"} />
          <ThemeInput placeholder={"Lastname"} />
        </div>
        <div className="bg-white px-3 pb-3 pt-2 my-2  shadow-md">
          <ThemeInput placeholder={"Address"} />
          <ThemeInput placeholder={"Postal Code"} />
          <ThemeInput placeholder={"City"} />
          <ThemeInput placeholder={"Province / state"} />
          <ThemeInput placeholder={"Country"} />
          <ThemeInput placeholder={"Website"} />
          <ThemeInput placeholder={"Phone"} />
          <ThemeInput placeholder={"Email"} />
        </div>
        <div className="bg-white px-3 pb-3 pt-2 my-2  shadow-md">
          <ThemeSelect
            className={"!mb-1"}
            label={"Associatie"}
            data={["Associatie 1", "Associatie 2", "Associatie 3"]}
          />
          <ThemeInput placeholder={"Skype address"} />
          <ThemeInput placeholder={"Linkedin address"} />
          <ThemeInput placeholder={"Twitter address"} />
          <ThemeInput placeholder={"Facebook address"} />
          <ThemeInput placeholder={"Note"} multiline rows={4} />
        </div>
      </div>
    </ThemeProvider>
  );
};

export { BasicInfo };
