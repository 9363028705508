import { ThemeTable } from "components/components";
import { Dropdown } from "antd";
import { submenu_icon } from "assets/icons/icons";
import items from "../optionItems/optionItems";

const data = [
  {
    key: 1,
    number: `01`,
    name: "Kuhn LLC",
    date: "26/Mar/2023",
    amount: `4,099.00 USD`,
    status: `Sent`,
  },
  {
    key: 2,
    number: `01`,
    name: "Kuhn LLC",
    date: "26/Mar/2023",
    amount: `4,099.00 USD`,
    status: `Sent`,
  },
  {
    key: 3,
    number: `01`,
    name: "Kuhn LLC",
    date: "26/Mar/2023",
    amount: `4,099.00 USD`,
    status: `Sent`,
  },
  {
    key: 4,
    number: `01`,
    name: "Kuhn LLC",
    date: "26/Mar/2023",
    amount: `4,099.00 USD`,
    status: `Sent`,
  },
  {
    key: 5,
    number: `01`,
    name: "Kuhn LLC",
    date: "26/Mar/2023",
    amount: `4,099.00 USD`,
    status: `Sent`,
  },
  {
    key: 6,
    number: `01`,
    name: "Kuhn LLC",
    date: "26/Mar/2023",
    amount: `4,099.00 USD`,
    status: `Sent`,
  },
];

const columns = [
  {
    title: "",
    dataIndex: "client",
    width: "5%",
    render: () => (
      <Dropdown
        menu={{
          items,
        }}
        placement="bottomLeft"
        trigger={["click"]}
      >
        <div className="flex justify-center">
          <img src={submenu_icon} alt="" className="cursor-pointer" />
        </div>
      </Dropdown>
    ),
  },
  {
    title: "Number",
    dataIndex: "number",
    width: "6%",
    sorter: (a, b) => a.number - b.number,
    filters: [
      {
        text: "01",
        value: "01",
      },
    ],
    filterMode: "tree",
    filterSearch: true,
    onFilter: (value, record) => record.number.includes(value),
  },
  {
    title: "Name",
    dataIndex: "name",
    sorter: (a, b) => a.name - b.name,
    width: "20%",
    filters: [
      {
        text: "Kuhn LLC",
        value: "Kuhn LLC",
      },
    ],
    filterMode: "tree",
    filterSearch: true,
    onFilter: (value, record) => record.name.includes(value),
  },
  {
    title: "Date",
    dataIndex: "date",
    sorter: (a, b) => a.date - b.date,
    width: "20%",
    filters: [
      {
        text: "26/Mar/2023",
        value: "26/Mar/2023",
      },
    ],
    filterMode: "tree",
    filterSearch: true,
    onFilter: (value, record) => record.date.includes(value),
  },
  {
    title: "Amount",
    dataIndex: "amount",
    width: "20%",
    sorter: (a, b) => a.amount - b.amount,
    filters: [
      {
        text: "4,099.00 USD",
        value: "4,099.00 USD",
      },
    ],
    filterMode: "tree",
    filterSearch: true,
    onFilter: (value, record) => record.amount.includes(value),
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "11%",
    render: (text) => (
      <div className="flex justify-center">
        <div
          className={`bg-primary text-white h-7 w-20 text-sm flex justify-center items-center rounded-[5px]`}
        >
          <p>{text}</p>
        </div>
      </div>
    ),
  },
];

const Invoices = () => {
  return (
    <ThemeTable
      noPagination
      noSelectRows
      columns={columns}
      data={data}
      density={"middle"}
    />
  );
};

export default Invoices;
