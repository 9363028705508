import { Switch } from "@mui/material";
import { Button } from "antd";
import { settings_white_icon } from "assets/icons/icons";
import { ThemeInput, ThemeSelect } from "components/components";

const Defaults = ({ setConfigure }) => {
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2">
      <div className="bg-white p-3 shadow-md">
        <ThemeSelect
          label={"Due on Receipt"}
          data={[
            "Net 7",
            "Net 10",
            "Net 14",
            "Net 15",
            "Net 30",
            "Net 60",
            "Net 90",
          ]}
        />
        <ThemeSelect
          className={"!mt-1"}
          label={"Due on Receipt"}
          data={[
            "Net 7",
            "Net 10",
            "Net 14",
            "Net 15",
            "Net 30",
            "Net 60",
            "Net 90",
          ]}
        />
      </div>
      <Button
        onClick={() => setConfigure(true)}
        type="primary"
        className="w-full my-5 h-10 flex items-center justify-center"
      >
        <img src={settings_white_icon} className="mr-3" />
        CONFIGURE PAYMENT TERMS
      </Button>
      <div className="bg-white p-3 shadow-md">
        <div className="flex items-center justify-between my-5">
          <div>
            <p>Use Quote Terms</p>
            <p className="text-themeGray-dark text-sm">
              When converting a quote to an invoice
            </p>
          </div>
          <Switch defaultChecked />
        </div>
        <ThemeInput
          noRequired
          placeholder={"Invoice Terms"}
          multiline
          rows={2}
        />
        <ThemeInput
          noRequired
          placeholder={"Invoice Footer"}
          multiline
          rows={2}
        />
        <ThemeInput noRequired placeholder={"Quote Terms"} multiline rows={2} />
        <ThemeInput
          noRequired
          placeholder={"Quote Footer"}
          multiline
          rows={2}
        />
        <ThemeInput
          noRequired
          placeholder={"Credit Terms"}
          multiline
          rows={2}
        />
        <ThemeInput
          noRequired
          placeholder={"Credit Footer"}
          multiline
          rows={2}
        />
        <ThemeInput
          noRequired
          placeholder={"Purchase Order Terms"}
          multiline
          rows={2}
        />
        <ThemeInput
          noRequired
          placeholder={"Purchase Order Footer"}
          multiline
          rows={2}
        />
      </div>
    </div>
  );
};

export default Defaults;
