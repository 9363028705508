import { useState } from "react";
import { Container } from "components/components";
import OverView from "./overView/overView";
import { Button } from "antd";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "./card/card";
import { cross_icon } from "assets/icons/icons";
import Budget from "./budget/budget";
import UploadFiles from "./uploadFiles/uploadFiles";
import BoardView from "./boardView/boardView";
import { ProjectDetails } from "constant/constant";
import BoardDetails from "./boardDetails/boardDetails";

const Projects = () => {
  const [detailsModal, setDetailsModal] = useState("addModalHandle");
  const [tableModal, setTableModal] = useState("projectTableModalOpen");
  const [boardModal, setBoardModal] = useState("projectDetailsModalClose");
  const [boardDetails, setBoardDetails] = useState("projectDetailsModalClose");
  const [projectDetails, setProjectDetails] = useState(ProjectDetails);
  const [steps, setSteps] = useState(1);
  const handleCompanyDetails = () => {
    setDetailsModal("detailsModalOpen");
  };
  const handleBoards = () => {
    setTableModal("projectTableModalClose");
    setDetailsModal("detailsModalClose");
    setBoardModal("projectDetailsModalOpen");
  };
  const handleBoardDetails = () => {
    setBoardDetails("projectDetailsModalOpen");
  };
  const archive = [
    {
      title: "Birthday Party",
      desc: "11:00am - 1:00pm",
    },
    {
      title: "Designer Metting",
      desc: "11:00am - 1:00pm",
    },
    {
      title: "Birthday Party",
      desc: "11:00am - 1:00pm",
    },
  ];
  return (
    <Container client active={"Projects"}>
      <div className="flex bg-white">
        <div
          className={`w-full overflow-y-scroll h-screen scrol ${tableModal}`}
        >
          {tableModal === "projectTableModalOpen" && (
            <div className="px-14 mt-20">
              <div>
                <p className="text-3xl font-semibold">Projects</p>
                <p className="pt-5 text-sm">
                  It looks like you have 1 running project.
                </p>
                <p className="pb-5 text-sm">
                  There are 2 project thar are complete. Click on it to see the
                  project
                </p>
                <Button
                  onClick={handleCompanyDetails}
                  type="primary"
                  className="h-10 mt-1 text-white"
                >
                  Want to start a new project?
                </Button>
              </div>
              <div className="grid grid-cols-2 mt-10 gap-7">
                <div>
                  <p className="text-2xl font-semibold">Running</p>
                  <div className="flex items-center mt-3">
                    <FontAwesomeIcon className="text-lg" icon={faCalendar} />
                    <p className="text-xl font-medium ml-2">02 November</p>
                  </div>
                  <Card
                    onClick={handleBoards}
                    title={"Admin dashboard"}
                    desc={"Roadmap to new project"}
                  />
                </div>
                <div>
                  <p className="text-2xl font-semibold">Archive</p>
                  <div className="flex items-center mt-3">
                    <FontAwesomeIcon className="text-lg" icon={faCalendar} />
                    <p className="text-xl font-medium ml-2">05 November</p>
                  </div>
                  {archive.map((e, i) => {
                    return (
                      <Card
                        onClick={handleBoards}
                        key={i}
                        title={e.title}
                        desc={e.desc}
                        deleteBtn
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>

        <div
          className={`bg-white ${detailsModal} ${
            (detailsModal === "addModalHandle" ||
              detailsModal === "detailsModalClose") &&
            "-z-10"
          }`}
        >
          <div className="p-7">
            <img
              width={15}
              className="cursor-pointer absolute right-7"
              src={cross_icon}
              onClick={() => setDetailsModal("detailsModalClose")}
            />
            {steps === 1 && <OverView setSteps={setSteps} />}
            {steps === 2 && <Budget setSteps={setSteps} />}
            {steps === 3 && (
              <UploadFiles
                setFirst={() => setSteps(1)}
                setSteps={() => {
                  setDetailsModal("detailsModalClose");
                  setSteps(1);
                }}
              />
            )}
          </div>
        </div>

        <div
          className={`bg-white grid grid-cols-1 overflow-scroll ${boardModal} h-screen scrol`}
        >
          <div className="flex col-span-1 h-12 sticky left-0 items-center border-b-2 border-[rgba(0,0,0,.2)] justify-between px-5">
            <p className="font-semibold">Admin dashboard</p>
            <Button
              type="primary"
              className="!border-none flex items-center justify-center font-medium hover:!text-white"
              onClick={() => {
                setTableModal("projectTableModalOpen");
                setBoardModal("projectDetailsModalClose");
                setBoardDetails("projectDetailsModalClose");
              }}
            >
              GO BACK
            </Button>
          </div>
          <BoardView
            data={projectDetails}
            setData={(e) => setProjectDetails(e)}
            onClick={handleBoardDetails}
          />
        </div>

        <div
          className={`bg-white grid grid-cols-1 overflow-scroll ${boardDetails} h-screen scrol`}
        >
          {boardDetails === "projectDetailsModalOpen" && (
            <>
              <div className="flex z-[5] items-center justify-between w-full pr-5 shadow-md border-b-2 border-[rgba(0,0,0,.2)] bg-white sticky top-0">
                <div className="h-12 items-center w-[432px] flex">
                  <div className="pl-3">
                    <p className="font-semibold">
                      #23: Support tickets list doesn't support commas
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <img
                    src={cross_icon}
                    alt=""
                    className="cursor-pointer opacity-70"
                    width={16}
                    onClick={() => setBoardDetails("projectDetailsModalClose")}
                  />
                </div>
              </div>
              <BoardDetails />
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Projects;
