import { useState } from "react";
import { Button } from "antd";
import { ThemeInput, ThemeSelect } from "components/components";
import { DeleteTwoTone } from "@ant-design/icons";

const Fields = () => {
  const [count, setCount] = useState([1, 2, 3, 4]);
  const handleAddStatus = () => {
    let update = [...count];
    update.push(Math.random());
    setCount(update);
  };
  const handleDelete = (i) => {
    let update = [...count];
    update.splice(i, 1);
    setCount(update);
  };
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        {count.map((e, i) => {
          return (
            <div className="flex">
              <ThemeInput placeholder={"Company Field"} />
              <ThemeSelect
                className={"!mt-1 !mx-4"}
                width={"50%"}
                label={"Field Type"}
                data={[
                  "Single-line text",
                  "Multi-line text",
                  "Switch",
                  "Dropdown",
                  "Date",
                ]}
              />
              <DeleteTwoTone
                className="text-lg cursor-pointer"
                onClick={() => handleDelete(i)}
              />
            </div>
          );
        })}
      </div>
      <Button type="primary w-full my-4 h-9" onClick={handleAddStatus}>
        Add New
      </Button>
    </div>
  );
};

export default Fields;
