import { ThemeInput } from "components/components";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Checkbox } from "@mui/material";
import { Button } from "antd";

const OverView = ({ setSteps }) => {
  return (
    <>
      <p className="font-semibold">Project Settings</p>
      <p className="text-themeGray-medium300 text-sm">
        If you need more info, please check{" "}
        <span className="text-primary cursor-pointer">Project Guidelines</span>
      </p>
      <div className="my-5">
        <ThemeInput placeholder={"Project Name"} />
        <ThemeInput placeholder={"Project Description"} multiline rows={4} />
        <div className="dateDiv">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD-MM-YYYY"
              label="Payment Date*"
              className="!w-full"
              slotProps={{ textField: { variant: "filled" } }}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div>
          <p>Notifications</p>
          <p className="text-themeGray-medium300 text-sm">
            If you need more info, please check
          </p>
        </div>
        <div className="flex items-center">
          <div className="flex items-center">
            <Checkbox />
            <p>Email</p>
          </div>
          <div className="flex items-center ml-3">
            <Checkbox />
            <p>Phone</p>
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-7">
        <Button type="primary" className="h-10" onClick={() => setSteps(2)}>
          Budget
        </Button>
      </div>
    </>
  );
};

export default OverView;
