import { Tabs } from "antd";
import Invoices from "./invoices/invoices";
import Quotes from "./quotes/quotes";

const Workflow = () => {
  const items = [
    {
      key: "1",
      label: `Invoices`,
      children: <Invoices />,
    },
    {
      key: "2",
      label: `Quotes`,
      children: <Quotes />,
    },
  ];
  return (
    <div className="ml-2">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default Workflow;
