import { MobContainer } from "components/components";
import CardDetails from "../../projectsDesktop/cardDetails/cardDetails";
import { useNavigate } from "react-router-dom";

const CardDetailsAdmin = () => {
  const navigate = useNavigate();
  return (
    <MobContainer
      heading={"Task Details"}
      addCompany
      onAdd={() => navigate("/app/projects/add")}
    >
      <CardDetails onMob />
    </MobContainer>
  );
};

export default CardDetailsAdmin;
