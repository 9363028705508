import React, { useState } from "react";
import { ThemeTable } from "components/components";
import { Button, Popconfirm, Input } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";

const Tax = () => {
  const [data, setData] = useState([
    {
      key: "1",
      id: "1",
      tax_name: "GST",
      rate: "10",
    },
    {
      key: "2",
      id: "2",
      tax_name: "GST",
      rate: "10",
    },
    {
      key: "3",
      id: "3",
      tax_name: "GST",
      rate: "10",
    },
    {
      key: "4",
      id: "4",
      tax_name: "GST",
      rate: "10",
    },
    {
      key: "5",
      id: "5",
      tax_name: "GST",
      rate: "10",
    },
  ]);
  const handleDelete = (key) => {
    const newData = data.filter((item) => item.key !== key);
    setData(newData);
  };
  const columns = [
    {
      title: "#",
      dataIndex: "id",
    },
    {
      title: "Tax Name",
      dataIndex: "tax_name",
      render: (text, record, ind) => (
        <Input
          value={text}
          onChange={(e) => {
            let update = [...data];
            update.splice(ind, 1, {
              key: record.key,
              id: record.id,
              tax_name: e.target.value,
              rate: record.rate,
            });
            setData(update);
          }}
        />
      ),
    },
    {
      title: "Rate %",
      dataIndex: "rate",
      render: (text, record, ind) => (
        <Input
          value={text}
          onChange={(e) => {
            let update = [...data];
            update.splice(ind, 1, {
              key: record.key,
              id: record.id,
              tax_name: record.tax_name,
              rate: e.target.value,
            });
            setData(update);
          }}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) =>
        data.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <DeleteTwoTone className="text-lg" />
          </Popconfirm>
        ) : null,
    },
  ];
  return (
    <div className="bg-themeGray-light200 p-3 mt-4 min-h-[73.8vh] desktop:h-[79vh] bigDesktop:h-[87vh]">
      <div className="bg-white p-3 shadow-md">
        <ThemeTable noSelectRows columns={columns} data={data} noPagination />
        <Button
          onClick={() => {
            let update = [...data];
            update.push({
              key: data.length + 1,
              id: data.length + 1,
              tax_name: "",
              rate: "",
            });
            setData(update);
          }}
          type="primary"
          className="h-9 w-full mt-3"
        >
          Add Tax
        </Button>
      </div>
    </div>
  );
};

export default Tax;
