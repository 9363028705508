import { Button, Input, Modal } from "antd";
import { ThemeInput, ThemeSelect } from "components/components";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Switch } from "@mui/material";
import { DeleteOutlined } from "@ant-design/icons";

const AddEventModal = ({
  isModalOpen,
  setIsModalOpen,
  labelsData,
  defaultDate,
  eventsData,
  setEventsData,
  name,
  setName,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  label,
  setLabel,
  type,
  indexNo,
  description,
  setDescription,
  allDay,
  setAllDay,
  isChoosen,
  setIsChoosen,
}) => {
  const handleSelection = (e) => {
    setLabel(e);
    let getData = labelsData.find((val) => {
      if (val.color === e) return val;
    });
    setIsChoosen(getData.isChoosen);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setName("");
    setLabel("");
    setStartDate("");
    setEndDate("");
    setAllDay(false);
    setDescription("");
  };
  const handleEvent = () => {
    if (name && label) {
      let update = [...eventsData];
      update.push({
        key: update.length,
        title: name,
        start: startDate ? new Date(startDate.toString()) : defaultDate,
        end: endDate ? new Date(endDate.toString()) : defaultDate,
        color: label,
        allDay: allDay,
        description: description,
        isChoosen: isChoosen,
      });
      setEventsData(update);
      setIsModalOpen(false);
      setName("");
      setAllDay(false);
      setDescription("");
      setLabel("");
      setStartDate("");
      setEndDate("");
    }
  };
  const handleEditEvent = () => {
    if (name && label) {
      let update = [...eventsData];
      update.splice(indexNo, 1, {
        key: indexNo,
        title: name,
        start: new Date(startDate.toString()),
        end: new Date(endDate.toString()),
        color: label,
        allDay: allDay,
        description: description,
        isChoosen: isChoosen,
      });
      setEventsData(update);
      setIsModalOpen(false);
      setName("");
      setAllDay(false);
      setDescription("");
      setLabel("");
      setStartDate("");
      setEndDate("");
    }
  };
  const handleDelete = () => {
    let update = [...eventsData];
    update.splice(indexNo, 1);
    const updateKey = update.map((e, i) => {
      if (i >= indexNo) {
        return { ...e, key: i };
      } else {
        return { ...e };
      }
    });
    setEventsData(updateKey);
    setIsModalOpen(false);
    setName("");
    setAllDay(false);
    setDescription("");
    setLabel("");
    setStartDate("");
    setEndDate("");
  };
  return (
    <Modal
      onCancel={handleCancel}
      footer={false}
      title="Add Event"
      open={isModalOpen}
    >
      <ThemeInput
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder={"Event Name"}
      />
      <div className="dateDiv">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format="DD-MM-YYYY"
            label="Start Date*"
            className="!w-full"
            slotProps={{ textField: { variant: "filled" } }}
            value={startDate ? dayjs(startDate) : dayjs(defaultDate.toString())}
            onChange={(e) => {
              console.log(e.$d.toString());
              setStartDate(e.$d.toString());
            }}
          />
        </LocalizationProvider>
      </div>
      <div className="dateDiv">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format="DD-MM-YYYY"
            minDate={
              startDate
                ? dayjs(startDate.toString())
                : dayjs(defaultDate.toString())
            }
            label="End Date*"
            className="!w-full"
            slotProps={{ textField: { variant: "filled" } }}
            value={endDate ? dayjs(endDate) : dayjs(defaultDate.toString())}
            onChange={(e) => setEndDate(e.$d.toString())}
          />
        </LocalizationProvider>
      </div>
      <div className="flex items-center -ml-2 mt-2">
        <Switch
          checked={allDay}
          onChange={(e) => setAllDay(e.target.checked)}
        />
        <p>All Day</p>
      </div>
      <ThemeSelect
        value={label}
        setValue={handleSelection}
        label={"Select Label"}
        className={"!mt-2"}
        object={labelsData}
      />
      <ThemeInput
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder={"Description"}
        multiline
        rows={4}
      />
      <div className="flex justify-end">
        {type === "add" ? (
          <Button type="primary mt-4" onClick={handleEvent}>
            Add Event
          </Button>
        ) : (
          type === "edit" && (
            <div className="mt-4">
              <DeleteOutlined
                onClick={handleDelete}
                style={{ fontSize: 18, cursor: "pointer" }}
              />
              <Button type="primary ml-3" onClick={handleEditEvent}>
                Save
              </Button>
            </div>
          )
        )}
      </div>
    </Modal>
  );
};

export default AddEventModal;
