const Events = [
  {
    key: 0,
    title: "First Meeting",
    start: new Date(2023, 6, 2),
    end: new Date(2023, 6, 2),
    color: "#0000FF",
    allDay: false,
    description: "First Meeting",
    isChoosen: true,
  },
  {
    key: 1,
    title: "Second Meeting",
    start: new Date(2023, 6, 4),
    end: new Date(2023, 6, 6),
    color: "#00FF00",
    allDay: true,
    description: "Second Meeting",
    isChoosen: true,
  },
];

export default Events;
