import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "antd";
import React from "react";

const columns3 = [
  {
    title: "Project name | Client",
    dataIndex: "name",
    key: "name",
    render: (text) => (
      <div className="flex ">
        <FontAwesomeIcon
          icon={faBriefcase}
          color="#b7ecec"
          fontSize={18}
          className="mr-2"
        />{" "}
        <span className="font-bold">{text}</span>
      </div>
    ),
  },
  {
    title: "Due Date",
    dataIndex: "duaDate",
    key: "duaDate",
  },

  {
    title: "Progress",
    dataIndex: "Progress",
    key: "Progress",
    className: "min-width-column",
    render: (text) => (
      <div className="">
        {text ? (
          <div class="relative pt-1">
            <div class="overflow-hidden h-1 mb-4 text-xs flex rounded bg-emerald-200">
              <div
                style={{ width: "30%" }}
                class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-[#0085E0]"
              ></div>
              <div
                style={{ width: "50%" }}
                class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"
              ></div>
              <div
                style={{ width: "20%" }}
                class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-[#FAD200]"
              ></div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    ),
  },

  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    className: "min-width-column",
    render: (text) => (
      <div className="bg-green-600 text-white px-1 rounded-md inline-block">
        {text}
      </div>
    ),
  },
];
const data3 = [
  {
    name: "Fixed Price Project (example)",
    Progress: "yes",
    duaDate: "11/09",
    Status: "In progress",
  },
  {
    name: "Fixed Price Project (example)",
    Progress: "yes",
    duaDate: "11/09",
    Status: "In progress",
  },
  {
    name: "Fixed Price Project (example)",
    Progress: "yes",
    duaDate: "11/09",
    Status: "In progress",
  },
];

const Projects = () => {
  return (
    <div className="shadow bg-white rounded-md p-3 h-[380px] overflow-auto scrol">
      <span class="mb-2 text-[12px] text-[#000] font-medium">Projects</span>
      <Table columns={columns3} dataSource={data3} pagination={false} />
    </div>
  );
};

export default Projects;
