import { useState } from "react";
import { Button, Radio } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { domy_profile } from "assets/images/images";
import { ThemeInput } from "components/components";

const Profile = ({ onContacts }) => {
  const [value, setValue] = useState(1);
  const [name, setName] = useState("Sabtain");
  const [email, setEmail] = useState("info@gmail.com");
  const [about, setAbout] = useState("myprofile");
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  return (
    <div>
      <div className="flex items-center p-4 border-b-2 border-[rgba(0,0,0,.2)]">
        <FontAwesomeIcon
          onClick={onContacts}
          icon={faArrowLeft}
          className="cursor-pointer"
        />
        <p className="ml-3 text-lg font-semibold">Profile</p>
      </div>
      <div className="flex justify-center mt-6">
        <div className="relative">
          <img src={domy_profile} alt="" className="rounded-full" />
          <div className="h-3 w-3 absolute right-6 bottom-6 bg-green-500 border-[1px] border-white rounded-full"></div>
        </div>
      </div>
      <div className="p-4">
        <ThemeInput
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={"Name"}
        />
        <ThemeInput
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={"Email"}
        />
        <ThemeInput
          value={about}
          onChange={(e) => setAbout(e.target.value)}
          placeholder={"About"}
        />
        <p className="mt-3 text-themeGray-medium300">Status</p>
        <Radio.Group
          onChange={onChange}
          className="flex flex-col mt-1"
          value={value}
        >
          <Radio value={1} className="my-1">
            <div className="flex items-center">
              <div className="h-[10px] w-[10px] bg-green-500 border-[1px] border-white rounded-full"></div>
              <div className="ml-2">
                <p>Online</p>
              </div>
            </div>
          </Radio>
          <Radio value={2} className="my-1">
            <div className="flex items-center">
              <div className="h-[10px] w-[10px] bg-orange-400 border-[1px] border-white rounded-full"></div>
              <div className="ml-2">
                <p>Away</p>
              </div>
            </div>
          </Radio>
          <Radio value={3} className="my-1">
            <div className="flex items-center">
              <div className="h-[10px] w-[10px] bg-red-500 border-[1px] border-white rounded-full"></div>
              <div className="ml-2">
                <p>Do not disturb</p>
              </div>
            </div>
          </Radio>
          <Radio value={4} className="my-1">
            <div className="flex items-center">
              <div className="h-[10px] w-[10px] bg-gray-400 border-[1px] border-white rounded-full"></div>
              <div className="ml-2">
                <p>Offline</p>
              </div>
            </div>
          </Radio>
        </Radio.Group>
        <div className="flex justify-end mt-3">
          <Button onClick={onContacts}>Cancel</Button>
          <Button onClick={onContacts} type="primary" className="ml-3">
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
