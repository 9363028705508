import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col } from "antd";
import React from "react";

const CardRedArrow = ({
  heading,
  title,
  percent,
  description,
  subValue,
  green,
}) => {
  return (
    <Col span={24} md={8} lg={6} xl={4}>
      <a
        href="/#"
        class="block max-w-sm h-[110px] p-4 pt-2  bg-white rounded-md shadow "
      >
        <span class="mb-2 text-[12px] text-[#000] font-medium truncate w-[160px] block m-0">
          {heading}
        </span>
        <div className="flex items-center mt-3 mb-1 justify-center">
          <div className="text-center">
            <FontAwesomeIcon
              icon={faPlay}
              rotation={90}
              color={green ? "#50B83C" : "#DE1818"}
              fontSize={25}
            />
            <p className="text-[#1F2226] text-[14px] hover:text-[#E43958] leading-[25px]">
              {percent}
            </p>
          </div>
          <div className="ml-3">
            <p class="text-[12px] text-[#494F59] font-medium">{title}</p>
            <p className="text-[#1F2226] text-[23px] hover:text-[#E43958] leading-[25px]">
              {description}
              <sub class="text-[12px] text-[#494F59] font-medium">
                {subValue}
              </sub>
            </p>
          </div>
        </div>
      </a>
    </Col>
  );
};

export default CardRedArrow;
