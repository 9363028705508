import React, { useState, useEffect } from "react";
import { Col, Row, Table } from "antd";
import { Container, FilterOptions, ThemeTable } from "components/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faDownload,
  faEllipsisVertical,
  faEnvelope,
  faPrint,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { home_icon } from "assets/icons/icons";
import { useParams } from "react-router-dom";

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};
const WorkInProgress = () => {
  const { type } = useParams();
  const [selectionType, setSelectionType] = useState("checkbox");
  const [inddata, setIndData] = useState([]);
  useEffect(() => {
    let update = [...inddata];
    for (let i = 0; i < 10; i++) {
      update.push({
        customers: "Lets do business",
        country: "America",
        city: "New York",
        industry: "Media",
        sector: "Online",
        size: "10-15",
        status: "Live",
        register: "25-01-2022",
      });
      setIndData(update);
    }
  }, []);
  const indcolumns = [
    {
      title: "Customers",
      dataIndex: "customers",
    },
    {
      title: "Country",
      dataIndex: "country",
    },
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "Industry",
      dataIndex: "industry",
    },
    {
      title: "Sector",
      dataIndex: "sector",
    },
    {
      title: "Size",
      dataIndex: "size",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Register",
      dataIndex: "register",
    },
  ];

  const columns = [
    {
      title: "Project",
      dataIndex: "Project",
      key: "Project",
      render: (text) => <div className="font-bold">{text}</div>,
    },
    {
      title: "Income (Budget)",
      dataIndex: "IncomeBudget",
      key: "IncomeBudget",
    },

    {
      title: "Income",
      dataIndex: "Income",
      key: "Income",
    },
    {
      title: "Recognizable income",
      dataIndex: "RecognizableIncome",
      key: "RecognizableIncome",
    },
    {
      title: "Cost (Budget)",
      dataIndex: "costBudget",
      key: "costBudget",
    },
    {
      title: "Cost",
      dataIndex: "Cost",
      key: "Cost",
    },
    {
      title: "Estimated duration",
      dataIndex: "EstimatedDuration",
      key: "EstimatedDuration",
    },
    {
      title: "Duration",
      dataIndex: "Duration",
      key: "Duration",
    },
    {
      title: "Project progress",
      dataIndex: "ProjectProgress",
      key: "ProjectProgress",
    },
  ];
  const data = [
    {
      key: "1",
      Project: "Fixed Price Project (example)",
      IncomeBudget: "11.400,00",
      Income: "5.400,00",
      RecognizableIncome: "6.954,00",
      Cost: "8.600,00",
      costBudget: "8.600,00",
      EstimatedDuration: "4.575,00",
      Duration: "100:00 h",
      ProjectProgress: "61,00%",
    },
    {
      key: "2",
      Project: "Fixed Price Project (example)",
      IncomeBudget: "11.400,00",
      Income: "5.400,00",
      RecognizableIncome: "6.954,00",
      Cost: "8.600,00",
      costBudget: "8.600,00",
      EstimatedDuration: "4.575,00",
      Duration: "100:00 h",
      ProjectProgress: "61,00%",
    },
    {
      key: "3",
      Project: "Fixed Price Project (example)",
      IncomeBudget: "11.400,00",
      Income: "5.400,00",
      RecognizableIncome: "6.954,00",
      Cost: "8.600,00",
      costBudget: "8.600,00",
      EstimatedDuration: "4.575,00",
      Duration: "100:00 h",
      ProjectProgress: "61,00%",
    },
    {
      key: "1",
      Project: "Fixed Price Project (example)",
      IncomeBudget: "11.400,00",
      Income: "5.400,00",
      RecognizableIncome: "6.954,00",
      Cost: "8.600,00",
      costBudget: "8.600,00",
      EstimatedDuration: "4.575,00",
      Duration: "100:00 h",
      ProjectProgress: "61,00%",
    },
  ];

  return (
    <Container superAdmin={type === "backend" && true} active={"Reports"}>
      <div className="w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
        <div className="bg-white" style={{ top: 54 }}>
          <div className="flex items-center justify-between px-5 py-3">
            <div className="flex items-center">
              <p className="font-medium text-sm">Company</p>
              <img src={home_icon} alt="" className="mr-3 ml-8" />
              <p className="font-medium text-sm">
                Home
                <span className="text-bgBlue"> / </span>
                <a href="#" className="text-bgBlue">
                  Report
                </a>
                <span className="text-bgBlue"> / </span>
                <a href="#" className="text-bgBlue">
                  Default
                </a>
              </p>
            </div>
            <div className="text-end pe-5">
              <div className="flex items-center">
                <div className="me-4">
                  <FontAwesomeIcon icon={faArrowsRotate} />
                </div>
                <p className="p-0 m-0 text-gray-700">
                  Data refreshed at jul 18, 2023 04:06 PM
                </p>
                <div className="flex ">
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faEnvelope} className="fs-1" />
                  </div>
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faDownload} />
                  </div>
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faPrint} />
                  </div>
                </div>
                <FontAwesomeIcon
                  icon={faEllipsisVertical}
                  className="fs-1 mx-4"
                />
                <FontAwesomeIcon icon={faXmark} className="fs-1" />
              </div>
            </div>
          </div>
          <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
        </div>
        <FilterOptions onTop />
        {type === "backend" ? (
          <div className="mt-7 mb-5">
            <div className="font-bold text-xl mb-2 pl-2">Customers</div>
            <section className="m-4 summery-sec border shadow-lg rounded-md">
              <ThemeTable data={inddata} columns={indcolumns} noSelectRows />
            </section>
          </div>
        ) : (
          <div className="mt-7 mb-5">
            <div className="px-4">
              <div className="font-bold text-xl mb-2">WIP report</div>
              <section className="summery-sec border shadow-lg rounded-md">
                <div>
                  <Row className="border-b py-4">
                    <Col
                      span={12}
                      sm={12}
                      md={8}
                      lg={6}
                      className="text-center my-2"
                    >
                      <p>Sum (Incl tax)</p>
                      <strong className="text-lg">
                        70.650,00 <span className="text-sm">EUR</span>
                      </strong>
                    </Col>
                    <Col
                      span={12}
                      sm={12}
                      md={8}
                      lg={6}
                      className="text-center my-2"
                    >
                      <p>Sum (Incl tax)</p>
                      <strong className="text-lg">
                        70.650,00 <span className="text-sm">EUR</span>
                      </strong>
                    </Col>
                    <Col
                      span={12}
                      sm={12}
                      md={8}
                      lg={6}
                      className="text-center my-2"
                    >
                      <p>Sum (Incl tax)</p>
                      <strong className="text-lg">
                        70.650,00 <span className="text-sm">EUR</span>
                      </strong>
                    </Col>
                    <Col
                      span={12}
                      sm={12}
                      md={8}
                      lg={6}
                      className="text-center my-2"
                    >
                      <p>Sum (Incl tax)</p>
                      <strong className="text-lg">
                        70.650,00 <span className="text-sm">EUR</span>
                      </strong>
                    </Col>
                  </Row>
                </div>
                <div className="bg-white rounded-md p-3 h-[380px] overflow-auto">
                  <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    rowSelection={{
                      type: selectionType,
                      ...rowSelection,
                    }}
                  />
                </div>
              </section>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default WorkInProgress;
