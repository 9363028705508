const ClientInvoice = [
  {
    key: 1,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Paid",
  },
  {
    key: 4,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Cancel",
  },
  {
    key: 5,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Send",
  },
  {
    key: 6,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Unpaid",
  },
  {
    key: 7,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Paid",
  },
  {
    key: 8,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Cancel",
  },
  {
    key: 9,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Send",
  },
  {
    key: 10,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Unpaid",
  },
  {
    key: 11,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Paid",
  },
  {
    key: 12,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Cancel",
  },
  {
    key: 13,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Send",
  },
  {
    key: 14,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Unpaid",
  },
  {
    key: 15,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Paid",
  },
];

export default ClientInvoice;
