const TicketsData = [
  {
    key: 1,
    id: `20230918-084927`,
    subject: "Test ticket",
    customer: `Lisa Elizabeth`,
    email: `example@gmail.com`,
    next_payment_date: `20-05-2023`,
    phone: `02345678`,
    category: `Administration`,
    status: `New`,
  },
  {
    key: 2,
    id: `20230918-084927`,
    subject: "Test ticket",
    customer: `Lisa Elizabeth`,
    email: `example@gmail.com`,
    next_payment_date: `20-05-2023`,
    phone: `02345678`,
    category: `Abuse`,
    status: `Waiting`,
  },
  {
    key: 3,
    id: `20230918-084927`,
    subject: "Test ticket",
    customer: `Lisa Elizabeth`,
    email: `example@gmail.com`,
    next_payment_date: `20-05-2023`,
    phone: `02345678`,
    category: `Problem`,
    status: `Draft`,
  },
  {
    key: 4,
    id: `20230918-084927`,
    subject: "Test ticket",
    customer: `Lisa Elizabeth`,
    email: `example@gmail.com`,
    next_payment_date: `20-05-2023`,
    phone: `02345678`,
    category: `Administration`,
    status: `Paid`,
  },
  {
    key: 5,
    id: `20230918-084927`,
    subject: "Test ticket",
    customer: `Lisa Elizabeth`,
    email: `example@gmail.com`,
    next_payment_date: `20-05-2023`,
    phone: `02345678`,
    category: `Abuse`,
    status: `Sent`,
  },
  {
    key: 6,
    id: `20230918-084927`,
    subject: "Test ticket",
    customer: `Lisa Elizabeth`,
    email: `example@gmail.com`,
    next_payment_date: `20-05-2023`,
    phone: `02345678`,
    category: `Problem`,
    status: `New`,
  },
  {
    key: 7,
    id: `20230918-084927`,
    subject: "Test ticket",
    customer: `Lisa Elizabeth`,
    email: `example@gmail.com`,
    next_payment_date: `20-05-2023`,
    phone: `02345678`,
    category: `Administration`,
    status: `Waiting`,
  },
  {
    key: 8,
    id: `20230918-084927`,
    subject: "Test ticket",
    customer: `Lisa Elizabeth`,
    email: `example@gmail.com`,
    next_payment_date: `20-05-2023`,
    phone: `02345678`,
    category: `Abuse`,
    status: `Draft`,
  },
  {
    key: 9,
    id: `20230918-084927`,
    subject: "Test ticket",
    customer: `Lisa Elizabeth`,
    email: `example@gmail.com`,
    next_payment_date: `20-05-2023`,
    phone: `02345678`,
    category: `Problem`,
    status: `Paid`,
  },
  {
    key: 10,
    id: `20230918-084927`,
    subject: "Test ticket",
    customer: `Lisa Elizabeth`,
    email: `example@gmail.com`,
    next_payment_date: `20-05-2023`,
    phone: `02345678`,
    category: `Administration`,
    status: `Sent`,
  },
  {
    key: 11,
    id: `20230918-084927`,
    subject: "Test ticket",
    customer: `Lisa Elizabeth`,
    email: `example@gmail.com`,
    next_payment_date: `20-05-2023`,
    phone: `02345678`,
    category: `Abuse`,
    status: `New`,
  },
  {
    key: 12,
    id: `20230918-084927`,
    subject: "Test ticket",
    customer: `Lisa Elizabeth`,
    email: `example@gmail.com`,
    next_payment_date: `20-05-2023`,
    phone: `02345678`,
    category: `Problem`,
    status: `Waiting`,
  },
  {
    key: 13,
    id: `20230918-084927`,
    subject: "Test ticket",
    customer: `Lisa Elizabeth`,
    email: `example@gmail.com`,
    next_payment_date: `20-05-2023`,
    phone: `02345678`,
    category: `Administration`,
    status: `Draft`,
  },
  {
    key: 14,
    id: `20230918-084927`,
    subject: "Test ticket",
    customer: `Lisa Elizabeth`,
    email: `example@gmail.com`,
    next_payment_date: `20-05-2023`,
    phone: `02345678`,
    category: `Abuse`,
    status: `Paid`,
  },
  {
    key: 15,
    id: `20230918-084927`,
    subject: "Test ticket",
    customer: `Lisa Elizabeth`,
    email: `example@gmail.com`,
    next_payment_date: `20-05-2023`,
    phone: `02345678`,
    category: `Problem`,
    status: `Sent`,
  },
  {
    key: 16,
    id: `20230918-084927`,
    subject: "Test ticket",
    customer: `Lisa Elizabeth`,
    email: `example@gmail.com`,
    next_payment_date: `20-05-2023`,
    phone: `02345678`,
    category: `Administration`,
    status: `New`,
  },
  {
    key: 17,
    id: `20230918-084927`,
    subject: "Test ticket",
    customer: `Lisa Elizabeth`,
    email: `example@gmail.com`,
    next_payment_date: `20-05-2023`,
    phone: `02345678`,
    category: `Abuse`,
    status: `Waiting`,
  },
  {
    key: 18,
    id: `20230918-084927`,
    subject: "Test ticket",
    customer: `Lisa Elizabeth`,
    email: `example@gmail.com`,
    next_payment_date: `20-05-2023`,
    phone: `02345678`,
    category: `Problem`,
    status: `Draft`,
  },
  {
    key: 19,
    id: `20230918-084927`,
    subject: "Test ticket",
    customer: `Lisa Elizabeth`,
    email: `example@gmail.com`,
    next_payment_date: `20-05-2023`,
    phone: `02345678`,
    category: `Administration`,
    status: `Paid`,
  },
  {
    key: 20,
    id: `20230918-084927`,
    subject: "Test ticket",
    customer: `Lisa Elizabeth`,
    email: `example@gmail.com`,
    next_payment_date: `20-05-2023`,
    phone: `02345678`,
    category: `Abuse`,
    status: `Sent`,
  },
];

export default TicketsData;
