import { Tabs } from "antd";
import Status from "./status/status";

const Quotes = () => {
  const items = [
    {
      key: "1",
      label: `Status`,
      children: <Status />,
    },
  ];
  return (
    <div className="ml-2">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default Quotes;
