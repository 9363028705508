import { ThemeInput, ThemeSelect } from "components/components";

const GeneratedNumbers = () => {
  const data = [
    "Invoice",
    "Deals",
    "Leads",
    "Credit",
    "Project",
    "Tasks",
    "Recurring invoices",
    "Expenses",
    "Recurring expenses",
    "Payments",
    "Contact persons",
  ];
  return (
    <div className="bg-themeGray-light200 p-3 mt-4 min-h-[73.8vh] desktop:min-h-[79vh] bigDesktop:min-h-[87vh]">
      <div className="bg-white p-3 shadow-md">
        {data.map((e, i) => {
          return (
            <div key={i} className="grid grid-cols-3 gap-5 mb-2">
              <ThemeInput placeholder={e} />
              <ThemeSelect
                className={"!mt-1"}
                label={`${e} number format`}
                data={["format 1", "format 2", "format 3"]}
              />
              <div className="flex items-center justify-end">
                <p>Example</p>
                <div className="bg-themeGray-light200 w-36 h-10 flex items-center rounded-md px-2 ml-3">
                  <p>INV00001</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GeneratedNumbers;
