import { Checkbox } from "@mui/material";
import { Button, Input } from "antd";
import { cancel_icon } from "assets/icons/icons";
import { ThemeInput } from "components/components";
import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";

const Template = () => {
  const [selected, setSelected] = useState("");
  const tickets = [
    "New Ticket Opened (Opened by Staff, Sent to Customer)",
    "Ticket Reply (Sent to Customer)",
    "New Ticket Opened - Autoresponse",
    "New Ticket Created (Opened by Customer, Sent to Staff Members)",
    "Ticket Reply (Sent to Staff)",
    "Auto Close Ticket",
    "New Ticket Assigned (Sent to Staff)",
  ];
  const estimates = [
    "Send Estimate to Customer",
    "Estimate Already Sent to Customer",
    "Estimate Declined (Sent to Staff)",
    "Estimate Accepted (Sent to Staff)",
    "Thank You Email (Sent to Customer After Accept)",
    "Estimate Expiration Reminder",
  ];
  return (
    <div className="bg-themeGray-light200 p-3 mt-4 min-h-[73.8vh] desktop:min-h-[79vh] bigDesktop:min-h-[87vh]">
      {selected ? (
        <>
          <div className="bg-white p-3 shadow-md">
            <div className="flex mb-2 items-center justify-between">
              <p className="text-bgBlue">{selected}</p>
              <Button
                type="primary"
                className="!text-sm p-0 flex items-center justify-center font-medium"
                style={{
                  width: 85,
                }}
                onClick={() => {
                  setSelected("");
                }}
              >
                Go Back
              </Button>
            </div>
            <p className="text-sm mt-1 pb-2">
              #name# #proposal# #company# #phone# #mobile# #fax# #website#
              #address# #street# #zip# #city# #country# #email# #description#
            </p>
            <ThemeInput
              disabled
              placeholder={"Template Title"}
              defaultValue={selected}
            />
            <ThemeInput placeholder={"Subject"} />
            <ThemeInput placeholder={"From name"} />
            <div className="flex items-center">
              <Checkbox />
              <p>Send as Plaintext</p>
            </div>
            <div className="flex items-center">
              <Checkbox />
              <p>Disabled</p>
            </div>
            <div
              style={{ minHeight: 250 }}
              className="border-[1px] mb-5 mt-7 border-[rgba(0,0,0,.1)]"
            >
              <Editor
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName px-3"
              />
            </div>
            <Button>Preview {`>>`}</Button>
            <p className="text-sm mt-1 opacity-50">
              Click on the dynamic fields (e.g. #name#) on the left to add them
              to your signature, then click on the{" "}
              <span className="font-bold">Preview</span> button to see what they
              will look like in your e-mail.
            </p>
            <Input.TextArea rows={8} className="mt-4" />
          </div>
        </>
      ) : (
        <>
          <div className="bg-white p-3 shadow-md">
            <div className="border-y-2 text-sm flex items-center justify-between border-[rgba(0,0,0,.2)] py-3 px-2">
              <p className="font-medium">Tickets</p>
              <a href="#">Enable All Disable All</a>
            </div>
            <div className="text-sm border-2 border-[rgba(0,0,0,.2)] rounded-md mt-4">
              <div className="p-3">
                <p>Template Name</p>
              </div>
              {tickets.map((e, i) => {
                return (
                  <div
                    key={i}
                    className="text-bgBlue flex items-center justify-between border-t-2 border-[rgba(0,0,0,.2)] p-3"
                  >
                    <p
                      className="cursor-pointer text-bgBlue"
                      onClick={() => setSelected(e)}
                    >
                      {e}
                    </p>
                    <a href="">Disable</a>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="bg-white p-3 shadow-md mt-3">
            <div className="border-y-2 text-sm flex items-center justify-between border-[rgba(0,0,0,.2)] py-3 px-2">
              <p className="font-medium">Estimates</p>
              <a href="#">Enable All Disable All</a>
            </div>
            <div className="text-sm border-2 border-[rgba(0,0,0,.2)] rounded-md mt-4">
              <div className="p-3">
                <p>Template Name</p>
              </div>
              {estimates.map((e, i) => {
                return (
                  <div
                    key={i}
                    className="text-bgBlue flex items-center justify-between border-t-2 border-[rgba(0,0,0,.2)] p-3"
                  >
                    <a href="">{e}</a>
                    <a href="">Disable</a>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Template;
