import { useState, useRef } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import { Container } from "components/components";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Events } from "constant/constant";
import AddEventModal from "./addEventModal";
import Labels from "./labels";
import AddLabelModal from "./addLabelModal";

const Calendar = () => {
  const [addEventModal, setAddEventModal] = useState(false);
  const [isChoosen, setIsChoosen] = useState("");
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [label, setLabel] = useState("");
  const [addLabelModal, setAddLabelModal] = useState(false);
  const [indexNo, setIndexNo] = useState("");
  const [eventsData, setEventsData] = useState(Events);
  const filterEvents = eventsData.filter((val) => {
    if (val.isChoosen) return val;
  });
  const [description, setDescription] = useState("");
  const [allDay, setAllDay] = useState("");
  const [type, setType] = useState("");
  const [defaultDate, setDefaultDate] = useState("");
  const [calenderView, setCalenderView] = useState("Month");
  const calendarRef = useRef();
  const calendarApi = () => calendarRef.current?.getApi();
  const [labels, setLabels] = useState([
    {
      title: "Work",
      color: "#0000FF",
      isChoosen: true,
    },
    {
      title: "Personal",
      color: "#00FF00",
      isChoosen: true,
    },
  ]);
  const onDateClick = (arg) => {
    setAddEventModal(true);
    setType("add");
    setDefaultDate(arg.date);
  };
  const handleEvent = (clickInfo) => {
    setType("edit");
    const { event } = clickInfo;
    const { title } = event;
    const data = eventsData.find((e) => {
      if (e.title === title) return e;
    });
    const { key } = data;
    setIndexNo(key);
    setName(data.title);
    setStartDate(data.start);
    setEndDate(data.end);
    setLabel(data.color);
    setAllDay(data.allDay);
    setDescription(data.description);
    setIsChoosen(data.isChoosen);
    setAddEventModal(true);
  };
  const handleMenuClick = (e) => {
    calendarApi().changeView(e.key);
    e.key === "dayGridMonth" && setCalenderView("Month");
    e.key === "timeGridWeek" && setCalenderView("Week");
    e.key === "timeGridDay" && setCalenderView("Day");
  };
  const items = [
    {
      label: <p>Month</p>,
      key: "dayGridMonth",
    },
    {
      label: <p>Week</p>,
      key: "timeGridWeek",
    },
    {
      label: <p>Day</p>,
      key: "timeGridDay",
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Container>
      <AddEventModal
        isModalOpen={addEventModal}
        setIsModalOpen={(e) => setAddEventModal(e)}
        labelsData={labels}
        defaultDate={defaultDate}
        eventsData={eventsData}
        setEventsData={(e) => setEventsData(e)}
        name={name}
        setName={(e) => setName(e)}
        startDate={startDate}
        setStartDate={(e) => setStartDate(e)}
        endDate={endDate}
        setEndDate={(e) => setEndDate(e)}
        label={label}
        setLabel={(e) => setLabel(e)}
        type={type}
        indexNo={indexNo}
        description={description}
        setDescription={(e) => setDescription(e)}
        allDay={allDay}
        setAllDay={(e) => setAllDay(e)}
        isChoosen={isChoosen}
        setIsChoosen={(e) => setIsChoosen(e)}
      />
      <AddLabelModal
        isModalOpen={addLabelModal}
        setIsModalOpen={(e) => setAddLabelModal(e)}
        data={labels}
        setData={(e) => setLabels(e)}
        eventsData={eventsData}
        setEventsData={(e) => setEventsData(e)}
      />
      <div className="p-5 overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol flex">
        <div className="w-[20%]">
          <Labels
            data={labels}
            setData={(e) => setLabel(e)}
            onClick={() => setAddLabelModal(true)}
            eventsData={eventsData}
            setEventsData={(e) => setEventsData(e)}
          />
        </div>
        <div className="w-[80%] !z-0">
          <div className="relative">
            <div className="absolute right-48">
              <Dropdown
                trigger={["click"]}
                menu={menuProps}
                className="w-32 h-9"
              >
                <Button>
                  <Space className="flex justify-between">
                    {calenderView}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </div>
          </div>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            editable
            selectable
            selectMirror
            dayMaxEvents
            weekends
            events={filterEvents}
            eventClick={handleEvent}
            dateClick={onDateClick}
            ref={calendarRef}
          />
          <div className="h-1"></div>
        </div>
      </div>
    </Container>
  );
};

export default Calendar;
