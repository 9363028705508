import { useLayoutEffect, Fragment } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { Calendar, Chat, Login } from "pages/pages";
import {
  CompanyAdmin,
  AddCompanyMobAdmin,
  CompanyDetailsAdmin,
  ContactPersonAdmin,
  ExpensesAdmin,
  AddExpensesMobAdmin,
  ExpenseDetailsAdmin,
  InvoicesAdmin,
  PaymentsAdmin,
  AddPaymentAdmin,
  PaymentDetailsAdmin,
  ProductsAdmin,
  AddProductMobAdmin,
  ProductDetailsAdmin,
  ProjectsAdmin,
  AddProjectAdmin,
  ProjectDetailsAdmin,
  CardDetailsAdmin,
  FaqsAdmin,
  SupportAdmin,
  QuotesAdmin,
  CreditsAdmin,
  LeadsAdmin,
  TasksAdmin,
  TimeTrackingAdmin,
  Settings,
  OverviewAdmin,
  FinanceAdmin,
  ProjectManagementAdmin,
  SalesAdmin,
  SummaryAdmin,
  ReportAdmin,
  WorkInProgressAdmin,
  FinanceDetailedReportAdmin,
  FinanceSummeryAdmin,
  PipelineAdmin,
  FinanceMonitorAdmin,
  AgedDebtorsAdmin,
  CustomerSalesAdmin,
  ProfitLossAdmin,
  ExpensesReportAdmin,
  SourcesAdmin,
} from "pages/app/app";
import {
  CompanySuperAdmin,
  AppSettingsSuperAdmin,
  InvoicesSuperAdmin,
  TicketsSuperAdmin,
  AdminFaqSuper,
  PackagesSuperAdmin,
  DashboardSuperAdmin,
  TaxReportSuperAdmin,
} from "pages/backend/backend";
import {
  AccountClient,
  CreditClient,
  DashboardClient,
  EditAccountClient,
  InvoicesClient,
  ProjectsClient,
  QuotesClient,
  TasksClient,
} from "pages/client/client";

function ScrollToTop() {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function AppRouter() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/:type/chat" element={<Chat />} />
        <Route exact path="/:type/support" element={<SupportAdmin />} />
        <Route exact path="/app/dashboard" element={<OverviewAdmin />} />
        <Route
          exact
          path="/app/project-management"
          element={<ProjectManagementAdmin />}
        />
        <Route exact path="/backend/tax" element={<TaxReportSuperAdmin />} />
        <Route exact path="/app/sales" element={<SalesAdmin />} />
        <Route exact path="/app/finance" element={<FinanceAdmin />} />
        <Route exact path="/:type/summary" element={<SummaryAdmin />} />
        <Route exact path="/:type/report" element={<ReportAdmin />} />
        <Route
          exact
          path="/:type/aged-debtors"
          element={<AgedDebtorsAdmin />}
        />
        <Route
          exact
          path="/app/customer-sales"
          element={<CustomerSalesAdmin />}
        />
        <Route exact path="/:type/profit-loss" element={<ProfitLossAdmin />} />
        <Route
          exact
          path="/:type/expense-reports"
          element={<ExpensesReportAdmin />}
        />
        <Route exact path="/app/sources" element={<SourcesAdmin />} />
        <Route
          exact
          path="/:type/work-in-progress"
          element={<WorkInProgressAdmin />}
        />
        <Route
          exact
          path="/:type/languages"
          element={<WorkInProgressAdmin />}
        />
        <Route
          exact
          path="/:type/finance-detailed-report"
          element={<FinanceDetailedReportAdmin />}
        />
        <Route
          exact
          path="/:type/finance-summary"
          element={<FinanceSummeryAdmin />}
        />
        <Route exact path="/app/pipeline" element={<PipelineAdmin />} />
        <Route
          exact
          path="/:type/finance-monitor"
          element={<FinanceMonitorAdmin />}
        />
        <Route exact path="/app/company" element={<CompanyAdmin />} />
        <Route exact path="/backend/company" element={<CompanySuperAdmin />} />
        <Route
          exact
          path="/backend/app-settings"
          element={<AppSettingsSuperAdmin />}
        />
        <Route
          exact
          path="/backend/invoices"
          element={<InvoicesSuperAdmin />}
        />
        <Route exact path="/backend/tickets" element={<TicketsSuperAdmin />} />
        <Route exact path="/backend/admin-faq" element={<AdminFaqSuper />} />
        <Route
          exact
          path="/backend/packages"
          element={<PackagesSuperAdmin />}
        />
        <Route
          exact
          path="/backend/dashboard"
          element={<DashboardSuperAdmin />}
        />
        <Route exact path="/company" element={<CompanySuperAdmin />} />
        <Route exact path="/faqs" element={<FaqsAdmin />} />
        <Route exact path="/app/products" element={<ProductsAdmin />} />
        <Route exact path="/app/payments" element={<PaymentsAdmin />} />
        <Route exact path="/app/expenses" element={<ExpensesAdmin />} />
        <Route exact path="/app/invoices" element={<InvoicesAdmin />} />
        <Route exact path="/app/quotes" element={<QuotesAdmin />} />
        <Route exact path="/app/leads" element={<LeadsAdmin />} />
        <Route exact path="/app/credits" element={<CreditsAdmin />} />
        <Route exact path="/app/tasks" element={<TasksAdmin />} />
        <Route exact path="/app/projects" element={<ProjectsAdmin />} />
        <Route exact path="/app/settings" element={<Settings />} />
        <Route
          exact
          path="/app/time-tracking"
          element={<TimeTrackingAdmin />}
        />
        <Route
          exact
          path="/app/contact-person"
          element={<ContactPersonAdmin />}
        />
        <Route exact path="/company/add" element={<AddCompanyMobAdmin />} />
        <Route
          exact
          path="/company/details"
          element={<CompanyDetailsAdmin />}
        />
        <Route exact path="/products/add" element={<AddProductMobAdmin />} />
        <Route
          exact
          path="/products/details"
          element={<ProductDetailsAdmin />}
        />
        <Route exact path="/payments/add" element={<AddPaymentAdmin />} />
        <Route
          exact
          path="/payments/details"
          element={<PaymentDetailsAdmin />}
        />
        <Route exact path="/expenses/add" element={<AddExpensesMobAdmin />} />
        <Route
          exact
          path="/expenses/details"
          element={<ExpenseDetailsAdmin />}
        />
        <Route exact path="/app/calendar" element={<Calendar />} />
        <Route exact path="/app/projects/add" element={<AddProjectAdmin />} />
        <Route
          exact
          path="/app/projects/details"
          element={<ProjectDetailsAdmin />}
        />
        <Route
          exact
          path="/app/projects/card-details"
          element={<CardDetailsAdmin />}
        />
        <Route exact path="/client/dashboard" element={<DashboardClient />} />
        <Route exact path="/client/invoices" element={<InvoicesClient />} />
        <Route exact path="/client/quotes" element={<QuotesClient />} />
        <Route exact path="/client/credit" element={<CreditClient />} />
        <Route exact path="/client/projects" element={<ProjectsClient />} />
        <Route exact path="/client/tasks" element={<TasksClient />} />
        <Route exact path="/:type/account" element={<AccountClient />} />
        <Route
          exact
          path="/:type/account/edit"
          element={<EditAccountClient />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
