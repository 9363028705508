import { useState } from "react";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input } from "antd";

const Source = () => {
  const [data, setData] = useState([
    {
      title: "Facebook",
      leads: 25,
    },
    {
      title: "Google",
      leads: 26,
    },
  ]);
  const onChange = (val, i) => {
    let update = [...data];
    update[i].title = val.target.value;
    setData(update);
  };
  const handleDeleteStatus = (i) => {
    let update = [...data];
    update.splice(i, 1);
    setData(update);
  };
  const handleAddStatus = () => {
    let update = [...data];
    update.push({
      title: "Write text",
      leads: 0,
    });
    setData(update);
  };
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        {data.map((e, i) => {
          return (
            <div
              key={i}
              className="grid grid-cols-3 py-3 border-b-2 border-[rgba(0,0,0,.2)]"
            >
              <Input
                className="text-primary"
                value={e.title}
                onChange={(val) => onChange(val, i)}
              />
              <p className="text-themeGray-dark text-center">
                Total Leads: {e.leads}
              </p>
              <div className="flex items-center justify-end">
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className="opacity-50 cursor-pointer ml-3"
                  onClick={() => handleDeleteStatus(i)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <Button type="primary w-full mt-4 h-9" onClick={handleAddStatus}>
        New source
      </Button>
    </div>
  );
};

export default Source;
