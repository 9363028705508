import { useState } from "react";
import {
  ThemeAutoComplete,
  ThemeInput,
  ThemeSelect,
} from "components/components";

const Details = () => {
  const [data, setData] = useState({});
  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        <ThemeInput
          noRequired
          placeholder={"Name"}
          onChange={onChange}
          value={data?.name}
          name={"name"}
          clearBtn={data.name}
          onClear={() => setData({ ...data, name: "" })}
        />
        <ThemeInput
          noRequired
          placeholder={"ID Number"}
          onChange={onChange}
          value={data?.id}
          name={"id"}
          clearBtn={data.id}
          onClear={() => setData({ ...data, id: "" })}
        />
        <ThemeInput
          noRequired
          placeholder={"VAT Number"}
          onChange={onChange}
          value={data?.vat}
          name={"vat"}
          clearBtn={data.vat}
          onClear={() => setData({ ...data, vat: "" })}
        />
        <ThemeInput
          noRequired
          placeholder={"Website"}
          onChange={onChange}
          value={data?.website}
          name={"website"}
          clearBtn={data.website}
          onClear={() => setData({ ...data, website: "" })}
        />
        <ThemeInput
          noRequired
          placeholder={"Email"}
          onChange={onChange}
          value={data?.email}
          name={"email"}
          clearBtn={data.email}
          onClear={() => setData({ ...data, email: "" })}
        />
        <ThemeInput
          noRequired
          placeholder={"Phone"}
          onChange={onChange}
          value={data?.phone}
          name={"phone"}
          clearBtn={data.phone}
          onClear={() => setData({ ...data, phone: "" })}
        />
        <ThemeSelect
          className={"!pb-1"}
          label={"Select"}
          data={["1 - 3", "4 - 10", "11 - 50", "51 - 100", "101 - 500", "500+"]}
        />
        <ThemeAutoComplete
          label={"Industry"}
          data={["Accounting & Legal", "Advertising", "Aeropace"]}
        />
      </div>
    </div>
  );
};

export default Details;
