const headerColor = JSON.parse(localStorage.getItem("color"))?.header
  ? JSON.parse(localStorage.getItem("color"))?.header
  : "#2C2C2C";
const addnewColor = JSON.parse(localStorage.getItem("color"))?.addnew
  ? JSON.parse(localStorage.getItem("color"))?.addnew
  : "#00A1E4";
const cancelColor = JSON.parse(localStorage.getItem("color"))?.cancel
  ? JSON.parse(localStorage.getItem("color"))?.cancel
  : "#fff";
const footerColor = JSON.parse(localStorage.getItem("color"))?.footer
  ? JSON.parse(localStorage.getItem("color"))?.footer
  : "#fff";
const logoutColor = JSON.parse(localStorage.getItem("color"))?.logout
  ? JSON.parse(localStorage.getItem("color"))?.logout
  : "#00A1E4";
const menubarColor = JSON.parse(localStorage.getItem("color"))?.menubar
  ? JSON.parse(localStorage.getItem("color"))?.menubar
  : "#fff";
const saveColor = JSON.parse(localStorage.getItem("color"))?.save
  ? JSON.parse(localStorage.getItem("color"))?.save
  : "#4D9A19";
const searchColor = JSON.parse(localStorage.getItem("color"))?.search
  ? JSON.parse(localStorage.getItem("color"))?.search
  : "#1F1F1F";
const submenuColor = JSON.parse(localStorage.getItem("color"))?.submenu
  ? JSON.parse(localStorage.getItem("color"))?.submenu
  : "#fff";
const timerColor = JSON.parse(localStorage.getItem("color"))?.timer
  ? JSON.parse(localStorage.getItem("color"))?.timer
  : "#00A1E4";
const headerIcons = JSON.parse(localStorage.getItem("color"))?.headIco
  ? JSON.parse(localStorage.getItem("color"))?.headIco
  : "#fff";
const sidebarIcons = JSON.parse(localStorage.getItem("color"))?.sideIco
  ? JSON.parse(localStorage.getItem("color"))?.sideIco
  : "#000";
const submenuIcons = JSON.parse(localStorage.getItem("color"))?.submenuIco
  ? JSON.parse(localStorage.getItem("color"))?.submenuIco
  : "#000";
const footerIcons = JSON.parse(localStorage.getItem("color"))?.footIco
  ? JSON.parse(localStorage.getItem("color"))?.footIco
  : "#000";

export {
  headerColor,
  addnewColor,
  cancelColor,
  footerColor,
  logoutColor,
  menubarColor,
  saveColor,
  searchColor,
  submenuColor,
  timerColor,
  headerIcons,
  sidebarIcons,
  submenuIcons,
  footerIcons,
};
