import { Checkbox, Switch } from "@mui/material";

const Permissions = () => {
  const data = [
    "All",
    "Client",
    "Product",
    "Invoice",
    "Recurring Invoice",
    "Payment",
    "Quote",
    "Credit",
    "Project",
  ];
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2">
      <div className="bg-white p-3 shadow-md">
        <div className="flex items-center justify-between mb-5 mt-1">
          <div>
            <p className="text-sm">Administrator</p>
            <p className="text-themeGray-dark text-xs">
              Allow user to manage users, change settings and modify all records
            </p>
          </div>
          <Switch />
        </div>
        <div className="flex items-center justify-between my-5">
          <div>
            <p className="text-sm">Dashboard</p>
            <p className="text-themeGray-dark text-xs">
              Allow user to access the dashboard, data is limited to available
              permissions
            </p>
          </div>
          <Switch />
        </div>
        <div className="flex items-center justify-between mt-5 mb-1">
          <div>
            <p className="text-sm">Reports</p>
            <p className="text-themeGray-dark text-xs">
              Allow user to access the reports, data is limited to available
              permissions
            </p>
          </div>
          <Switch />
        </div>
      </div>
      <div className="bg-white p-3 shadow-md mt-4">
        <div className="grid grid-cols-6 py-3 border-b-[1px] border-[rgba(0,0,0,.2)]">
          <div className="col-span-3"></div>
          <div>
            <p>Create</p>
          </div>
          <div>
            <p>View All</p>
          </div>
          <div>
            <p>Edit All</p>
          </div>
        </div>
        {data.map((e, i) => {
          return (
            <div key={i} className="grid grid-cols-6 py-3 border-b-[1px] border-[rgba(0,0,0,.2)]">
              <div className="col-span-3 flex items-center">
                <p>{e}</p>
              </div>
              <div>
                <Checkbox />
              </div>
              <div>
                <Checkbox />
              </div>
              <div>
                <Checkbox />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Permissions;
