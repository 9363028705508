import { Button } from "antd";
import { calender } from "assets/icons/icons";

const Summary = ({ data, setData }) => {
  const handleTabs = (i) => {
    let update = [...data];
    update.map((val, ind) =>
      ind === i ? (update[i].selected = true) : (val.selected = false)
    );
    setData(update);
  };
  return (
    <div>
      <div className="shadow-md mt-2 mx-2 flex items-center justify-between py-4 px-3">
        <div>
          <p className="text-lg font-semibold">Robert Andrews</p>
          <div className="flex text-sm items-center">
            <p>Sales Manager</p>
            <div className="h-2 w-2 bg-themeGray-medium mx-3 rounded-full"></div>
            <p>Acme Corp</p>
          </div>
        </div>
        <div>
          <Button>Email</Button>
          <Button>Call</Button>
          <Button type="primary" className="ml-3">
            Convert to client
          </Button>
        </div>
      </div>
      <div className="shadow-md mt-2 mx-2 py-4 px-3">
        <p className="text-sm pb-1 font-medium">Stage</p>
        <div>
          {data.map((e, i) => {
            return (
              <Button
                onClick={() => handleTabs(i)}
                key={i}
                className={`h-10 ${
                  i === 0
                    ? "!rounded-tr-none !rounded-br-none"
                    : i === data.length - 1
                    ? "!rounded-tl-none !rounded-bl-none"
                    : "!rounded-tr-none !rounded-br-none !rounded-tl-none !rounded-bl-none"
                }`}
                type={e.selected ? "primary" : "default"}
              >
                {e.title}
              </Button>
            );
          })}
        </div>
      </div>
      <div className="shadow-md mt-2 px-3 mx-2">
        <div className="h-14 border-b-[1px] flex items-center border-[rgba(0,0,0,.1)]">
          <p className="font-bold text-sm ml-1">Assignees</p>
        </div>
        <div className="flex items-center justify-between pb-4">
          <div className="pt-3">
            <p className="text-sm">Phone</p>
            <p className="text-sm mt-3">Emails</p>
            <p className="text-sm mt-3">Address</p>
            <p className="text-sm mt-3">Zip code</p>
            <p className="text-sm mt-3">City</p>
          </div>
          <div className="pt-3 text-end">
            <p className="text-bgBlue cursor-pointer text-sm">
              1-426-239-5787 x2826
            </p>
            <p className="text-bgBlue cursor-pointer text-sm mt-3">
              rob.andrews@gmail.com
            </p>
            <p className="text-lightBlack text-sm mt-3">
              Jakubowski Trafficway 549
            </p>
            <p className="text-lightBlack text-sm mt-3">11099-9079</p>
            <p className="text-lightBlack text-sm mt-3">United Kingdom</p>
          </div>
        </div>
      </div>
      <div className="shadow-md mt-2 px-3 mx-2">
        <div className="h-14 border-b-[1px] flex items-center border-[rgba(0,0,0,.1)]">
          <p className="font-bold text-sm ml-1">Budget</p>
        </div>
        <div className="flex items-center justify-between pb-4">
          <div className="pt-3">
            <p className="text-sm">Lead value</p>
            <p className="text-sm mt-3">Probalitity</p>
          </div>
          <div className="pt-3 text-end">
            <p className="text-lightBlack text-sm">$4.400,00</p>
            <p className="text-themeGray-dark text-sm mt-3">24%</p>
          </div>
        </div>
      </div>
      <div className="shadow-md mt-2 px-3 mx-2">
        <div className="h-14 border-b-[1px] flex items-center border-[rgba(0,0,0,.1)]">
          <p className="font-bold text-sm ml-1">Company</p>
        </div>
        <div className="flex items-center justify-between pb-4 mb-2">
          <div className="pt-3">
            <p className="text-sm">Company name</p>
            <p className="text-sm mt-3">Phone</p>
            <p className="text-sm mt-3">Address</p>
            <p className="text-sm mt-3">Zip</p>
            <p className="text-sm mt-3">City</p>
            <p className="text-sm mt-3">Country</p>
            <p className="text-sm mt-3">Email</p>
            <p className="text-sm mt-3">Website</p>
          </div>
          <div className="pt-3 text-end">
            <p className="text-lightBlack text-sm">Gas Appliance Repairer</p>
            <p className="text-bgBlue cursor-pointer text-sm mt-3">
              1-426-239-5787 x2826
            </p>
            <p className="text-themeGray-dark text-sm mt-3">
              Jakubowski Trafficway 549
            </p>
            <p className="text-themeGray-dark text-sm mt-3">11099-9079</p>
            <p className="text-themeGray-dark text-sm mt-3">United Kingdom</p>
            <p className="text-themeGray-dark text-sm mt-3">America</p>
            <p className="text-themeGray-dark text-sm mt-3">
              rob.andrews@gmail.com
            </p>
            <p className="text-themeGray-dark text-sm mt-3">
              example@gmail.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
