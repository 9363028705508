import React, { useState } from "react";
import { Col, Row, Table } from "antd";
import { Container, FilterOptions } from "components/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faDownload,
  faEllipsisVertical,
  faEnvelope,
  faPrint,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { home_icon } from "assets/icons/icons";
import { useParams } from "react-router-dom";

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};
const FinanceDetailedReport = () => {
  const { type } = useParams();
  const [selectionType, setSelectionType] = useState("checkbox");

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 60,
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",

      render: (text) => <div className="font-bold">{text}</div>,
    },

    {
      title: "Invoices",
      dataIndex: "invoices",
      key: "invoices",
    },
    {
      title: "Sum without tax",
      dataIndex: "sumWithoutTax",
      key: "sumWithoutTax",
    },
    {
      title: "Outstanding",
      dataIndex: "outstanding",
      key: "outstanding",
    },
  ];
  const data = [
    {
      key: "1",
      number: "1",
      client: "client1",
      invoices: "11 invoices",
      sumWithoutTax: "179.30,00",
      outstanding: "50.730,00",
    },
    {
      key: "2",
      number: "2",
      client: "client1",
      invoices: "11 invoices",
      sumWithoutTax: "179.30,00",
      outstanding: "50.730,00",
    },
    {
      key: "3",
      number: "3",
      client: "client1",
      invoices: "11 invoices",
      sumWithoutTax: "179.30,00",
      outstanding: "50.730,00",
    },
  ];
  const getTotal = (data, dataIndex) => {
    return data.reduce((total, item) => {
      const value = parseFloat(
        item[dataIndex].replace(",", "").replace(".", "").replace("€", "")
      );
      return total + value;
    }, 0);
  };

  const totalRow = {
    number: "Total",
    client: "",
    invoices: "26 Invoices",
    sumWithoutTax: getTotal(data, "sumWithoutTax").toLocaleString(),
    outstanding: getTotal(data, "outstanding").toLocaleString(),
  };
  return (
    <Container superAdmin={type === "backend" && true} active={"Reports"}>
      <div className="w-full overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
        <div className="bg-white" style={{ top: 54 }}>
          <div className="flex items-center justify-between px-5 py-3">
            <div className="flex items-center">
              <p className="font-medium text-sm">Company</p>
              <img src={home_icon} alt="" className="mr-3 ml-8" />
              <p className="font-medium text-sm">
                Home
                <span className="text-bgBlue"> / </span>
                <a href="#" className="text-bgBlue">
                  Report
                </a>
                <span className="text-bgBlue"> / </span>
                <a href="#" className="text-bgBlue">
                  Default
                </a>
              </p>
            </div>
            <div className="text-end pe-5">
              <div className="flex items-center">
                <div className="me-4">
                  <FontAwesomeIcon icon={faArrowsRotate} />
                </div>
                <p className="p-0 m-0 text-gray-700">
                  Data refreshed at jul 18, 2023 04:06 PM
                </p>
                <div className="flex ">
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faEnvelope} className="fs-1" />
                  </div>
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faDownload} />
                  </div>
                  <div className="ms-4">
                    <FontAwesomeIcon icon={faPrint} />
                  </div>
                </div>
                <FontAwesomeIcon
                  icon={faEllipsisVertical}
                  className="fs-1 mx-4"
                />
                <FontAwesomeIcon icon={faXmark} className="fs-1" />
              </div>
            </div>
          </div>
          <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
        </div>
        <FilterOptions onTop />
        <div className="mt-7 mb-5">
          <div className="px-4">
            <div className="font-bold text-xl mb-2">Detailed report</div>
            <section className="summery-sec border shadow-lg rounded-md">
              <div>
                <Row className="border-b py-4">
                  <Col
                    span={12}
                    sm={12}
                    md={8}
                    lg={6}
                    className="text-center my-2"
                  >
                    <p>Sum (Incl tax)</p>
                    <strong className="text-lg">
                      70.650,00 <span className="text-sm">EUR</span>
                    </strong>
                  </Col>
                  <Col
                    span={12}
                    sm={12}
                    md={8}
                    lg={6}
                    className="text-center my-2"
                  >
                    <p>Sum (Incl tax)</p>
                    <strong className="text-lg">
                      70.650,00 <span className="text-sm">EUR</span>
                    </strong>
                  </Col>
                  <Col
                    span={12}
                    sm={12}
                    md={8}
                    lg={6}
                    className="text-center my-2"
                  >
                    <p>Sum (Incl tax)</p>
                    <strong className="text-lg">
                      70.650,00 <span className="text-sm">EUR</span>
                    </strong>
                  </Col>
                  <Col
                    span={12}
                    sm={12}
                    md={8}
                    lg={6}
                    className="text-center my-2"
                  >
                    <p>Sum (Incl tax)</p>
                    <strong className="text-lg">
                      70.650,00 <span className="text-sm">EUR</span>
                    </strong>
                  </Col>
                </Row>
              </div>
              <div className="bg-white rounded-md p-3 h-[380px] overflow-auto">
                <Table
                  columns={columns}
                  dataSource={[...data, totalRow]}
                  pagination={false}
                  rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                  }}
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default FinanceDetailedReport;
