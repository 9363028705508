const currentDate = new Date();
const GanttTasks = [
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 30),
    name: "Project 1",
    id: "project_1",
    progress: 90,
    type: "project",
    hideChildren: false,
    styles: {
      progressColor: "#46ad51",
      progressSelectedColor: "#46ad51",
      backgroundColor: "#65c16f",
      backgroundSelectedColor: "#65c16f",
    },
    displayOrder: 1,
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 30),
    name: "To Do",
    id: "todo",
    progress: 30,
    type: "project",
    hideChildren: false,
    project: "project_1",
    displayOrder: 2,
    styles: {
      progressColor: "#46ad51",
      progressSelectedColor: "#46ad51",
      backgroundColor: "#65c16f",
      backgroundSelectedColor: "#65c16f",
    },
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 23),
    name: "Learning",
    id: "Task 1",
    progress: 25,
    dependencies: ["Task 0"],
    type: "task",
    project: "todo",
    displayOrder: 3,
    styles: {
      progressColor: "#299cb4",
      progressSelectedColor: "#299cb4",
      backgroundColor: "#3db9d3",
      backgroundSelectedColor: "#3db9d3",
    },
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 30),
    name: "Doing",
    id: "doing",
    progress: 50,
    type: "project",
    hideChildren: false,
    displayOrder: 4,
    styles: {
      progressColor: "#46ad51",
      progressSelectedColor: "#46ad51",
      backgroundColor: "#65c16f",
      backgroundSelectedColor: "#65c16f",
    },
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 23),
    name: "Design",
    id: "Task 2",
    progress: 15,
    dependencies: ["Task 1"],
    type: "task",
    project: "doing",
    displayOrder: 5,
    styles: {
      progressColor: "#299cb4",
      progressSelectedColor: "#299cb4",
      backgroundColor: "#3db9d3",
      backgroundSelectedColor: "#3db9d3",
    },
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 23),
    name: "Development",
    id: "Task 3",
    progress: 35,
    dependencies: ["Task 2"],
    type: "task",
    project: "doing",
    displayOrder: 6,
    styles: {
      progressColor: "#299cb4",
      progressSelectedColor: "#299cb4",
      backgroundColor: "#3db9d3",
      backgroundSelectedColor: "#3db9d3",
    },
  },
];

const GanttProjectTasks = [
  {
    start: new Date(2023, 3, 12),
    end: new Date(2023, 3, 15),
    name: "Support lead",
    id: "Task 1",
    progress: 80,
    type: "task",
    displayOrder: 1,
    styles: {
      progressColor: "#299cb4",
      progressSelectedColor: "#299cb4",
      backgroundColor: "#3db9d3",
      backgroundSelectedColor: "#3db9d3",
    },
  },
  {
    start: new Date(2023, 3, 13),
    end: new Date(2023, 3, 16),
    name: "Support lead",
    id: "Task 2",
    progress: 60,
    type: "task",
    displayOrder: 2,
    styles: {
      progressColor: "#299cb4",
      progressSelectedColor: "#299cb4",
      backgroundColor: "#3db9d3",
      backgroundSelectedColor: "#3db9d3",
    },
  },
];

export { GanttTasks, GanttProjectTasks };
