import {
  attachment,
  check_outlined_icon,
  message_black_icon,
  profile_icon,
  submenu_icon,
} from "assets/icons/icons";
import { Progress, Dropdown } from "antd";

const items = [
  {
    key: "1",
    label: (
      <div className="w-44">
        <a href="#">Move</a>
      </div>
    ),
  },
  {
    key: "2",
    label: (
      <div>
        <a href="#">Copy</a>
      </div>
    ),
  },
  {
    key: "3",
    label: (
      <div>
        <a href="#">Archive</a>
      </div>
    ),
  },
  {
    key: "4",
    label: (
      <div>
        <a href="#">Share</a>
      </div>
    ),
  },
  {
    key: "5",
    label: (
      <div>
        <a href="#">Delete</a>
      </div>
    ),
  },
];

const ProjectCard = ({ title, onClick, statusColor }) => {
  return (
    <div
      onClick={onClick}
      className="flex rounded-tl-lg rounded-bl-lg overflow-hidden shadow-lg cursor-pointer"
    >
      <div className={`${statusColor} w-1`}></div>
      <div className="bg-white p-3 w-full">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img src={check_outlined_icon} alt="" />
            <p className="text-sm ml-1">{title}</p>
          </div>
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
            placement="bottomLeft"
          >
            <img src={submenu_icon} alt="" className="ml-3" />
          </Dropdown>
        </div>
        <div className="flex items-center mt-4">
          <img src={profile_icon} alt="" />
          <p className="text-sm ml-4">Ongoing</p>
        </div>
        <div className="flex items-center">
          <Progress percent={100} className="mt-2" />
          <img src={message_black_icon} alt="" />
          <img src={attachment} alt="" className="ml-3" />
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
