const TasksData = [
  {
    title: "Today",
    data: [
      {
        name: "To do",
        title: "Ut voluptatem ratione hic fugit.",
        status: "IMPORTANT",
      },
      {
        name: "In progress",
        title: "Ut voluptatem ratione hic fugit.",
        status: "NEW",
      },
      {
        name: "Code review",
        title: "Ut voluptatem ratione hic fugit.",
        status: "LOW",
      },
    ],
  },
  {
    title: "Yesterday",
    data: [
      {
        name: "To do",
        title: "Ut voluptatem ratione hic fugit.",
        status: "IMPORTANT",
      },
      {
        name: "Code review",
        title: "Ut voluptatem ratione hic fugit.",
        status: "LOW",
      },
    ],
  },
];

export default TasksData;
