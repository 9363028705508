import { Tabs } from "antd";
import EnableModules from "./enableModules/enableModules";
import Integrations from "./integrations/integrations";
import Security from "./security/security";

const Account = () => {
  const items = [
    {
      key: "1",
      label: `Enabled Modules`,
      children: <EnableModules />,
    },
    {
      key: "2",
      label: `Integrations`,
      children: <Integrations />,
    },
    {
      key: "3",
      label: `Security Settings`,
      children: <Security />,
    },
  ];
  return (
    <div className="ml-2">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default Account;
