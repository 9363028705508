import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "antd";
import React from "react";

const columns = [
  {
    title: "Issue date",
    dataIndex: "issueDate",
    key: "issueDate",
  },
  {
    title: "No.",
    dataIndex: "no",
    key: "no",
  },
  {
    title: "Payer | Project",
    dataIndex: "payerProject",
    key: "payerProject",
    render: (text) => (
      <div className="flex">
        <FontAwesomeIcon
          icon={faFileInvoice}
          color="#969fae"
          className="mr-2 mt-1"
        />
        {text ? (
          <div>
            <strong>Client C</strong>
            <p>Coaching workshop (example)</p>
          </div>
        ) : (
          ""
        )}
      </div>
    ),
  },
  {
    title: "Sum EUR",
    dataIndex: "sumEUR",
    key: "sumEUR",
  },
  {
    title: "Outstanding",
    dataIndex: "Outstanding",
    key: "Outstanding",
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    render: (text) => (
      <div className="bg-red-600 text-white px-1 rounded-md">{text}</div>
    ),
  },
  {
    title: "Due date",
    dataIndex: "dueDate",
    key: "dueDate",
  },
  {
    title: "Overdue",
    dataIndex: "Overdue",
    key: "Overdue",
  },
];
const data = [
  {
    issueDate: "04/07",
    payerProject: "Emily Pearson",
    sumEUR: "8.000,00",
    no: "51",
    Status: "05/08",
    Outstanding: "1.500,00",
    dueDate: "05/08",
    Overdue: "9 days",
  },
  {
    issueDate: "04/07",
    payerProject: "Emily Pearson",
    sumEUR: "8.000,00",
    no: "51",
    Status: "05/08",
    Outstanding: "1.500,00",
    dueDate: "05/08",
    Overdue: "9 days",
  },
];

const OverdueInvoices = () => {
  return (
    <div className="shadow bg-white rounded-md p-3 h-[380px] overflow-auto scrol">
      <span class="mb-2 text-[12px] text-[#000] font-medium">
        Overdue invoices
      </span>
      <h5 className="text-sky-500	text-lg mt-4">July 2023</h5>
      <Table columns={columns} dataSource={data} pagination={false} />

      <h5 className="text-sky-500	text-lg mt-6">Aug 2023</h5>
      <Table columns={columns} dataSource={data} pagination={false} />

      <h5 className="text-sky-500	text-lg mt-6">Sep 2023</h5>
      <Table columns={columns} dataSource={data} pagination={false} />
    </div>
  );
};

export default OverdueInvoices;
