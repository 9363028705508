import { useState } from "react";
import { Button, Modal, Upload } from "antd";
import { FileTextTwoTone } from "@ant-design/icons";
import { Switch } from "@mui/material";
import { PDF_file_icon } from "assets/icons/icons";
import { ThemeSelect } from "components/components";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const UploadFiles = ({ setSteps, setFirst }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  return (
    <>
      <p className="font-semibold">Upload Files</p>
      <p className="text-themeGray-medium300 text-sm">
        If you need more info, please check{" "}
        <span className="text-primary cursor-pointer">Project Guidelines</span>
      </p>
      <Upload
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        className="cursor-pointer w-full flex items-start mt-5 border-dashed border-primary bg-blue-50 border-2 rounded-md p-4"
      >
        <div className="flex items-start">
          <FileTextTwoTone className="text-xl" />
          <div className="ml-3">
            <p className="font-semibold">Drag files here or click to upload.</p>
            <p className="text-sm text-themeGray-medium300 mt-1">
              Upload up to 10 files
            </p>
          </div>
        </div>
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
      <div className="mt-5">
        <p>Uploaded Files</p>
        <div className="h-80 overflow-y-scroll scrol">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, i) => {
            return (
              <div
                key={i}
                className="flex items-center justify-between border-dashed border-b-[1px] border-[rgba(0,0,0,.1)] py-3"
              >
                <div className="flex items-center">
                  <img src={PDF_file_icon} width={30} />
                  <div className="ml-3">
                    <p className="text-sm">User CRUD Styles</p>
                    <p className="text-themeGray-medium300 text-xs">85kb</p>
                  </div>
                </div>
                <div className="w-32">
                  <ThemeSelect label={"Edit"} data={["Pdf", "JPG", "PNG"]} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex mt-5 items-center justify-between">
        <div>
          <p>Allow Changes in Budget</p>
          <p className="text-themeGray-medium300 text-sm">
            If you need more info, please check budget plannning
          </p>
        </div>
        <div className="flex items-center">
          <Switch />
          <p>Allowed</p>
        </div>
      </div>
      <div className="flex items-center justify-between mt-5">
        <Button onClick={setFirst}>Set First Target</Button>
        <Button onClick={setSteps} type="primary">
          Complete
        </Button>
      </div>
    </>
  );
};

export default UploadFiles;
