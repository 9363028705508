import { useState, useEffect } from "react";
import { Container } from "components/components";
import { cross_icon, download_icon, print_icon } from "assets/icons/icons";
import OverView from "./overView/overView";

const Tasks = () => {
  const [data, setData] = useState([]);
  const [detailsModal, setDetailsModal] = useState("detailsModalClose");
  const handleCompanyDetails = () => {
    setDetailsModal("detailsModalOpen");
  };
  useEffect(() => {
    let update = [...data];
    for (let i = 0; i < 15; i++) {
      update.push({
        key: i,
        title: "Fix permission issues that the 0.7 - alpha2 has introduced",
        startDate: "Aug 25",
        endDate: "Sep 05",
      });
      setData(update);
    }
  }, []);
  return (
    <Container client active={"Tasks"}>
      <div className="flex">
        <div className="w-full overflow-y-scroll h-screen scrol">
          <div className="bg-white" style={{ top: 54 }}>
            <div className="flex items-center px-5 py-3">
              <p className="font-semibold">Tasks</p>
            </div>
            <div className="border-t-2 border-[rgba(0,0,0,.1)]"></div>
          </div>
          {data.map((e, i) => {
            return (
              <div
                onClick={handleCompanyDetails}
                key={i}
                className="flex px-5 items-center justify-between border-b-2 border-[rgba(0,0,0,.2)] h-16 cursor-pointer hover:bg-themeGray-light"
              >
                <p className="text-sm">{e.title}</p>
                <p className="text-themeGray-medium300 text-xs">
                  {e.startDate} - {e.endDate}
                </p>
              </div>
            );
          })}
        </div>

        <div
          className={`bg-white ${detailsModal} overflow-y-scroll h-screen scrol`}
        >
          {detailsModal === "detailsModalOpen" && (
            <>
              <div className="flex z-[5] items-center justify-between w-full pr-5 shadow-md border-b-2 border-[rgba(0,0,0,.2)] bg-white sticky top-0">
                <div className="h-11 items-center w-[432px] flex">
                  <div className="pl-3">
                    <p className="font-semibold">
                      #23: Support tickets list doesn't support commas
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <img
                    src={cross_icon}
                    alt=""
                    className="cursor-pointer opacity-70"
                    width={16}
                    onClick={() => setDetailsModal("detailsModalClose")}
                  />
                </div>
              </div>
              <OverView />
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Tasks;
