import { useState } from "react";
import { ThemeTable, ThemeInput } from "components/components";
import { Button, Popconfirm } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";

const CreateExpense = () => {
  const [method, setMethod] = useState("");
  const [data, setData] = useState([]);
  const handleDelete = (key) => {
    const newData = data.filter((item) => item.key !== key);
    setData(newData);
  };
  const columns = [
    {
      title: "#",
      dataIndex: "id",
    },
    {
      title: "Expense Name",
      dataIndex: "expense_name",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) =>
        data.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <DeleteTwoTone className="text-lg" />
          </Popconfirm>
        ) : null,
    },
  ];
  const handleMethod = () => {
    if (method) {
      let update = [...data];
      update.push({
        id: data.length + 1,
        key: data.length + 1,
        expense_name: method,
        action: "",
      });
      setData(update);
      setMethod("");
    }
  };
  return (
    <div className="bg-themeGray-light200 p-3 mt-4 -ml-2 min-h-[73.8vh] desktop:h-[79vh] bigDesktop:h-[87vh]">
      <div className="bg-white p-3 shadow-md">
        <ThemeInput
          placeholder={"Expense name"}
          value={method}
          onChange={(e) => setMethod(e.target.value)}
        />
      </div>
      <Button type="primary" className="w-full mt-3" onClick={handleMethod}>
        Add new
      </Button>
      <div className="bg-white p-3 shadow-md mt-3">
        <ThemeTable columns={columns} noSelectRows data={data} noPagination />
      </div>
    </div>
  );
};

export default CreateExpense;
