import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const ThemeAutoComplete = ({ label, data }) => {
  const defaultProps = {
    options: data,
    getOptionLabel: (option) => option,
  };
  return (
    <Autocomplete
      className="mt-2"
      {...defaultProps}
      id="clear-on-escape"
      clearOnEscape
      renderInput={(params) => (
        <TextField {...params} label={label} variant="standard" />
      )}
    />
  );
};

export default ThemeAutoComplete;
