import { ThemeAutoComplete, ThemeSelect } from "components/components";
import React, { useState } from "react";
import { Radio } from "antd";
import { Switch } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faQuoteRight } from "@fortawesome/free-solid-svg-icons";

const Settings = () => {
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        <ThemeAutoComplete
          label={"Currency"}
          data={["Albanian Lek", "Algerian Dinar", "Angolan Kwanza"]}
        />
        <p className="text-xs mt-3">Current Format</p>
        <Radio.Group onChange={onChange} value={value} className="mt-2">
          <Radio value={1}>Symbol: $1,000.00</Radio>
          <Radio value={2}>Code: 1,000,00 USD</Radio>
        </Radio.Group>
      </div>
      <div className="bg-white p-3 shadow-md mt-3">
        <ThemeAutoComplete
          label={"Timezone"}
          data={["Africa/Cairo", "Africa/Casablanca", "Africa/Harare"]}
        />
        <ThemeAutoComplete
          label={"Date Formate"}
          data={["01/31/2000", "2000/01/31", "31-01-2000"]}
        />
        <div className="flex items-center justify-between mt-5">
          <div className="flex items-center">
            <FontAwesomeIcon
              className="opacity-50 mr-5 text-xl"
              icon={faClock}
            />
            <div>
              <p>Military Time</p>
              <p className="text-themeGray-dark text-xs">24 Hour Disply</p>
            </div>
          </div>
          <Switch />
        </div>
        <div className="flex items-center justify-between mt-5">
          <div className="flex items-center">
            <FontAwesomeIcon
              className="opacity-50 mr-5 text-xl"
              icon={faQuoteRight}
            />
            <div>
              <p>Decimal Comma</p>
              <p className="text-themeGray-dark text-xs">
                Use comma as decimal place in forms
              </p>
            </div>
          </div>
          <Switch />
        </div>
        <ThemeSelect
          label={"Month"}
          className={"!mt-2"}
          data={["January", "February", "March"]}
        />
      </div>
    </div>
  );
};

export default Settings;
