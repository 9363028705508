import { Checkbox } from "@mui/material";

const EnableModules = () => {
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        <div className="flex items-center">
          <Checkbox />
          <p className="ml-1">Invoices</p>
        </div>
        <div className="flex items-center">
          <Checkbox />
          <p className="ml-1">Recurring Invoices</p>
        </div>
        <div className="flex items-center">
          <Checkbox />
          <p className="ml-1">Quotes</p>
        </div>
        <div className="flex items-center">
          <Checkbox />
          <p className="ml-1">Credits</p>
        </div>
        <div className="flex items-center">
          <Checkbox />
          <p className="ml-1">Projects</p>
        </div>
        <div className="flex items-center">
          <Checkbox />
          <p className="ml-1">Tasks</p>
        </div>
        <div className="flex items-center">
          <Checkbox />
          <p className="ml-1">Vendors</p>
        </div>
        <div className="flex items-center">
          <Checkbox />
          <p className="ml-1">Purchase Orders</p>
        </div>
        <div className="flex items-center">
          <Checkbox />
          <p className="ml-1">Expenses</p>
        </div>
        <div className="flex items-center">
          <Checkbox />
          <p className="ml-1">Recurring Expenses</p>
        </div>
        <div className="flex items-center">
          <Checkbox />
          <p className="ml-1">Transactions</p>
        </div>
      </div>
    </div>
  );
};

export default EnableModules;
