import { useState } from "react";
import { Tabs } from "antd";
import Industry from "./industry/industry";
import FreeTrial from "./freeTrial/freeTrial";

const App = () => {
  const [industryData, setIndustryData] = useState([
    {
      key: "1",
      id: "1",
      tax_name: "Media",
    },
    {
      key: "2",
      id: "2",
      tax_name: "Metal",
    },
    {
      key: "3",
      id: "3",
      tax_name: "Accountent",
    },
    {
      key: "4",
      id: "4",
      tax_name: "Marketing",
    },
  ]);
  const [sizeData, setSizeData] = useState([
    {
      key: "1",
      id: "1",
      tax_name: "1-5",
    },
    {
      key: "2",
      id: "2",
      tax_name: "5-10",
    },
    {
      key: "3",
      id: "3",
      tax_name: "10-15",
    },
    {
      key: "4",
      id: "4",
      tax_name: "15-20",
    },
  ]);
  const items = [
    {
      key: "1",
      label: `Industry`,
      children: <Industry data={industryData} setData={setIndustryData} />,
    },
    {
      key: "2",
      label: `Sector`,
      children: <Industry data={industryData} setData={setIndustryData} />,
    },
    {
      key: "3",
      label: `Size`,
      children: <Industry data={sizeData} setData={setSizeData} />,
    },
    // {
    //   key: "4",
    //   label: `Payments`,
    //   children: "",
    // },
    {
      key: "5",
      label: `Free trial of`,
      children: <FreeTrial />,
    },
  ];
  return (
    <div className="ml-2">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default App;
