import { submenu_icon } from "assets/icons/icons";
import { profile_pic } from "assets/images/images";
import { Dropdown, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { ContactView } from "components/components";

const ContactPanel = ({ onClick, onProfile }) => {
  const items = [
    {
      key: "1",
      label: <p onClick={onProfile}>Profile</p>,
    },
    {
      key: "2",
      label: <p>Logout</p>,
    },
  ];
  return (
    <>
      <div className="border-b-2 border-[rgba(0,0,0,.2)]">
        <div className="p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="relative">
                <img src={profile_pic} alt="" width={45} />
                <div className="h-3 w-3 absolute right-0 bottom-0 bg-green-500 border-[1px] border-white rounded-full"></div>
              </div>
              <p className="ml-3 text-sm font-medium">Brian Hughes</p>
            </div>
            <Dropdown
              menu={{
                items,
              }}
              placement="bottomLeft"
              trigger={["click"]}
            >
              <img
                src={submenu_icon}
                width={4}
                alt=""
                className="cursor-pointer"
              />
            </Dropdown>
          </div>
          <Input
            className="h-10 !rounded-[5px] px-4 mt-4"
            prefix={
              <FontAwesomeIcon className="mr-2" icon={faMagnifyingGlass} />
            }
            placeholder="Search or start new chat"
          />
        </div>
      </div>
      <div>
        <p className="text-lg font-semibold px-4 py-5">Chats</p>
        {[1, 2, 3, 4, 5].map((e, i) => {
          return <ContactView onClick={onClick} key={i} isChat />;
        })}
        <p className="text-lg font-semibold px-4 py-5">Contacts</p>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((e, i) => {
          return <ContactView onClick={onClick} key={i} />;
        })}
      </div>
    </>
  );
};

export default ContactPanel;
