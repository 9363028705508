const ClientQuotes = [
  {
    key: 1,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Accept",
  },
  {
    key: 4,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "New",
  },
  {
    key: 5,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Decline",
  },
  {
    key: 6,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Waiting",
  },
  {
    key: 7,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Accept",
  },
  {
    key: 8,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "New",
  },
  {
    key: 9,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Decline",
  },
  {
    key: 10,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Waiting",
  },
  {
    key: 11,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Accept",
  },
  {
    key: 12,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "New",
  },
  {
    key: 13,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Decline",
  },
  {
    key: 14,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Waiting",
  },
  {
    key: 15,
    id: `0014`,
    invoice: "ABC Invoice",
    amount: "$2000",
    status: "Accept",
  },
];

export default ClientQuotes;
