import { Tabs } from "antd";
import ActivityTabs from "./activityTabs/activityTabs";
import Status from "./status/status";

const Task = () => {
  const items = [
    {
      key: "1",
      label: `Activity types`,
      children: <ActivityTabs />,
    },
    {
      key: "2",
      label: `Status`,
      children: <Status />,
    },
    {
      key: "3",
      label: `Priority`,
      children: <Status name={"Priority"} />,
    },
  ];
  return (
    <div className="ml-2">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default Task;
