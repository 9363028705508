import React, { useState } from "react";
import { ThemeTable } from "components/components";
import { Button, Popconfirm, Input } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";

const ProjectCategory = () => {
  const [data, setData] = useState([
    {
      key: "1",
      id: "1",
      category_name: "Laravel",
    },
    {
      key: "2",
      id: "2",
      category_name: "Vuejs",
    },
    {
      key: "3",
      id: "3",
      category_name: "React",
    },
    {
      key: "4",
      id: "4",
      category_name: "Zend",
    },
    {
      key: "5",
      id: "5",
      category_name: "CakePhp",
    },
  ]);
  const handleDelete = (key) => {
    const newData = data.filter((item) => item.key !== key);
    setData(newData);
  };
  const columns = [
    {
      title: "#",
      dataIndex: "id",
    },
    {
      title: "Category Name",
      dataIndex: "category_name",
      render: (text, record, ind) => (
        <Input
          value={text}
          onChange={(e) => {
            let update = [...data];
            update.splice(ind, 1, {
              key: record.key,
              id: record.id,
              category_name: e.target.value,
            });
            setData(update);
          }}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) =>
        data.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <DeleteTwoTone className="text-lg" />
          </Popconfirm>
        ) : null,
    },
  ];
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        <ThemeTable noSelectRows columns={columns} data={data} noPagination />
        <Button
          onClick={() => {
            let update = [...data];
            update.push({
              key: data.length + 1,
              id: data.length + 1,
              category_name: "",
            });
            setData(update);
          }}
          type="primary"
          className="h-9 w-full mt-3"
        >
          Add Category
        </Button>
      </div>
    </div>
  );
};

export default ProjectCategory;
