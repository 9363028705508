import {
  faBoxArchive,
  faCircleStop,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch } from "@mui/material";
import { ThemeSelect } from "components/components";

const Invoices = () => {
  return (
    <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 min-h-[70vh] desktop:h-[76vh] bigDesktop:h-[83vh]">
      <div className="bg-white p-3 shadow-md">
        <div className="flex items-center justify-between mt-2">
          <div className="flex items-center">
            <FontAwesomeIcon
              className="opacity-50 mr-5 text-xl"
              icon={faEnvelope}
            />
            <div>
              <p>Auto Email</p>
              <p className="text-themeGray-dark text-xs">
                Automatically email recurring invoices when they are created
              </p>
            </div>
          </div>
          <Switch defaultChecked />
        </div>
        <div className="flex items-center justify-between mt-5">
          <div className="flex items-center">
            <FontAwesomeIcon
              className="opacity-50 mr-5 text-xl"
              icon={faCircleStop}
            />
            <div>
              <p>Stop On Unpaid</p>
              <p className="text-themeGray-dark text-xs">
                Stop creating recurring invoices if the last invoice is unpaid
              </p>
            </div>
          </div>
          <Switch />
        </div>
        <div className="flex items-center justify-between mt-5">
          <div className="flex items-center">
            <FontAwesomeIcon
              className="opacity-50 mr-5 text-xl"
              icon={faBoxArchive}
            />
            <div>
              <p>Auto Archive Paid</p>
              <p className="text-themeGray-dark text-xs">Auto Archive Paid</p>
            </div>
          </div>
          <Switch />
        </div>
        <div className="flex items-center justify-between my-5">
          <div className="flex items-center">
            <FontAwesomeIcon
              className="opacity-50 mr-5 text-xl"
              icon={faBoxArchive}
            />
            <div>
              <p>Auto Archive Cancelled</p>
              <p className="text-themeGray-dark text-xs">
                Automatically archive invoices when they are Cancelled
              </p>
            </div>
          </div>
          <Switch />
        </div>
        <ThemeSelect
          label={"Select"}
          data={["All", "When Sent", "When Paid"]}
        />
      </div>
    </div>
  );
};

export default Invoices;
