import ContactPersonDesktop from "./contactPersonDesktop/contactPersonDesktop";
import ContactPersonMobile from "./contactPersonMobile/contactPersonMobile";

const ContactPerson = () => {
  return (
    <>
      <div className="hidden xl:block">
        <ContactPersonDesktop />
      </div>
      <div className="block xl:hidden">
        <ContactPersonMobile />
      </div>
    </>
  );
};

export default ContactPerson;
