import { ThemeProvider, createTheme } from "@mui/material";
import { ThemeInput } from "components/components";
import Inter from "assets/font/Inter.ttf";
import { Button } from "antd";

const theme = createTheme({
  typography: {
    fontFamily: Inter,
  },
  palette: {
    primary: {
      main: "#00A1E4",
    },
  },
});

const BasicInfo = ({ className }) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2">
        <div className="bg-white px-3 pb-3 pt-2 my-2  shadow-md">
          <ThemeInput placeholder={"Company Name"} />
          <ThemeInput placeholder={"Email"} />
          <ThemeInput placeholder={"Address"} />
        </div>
        <div className="bg-white px-3 pb-3 pt-2 my-2  shadow-md">
          <ThemeInput placeholder={"City"} />
          <ThemeInput placeholder={"Postal Code"} />
          <ThemeInput placeholder={"Province / state"} />
          <ThemeInput placeholder={"Country"} />
          <ThemeInput placeholder={"Website"} />
          <ThemeInput placeholder={"Phone"} />
          <ThemeInput placeholder={"Fax Machine"} />
        </div>
        <div className="bg-white px-3 pb-3 pt-2 my-2  shadow-md">
          <ThemeInput placeholder={"Industry"} />
          <ThemeInput placeholder={"Sector"} />
          <ThemeInput placeholder={"Size"} />
          <ThemeInput placeholder={"Chamber of Commerce number"} />
          <ThemeInput placeholder={"VAT number"} />
        </div>
      </div>
    </ThemeProvider>
  );
};

const ContactPerson = ({ className }) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="bg-themeGray-light200 p-3 -mt-4 -ml-2 h-[66.7vh] desktop:h-[74vh] bigDesktop:h-[78vh]">
        <div className="bg-white px-3 pb-3 pt-2 my-2  shadow-md">
          <ThemeInput placeholder={"First Name"} />
          <ThemeInput placeholder={"Last Name"} />
          <ThemeInput placeholder={"Phone"} />
          <ThemeInput placeholder={"Email"} />
        </div>
        <Button className="w-full mt-2 mb-1" type="primary">
          Add new
        </Button>
      </div>
    </ThemeProvider>
  );
};

export { BasicInfo, ContactPerson };
