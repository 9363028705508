import ProjectsDesktop from "./projectsDesktop/projectsDesktop";
import ProjectsMobile from "./projectsMobile/projectsMobile";

const Projects = () => {
  return (
    <>
      <div className="hidden xl:block">
        <ProjectsDesktop />
      </div>
      <div className="block xl:hidden">
        <ProjectsMobile />
      </div>
    </>
  );
};

export default Projects;
