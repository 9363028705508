import { useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import { Container, ThemeTable } from "components/components";
import CategoryModal from "./categoryModal/categoryModal";
import Details from "./details/details";
import { cross_icon } from "assets/icons/icons";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";

const AdminFaq = () => {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("add");
  const [addModal, setAddModal] = useState("detailsModalClose");
  const [selected, setSelected] = useState("");
  const filterData = data.filter((e) => {
    if (e.title === selected || selected === "") return e;
  });
  const [isCategoryModal, setIsCategoryModal] = useState(false);
  const handleDelete = (index) => {
    let update = [...data];
    update.splice(index, 1);
    setData(update);
  };
  const addFaq = () => {
    setAddModal("detailsModalOpen");
    setType("add");
    setTitle("");
    setDescription("");
    setId("");
    setCategory("");
  };
  const handleCompanyDetails = (record, index) => {
    setTitle(record.heading);
    setCategory(record.title);
    setId(index);
    setType("edit");
    setDescription(record.category);
    setAddModal("detailsModalOpen");
  };
  const handleClose = () => {
    setTitle("");
    setDescription("");
    setId("");
    setCategory("");
    setAddModal("detailsModalClose");
  };
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (text, record, index) => <p>{index + 1}</p>,
    },
    {
      title: "Article Heading",
      dataIndex: "heading",
    },
    {
      title: "Article Category",
      dataIndex: "title",
    },
    {
      title: "Article Description",
      dataIndex: "category",
      render: (text) => <p>{text.blocks[0].text}</p>,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record, index) => (
        <div>
          <DeleteTwoTone
            className="text-lg cursor-pointer"
            onClick={() => handleDelete(index)}
          />
          <EditTwoTone
            className="text-lg ml-5 cursor-pointer"
            onClick={() => handleCompanyDetails(record, index)}
          />
        </div>
      ),
    },
  ];
  return (
    <Container superAdmin active={"Admin FAQ"}>
      <CategoryModal
        categories={categories}
        setCategories={setCategories}
        isModalOpen={isCategoryModal}
        setIsModalOpen={setIsCategoryModal}
      />
      <div className="flex">
        <div className="w-full">
          <div className="flex">
            <div className="w-[20%] overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol">
              <div
                className={`${
                  selected === "" && "bg-gray-100"
                } p-5 cursor-pointer hover:bg-gray-100 border-b-[1px] border-[rgba(0,0,0,.1)]`}
                onClick={() => setSelected("")}
              >
                <p>All</p>
              </div>
              {categories.map((e, i) => {
                return (
                  <div
                    onClick={() => setSelected(e)}
                    key={i}
                    className={`${
                      selected === e && "bg-gray-100"
                    } p-5 cursor-pointer hover:bg-gray-100 border-b-[1px] border-[rgba(0,0,0,.1)]`}
                  >
                    <p>{e}</p>
                  </div>
                );
              })}
            </div>
            <div className="w-[80%] overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol px-5">
              <div className="my-5">
                <Button onClick={addFaq} type="primary" className="h-10">
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  Add New Admin FAQ
                </Button>
                <Button
                  onClick={() => setIsCategoryModal(true)}
                  className="h-10 ml-5"
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  Manage Category
                </Button>
              </div>
              <ThemeTable
                columns={columns}
                data={filterData}
                noPagination
                noSelectRows
              />
            </div>
          </div>
        </div>

        <div
          className={`bg-white ${addModal} overflow-y-scroll h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh] scrol`}
        >
          {addModal === "detailsModalOpen" && (
            <>
              <div className="flex h-11 z-10 items-center justify-between w-full px-5 shadow-md border-b-2 border-[rgba(0,0,0,.2)] bg-white sticky top-0">
                <p className="font-semibold">Create Admin FAQ</p>
                <div className="flex items-center">
                  <img
                    src={cross_icon}
                    alt=""
                    className="cursor-pointer opacity-70"
                    width={16}
                    onClick={handleClose}
                  />
                </div>
              </div>
              <Details
                categories={categories}
                data={data}
                setData={setData}
                title={title}
                setTitle={setTitle}
                category={category}
                setCategory={setCategory}
                description={description}
                setDescription={setDescription}
                setAddModal={setAddModal}
                id={id}
                type={type}
              />
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default AdminFaq;
