import { useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { ThemeInput, ThemeSelect } from "components/components";
import Inter from "assets/font/Inter.ttf";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Upload, Modal, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Switch from "@mui/material/Switch";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const theme = createTheme({
  typography: {
    fontFamily: Inter,
  },
  palette: {
    primary: {
      main: "#00A1E4",
    },
  },
});

const ExpenseInfo = ({ className }) => {
  const [categories, setCategories] = useState([1]);
  const [recurringInvoice, setRecurringInvoice] = useState("");
  const [isCustomRecurring, setIsCustomRecurring] = useState(false);
  const [taxes, setTaxes] = useState([1]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalTax, setIsModalTax] = useState(false);
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload Receipt
      </div>
    </div>
  );
  const handleCategories = () => {
    let update = [...categories];
    update.push(Math.random);
    setCategories(update);
    setIsModalVisible(false);
  };
  const handleTaxes = () => {
    let update = [...taxes];
    update.push(Math.random);
    setTaxes(update);
    setIsModalTax(false);
  };
  const handleCancelCategory = () => {
    setIsModalVisible(false);
  };
  const handleCancelTax = () => {
    setIsModalTax(false);
  };
  const recurringOptions = [
    "Every 1 month",
    "Every 2 months",
    "Every 3 months",
    "Every 4 months",
    "Every 5 months",
    "Every 6 months",
    "Every 7 months",
    "Every 8 months",
    "Every 9 months",
    "Every 10 months",
    "Every 11 months",
    "Every 12 months",
    "Custom",
  ];
  const handleBillingType = (e) => {
    setRecurringInvoice(e);
    e === "Custom" ? setIsCustomRecurring(true) : setIsCustomRecurring(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <Modal
        title="Add Expense Category"
        footer={false}
        open={isModalVisible}
        onCancel={handleCancelCategory}
      >
        <div className="flex justify-center">
          <Button
            onClick={handleCategories}
            type="primary"
            className="bg-primary h-10 w-52"
          >
            Add New
          </Button>
        </div>
      </Modal>
      <Modal
        title="Add Tax"
        footer={false}
        open={isModalTax}
        onCancel={handleCancelTax}
      >
        <div className="flex justify-center">
          <Button
            onClick={handleTaxes}
            type="primary"
            className="bg-primary h-10 w-52"
          >
            Add New
          </Button>
        </div>
      </Modal>
      <div className="bg-themeGray-light200 p-3 mt-2">
        <div className="bg-white px-3 pb-3 pt-2 my-2  shadow-md">
          <div className="-mb-3">
            <ThemeInput placeholder={"Name"} />
          </div>
          {categories.map((e, i) => {
            return (
              <div key={i} className="flex items-center justify-between">
                <ThemeSelect
                  width={"92%"}
                  className={"!mt-3"}
                  label={"Exspense Category*"}
                  data={["General", "Normal"]}
                />
                <div
                  onClick={() => setIsModalVisible(true)}
                  className="w-12 h-12 border-b-[1px] mt-[10px] items-center border-[rgba(0,0,0,.4)] flex justify-center"
                >
                  <PlusOutlined
                    style={{ color: "rgba(0,0,0,.6)" }}
                    className="cursor-pointer"
                  />
                </div>
              </div>
            );
          })}
          <div className="dateDiv mb-2">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="DD-MM-YYYY"
                label="Expense Date*"
                className="!w-full"
                slotProps={{ textField: { variant: "filled" } }}
              />
            </LocalizationProvider>
          </div>
          <div className="-mb-3">
            <ThemeInput placeholder={"Amount"} />
          </div>
          {taxes.map((e, i) => {
            return (
              <div key={i} className="flex items-center justify-between">
                <ThemeSelect
                  width={"92%"}
                  className={"!mt-3"}
                  label={"Tax*"}
                  data={["10%", "20%", "30%"]}
                />
                <div
                  onClick={() => setIsModalTax(true)}
                  className="w-12 h-12 border-b-[1px] mt-[10px] items-center border-[rgba(0,0,0,.4)] flex justify-center"
                >
                  <PlusOutlined
                    style={{ color: "rgba(0,0,0,.6)" }}
                    className="cursor-pointer"
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="bg-white px-3 pb-3 pt-2 my-2  shadow-md">
          <ThemeSelect
            value={recurringInvoice}
            setValue={(e) => handleBillingType(e)}
            className={"!mt-[10px]"}
            label={"Recurring Expense?"}
            data={recurringOptions}
          />
          {isCustomRecurring && (
            <>
              <ThemeInput noPadding={"!mt-1"} placeholder={"Number"} />
              <ThemeSelect
                label={"Duration"}
                className={"!mt-1"}
                data={["Day(s)", "Week(s)", "Month(s)", "Years(s)"]}
              />
            </>
          )}
          <ThemeInput placeholder={"Client"} />
          <ThemeInput placeholder={"Project"} />
          <ThemeInput placeholder={"Note"} multiline rows={4} />
        </div>
        <div className="bg-white px-3 pb-3 pt-2 my-2  shadow-md">
          <ThemeInput placeholder={"Client"} />
          <ThemeInput placeholder={"Payment Methode"} />
          <ThemeInput placeholder={"Reference"} />
          <ThemeSelect
            label={"Billable"}
            data={["Billable 1", "Billable 2", "Billable 3"]}
          />
        </div>
        <div className="bg-white px-3 pb-3 pt-2 my-2  shadow-md">
          <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            className="mt-4"
          >
            {fileList.length >= 8 ? null : uploadButton}
          </Upload>
        </div>
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img
            alt="example"
            style={{
              width: "100%",
            }}
            src={previewImage}
          />
        </Modal>
        <div className="bg-white px-3 pb-3 pt-2 my-2  shadow-md">
          <div className="flex items-center justify-between mt-3">
            <div>
              <p>Mark Paid</p>
              <p className="text-themeGray-dark text-sm">
                Track the expense has been paid to the vendor
              </p>
            </div>
            <Switch onChange={(e) => setShowOptions(e.target.checked)} />
          </div>
          {showOptions && (
            <>
              <ThemeInput placeholder={"Payment Type"} />
              <div className="dateDiv mb-1">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD-MM-YYYY"
                    label="Date"
                    className="!w-full"
                    slotProps={{ textField: { variant: "filled" } }}
                  />
                </LocalizationProvider>
              </div>
              <ThemeInput placeholder={"Transaction Reference"} />
            </>
          )}
          <div className="flex items-center justify-between mt-3">
            <div>
              <p>Add Document to Invoice</p>
              <p className="text-themeGray-dark text-sm">
                Make the documents visible to clients
              </p>
            </div>
            <Switch />
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export { ExpenseInfo };
