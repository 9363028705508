import React, { useState } from "react";
import classnames from "classnames";
import { Button } from "antd";
import { ThemeInput } from "components/components";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { home_icon } from "assets/icons/icons";
import { Container } from "components/components";
import { domy_profile } from "assets/images/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const EditAccount = () => {
  const { type } = useParams();
  const [percentage, setPercentage] = useState(10);
  const [popoverStyle, setPopoverStyle] = useState({ opacity: 0 });
  return (
    <Container
      superAdmin={type === "backend" ? true : false}
      client={type === "client" ? true : false}
      active={"Account"}
    >
      <div className="flex ps-4 pb-2">
        <div
          className={`w-full overflow-y-scroll ${
            type === "client"
              ? "h-screen"
              : "h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh]"
          } scrol`}
        >
          <div className="bg-white" style={{ top: 54 }}>
            <div className="flex items-center justify-between px-5 py-3">
              <div></div>
              <div className="flex items-center">
                <Button
                  type="primary"
                  className="bg-bgBlue mr-4 !text-sm p-0 font-medium"
                  style={{ borderRadius: 5, height: 27, width: 85 }}
                >
                  <Link to={"/profile/edit-profile"}>Edit Profile</Link>
                </Button>
              </div>
            </div>
            <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
          </div>

          <div className="flex flex-col sm:flex-row">
            <div className="sm:w-1/4 my-3" id="profile-card">
              <div
                className="p-4 rounded border shadow-lg w-full"
                style={{ height: "100vh" }}
              >
                <div className="relative rounded-lg w-1/2 mx-auto">
                  <img
                    className="rounded-lg"
                    width="100%"
                    src={domy_profile}
                    alt="Sunset in the mountains"
                  />
                  <div className="absolute top-36" style={{ right: "-10px" }}>
                    <div className="w-6 border border-white h-6 bg-green-500 rounded-full"></div>
                  </div>
                </div>
                <div className=" py-4 text-center">
                  <div className="font-bold text-xl mb-2">Syed Basit</div>
                  <p className="text-gray-700 text-base">Web Developer</p>
                  <Button
                    className="bg-gray-100 !text-sm px-2 text-center mt-2 p-0 font-medium"
                    style={{ borderRadius: 5, height: 27, width: 160 }}
                  >
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      className="w-4 h-4 mr-2"
                    />
                    Edit cover image
                  </Button>
                </div>
                <div className=" pt-4 pb-2">
                  <div className="flex items-center justify-between">
                    <h3 className="fw-500">Complete your Profile</h3>
                    <div>
                      <Button
                        className="bg-gray-100 mr-4 !text-sm px-2 text-center mt-2 p-0 font-medium"
                        style={{ borderRadius: 5, height: 27, width: 70 }}
                      >
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          className="w-4 h-4 mr-2"
                        />
                        Edit
                      </Button>
                    </div>
                  </div>
                  <div className="relative">
                    <div className="relative">
                      <div
                        className={classnames(
                          "absolute left-1/2 transform -mt-10 -translate-x-1/2 px-2 py-1 bg-gray-800 text-white text-xs rounded pointer-events-none",
                          popoverStyle
                        )}
                      >
                        {Math.round(percentage)}%
                      </div>
                    </div>
                    <div className="bg-gray-100 h-2 mt-12 rounded-full">
                      <div
                        className="h-full bg-blue-500 rounded-full"
                        style={{ width: `${percentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="fw-500 text-xl mb-2">Portfolio</div>
                  <div className="my-3">
                    <ThemeInput placeholder="Profile link" />
                    <ThemeInput placeholder="Profile link" />
                    <ThemeInput placeholder="Profile link" />
                  </div>
                </div>
              </div>
            </div>

            <div className="px-5 sm:w-3/4 mt-3" id="edit-profile-form">
              <div className="mb-6">
                <button className="p-1 px-2 !text-sm font-medium fw-500 rounded text-white  bg-gray-800">
                  Personal Details
                </button>
                <button className="bg-gray-100 ms-3 p-1 px-2 !text-sm font-medium fw-500 rounded ">
                  Change Password
                </button>
                <button className="bg-gray-100 ms-3 p-1 px-2 !text-sm font-medium fw-500 rounded ">
                  Security & Privacy
                </button>
              </div>
              <form className="p-4 rounded border shadow-lg w-full">
                <div className="grid md:grid-cols-2 md:gap-6">
                  <ThemeInput placeholder="First Name" />
                  <div className="relative z-0 w-full mb-6 group">
                    <ThemeInput placeholder="Last Name" />
                  </div>
                </div>
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="relative z-0 w-full mb-6 group">
                    <ThemeInput placeholder="Phone" />
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <ThemeInput placeholder="Email" />
                  </div>
                </div>
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="relative z-0 w-full mb-6 group">
                    <div className="dateDiv mt-4">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format="DD-MM-YYYY"
                          label="Birth Date*"
                          className="!w-full"
                          slotProps={{ textField: { variant: "filled" } }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="relative z-0 w-full mb-6 group mt-5">
                    <ThemeInput placeholder="Company" />
                  </div>
                </div>
                <div className="grid md:grid-cols-2 md:gap-6 mt-5">
                  <div className="relative z-0 w-full mb-6 group">
                    <ThemeInput placeholder="Designation" />
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <ThemeInput placeholder="Website" />
                  </div>
                </div>
                <div className="grid md:grid-cols-4 md:gap-6 mt-5">
                  <div className="relative z-0 w-full mb-6 group">
                    <ThemeInput placeholder="Address" />
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <ThemeInput placeholder="City" />
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <ThemeInput placeholder="Designation" />
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <ThemeInput placeholder="Designation" />
                  </div>
                </div>
                <div className="relative z-0 w-full mb-6 group mt-3">
                  <ThemeInput placeholder="Description" />
                </div>
                <div className="text-end">
                  <Button
                    type="primary"
                    className="bg-bgBlue mr-4 !text-sm p-0 font-medium"
                    style={{ borderRadius: 5, height: 27, width: 85 }}
                  >
                    Update
                  </Button>
                  <Button
                    type="danger"
                    className="mr-4 text-sm p-0 font-medium text-white"
                    style={{
                      borderRadius: 5,
                      height: 27,
                      width: 85,
                      background: "#ff4d4f",
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default EditAccount;
