import { Button } from "antd";
import { InputColorPicker } from "components/components";

const Device = ({ color, setColor }) => {
  return (
    <div className="bg-themeGray-light200 p-3 mt-4 min-h-[73.8vh] desktop:min-h-[79vh] bigDesktop:min-h-[87vh]">
      <div className="bg-white p-3 shadow-md">
        <InputColorPicker
          color={color?.header}
          setColor={(e) => setColor({ ...color, header: e })}
          label={"Header"}
        />
        <InputColorPicker
          color={color?.search}
          setColor={(e) => setColor({ ...color, search: e })}
          label={"Search"}
        />
        <InputColorPicker
          color={color?.menubar}
          setColor={(e) => setColor({ ...color, menubar: e })}
          label={"Menubar"}
        />
        <InputColorPicker
          color={color?.submenu}
          setColor={(e) => setColor({ ...color, submenu: e })}
          label={"Sub menu"}
        />
        <InputColorPicker
          top
          color={color?.footer}
          setColor={(e) => setColor({ ...color, footer: e })}
          label={"Footer"}
        />
        <InputColorPicker
          top
          color={color?.addnew}
          setColor={(e) => setColor({ ...color, addnew: e })}
          label={"Add new button"}
        />
        <InputColorPicker
          top
          color={color?.logout}
          setColor={(e) => setColor({ ...color, logout: e })}
          label={"Logout"}
        />
        <InputColorPicker
          top
          color={color?.timer}
          setColor={(e) => setColor({ ...color, timer: e })}
          label={"Timer button"}
        />
        <InputColorPicker
          top
          color={color?.save}
          setColor={(e) => setColor({ ...color, save: e })}
          label={"Save"}
        />
        <InputColorPicker
          top
          color={color?.cancel}
          setColor={(e) => setColor({ ...color, cancel: e })}
          label={"Cancel"}
        />
        <InputColorPicker
          top
          color={color?.headIco}
          setColor={(e) => setColor({ ...color, headIco: e })}
          label={"Header Icons"}
        />
        <InputColorPicker
          top
          color={color?.sideIco}
          setColor={(e) => setColor({ ...color, sideIco: e })}
          label={"Menubar Icons"}
        />
        <InputColorPicker
          top
          color={color?.submenuIco}
          setColor={(e) => setColor({ ...color, submenuIco: e })}
          label={"Sub Menu Icons"}
        />
        <InputColorPicker
          top
          color={color?.footIco}
          setColor={(e) => setColor({ ...color, footIco: e })}
          label={"Footer Icons"}
        />
      </div>
      <Button
        type="primary"
        className="w-full h-9 mt-4"
        onClick={() => {
          localStorage.removeItem("color");
          window.location.reload();
        }}
      >
        Reset Colors
      </Button>
    </div>
  );
};

export default Device;
