import React, { useState } from "react";
import { Button } from "antd";
import { Container } from "components/components";
import { Link } from "react-router-dom";
import ProfileCard from "./profileCard/profileCard";
import OverView from "./overView/overView";
import Security from "./security/security";
import TimeSheet from "./timeSheet/timeSheet";
import Projects from "./projects/projects";
import InviteFriends from "./inviteFriends/inviteFriends";
import { useParams } from "react-router-dom";

const Account = () => {
  const { type } = useParams();
  const [activeButton, setActiveButton] = useState("overview");
  const handleButtonClick = (sectionName) => {
    setActiveButton(sectionName);
  };
  return (
    <Container
      client={type === "client" ? true : false}
      superAdmin={type === "backend" ? true : false}
      active={"Account"}
    >
      <div className="flex ps-4">
        <div
          className={`w-full overflow-y-scroll ${
            type === "client"
              ? "h-screen"
              : "h-[81.5vh] desktop:h-[85.5vh] bigDesktop:h-[89.5vh]"
          } scrol`}
        >
          <div className="bg-white" style={{ top: 54 }}>
            <div className="flex items-center justify-between px-5 py-3">
              <div></div>
              <div className="flex items-center">
                <Button
                  type="primary"
                  className="bg-bgBlue mr-4 !text-sm p-0 font-medium"
                  style={{ borderRadius: 5, height: 27, width: 85 }}
                >
                  <Link to={`/${type}/account/edit`}>Edit Profile</Link>
                </Button>
              </div>
            </div>
            <div className="border-t-2 mb-3 border-[rgba(0,0,0,.1)]"></div>
          </div>
          <div className="flex flex-col sm:flex-row">
            <div className="sm:w-1/4 my-3" id="profile-card">
              <ProfileCard />
            </div>
            <div className="px-5 sm:w-3/4 mt-3" id="overView-detail">
              <div className="mb-6 flex justify-between items-center">
                <div className="">
                  <button
                    className={`p-1 px-2 !text-sm font-medium fw-500 rounded ${
                      activeButton === "overview"
                        ? "bg-gray-800 text-white"
                        : "bg-gray-100 text-gray-800"
                    }`}
                    onClick={() => handleButtonClick("overview")}
                  >
                    Overview
                  </button>
                  <button
                    className={`p-1 px-2 !text-sm font-medium fw-500 ms-3 rounded ${
                      activeButton === "security"
                        ? "bg-gray-800 text-white"
                        : "bg-gray-100 text-gray-800"
                    }`}
                    onClick={() => handleButtonClick("security")}
                  >
                    Security
                  </button>
                  {type !== "backend" && (
                    <button
                      className={`p-1 px-2 !text-sm font-medium fw-500 ms-3 rounded ${
                        activeButton === "Notifications"
                          ? "bg-gray-800 text-white"
                          : "bg-gray-100 text-gray-800"
                      }`}
                      onClick={() => handleButtonClick("Notifications")}
                    >
                      Notifications
                    </button>
                  )}
                </div>
              </div>
              {activeButton === "overview" && <OverView />}
              {activeButton === "security" && <Security />}
              {activeButton === "Notifications" && <InviteFriends />}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Account;
