import { Tabs } from "antd";
import Details from "./details/details";
import Notifications from "./notifications/notifications";

const UserDetails = () => {
  const items = [
    {
      key: "1",
      label: `Details`,
      children: <Details />,
    },
    {
      key: "2",
      label: `Notifications`,
      children: <Notifications />,
    },
  ];
  return (
    <div className="ml-2">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default UserDetails;
